/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import { ServiceClientOptions } from "@azure/ms-rest-js";
import * as msRest from "@azure/ms-rest-js";

/**
 * An interface representing BulkTakahiroWikiPageCategoryRequestCategory.
 */
export interface BulkTakahiroWikiPageCategoryRequestCategory {
  /**
   * カテゴリの名前です.
   */
  name?: string;
  /**
   * 親カテゴリの名前です.
   */
  parentName?: string;
}

/**
 * An interface representing ColumnNameListPerSheetsColumns.
 */
export interface ColumnNameListPerSheetsColumns {
  columns?: string[];
}

/**
 * An interface representing DataRow.
 */
export interface DataRow {
  row?: { [propertyName: string]: string };
}

/**
 * An interface representing GetChatGptCompletionsRequestmessage.
 * @summary Chat Gpt の単位会話です
 */
export interface GetChatGptCompletionsRequestmessage {
  /**
   * Chat Gpt の会話のロールです.
   */
  role?: string;
  /**
   * Chat Gpt の１会話のコンテンツです.
   */
  content?: string;
}

/**
 * An interface representing WikiAccessAnalyticsOneEntry.
 */
export interface WikiAccessAnalyticsOneEntry {
  /**
   * 集計の日付です.
   */
  date?: Date;
  /**
   * 集計数です.
   */
  num?: string;
}

/**
 * An interface representing WikiDataSheetsData.
 */
export interface WikiDataSheetsData {
  rows?: DataRow[];
}

/**
 * `Any` contains an arbitrary serialized protocol buffer message along with a
 * URL that describes the type of the serialized message.
 *
 * Protobuf library provides support to pack/unpack Any values in the form
 * of utility functions or additional generated methods of the Any type.
 *
 * Example 1: Pack and unpack a message in C++.
 *
 * Foo foo = ...;
 * Any any;
 * any.PackFrom(foo);
 * ...
 * if (any.UnpackTo(&foo)) {
 * ...
 * }
 *
 * Example 2: Pack and unpack a message in Java.
 *
 * Foo foo = ...;
 * Any any = Any.pack(foo);
 * ...
 * if (any.is(Foo.class)) {
 * foo = any.unpack(Foo.class);
 * }
 * // or ...
 * if (any.isSameTypeAs(Foo.getDefaultInstance())) {
 * foo = any.unpack(Foo.getDefaultInstance());
 * }
 *
 * Example 3: Pack and unpack a message in Python.
 *
 * foo = Foo(...)
 * any = Any()
 * any.Pack(foo)
 * ...
 * if any.Is(Foo.DESCRIPTOR):
 * any.Unpack(foo)
 * ...
 *
 * Example 4: Pack and unpack a message in Go
 *
 * foo := &pb.Foo{...}
 * any, err := anypb.New(foo)
 * if err != nil {
 * ...
 * }
 * ...
 * foo := &pb.Foo{}
 * if err := any.UnmarshalTo(foo); err != nil {
 * ...
 * }
 *
 * The pack methods provided by protobuf library will by default use
 * 'type.googleapis.com/full.type.name' as the type URL and the unpack
 * methods only use the fully qualified type name after the last '/'
 * in the type URL, for example "foo.bar.com/x/y.z" will yield type
 * name "y.z".
 *
 * JSON
 *
 * The JSON representation of an `Any` value uses the regular
 * representation of the deserialized, embedded message, with an
 * additional field `@type` which contains the type URL. Example:
 *
 * package google.profile;
 * message Person {
 * string first_name = 1;
 * string last_name = 2;
 * }
 *
 * {
 * "@type": "type.googleapis.com/google.profile.Person",
 * "firstName": <string>,
 * "lastName": <string>
 * }
 *
 * If the embedded message type is well-known and has a custom JSON
 * representation, that representation will be embedded adding a field
 * `value` which holds the custom JSON in addition to the `@type`
 * field. Example (for message [google.protobuf.Duration][]):
 *
 * {
 * "@type": "type.googleapis.com/google.protobuf.Duration",
 * "value": "1.212s"
 * }
 */
export interface ProtobufAny {
  /**
   * A URL/resource name that uniquely identifies the type of the serialized
   * protocol buffer message. This string must contain at least
   * one "/" character. The last segment of the URL's path must represent
   * the fully qualified name of the type (as in
   * `path/google.protobuf.Duration`). The name should be in a canonical form
   * (e.g., leading "." is not accepted).
   *
   * In practice, teams usually precompile into the binary all types that they
   * expect it to use in the context of Any. However, for URLs which use the
   * scheme `http`, `https`, or no scheme, one can optionally set up a type
   * server that maps type URLs to message definitions as follows:
   *
   * * If no scheme is provided, `https` is assumed.
   * * An HTTP GET on the URL must yield a [google.protobuf.Type][]
   * value in binary format, or produce an error.
   * * Applications are allowed to cache lookup results based on the
   * URL, or have them precompiled into a binary to avoid any
   * lookup. Therefore, binary compatibility needs to be preserved
   * on changes to types. (Use versioned type names to manage
   * breaking changes.)
   *
   * Note: this functionality is not currently available in the official
   * protobuf release, and it is not used for type URLs beginning with
   * type.googleapis.com.
   *
   * Schemes other than `http`, `https` (or the empty scheme) might be
   * used with implementation specific semantics.
   */
  typeUrl?: string;
  /**
   * Must be a valid serialized protocol buffer of the above specified type.
   */
  value?: Uint8Array;
}

/**
 * An interface representing RpcStatus.
 */
export interface RpcStatus {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
}

/**
 * An interface representing V1AdjustSpeculativeWikiDataSheetsRequest.
 * @summary Wiki テンプレートコード実行用に投機的に作られたスプレッドシート群を整理するためのリクエストです
 */
export interface V1AdjustSpeculativeWikiDataSheetsRequest {
  /**
   * Wiki をユニークに識別する人間可読の ID です.
   */
  name?: string;
  /**
   * 優先するデータシートの ID です.
   */
  primaryID?: string;
  /**
   * 調整の結果削除されるデータシートの ID です.
   */
  cancellationID?: string[];
}

/**
 * An interface representing V1BulkCreateWikiPageRequestArticle.
 */
export interface V1BulkCreateWikiPageRequestArticle {
  /**
   * 元となるファイルの名前です.
   */
  file?: string;
  /**
   * Wiki ページのタイトルです (メタ情報として保存されます).
   */
  title?: string;
  /**
   * Wiki ページの本文です.
   */
  body?: string;
  /**
   * カテゴリを表す ID です.
   */
  categoryId?: string;
  /**
   * ページ編集権限です.
   */
  editPermission?: string;
  /**
   * 記事公開日時です.
   */
  publishedAt?: Date;
  /**
   * いわゆる meta keywords です.
   */
  keywords?: string;
  /**
   * いわゆる meta description です.
   */
  description?: string;
  /**
   * 依存するスプレッドシートの URL 群です.
   */
  spreadsheetURL?: string[];
  /**
   * ページに noindex が設定されているかどうかのフラグです.
   */
  noindex?: boolean;
  /**
   * ページのOGP Image URL (meta og:image)です.
   */
  metaOgpImageURL?: string;
  /**
   * ページのThumbnail Image URL (meta thumbnail)です.
   */
  metaThumbnailImageURL?: string;
  /**
   * ページのmetaThumbnailImageURLが存在しない場合、自動的に設定するかのフラグです.
   */
  metaThumbnailImageAutoSelect?: boolean;
}

/**
 * An interface representing V1BulkCreateWikiPageRequestBody.
 */
export interface V1BulkCreateWikiPageRequestBody {
  articles?: V1BulkCreateWikiPageRequestArticle[];
}

/**
 * An interface representing V1BulkCreateWikiPageResultResult.
 */
export interface V1BulkCreateWikiPageResultResult {
  /**
   * 元となるファイルの名前です.
   */
  file?: string;
  /**
   * エラーがあった場合の内容です.
   */
  error?: string;
  /**
   * Wiki ページの ID です.
   */
  id?: string;
  /**
   * Wiki ページのタイトルです.
   */
  title?: string;
  /**
   * Wiki ページのパスです.
   */
  path?: string;
}

/**
 * An interface representing V1BulkCreateWikiPageResult.
 * @summary Wiki ページ一括作成結果を表すオブジェクトです
 */
export interface V1BulkCreateWikiPageResult {
  result?: V1BulkCreateWikiPageResultResult[];
}

/**
 * An interface representing V1BulkTakahiroWikiPageCategoryRequestBody.
 */
export interface V1BulkTakahiroWikiPageCategoryRequestBody {
  categories?: BulkTakahiroWikiPageCategoryRequestCategory[];
}

/**
 * An interface representing V1BulkTakahiroWikiPageCategoryResultResult.
 */
export interface V1BulkTakahiroWikiPageCategoryResultResult {
  /**
   * カテゴリの名前です.
   */
  name?: string;
  /**
   * エラーがあった場合の内容です.
   */
  error?: string;
}

/**
 * An interface representing V1BulkTakahiroWikiPageCategoryResult.
 * @summary takahiro-games 由来の Wiki ページ一括更新結果を表すオブジェクトです
 */
export interface V1BulkTakahiroWikiPageCategoryResult {
  result?: V1BulkTakahiroWikiPageCategoryResultResult[];
}

/**
 * An interface representing V1BulkTakahiroWikiPageRequestArticle.
 */
export interface V1BulkTakahiroWikiPageRequestArticle {
  /**
   * 元となるファイルの名前です.
   */
  file?: string;
  /**
   * Wiki ページのタイトルです (メタ情報として保存されます).
   */
  title?: string;
  /**
   * Wiki ページの本文です.
   */
  body?: string;
  /**
   * ページ編集権限です.
   */
  editPermission?: string;
  /**
   * 記事公開日時です.
   */
  publishedAt?: Date;
  /**
   * 記事作成日時です.
   */
  createdAt?: Date;
  /**
   * 記事更新日時です.
   */
  updatedAt?: Date;
  /**
   * いわゆる meta keywords です.
   */
  keywords?: string;
  /**
   * いわゆる meta description です.
   */
  description?: string;
  /**
   * 依存するスプレッドシートの URL 群です.
   */
  spreadsheetURL?: string[];
  /**
   * ページに noindex が設定されているかどうかのフラグです.
   */
  noindex?: boolean;
}

/**
 * An interface representing V1BulkTakahiroWikiPageRequestBody.
 */
export interface V1BulkTakahiroWikiPageRequestBody {
  articles?: V1BulkTakahiroWikiPageRequestArticle[];
}

/**
 * An interface representing V1BulkTakahiroWikiPageResultResult.
 */
export interface V1BulkTakahiroWikiPageResultResult {
  /**
   * 元となるファイルの名前です.
   */
  file?: string;
  /**
   * エラーがあった場合の内容です.
   */
  error?: string;
  /**
   * Wiki ページの ID です.
   */
  id?: string;
  /**
   * Wiki ページのタイトルです.
   */
  title?: string;
  /**
   * Wiki ページのパスです.
   */
  path?: string;
}

/**
 * An interface representing V1BulkTakahiroWikiPageResult.
 * @summary takahiro-games 由来の Wiki ページ一括更新結果を表すオブジェクトです
 */
export interface V1BulkTakahiroWikiPageResult {
  result?: V1BulkTakahiroWikiPageResultResult[];
}

/**
 * An interface representing V1BulkUpdateCategoryOfWikiPageRequestBody.
 */
export interface V1BulkUpdateCategoryOfWikiPageRequestBody {
  /**
   * カテゴリを表す ID です.
   */
  categoryId?: string;
  /**
   * Wiki ページの ID です.
   */
  pageId?: string[];
}

/**
 * An interface representing V1BulkUpdateWikiPageRequestArticle.
 */
export interface V1BulkUpdateWikiPageRequestArticle {
  /**
   * 元となるファイルの名前です.
   */
  file?: string;
  /**
   * Wiki ページの ID です.
   */
  pageId?: string;
  /**
   * Wiki ページのタイトルです (メタ情報として保存されます).
   */
  title?: string;
  /**
   * Wiki ページの本文です.
   */
  body?: string;
  /**
   * カテゴリを表す ID です.
   */
  categoryId?: string;
  /**
   * ページ編集権限です.
   */
  editPermission?: string;
  /**
   * 記事公開日時です.
   */
  publishedAt?: Date;
  /**
   * いわゆる meta keywords です.
   */
  keywords?: string;
  /**
   * いわゆる meta description です.
   */
  description?: string;
  /**
   * 依存するスプレッドシートの URL 群です.
   */
  spreadsheetURL?: string[];
  /**
   * ページに noindex が設定されているかどうかのフラグです.
   */
  noindex?: boolean;
  /**
   * ページのOGP Image URL (meta og:image)です.
   */
  metaOgpImageURL?: string;
  /**
   * ページのThumbnail Image URL (meta thumbnail)です.
   */
  metaThumbnailImageURL?: string;
  /**
   * ページのmetaThumbnailImageURLが存在しない場合、自動的に設定するかのフラグです.
   */
  metaThumbnailImageAutoSelect?: boolean;
}

/**
 * An interface representing V1BulkUpdateWikiPageRequestBody.
 */
export interface V1BulkUpdateWikiPageRequestBody {
  articles?: V1BulkUpdateWikiPageRequestArticle[];
}

/**
 * An interface representing V1BulkUpdateWikiPageResultResult.
 */
export interface V1BulkUpdateWikiPageResultResult {
  /**
   * 元となるファイルの名前です.
   */
  file?: string;
  /**
   * エラーがあった場合の内容です.
   */
  error?: string;
  /**
   * Wiki ページの ID です.
   */
  id?: string;
  /**
   * Wiki ページのタイトルです.
   */
  title?: string;
  /**
   * Wiki ページのパスです.
   */
  path?: string;
}

/**
 * An interface representing V1BulkUpdateWikiPageResult.
 * @summary Wiki ページ一括更新結果を表すオブジェクトです
 */
export interface V1BulkUpdateWikiPageResult {
  result?: V1BulkUpdateWikiPageResultResult[];
}

/**
 * An interface representing V1ColumnNameListPerSheets.
 * @summary Wiki データシートごとのカラムの一覧です
 */
export interface V1ColumnNameListPerSheets {
  sheets?: { [propertyName: string]: ColumnNameListPerSheetsColumns };
}

/**
 * An interface representing V1RealtimeDatabaseRule.
 * @summary Realtime Database におけるルール設定です
 */
export interface V1RealtimeDatabaseRule {
  /**
   * 対象のシート名です.
   */
  sheetName?: string;
  /**
   * インデックス対象のカラムです.
   */
  indexOn?: string[];
}

/**
 * An interface representing V1ConfigureRealtimeDatabaseRuleRequest.
 * @summary Realtime Database におけるルール設定をおこなうためのリクエストです
 */
export interface V1ConfigureRealtimeDatabaseRuleRequest {
  /**
   * 対象の Wiki の ID です.
   */
  wikiName?: string;
  /**
   * ルール群です.
   */
  rules?: V1RealtimeDatabaseRule[];
}

/**
 * An interface representing V1CreateGlobalDomainAllowlistRequestBody.
 */
export interface V1CreateGlobalDomainAllowlistRequestBody {
  /**
   * 許可するドメイン名です.
   */
  domain: string;
}

/**
 * An interface representing V1WikiDataChangelogSheet.
 * @summary Wiki データシートの更新履歴におけるデータシート毎の情報です
 */
export interface V1WikiDataChangelogSheet {
  /**
   * スプレッドシート ID です.
   */
  spreadsheetId?: string;
  /**
   * シート名です.
   */
  sheetName?: string;
  /**
   * 更新対象のセル数です.
   */
  cellCount?: string;
}

/**
 * An interface representing V1CreateWikiDataChangelogRequest.
 * @summary Wiki データシートの更新履歴を作成するためのリクエストです
 */
export interface V1CreateWikiDataChangelogRequest {
  /**
   * Wiki の ID です.
   */
  wikiName?: string;
  /**
   * シートの情報群です.
   */
  sheets?: V1WikiDataChangelogSheet[];
}

/**
 * An interface representing V1CreateWikiDomainAllowlistRequestBody.
 */
export interface V1CreateWikiDomainAllowlistRequestBody {
  /**
   * 許可するドメイン名です.
   */
  domain: string;
}

/**
 * An interface representing V1WikiMeta.
 * @summary Wiki のメタ情報を表すオブジェクトです
 */
export interface V1WikiMeta {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * メタ情報を表すキーとなる名前です.
   */
  name?: string;
  /**
   * メタ情報の具体的な内容です.
   */
  value?: string;
}

/**
 * An interface representing V1WikiNav.
 * @summary Wiki のグローバルナビ情報を表すオブジェクトです
 */
export interface V1WikiNav {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * ナビ項目名です.
   */
  name?: string;
  /**
   * リンク先に設定された wiki ページの ID です.
   */
  wikiPageID?: string;
  /**
   * リンク先に指定された wiki ページのタイトルです.
   */
  wikiPageTitle?: string;
  /**
   * ナビのソート順を表す数値です.
   */
  sortScore?: number;
  /**
   * リンク先の canonical なページ名です.
   */
  wikiPageCanonicalName?: string;
}

/**
 * An interface representing V1WikiCustomHeader.
 * @summary Wiki のカスタムヘッダ情報を表すオブジェクトです
 */
export interface V1WikiCustomHeader {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * カスタムヘッダの項目名です.
   */
  name?: string;
  /**
   * カスタムヘッダのリンク先に設定されたページの ID です.
   */
  wikiPageID?: string;
  /**
   * カスタムヘッダのリンク先に設定されたページのタイトルです.
   */
  wikiPageTitle?: string;
  /**
   * カスタムヘッダのリンク先の canonical な ページ名です.
   */
  wikiPageCanonicalName?: string;
  /**
   * カスタムヘッダに設定された URL です.
   */
  url?: string;
  /**
   * カスタムヘッダのソート順を表す数値です.
   */
  sortScore?: number;
}

/**
 * An interface representing V1ListWikiCustomHeaderWithWiki.
 * @summary Wiki のカスタムヘッダ情報を一覧するオブジェクトです
 */
export interface V1ListWikiCustomHeaderWithWiki {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * 一覧されるカスタムヘッダ情報です.
   */
  customHeader?: V1WikiCustomHeader[];
  /**
   * カスタムヘッダのタイトルです.
   */
  title?: string;
}

/**
 * An interface representing V1WikiMarkdownTextWidget.
 * @summary Wiki のマークダウン書式で記述される Wiki 共通のコンテンツです
 */
export interface V1WikiMarkdownTextWidget {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * コンテンツの名称です.
   */
  name?: string;
  /**
   * コンテンツの内容です.
   */
  body?: string;
}

/**
 * An interface representing V1Wiki.
 * @summary Wiki を表すオブジェクトです
 */
export interface V1Wiki {
  /**
   * Wiki をユニークに識別するための内部的な ID です.
   */
  id?: string;
  /**
   * Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます.
   */
  name?: string;
  /**
   * 取り扱いゲームを表す値です。これはあらかじめ用意されたもののほか、自由入力によって設定することもできます.
   */
  game?: string;
  /**
   * 閲覧者向けに表示する Wiki のタイトルです.
   */
  title?: string;
  /**
   * 閲覧者向けに表示する Wiki の説明文です.
   */
  description?: string;
  /**
   * 編集議論を進める掲示板を表す ID です (関係者外秘).
   */
  threadId?: string;
  /**
   * Wiki のメタ情報です.
   */
  meta?: V1WikiMeta[];
  /**
   * Wiki のグローバルナビ情報です.
   */
  nav?: V1WikiNav[];
  /**
   * Wiki のカスタムヘッダ情報です.
   */
  customHeader?: V1ListWikiCustomHeaderWithWiki;
  /**
   * Wiki の icon 画像の URL です.
   */
  iconImgURL?: string;
  /**
   * Wiki の header 画像の URL です.
   */
  headerImgURL?: string;
  /**
   * noindex 設定をおこなうかどうかを表す値です.
   */
  noindex?: boolean;
  /**
   * 限定公開の Wiki かどうかを表す値です.
   */
  isPrivate?: boolean;
  /**
   * ルート起点の (/wiki プレフィクスを持たない) パスを持つ Wiki かどうかを表す値です.
   */
  isRootPath?: boolean;
  /**
   * title タグに挿入されるサフィックスです.
   */
  titleSuffix?: string;
  /**
   * 編集部の名前です.
   */
  teamName?: string;
  /**
   * 編集部の SNS アカウントの URL です.
   */
  teamSNSURL?: string;
  /**
   * Wiki のメタ情報のうちテキストで管理しているものです.
   */
  markdownTextWidget?: V1WikiMarkdownTextWidget[];
  /**
   * Wiki がアーカイブ状態かどうかを表す値です.
   */
  isArchived?: boolean;
  /**
   * サイトマップに載せる記事を縮小すべきかどうかを表す値です.
   */
  shouldSitemapShrink?: boolean;
}

/**
 * An interface representing V1CreateWikiMemberInviteRequest.
 * @summary Wiki メンバー招待作成用のリクエストです
 */
export interface V1CreateWikiMemberInviteRequest {
  /**
   * 対象となる Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * メンバー招待の説明文です.
   */
  label?: string;
  /**
   * この招待を利用可能な回数の上限です.
   */
  limitCount?: string;
  /**
   * 招待の有効期限です.
   */
  expiredAt?: Date;
}

/**
 * An interface representing V1CreateWikiMemberRequestRequest.
 * @summary Wiki メンバー申請用のリクエストです
 */
export interface V1CreateWikiMemberRequestRequest {
  /**
   * 申請対象となる Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * 自己 PR 文など申請の本文となる内容です.
   */
  description?: string;
}

/**
 * An interface representing V1CreateWikiPagePartialRequest.
 */
export interface V1CreateWikiPagePartialRequest {
  /**
   * ページ部品が所属する Wiki を識別する人間可読な ID です。.
   */
  wikiName?: string;
  /**
   * ページ部品の名称です.
   */
  name?: string;
  /**
   * ページ部品の本文です.
   */
  body?: string;
  /**
   * ページ部品で利用されるデータシートのカラム一覧です.
   */
  columns?: V1ColumnNameListPerSheets;
}

/**
 * An interface representing V1CreateWikiPageRequestBody.
 */
export interface V1CreateWikiPageRequestBody {
  /**
   * Wiki ページのタイトルです (メタ情報として保存されます).
   */
  title?: string;
  /**
   * Wiki ページの本文です.
   */
  body?: string;
  /**
   * カテゴリを表す ID です.
   */
  categoryId?: string;
  /**
   * ページ編集権限です.
   */
  editPermission?: string;
  /**
   * 記事公開日時です.
   */
  publishedAt?: Date;
  /**
   * いわゆる meta keywords です.
   */
  keywords?: string;
  /**
   * いわゆる meta description です.
   */
  description?: string;
  /**
   * 依存するスプレッドシートの URL 群です.
   */
  spreadsheetURL?: string[];
  /**
   * ページに noindex が設定されているかどうかのフラグです.
   */
  noindex?: boolean;
  /**
   * ページのOGP Image URL (meta og:image)です.
   */
  metaOgpImageURL?: string;
  /**
   * ページのThumbnail Image URL (meta thumbnail)です.
   */
  metaThumbnailImageURL?: string;
  /**
   * ページのmetaThumbnailImageURLが存在しない場合、自動的に設定するかのフラグです.
   */
  metaThumbnailImageAutoSelect?: boolean;
  /**
   * ページのタイトルの文頭です.
   */
  titlePrefix?: string;
}

/**
 * An interface representing V1CreateWikiPageTemplateRequestBody.
 */
export interface V1CreateWikiPageTemplateRequestBody {
  /**
   * テンプレートのタイトルです.
   */
  title?: string;
  /**
   * テンプレートの本文です.
   */
  body?: string;
  /**
   * テンプレートのカテゴリを表す ID です.
   */
  categoryId?: string;
  /**
   * テンプレートの keywords です.
   */
  keywords?: string;
  /**
   * テンプレートの description です.
   */
  description?: string;
}

/**
 * An interface representing V1CreateWikiRequest.
 * @summary Wiki 作成用のリクエストです
 */
export interface V1CreateWikiRequest {
  /**
   * Wiki をユニークに識別する人間可読の ID です.
   */
  name?: string;
  /**
   * 取り扱いゲームを表す値です.
   */
  game?: string;
  /**
   * 閲覧者向けに表示する Wiki のタイトルです.
   */
  title?: string;
  /**
   * 閲覧者向けに表示する Wiki の説明文です.
   */
  description?: string;
}

/**
 * An interface representing V1EditWikiPageDraftRequestBody.
 */
export interface V1EditWikiPageDraftRequestBody {
  /**
   * 下書きの最終更新者の id です.
   */
  lastAuthorId?: string;
  /**
   * 下書きのタイトルです.
   */
  title?: string;
  /**
   * 下書きの本文です.
   */
  body?: string;
  /**
   * カテゴリを表す ID です.
   */
  categoryId?: string;
  /**
   * いわゆる meta keywords です.
   */
  keywords?: string;
  /**
   * いわゆる meta description です.
   */
  description?: string;
  /**
   * 依存するスプレッドシートの URL 群です.
   */
  spreadsheetURL?: string[];
  /**
   * ページに noindex が設定されているかどうかのフラグです.
   */
  noindex?: boolean;
  /**
   * ページのOGP Image URL (meta og:image)です.
   */
  metaOgpImageURL?: string;
  /**
   * ページのThumbnail Image URL (meta thumbnail)です.
   */
  metaThumbnailImageURL?: string;
  /**
   * ページのmetaThumbnailImageURLが存在しない場合、自動的に設定するかのフラグです.
   */
  metaThumbnailImageAutoSelect?: boolean;
  /**
   * 下書きのタイトルの文頭です.
   */
  titlePrefix?: string;
}

/**
 * An interface representing V1EditWikiPagePartialRequest.
 */
export interface V1EditWikiPagePartialRequest {
  /**
   * ページ部品が所属する Wiki を識別する人間可読な ID です。.
   */
  wikiName?: string;
  /**
   * ページ部品の名称です.
   */
  wikiPagePartialName?: string;
  /**
   * ページ記事部品の名称です.
   */
  name?: string;
  /**
   * ページ部品の本文です.
   */
  body?: string;
  /**
   * ページ部品で利用されるデータシートのカラム一覧です.
   */
  columns?: V1ColumnNameListPerSheets;
}

/**
 * An interface representing V1EditWikiPageRequestBody.
 */
export interface V1EditWikiPageRequestBody {
  /**
   * ページの最終更新者の ID です.
   */
  authorId?: string;
  /**
   * Wiki ページのタイトルです (メタ情報として保存されます).
   */
  title?: string;
  /**
   * Wiki ページの本文です.
   */
  body?: string;
  /**
   * カテゴリを表す ID です.
   */
  categoryId?: string;
  /**
   * ページ編集権限です.
   */
  editPermission?: string;
  /**
   * 記事公開日時です.
   */
  publishedAt?: Date;
  /**
   * いわゆる meta keywords です.
   */
  keywords?: string;
  /**
   * いわゆる meta description です.
   */
  description?: string;
  /**
   * 依存するスプレッドシートの URL 群です.
   */
  spreadsheetURL?: string[];
  /**
   * ページに noindex が設定されているかどうかのフラグです.
   */
  noindex?: boolean;
  /**
   * ページのOGP Image URL (meta og:image)です.
   */
  metaOgpImageURL?: string;
  /**
   * ページのThumbnail Image URL (meta thumbnail)です.
   */
  metaThumbnailImageURL?: string;
  /**
   * ページのmetaThumbnailImageURLが存在しない場合、自動的に設定するかのフラグです.
   */
  metaThumbnailImageAutoSelect?: boolean;
  /**
   * ページのタイトルの文頭です.
   */
  titlePrefix?: string;
}

/**
 * An interface representing V1EditWikiPageTemplateRequestBody.
 */
export interface V1EditWikiPageTemplateRequestBody {
  /**
   * テンプレートのタイトルです.
   */
  title?: string;
  /**
   * テンプレートの本文です.
   */
  body?: string;
  /**
   * テンプレートのカテゴリを表す ID です.
   */
  categoryId?: string;
  /**
   * テンプレートの keywords です.
   */
  keywords?: string;
  /**
   * テンプレートの description です.
   */
  description?: string;
}

/**
 * An interface representing V1NullableBool.
 */
export interface V1NullableBool {
  value?: boolean;
}

/**
 * An interface representing V1EditWikiPublishConfigRequest.
 * @summary Wiki 公開設定編集用のリクエストです
 */
export interface V1EditWikiPublishConfigRequest {
  /**
   * Wiki をユニークに識別する人間可読の ID です.
   */
  name?: string;
  /**
   * noindex 設定を表す値です.
   */
  noindex?: V1NullableBool;
  /**
   * 限定公開の Wiki かどうかを表す値です.
   */
  isPrivate?: V1NullableBool;
  /**
   * ルート起点の (/wiki プレフィクスを持たない) パスを持つ Wiki かどうかを表す値です.
   */
  isRootPath?: V1NullableBool;
  /**
   * サイトマップに載せる記事を縮小すべきかどうかを表す値です.
   */
  shouldSitemapShrink?: V1NullableBool;
}

/**
 * An interface representing V1EditWikiRequest.
 * @summary Wiki 基本情報編集用のリクエストです
 */
export interface V1EditWikiRequest {
  /**
   * Wiki をユニークに識別する人間可読の ID です.
   */
  name?: string;
  /**
   * 閲覧者向けに表示する Wiki のタイトルです.
   */
  title?: string;
  /**
   * 閲覧者向けに表示する Wiki の説明文です.
   */
  description?: string;
  /**
   * title タグにつくタイトルのサフィックスです.
   */
  titleSuffix?: string;
  /**
   * 編集部の名前です.
   */
  teamName?: string;
  /**
   * 編集部の SNS アカウントの URL です.
   */
  teamSNSURL?: string;
}

/**
 * An interface representing V1GetChatGptCompletionsRequest.
 */
export interface V1GetChatGptCompletionsRequest {
  /**
   * 利用モデル.
   */
  model?: string;
  /**
   * 送信内容.
   */
  messages?: GetChatGptCompletionsRequestmessage[];
  wikiName?: string;
  /**
   * GPT のモデルパラメータ.
   */
  temperature?: number;
}

/**
 * An interface representing V1GetChatGptCompletionsResponse.
 */
export interface V1GetChatGptCompletionsResponse {
  /**
   * Chat GPT の completions API の結果.
   */
  content?: string;
}

/**
 * An interface representing V1GetChatGptCompletionsWithPromptRequest.
 */
export interface V1GetChatGptCompletionsWithPromptRequest {
  content?: string;
  wikiName?: string;
}

/**
 * An interface representing V1User.
 * @summary サイトへのログインを完了したユーザを表します
 */
export interface V1User {
  /**
   * ユーザをユニークに識別するための内部的な ID です.
   */
  id?: string;
  /**
   * 認証サーバにおけるユーザ ID です.
   */
  authUserId?: number;
  /**
   * ユーザのニックネームです.
   */
  nickname?: string;
  /**
   * ユーザのプロフィール画像です.
   */
  imageUrl?: string;
  /**
   * ユーザの最終ログイン時間です.
   */
  lastLoggedInAt?: Date;
}

/**
 * An interface representing V1GlobalAllowedDomain.
 * @summary GlobalAllowedDomain を表すオブジェクトです
 */
export interface V1GlobalAllowedDomain {
  /**
   * GlobalAllowedDomain をユニークに識別するための内部的な ID です.
   */
  id: string;
  /**
   * 許可するドメイン名です.
   */
  name: string;
  /**
   * ドメイン名を追加したユーザです.
   */
  creator?: V1User;
  /**
   * このドメイン名が追加された日時です.
   */
  createdAt: Date;
}

/**
 * An interface representing V1Image.
 * @summary 画像を表現するオブジェクトです
 */
export interface V1Image {
  /**
   * 画像をユニークに識別する ID です.
   */
  id?: string;
  /**
   * 画像を参照するための URL です.
   */
  url?: string;
  /**
   * 画像の名前です.
   */
  name?: string;
  /**
   * 画像が紐づくwikiのIDです.
   */
  wikiId?: string;
}

/**
 * An interface representing V1ListGlobalDomainAllowlistResponse.
 * @summary GlobalDomainAllowlist の一覧取得時のレスポンスです
 */
export interface V1ListGlobalDomainAllowlistResponse {
  /**
   * GlobalAllowedDomain の配列です.
   */
  globalAllowedDomains: V1GlobalAllowedDomain[];
}

/**
 * An interface representing V1WikiAllowedDomain.
 * @summary WikiAllowedDomain を表すオブジェクトです
 */
export interface V1WikiAllowedDomain {
  /**
   * WikiAllowedDomain をユニークに識別するための内部的な ID です.
   */
  id: string;
  /**
   * Wiki をユニークに識別するための内部的な ID です.
   */
  wikiId: string;
  /**
   * 許可するドメイン名です.
   */
  name: string;
  /**
   * ドメイン名を追加したユーザです.
   */
  creator?: V1User;
  /**
   * このドメイン名が追加された日時です.
   */
  createdAt: Date;
}

/**
 * An interface representing V1ListWikiDomainAllowlistResponse.
 * @summary WikiDomainAllowlist の中から指定された wiki の WikiAllowedDomain の一覧を取得するためのリクエストです
 */
export interface V1ListWikiDomainAllowlistResponse {
  /**
   * 指定した wiki の WikiAllowedDomain の配列です.
   */
  wikiAllowedDomains: V1WikiAllowedDomain[];
}

/**
 * An interface representing V1ListWikiNavWithWiki.
 * @summary Wiki のグローバルナビ情報を一覧するオブジェクトです
 */
export interface V1ListWikiNavWithWiki {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * 一覧されるグローバルナビ情報です.
   */
  nav?: V1WikiNav[];
}

/**
 * An interface representing V1ListWikiResponse.
 * @summary Wiki を一覧するレスポンスです
 */
export interface V1ListWikiResponse {
  /**
   * 一覧される Wiki 群です.
   */
  wiki?: V1Wiki[];
}

/**
 * An interface representing V1WikiTakahiroTemplateCodeSpreadsheetURL.
 * @summary テンプレートコード実行のために Wiki と連携するスプレッドシートです
 */
export interface V1WikiTakahiroTemplateCodeSpreadsheetURL {
  /**
   * スプレッドシートの URL です.
   */
  url?: string;
  /**
   * 最終更新日時です.
   */
  lastUpdatedAt?: Date;
  /**
   * 連携する wiki data の ID です.
   */
  wikiDataID?: string;
}

/**
 * An interface representing V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki.
 * @summary テンプレートコード実行のために Wiki と連携するスプレッドシート URL を一覧するオブジェクトです
 */
export interface V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * 一覧されるスプレッドシートの情報です.
   */
  spreadsheet?: V1WikiTakahiroTemplateCodeSpreadsheetURL[];
}

/**
 * An interface representing V1WikiTooltip.
 * @summary Wiki のツールチップ情報を表すオブジェクトです
 */
export interface V1WikiTooltip {
  /**
   * ツールチップのタイトルです.
   */
  title?: string;
  /**
   * ツールチップの対応する単語です.
   */
  word?: string;
  /**
   * ツールチップの説明です.
   */
  description?: string;
  /**
   * ツールチップのリンク先の URL です.
   */
  url?: string;
}

/**
 * An interface representing V1ListWikiTooltipMessage.
 * @summary Wiki のツールチップ情報を表すオブジェクトです
 */
export interface V1ListWikiTooltipMessage {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * 一覧されるツールチップ情報です.
   */
  tooltip?: V1WikiTooltip[];
}

/**
 * An interface representing V1LoginRequest.
 * @summary ログインのためのリクエスト情報です
 */
export interface V1LoginRequest {
  /**
   * 認証サーバから提供される ID Token です.
   */
  idToken?: string;
}

/**
 * An interface representing V1PublishWikiDataDownloadURLRequest.
 * @summary Wiki データ GCS ダウンロード URL を発行するリクエストを表現するオブジェクトです
 */
export interface V1PublishWikiDataDownloadURLRequest {
  /**
   * Wiki をユニークに識別する人間可読の ID です.
   */
  name?: string;
  /**
   * シートデータを保存している GCS 上のパスです.
   */
  bucketPath?: string;
}

/**
 * An interface representing V1RequestTemplateCodeExecutionRequest.
 * @summary Wiki テンプレートコード実行予約のためのリクエストです
 */
export interface V1RequestTemplateCodeExecutionRequest {
  /**
   * Wiki をユニークに識別する人間可読の ID です.
   */
  name?: string;
  /**
   * 更新対象とするスプレッドシートの URL です.
   */
  spreadsheetURL?: string[];
}

/**
 * https://github.com/stalniy/casl のサブパッケージ、 casl-ability の Rule クラスインスタンスとして表現可能な形式となります
 * @summary 権限を表すオブジェクトです
 */
export interface V1Rule {
  action?: string;
  subject?: string;
  inverted?: boolean;
  conditions?: { [propertyName: string]: ProtobufAny };
  reason?: string;
}

/**
 * An interface representing V1RuleCollection.
 * @summary 権限の一覧を表すオブジェクトです
 */
export interface V1RuleCollection {
  rules?: V1Rule[];
}

/**
 * An interface representing V1Session.
 * @summary ログインセッションの情報です
 */
export interface V1Session {
  /**
   * セッションを識別するためのセッション ID です.
   */
  id?: string;
  /**
   * セッションに関連づいたユーザの情報です.
   */
  user?: V1User;
  /**
   * セッションの有効期限です.
   */
  exp?: Date;
}

/**
 * An interface representing V1UpdateLinkedWikiPageCategoryRequest.
 * @summary Wiki ページに紐づけるカテゴリーを更新するためのリクエストです
 */
export interface V1UpdateLinkedWikiPageCategoryRequest {
  /**
   * Wiki を識別するための人間可読な ID です.
   */
  wikiName?: string;
  /**
   * Wiki ページの ID です.
   */
  pageId?: string;
  /**
   * 紐づけるカテゴリーの ID です.
   */
  categoryId?: string;
}

/**
 * An interface representing V1UpdateLinkedWikiPageTemplateCategoryRequest.
 * @summary Wiki ページテンプレートに紐づけるカテゴリーを更新するためのリクエストです
 */
export interface V1UpdateLinkedWikiPageTemplateCategoryRequest {
  /**
   * Wiki を識別するための人間可読な ID です.
   */
  wikiName?: string;
  /**
   * Wiki ページの ID です.
   */
  templateId?: string;
  /**
   * 紐づけるカテゴリーの ID です.
   */
  categoryId?: string;
}

/**
 * An interface representing V1UpdateWikiArchiveConfigRequest.
 * @summary Wiki アーカイブ設定用のリクエストです
 */
export interface V1UpdateWikiArchiveConfigRequest {
  /**
   * Wiki をユニークに識別する人間可読の ID です.
   */
  name?: string;
  /**
   * Wiki のアーカイブ設定を表す値です.
   */
  isArchived?: boolean;
}

/**
 * An interface representing V1UpdateWikiImageRequest.
 * @summary Wiki 画像設定用のリクエストです
 */
export interface V1UpdateWikiImageRequest {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * 設定したい画像です.
   */
  image?: V1Image;
}

/**
 * An interface representing V1UpdateWikiMemberPermissionRequest.
 * @summary Wiki メンバー権限設定用のリクエストです
 */
export interface V1UpdateWikiMemberPermissionRequest {
  /**
   * 申請対象となる Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * 対象となるユーザです.
   */
  user?: V1User;
  /**
   * メンバーの権限です. Possible values include: 'MEMBER', 'SUB_ADMIN', 'ADMIN'. Default value: 'MEMBER'.
   */
  permission?: Permission;
}

/**
 * An interface representing V1UpdateWikiPageHistoryDiffRequestBody.
 */
export interface V1UpdateWikiPageHistoryDiffRequestBody {
  /**
   * 変更履歴の差分行数です.
   */
  diffLines?: string;
}

/**
 * An interface representing V1UpdateWikiPageRedirectRequestBody.
 */
export interface V1UpdateWikiPageRedirectRequestBody {
  /**
   * リダイレクト先のページの ID です.
   */
  redirectPageId?: string;
}

/**
 * An interface representing V1WikiAccessAnalytics.
 * @summary Wiki 一定期間のアクセスの日付と数を表すオブジェクトです
 */
export interface V1WikiAccessAnalytics {
  /**
   * 一定期間のアクセスの日付と数の集合です.
   */
  analyticsEntries?: WikiAccessAnalyticsOneEntry[];
}

/**
 * An interface representing V1WikiAllowedIPAddress.
 * @summary Wiki 閲覧許可 IP アドレスです
 */
export interface V1WikiAllowedIPAddress {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * 改行区切りの IP アドレス群です.
   */
  ipAddress?: string;
}

/**
 * An interface representing V1WikiCollection.
 * @summary Wiki を一覧するオブジェクトです
 */
export interface V1WikiCollection {
  /**
   * 一覧される Wiki 群です.
   */
  wiki?: V1Wiki[];
}

/**
 * An interface representing V1WikiCustomCss.
 * @summary Wiki のカスタム CSS を表すオブジェクトです
 */
export interface V1WikiCustomCss {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * CSS の内容です.
   */
  css?: string;
}

/**
 * An interface representing V1WikiDataDownloadURL.
 * @summary Wiki データ GCS ダウンロード URL を表現するオブジェクトです
 */
export interface V1WikiDataDownloadURL {
  /**
   * Wiki データインポート用 URL です.
   */
  url?: string;
}

/**
 * An interface representing V1WikiDataSheet.
 * @summary Wiki データシートを表現するオブジェクトです
 */
export interface V1WikiDataSheet {
  /**
   * Wiki データシートを一意に特定するための ID です.
   */
  id?: string;
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * シート名です.
   */
  sheetName?: string;
  /**
   * JSON シリアライズされたシートデータです.
   */
  sheetData?: string;
  /**
   * オリジナルのシートデータ保存ストレージ上のパスです.
   */
  originBucketPath?: string;
  /**
   * 同期対象のスプレッドシートの ID です.
   */
  spreadsheetId?: string;
  /**
   * JSON シリアライズされたデータの SHA-1 ダイジェスト値です.
   */
  sheetDataHash?: string;
  /**
   * テンプレートコード用の Wiki データシートかのフラグです.
   */
  isForTemplateCode?: boolean;
}

/**
 * An interface representing V1WikiDataSheetCollection.
 * @summary Wiki データシートの一覧を表現するオブジェクトです
 */
export interface V1WikiDataSheetCollection {
  sheets?: V1WikiDataSheet[];
}

/**
 * An interface representing V1WikiDataSheetLink.
 * @summary Wiki データシート外部参照の情報を表現するオブジェクトです
 */
export interface V1WikiDataSheetLink {
  /**
   * id です.
   */
  id?: string;
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * シートの名称です.
   */
  sheetName?: string;
  /**
   * シートのスプレッドシート ID です.
   */
  spreadsheetId?: string;
  /**
   * このシートを参照しているシートの名称です.
   */
  linkSheetName?: string;
  /**
   * このシートを参照しているシートのスプレッドシート ID です.
   */
  linkSheetSpreadsheetId?: string;
}

/**
 * An interface representing V1WikiDataSheetLinkCollection.
 */
export interface V1WikiDataSheetLinkCollection {
  linkSheets?: V1WikiDataSheetLink[];
}

/**
 * An interface representing V1WikiDataSheets.
 */
export interface V1WikiDataSheets {
  sheets?: { [propertyName: string]: WikiDataSheetsData };
}

/**
 * An interface representing V1WikiDataUploadURL.
 * @summary Wiki データ GCS アップロード URL を表現するオブジェクトです
 */
export interface V1WikiDataUploadURL {
  /**
   * Wiki データインポート用 URL です.
   */
  url?: string;
  /**
   * シートデータを保存している GCS 上のパスです.
   */
  bucketPath?: string;
}

/**
 * An interface representing V1WikiForDashboard.
 * @summary WikiForDashboard を表すオブジェクトです
 */
export interface V1WikiForDashboard {
  /**
   * Wiki をユニークに識別するための内部的な ID です.
   */
  id?: string;
  /**
   * Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます.
   */
  name?: string;
  /**
   * 閲覧者向けに表示する Wiki のタイトルです.
   */
  title?: string;
  /**
   * 閲覧者向けに表示する Wiki の説明文です.
   */
  description?: string;
  /**
   * Wiki の icon 画像の URL です.
   */
  iconImgURL?: string;
  /**
   * noindex 設定をおこなうかどうかを表す値です.
   */
  noindex?: boolean;
  /**
   * 限定公開の Wiki かどうかを表す値です.
   */
  isPrivate?: boolean;
  /**
   * Wiki の作成者です.
   */
  author?: V1User;
}

/**
 * An interface representing V1WikiPageCategory.
 * @summary WikiPageCategory を表現するオブジェクト
 */
export interface V1WikiPageCategory {
  /**
   * WikiPageCategoryをユニークに識別するための内部的な ID です.
   */
  id?: string;
  /**
   * 関連づいた Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * カテゴリ名です.
   */
  name?: string;
  /**
   * 親カテゴリ ID です.
   */
  parentID?: string;
  /**
   * 親カテゴリを含めた表示名です.
   */
  displayName?: string;
  /**
   * 関連づいた wiki ページです.
   */
  wikiPageID?: string;
  /**
   * 関連づいた wiki ページのタイトルです.
   */
  wikiPageTitle?: string;
}

/**
 * An interface representing V1WikiPageTitle.
 * @summary Wiki ページの ID とタイトルです
 */
export interface V1WikiPageTitle {
  /**
   * Wiki ページの ID です.
   */
  id?: string;
  /**
   * Wiki ページのタイトルです.
   */
  title?: string;
  /**
   * Wiki ページのパスです.
   */
  path?: string;
  /**
   * 記事公開されているか.
   */
  published?: boolean;
}

/**
 * An interface representing V1WikiPage.
 * @summary Wiki に属するページ (記事) です
 */
export interface V1WikiPage {
  /**
   * Wiki ページをユニークに識別するための ID です。 path が指定されていない限りはこの値が URL の一部に含まれます.
   */
  id?: string;
  /**
   * ページが属する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * ページの最終更新者です.
   */
  author?: V1User;
  /**
   * サムネイル画像の URL です。 Google App Engine の Image Serving URL であることを意図しています.
   */
  thumbnailImageUrl?: string;
  /**
   * Wiki ページが属するカテゴリです.
   */
  category?: V1WikiPageCategory;
  meta?: any[];
  /**
   * ページの最終更新日時です.
   */
  lastUpdatedAt?: Date;
  /**
   * ページ編集権限です.
   */
  editPermission?: string;
  /**
   * ページの公開日時です.
   */
  publishedAt: Date;
  /**
   * ページのエイリアスです。指定されている場合、 id の代わりにこの値が URL の一部に含まれます.
   */
  path?: string;
  /**
   * ページの keywords (いわゆる meta keywords) です.
   */
  keywords?: string;
  /**
   * ページの description (いわゆる meta description) です.
   */
  description?: string;
  /**
   * ページのリダイレクト先ページです。指定されたページへリダイレクトされます.
   */
  redirectPageTitle?: V1WikiPageTitle;
  /**
   * ページに noindex が設定されているかどうかのフラグです.
   */
  noindex?: boolean;
  /**
   * ページのOGP Image URL (meta og:image)です.
   */
  metaOgpImageURL?: string;
  /**
   * ページのThumbnail Image URL (meta thumbnail)です.
   */
  metaThumbnailImageURL?: string;
  /**
   * ページのmetaThumbnailImageURLが存在しない場合、自動的に設定するかのフラグです.
   */
  metaThumbnailImageAutoSelect?: boolean;
  /**
   * ページのタイトルプレフィックスです.
   */
  titlePrefix?: string;
  /**
   * ページが削除されているかどうかのフラグです.
   */
  isDeleted?: boolean;
}

/**
 * An interface representing V1WikiPageHistory.
 * @summary Wiki ページの履歴です
 */
export interface V1WikiPageHistory {
  /**
   * 履歴をユニークに識別するための ID です.
   */
  id?: string;
  /**
   * 対象となる Wiki ページです.
   */
  wikiPage?: V1WikiPage;
  /**
   * ページのタイトルです.
   */
  title?: string;
  /**
   * ページの本文です.
   */
  body?: string;
  /**
   * ページの最終更新者です.
   */
  author?: V1User;
  /**
   * 一つ前のバージョンの履歴をユニークに識別するための ID です.
   */
  parentId?: string;
  /**
   * 履歴が作られた日時です.
   */
  createdAt?: Date;
  /**
   * 削除操作かです.
   */
  isDeleted: boolean;
  /**
   * 復元操作かです.
   */
  isRestored: boolean;
  /**
   * 変更行数です.
   */
  diffLines?: string;
  /**
   * ページのタイトルの文頭です.
   */
  titlePrefix?: string;
}

/**
 * An interface representing V1WikiHistoryCollection.
 * @summary Wiki ページ履歴の一覧 (Wiki 全体) です
 */
export interface V1WikiHistoryCollection {
  history?: V1WikiPageHistory[];
  /**
   * Wiki ページ履歴の総数です.
   */
  numOfTotalHistories?: number;
}

/**
 * An interface representing V1WikiMember.
 * @summary Wiki メンバーを表現するオブジェクトです
 */
export interface V1WikiMember {
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * 所属するユーザです.
   */
  user?: V1User;
  /**
   * 管理者権限を有しているかを表します.
   */
  isAdmin?: boolean;
  /**
   * 副管理者権限を有しているかを表します.
   */
  isSubAdmin?: boolean;
}

/**
 * An interface representing V1WikiMemberCollection.
 * @summary Wiki メンバーを一覧するオブジェクトです
 */
export interface V1WikiMemberCollection {
  members?: V1WikiMember[];
  /**
   * メンバーの総数です.
   */
  numOfTotalMembers?: number;
}

/**
 * An interface representing V1WikiMemberInvite.
 * @summary Wiki メンバー招待を表現するオブジェクトです
 */
export interface V1WikiMemberInvite {
  /**
   * メンバー招待を識別する ID です.
   */
  id?: string;
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * この招待がこれまでに利用された回数です.
   */
  usedCount?: string;
  /**
   * この招待を利用可能な回数の上限です.
   */
  limitCount?: string;
  /**
   * メンバー招待の説明文です.
   */
  label?: string;
  /**
   * 招待作成日時です.
   */
  createdAt?: Date;
  /**
   * 招待の有効期限です.
   */
  expiredAt?: Date;
}

/**
 * An interface representing V1WikiMemberInviteCollection.
 * @summary Wiki メンバー招待を一覧するオブジェクトです
 */
export interface V1WikiMemberInviteCollection {
  invites?: V1WikiMemberInvite[];
}

/**
 * An interface representing V1WikiMemberRequest.
 * @summary Wiki メンバー参加申請を表現するオブジェクトです
 */
export interface V1WikiMemberRequest {
  /**
   * 申請の ID です.
   */
  id?: string;
  /**
   * 関連する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * 申請をおこなったユーザです.
   */
  user?: V1User;
  /**
   * 自己 PR 文など申請の本文となる内容です.
   */
  description?: string;
  /**
   * 申請をおこなった日時です.
   */
  createdAt?: Date;
}

/**
 * An interface representing V1WikiMemberRequestCollection.
 * @summary Wiki メンバー申請を一覧するオブジェクトです
 */
export interface V1WikiMemberRequestCollection {
  requests?: V1WikiMemberRequest[];
}

/**
 * An interface representing V1WikiMemcacheKeyHash.
 * @summary メモキャッシュキーです
 */
export interface V1WikiMemcacheKeyHash {
  /**
   * キャッシュのモデルの種類です.
   */
  modelName?: string;
  /**
   * キャッシュのサフィックスにつく、gob モデルのハッシュです.
   */
  hash?: string;
}

/**
 * An interface representing V1WikiMemcacheKeyHashCollection.
 */
export interface V1WikiMemcacheKeyHashCollection {
  hashList?: V1WikiMemcacheKeyHash[];
}

/**
 * An interface representing V1WikiPageCategoryCollection.
 * @summary WikiPageCategoryの一覧を表現するオブジェクト
 */
export interface V1WikiPageCategoryCollection {
  wikiPageCategories?: V1WikiPageCategory[];
  /**
   * ページカテゴリの総数です.
   */
  numOfTotalWikiPageCategories?: number;
}

/**
 * An interface representing V1WikiPageCategoryCreateRequest.
 * @summary WikiPageCategory 作成のためのリクエスト
 */
export interface V1WikiPageCategoryCreateRequest {
  /**
   * WikiPageCategoryをユニークに識別するための内部的な ID です.
   */
  id?: string;
  /**
   * 関連づいた Wiki 名です.
   */
  wikiName?: string;
  /**
   * カテゴリ名です.
   */
  name?: string;
  /**
   * 親カテゴリ ID です.
   */
  parentID?: string;
  /**
   * 親カテゴリを含めた表示名です.
   */
  displayName?: string;
  /**
   * カテゴリに関連づいた wiki ページID です.
   */
  wikiPageID?: string;
}

/**
 * An interface representing V1WikiPageCategoryUpdateRequest.
 * @summary WikiPageCategory 更新のためのリクエスト
 */
export interface V1WikiPageCategoryUpdateRequest {
  /**
   * WikiPageCategoryをユニークに識別するための内部的な ID です.
   */
  id?: string;
  /**
   * 関連づいた Wiki 名です.
   */
  wikiName?: string;
  /**
   * カテゴリ名です.
   */
  name?: string;
  /**
   * 親カテゴリ ID です.
   */
  parentID?: string;
  /**
   * 親カテゴリを含めた表示名です.
   */
  displayName?: string;
  /**
   * カテゴリに関連づいた wiki ページID です.
   */
  wikiPageID?: string;
}

/**
 * An interface representing V1WikiPageDraft.
 * @summary Wiki ページの下書きです
 */
export interface V1WikiPageDraft {
  /**
   * 下書きの id です.
   */
  id?: string;
  /**
   * 下書きが所属する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * 下書きのもととなる Wiki ページです.
   */
  wikiPage?: V1WikiPage;
  /**
   * 下書きの最終更新者です.
   */
  lastAuthor?: V1User;
  /**
   * 下書きの最終更新日時です.
   */
  updatedAt?: Date;
  /**
   * 下書きのタイトルです.
   */
  title?: string;
  /**
   * 下書きの本文です.
   */
  body?: string;
  /**
   * 下書きが属するカテゴリです.
   */
  category?: V1WikiPageCategory;
  /**
   * 下書きの keywords (いわゆる meta keywords) です.
   */
  keywords?: string;
  /**
   * 下書きの description (いわゆる meta description) です.
   */
  description?: string;
  /**
   * ページに noindex が設定されているかどうかのフラグです.
   */
  noindex?: boolean;
  /**
   * ページのOGP Image URL (meta og:image)です.
   */
  metaOgpImageURL?: string;
  /**
   * ページのThumbnail Image URL (meta thumbnail)です.
   */
  metaThumbnailImageURL?: string;
  /**
   * ページのmetaThumbnailImageURLが存在しない場合、自動的に設定するかのフラグです.
   */
  metaThumbnailImageAutoSelect?: boolean;
  /**
   * 下書きのタイトルの文頭です.
   */
  titlePrefix?: string;
}

/**
 * An interface representing V1WikiPageDraftCollection.
 * @summary Wiki ページの下書きの一覧です
 */
export interface V1WikiPageDraftCollection {
  drafts?: V1WikiPageDraft[];
}

/**
 * An interface representing V1WikiPageForList.
 */
export interface V1WikiPageForList {
  /**
   * Wiki ページです.
   */
  wikiPage?: V1WikiPage;
  /**
   * Wiki ページのタイトルです.
   */
  title?: string;
  /**
   * Wiki ページの最終更新者です.
   */
  lastUpdater?: string;
  /**
   * 下書きを持っているかどうかを表すフラグです.
   */
  hasDraft?: boolean;
}

/**
 * An interface representing V1WikiPageForListCollection.
 */
export interface V1WikiPageForListCollection {
  wikiPage?: V1WikiPageForList[];
  /**
   * Wiki ページの総数です.
   */
  numOfTotalWikiPages?: number;
}

/**
 * An interface representing V1WikiPageHistoryCollection.
 * @summary Wiki ページ履歴の一覧 (ページごと) です
 */
export interface V1WikiPageHistoryCollection {
  /**
   * Wiki ページです.
   */
  wikiPage?: V1WikiPage;
  history?: V1WikiPageHistory[];
  /**
   * Wiki ページ履歴の総数です.
   */
  numOfTotalHistories?: number;
}

/**
 * An interface representing V1WikiPageImageSize.
 */
export interface V1WikiPageImageSize {
  /**
   * 画像の幅です.
   */
  width?: number;
  /**
   * 画像の高さです.
   */
  height?: number;
}

/**
 * An interface representing V1WikiPagePartial.
 */
export interface V1WikiPagePartial {
  /**
   * Wiki ページ部品をユニークに識別するための ID です。.
   */
  id?: string;
  /**
   * ページ部品が所属する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * Wiki ページ部品名称です.
   */
  name?: string;
  /**
   * Wiki ページ部品の本文です.
   */
  body?: string;
  /**
   * Wiki ページ部品の作成者です.
   */
  author?: V1User;
  /**
   * Wiki ページ部品の最終更新者です.
   */
  lastAuthor?: V1User;
  /**
   * Wiki ページ部品の最終更新日時です.
   */
  lastUpdatedAt?: Date;
  /**
   * Wiki ページ部品がアーカイブされているかどうかを表す値です.
   */
  isArchived?: boolean;
}

/**
 * An interface representing V1WikiPagePartialCollection.
 */
export interface V1WikiPagePartialCollection {
  /**
   * Wiki 記事部品の一覧です.
   */
  pagePartials?: V1WikiPagePartial[];
  /**
   * 記事部品の総数です.
   */
  numOfTotalPagePartials?: number;
}

/**
 * An interface representing V1WikiPagePartialName.
 */
export interface V1WikiPagePartialName {
  /**
   * Wiki ページ部品をユニークに識別するための ID です。.
   */
  id?: string;
  /**
   * Wiki ページ部品のタイトルです.
   */
  name?: string;
}

/**
 * An interface representing V1WikiPagePartialNameCollection.
 */
export interface V1WikiPagePartialNameCollection {
  wikiPagePartial?: V1WikiPagePartialName[];
}

/**
 * An interface representing V1WikiPageSitemap.
 * @summary サイトマップ向け Wiki ページ情報です
 */
export interface V1WikiPageSitemap {
  /**
   * Wiki ページをユニークに識別するための ID です.
   */
  id?: string;
  /**
   * ページのエイリアスです。指定されている場合、 id の代わりにこの値が URL の一部に含まれます.
   */
  path?: string;
  /**
   * そのページがカテゴリ記事として設定されているかどうかのフラグです.
   */
  isCategoryPage?: boolean;
  /**
   * 最終更新日時です.
   */
  lastUpdatedAt?: Date;
}

/**
 * An interface representing V1WikiPageSitemapCollection.
 * @summary サイトマップ向け Wiki ページ情報の一覧です
 */
export interface V1WikiPageSitemapCollection {
  /**
   * サイトマップ向け Wiki ページ情報です.
   */
  wikiPage?: V1WikiPageSitemap[];
  /**
   * 更新日時を隠すかどうかのフラグです.
   */
  isUpdatedAtHidden?: boolean;
}

/**
 * An interface representing V1WikiPageSummary.
 * @summary Wiki ページの本文以外の情報です
 */
export interface V1WikiPageSummary {
  /**
   * Wiki ページの ID です.
   */
  id?: string;
  /**
   * Wiki ページのタイトルです.
   */
  title?: string;
  /**
   * Wiki ページのパスです.
   */
  path?: string;
  /**
   * Wiki ページの親カテゴリです.
   */
  parentCategory?: string;
  /**
   * Wiki ページの子カテゴリです.
   */
  childCategory?: string;
  /**
   * Wiki ページの最終更新者です.
   */
  lastAuthor?: string;
  /**
   * Wiki ページの description (いわゆる meta description) です.
   */
  description?: string;
  /**
   * Wiki ページの最終更新日時です.
   */
  lastUpdatedAt?: Date;
  /**
   * Wiki ページのリダイレクト先のパスです.
   */
  redirectPageId?: string;
}

/**
 * An interface representing V1WikiPageSummaryCollection.
 * @summary Wiki ページの本文以外の情報の一覧です
 */
export interface V1WikiPageSummaryCollection {
  wikiPageSummaries?: V1WikiPageSummary[];
}

/**
 * An interface representing V1WikiPageTemplate.
 * @summary Wiki ページテンプレートです
 */
export interface V1WikiPageTemplate {
  /**
   * Wiki ページテンプレートをユニークに識別するための ID です.
   */
  id?: string;
  /**
   * テンプレートが属する Wiki です.
   */
  wiki?: V1Wiki;
  /**
   * テンプレート作成者です.
   */
  creator?: V1User;
  /**
   * テンプレート最終更新者です.
   */
  lastAuthor?: V1User;
  /**
   * テンプレートの最終更新日時です.
   */
  updatedAt?: Date;
  /**
   * テンプレートのタイトルです.
   */
  title?: string;
  /**
   * テンプレートの本文です.
   */
  body?: string;
  /**
   * テンプレートが属するカテゴリです.
   */
  category?: V1WikiPageCategory;
  /**
   * テンプレートの keywords です.
   */
  keywords?: string;
  /**
   * テンプレートの description です.
   */
  description?: string;
}

/**
 * An interface representing V1WikiPageTemplateCollection.
 * @summary Wiki ページテンプレートの一覧です
 */
export interface V1WikiPageTemplateCollection {
  template?: V1WikiPageTemplate[];
  /**
   * Wiki ページテンプレートの総数です.
   */
  numOfTotalTemplates?: number;
}

/**
 * An interface representing V1WikiPageTemplateTitleAndBody.
 * @summary Wiki ページテンプレートのタイトルと本文です
 */
export interface V1WikiPageTemplateTitleAndBody {
  /**
   * Wiki ページテンプレートをユニークに識別するための ID です.
   */
  id?: string;
  /**
   * テンプレートのタイトルです.
   */
  title?: string;
  /**
   * テンプレートの本文です.
   */
  body?: string;
}

/**
 * An interface representing V1WikiPageTemplateTitleBodyCollection.
 * @summary Wiki ページテンプレートのタイトルと本文の一覧です
 */
export interface V1WikiPageTemplateTitleBodyCollection {
  templateTitleAndBodies?: V1WikiPageTemplateTitleAndBody[];
}

/**
 * An interface representing V1WikiPageTitleCollection.
 * @summary Wiki ページタイトルの一覧です
 */
export interface V1WikiPageTitleCollection {
  wikiPageTitles?: V1WikiPageTitle[];
}

/**
 * An interface representing V1WikiPageWithTitleAndBody.
 */
export interface V1WikiPageWithTitleAndBody {
  /**
   * Wiki ページです.
   */
  wikiPage?: V1WikiPage;
  /**
   * Wiki ページのタイトルです.
   */
  title?: string;
  /**
   * Wiki ページの本文です.
   */
  body?: string;
  /**
   * Wiki ページの本文を表す ID です.
   */
  historyId?: string;
  /**
   * Wiki ページのタイトルの文頭です.
   */
  titlePrefix?: string;
  /**
   * Wiki ページの本文中に含まれる画像のサイズ情報です.
   */
  imageSizes?: { [propertyName: string]: V1WikiPageImageSize };
}

/**
 * An interface representing V1WikiPageWithTitleAndBodyCollection.
 */
export interface V1WikiPageWithTitleAndBodyCollection {
  wikiPage?: V1WikiPageWithTitleAndBody[];
  numOfTotalWikiPages?: number;
}

/**
 * An interface representing V1WikiPageWithTitleAndLastUpdater.
 */
export interface V1WikiPageWithTitleAndLastUpdater {
  /**
   * Wiki ページです.
   */
  wikiPage?: V1WikiPage;
  /**
   * Wiki ページのタイトルです.
   */
  title?: string;
  /**
   * Wiki ページの最終更新者です.
   */
  lastUpdater?: string;
}

/**
 * An interface representing V1WikiPageWithTitleAndLastUpdaterCollection.
 */
export interface V1WikiPageWithTitleAndLastUpdaterCollection {
  wikiPage?: V1WikiPageWithTitleAndLastUpdater[];
  /**
   * Wiki ページの総数です.
   */
  numOfTotalWikiPages?: number;
}

/**
 * An interface representing V1WikiSlackConfig.
 * @summary WikiSlackConfig を表すオブジェクトです
 */
export interface V1WikiSlackConfig {
  /**
   * Wiki をユニークに識別する人間可読の ID です.
   */
  wikiName?: string;
  /**
   * 記事(新規作成/更新).
   */
  notifyArticleCreateAndUpdate?: boolean;
  /**
   * 記事(削除).
   */
  notifyArticleDelete?: boolean;
  /**
   * 記事下書き(更新).
   */
  notifyArticleDraftUpdate?: boolean;
  /**
   * 記事部品(新規作成/更新).
   */
  notifyPartialCreateAndUpdate?: boolean;
  /**
   * カテゴリ(新規作成/更新).
   */
  notifyCategoryCreateAndUpdate?: boolean;
  /**
   * データシート(新規作成/更新).
   */
  notifyDatasheetsCreateAndUpdate?: boolean;
  /**
   * 通知するチャンネル(#から始まる、存在しない場合は"").
   */
  notifyChannelName?: string;
}

/**
 * An interface representing V1WithWikiRequest.
 * @summary Wiki を指定するためのリクエストです
 */
export interface V1WithWikiRequest {
  /**
   * Wiki をユニークに識別する人間可読の ID です.
   */
  name?: string;
}

/**
 * An interface representing KamigameWikiApiOptions.
 */
export interface KamigameWikiApiOptions extends ServiceClientOptions {
  baseUri?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListJoinedWikiOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Wiki 参加フラグです.
   */
  joined?: boolean;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiCreateWikiPageOptionalParams extends msRest.RequestOptionsBase {
  /**
   * ページの最終更新者の ID です.
   */
  authorId?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiBulkCreateWikiPageOptionalParams extends msRest.RequestOptionsBase {
  /**
   * ページの最終更新者の ID です.
   */
  authorId?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiBulkUpdateWikiPageOptionalParams extends msRest.RequestOptionsBase {
  /**
   * ページの最終更新者の ID です.
   */
  authorId?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiBulkTakahiroWikiPageOptionalParams extends msRest.RequestOptionsBase {
  /**
   * ページの最終更新者の ID です.
   */
  authorId?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPageCategoryOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 一度のリクエストで取得する件数です.
   */
  limit?: number;
  /**
   * 何件目から取得するかの数値です.
   */
  offset?: number;
  /**
   * 検索用の文字列です.
   */
  searchWords?: string[];
  /**
   * ソートする項目を表す文字列です.
   */
  sortedBy?: string;
  /**
   * ソートする順序を表す文字列です.
   */
  sortOrder?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiGetWikiDataSheetDataOptionalParams extends msRest.RequestOptionsBase {
  /**
   * データシートの検索条件です.
   */
  condition?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiDataSheetLinkOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 取得したいシートのシート名です.
   */
  sheetName?: string;
  /**
   * 取得したいシートに関連するスプレッドシートの ID です.
   */
  spreadsheetId?: string;
  /**
   * 自身が参照しているシートの一覧を取得するかのフラグです.
   */
  isInbound?: boolean;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPageHistoriesOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 一回のリクエストで取得する件数です.
   */
  limit?: number;
  /**
   * 何件目から取得するかです.
   */
  offset?: number;
  /**
   * 検索用の文字列です.
   */
  searchWords?: string[];
  /**
   * ソートする項目を表す文字列です.
   */
  sortedBy?: string;
  /**
   * ソートする順序です.
   */
  sortOrder?: string;
  /**
   * 作成日時で絞る場合の始点 Unixtime です.
   */
  startUnixtime?: string;
  /**
   * 作成日時で絞る場合の終点 Unixtime です.
   */
  endUnixtime?: string;
  /**
   * ユーザーで絞る場合のユーザー ID のカンマ区切りのリストです.
   */
  userIds?: string[];
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiGetWikiPageHistoryOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Wiki ページをユニークに識別する ID です.
   */
  pageId?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiMemberOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 一度のリクエストで取得する件数です.
   */
  limit?: number;
  /**
   * 何件目から取得するかの数値です.
   */
  offset?: number;
  /**
   * 検索用の文字列です.
   */
  searchWords?: string[];
  /**
   * ソートする項目を表す文字列です.
   */
  sortedBy?: string;
  /**
   * ソートする順序を表す文字列です.
   */
  sortOrder?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiMemberRequestOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Wiki ページをユニークに識別する ID もしくはパス名です.
   */
  pageId?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPagePartialOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 一度のリクエストで取得する件数です.
   */
  limit?: number;
  /**
   * 何件目から取得するかの数値です.
   */
  offset?: number;
  /**
   * 検索用の文字列です.
   */
  searchWords?: string[];
  /**
   * ソートする項目を表す文字列です.
   */
  sortedBy?: string;
  /**
   * ソートする順序を表す文字列です.
   */
  sortOrder?: string;
  /**
   * アーカイブされた記事部品を返すかどうかを表す値です.
   */
  isArchived?: boolean;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPagePartialTitleOptionalParams extends msRest.RequestOptionsBase {
  /**
   * アーカイブされた記事部品を返すかどうかを表す値です.
   */
  isArchived?: boolean;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPageDraftOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Wiki ページをユニークに識別する ID もしくはパス名です.
   */
  pageId?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPageSummaryOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 削除された記事を対象とするかのフラグです.
   */
  isDeleted?: boolean;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPageTitleBodiesOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 削除された記事を取得するか、を表すフラグです.
   */
  isDeleted?: boolean;
  /**
   * 一度のリクエストで取得する件数です.
   */
  limit?: number;
  /**
   * 何件目から取得するかの数値です.
   */
  offset?: number;
  /**
   * 検索用の文字列です.
   */
  searchWords?: string[];
  /**
   * ソートする項目を表す文字列です.
   */
  sortedBy?: string;
  /**
   * ソートする順序を表す文字列です.
   */
  sortOrder?: string;
  /**
   * 絞り込み対象となるカテゴリの ID です (マイナス値の場合はカテゴリ未設定のみ絞り込み).
   */
  categoryId?: string;
  /**
   * リダイレクト設定された記事のみを取得するか、を表すフラグです.
   */
  onlyRedirected?: boolean;
  /**
   * リダイレクト設定された記事を除外するか、を表すフラグです.
   */
  excludeRedirected?: boolean;
  /**
   * 検索寺に本文も含めて検索するか、を表すフラグです.
   */
  includesBodyOnSearch?: boolean;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPageTitlesOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 削除された記事を取得するか、を表すフラグです.
   */
  isDeleted?: boolean;
  /**
   * 一度のリクエストで取得する件数です.
   */
  limit?: number;
  /**
   * 何件目から取得するかの数値です.
   */
  offset?: number;
  /**
   * 検索用の文字列です.
   */
  searchWords?: string[];
  /**
   * ソートする項目を表す文字列です.
   */
  sortedBy?: string;
  /**
   * ソートする順序を表す文字列です.
   */
  sortOrder?: string;
  /**
   * 絞り込み対象となるカテゴリの ID です (マイナス値の場合はカテゴリ未設定のみ絞り込み).
   */
  categoryId?: string;
  /**
   * リダイレクト設定された記事のみを取得するか、を表すフラグです.
   */
  onlyRedirected?: boolean;
  /**
   * リダイレクト設定された記事を除外するか、を表すフラグです.
   */
  excludeRedirected?: boolean;
  /**
   * 検索寺に本文も含めて検索するか、を表すフラグです.
   */
  includesBodyOnSearch?: boolean;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiEditWikiPageDraftOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 下書きの id です.
   */
  id?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPageHistoriesByWikiPageOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 一回のリクエストで取得する件数です.
   */
  limit?: number;
  /**
   * 何件目から取得するかです.
   */
  offset?: number;
  /**
   * 検索用の文字列です.
   */
  searchWords?: string[];
  /**
   * ソートする項目を表す文字列です.
   */
  sortedBy?: string;
  /**
   * ソートする順序です.
   */
  sortOrder?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPageTemplatesOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 1 回のリクエストで取得する件数です.
   */
  limit?: number;
  /**
   * 何件目から取得するかです.
   */
  offset?: number;
  /**
   * 検索用の文字列です.
   */
  searchWords?: string[];
  /**
   * ソートする項目を表す文字列です.
   */
  sortedBy?: string;
  /**
   * ソートする順序です.
   */
  sortOrder?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiDeleteWikiPageTemplateOptionalParams extends msRest.RequestOptionsBase {
  /**
   * テンプレートのタイトルです.
   */
  bodytitle?: string;
  /**
   * テンプレートの本文です.
   */
  bodybody?: string;
  /**
   * テンプレートのカテゴリを表す ID です.
   */
  bodycategoryId?: string;
  /**
   * テンプレートの keywords です.
   */
  bodykeywords?: string;
  /**
   * テンプレートの description です.
   */
  bodydescription?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiGetImageOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 画像を参照するための URL です.
   */
  url?: string;
  /**
   * 画像の名前です.
   */
  name?: string;
  /**
   * 画像が紐づくwikiのIDです.
   */
  wikiId?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPagesOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 削除された記事を取得するか、を表すフラグです.
   */
  isDeleted?: boolean;
  /**
   * 一度のリクエストで取得する件数です.
   */
  limit?: number;
  /**
   * 何件目から取得するかの数値です.
   */
  offset?: number;
  /**
   * 検索用の文字列です.
   */
  searchWords?: string[];
  /**
   * ソートする項目を表す文字列です.
   */
  sortedBy?: string;
  /**
   * ソートする順序を表す文字列です.
   */
  sortOrder?: string;
  /**
   * 絞り込み対象となるカテゴリの ID です (マイナス値の場合はカテゴリ未設定のみ絞り込み).
   */
  categoryId?: string;
  /**
   * リダイレクト設定された記事のみを取得するか、を表すフラグです.
   */
  onlyRedirected?: boolean;
  /**
   * リダイレクト設定された記事を除外するか、を表すフラグです.
   */
  excludeRedirected?: boolean;
  /**
   * 検索寺に本文も含めて検索するか、を表すフラグです.
   */
  includesBodyOnSearch?: boolean;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiLatestPagesOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Wiki ページをユニークに識別する ID もしくはパス名です.
   */
  pageId?: string;
}

/**
 * Optional Parameters.
 */
export interface KamigameWikiApiListWikiPagesForSitemapOptionalParams extends msRest.RequestOptionsBase {
  /**
   * 削除された記事を取得するか、を表すフラグです.
   */
  isDeleted?: boolean;
  /**
   * 一度のリクエストで取得する件数です.
   */
  limit?: number;
  /**
   * 何件目から取得するかの数値です.
   */
  offset?: number;
  /**
   * 検索用の文字列です.
   */
  searchWords?: string[];
  /**
   * ソートする項目を表す文字列です.
   */
  sortedBy?: string;
  /**
   * ソートする順序を表す文字列です.
   */
  sortOrder?: string;
  /**
   * 絞り込み対象となるカテゴリの ID です (マイナス値の場合はカテゴリ未設定のみ絞り込み).
   */
  categoryId?: string;
  /**
   * リダイレクト設定された記事のみを取得するか、を表すフラグです.
   */
  onlyRedirected?: boolean;
  /**
   * リダイレクト設定された記事を除外するか、を表すフラグです.
   */
  excludeRedirected?: boolean;
  /**
   * 検索寺に本文も含めて検索するか、を表すフラグです.
   */
  includesBodyOnSearch?: boolean;
}

/**
 * Defines values for Permission.
 * Possible values include: 'MEMBER', 'SUB_ADMIN', 'ADMIN'
 * @readonly
 * @enum {string}
 */
export enum Permission {
  MEMBER = 'MEMBER',
  SUBADMIN = 'SUB_ADMIN',
  ADMIN = 'ADMIN',
}

/**
 * Contains response data for the getAllWiki operation.
 */
export type GetAllWikiResponse = V1WikiCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiCollection;
    };
};

/**
 * Contains response data for the createWiki operation.
 */
export type CreateWikiResponse = V1Wiki & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1Wiki;
    };
};

/**
 * Contains response data for the listJoinedWiki operation.
 */
export type ListJoinedWikiResponse = V1WikiCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiCollection;
    };
};

/**
 * Contains response data for the updateWiki operation.
 */
export type UpdateWikiResponse = V1Wiki & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1Wiki;
    };
};

/**
 * Contains response data for the getWikiAccessAnalytics operation.
 */
export type GetWikiAccessAnalyticsResponse = V1WikiAccessAnalytics & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiAccessAnalytics;
    };
};

/**
 * Contains response data for the adjustSpeculativeWikiDataSheets operation.
 */
export type AdjustSpeculativeWikiDataSheetsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getWikiAllowedIPAddress operation.
 */
export type GetWikiAllowedIPAddressResponse = V1WikiAllowedIPAddress & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiAllowedIPAddress;
    };
};

/**
 * Contains response data for the updateWikiIsArchived operation.
 */
export type UpdateWikiIsArchivedResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the createWikiPageCategory operation.
 */
export type CreateWikiPageCategoryResponse = V1WikiPageCategory & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageCategory;
    };
};

/**
 * Contains response data for the getWikiForDashboard operation.
 */
export type GetWikiForDashboardResponse = V1WikiForDashboard & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiForDashboard;
    };
};

/**
 * Contains response data for the listWikiDataSheet operation.
 */
export type ListWikiDataSheetResponse = V1WikiDataSheetCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiDataSheetCollection;
    };
};

/**
 * Contains response data for the publishWikiDataDownloadURL operation.
 */
export type PublishWikiDataDownloadURLResponse = V1WikiDataDownloadURL & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiDataDownloadURL;
    };
};

/**
 * Contains response data for the publishWikiDataUploadURL operation.
 */
export type PublishWikiDataUploadURLResponse = V1WikiDataUploadURL & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiDataUploadURL;
    };
};

/**
 * Contains response data for the listWikiDomainAllowlist operation.
 */
export type ListWikiDomainAllowlistResponse = V1ListWikiDomainAllowlistResponse & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1ListWikiDomainAllowlistResponse;
    };
};

/**
 * Contains response data for the listWikiMemberInvite operation.
 */
export type ListWikiMemberInviteResponse = V1WikiMemberInviteCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiMemberInviteCollection;
    };
};

/**
 * Contains response data for the updateWikiPublishConfig operation.
 */
export type UpdateWikiPublishConfigResponse = V1Wiki & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1Wiki;
    };
};

/**
 * Contains response data for the requestTemplateCodeExecution operation.
 */
export type RequestTemplateCodeExecutionResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listWikiTakahiroTemplateCodeSpreadsheetURL operation.
 */
export type ListWikiTakahiroTemplateCodeSpreadsheetURLResponse = V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki;
    };
};

/**
 * Contains response data for the listWikiPageTemplateTitleBodies operation.
 */
export type ListWikiPageTemplateTitleBodiesResponse = V1WikiPageTemplateTitleBodyCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageTemplateTitleBodyCollection;
    };
};

/**
 * Contains response data for the updateWikiAllowedIPAddress operation.
 */
export type UpdateWikiAllowedIPAddressResponse = V1WikiAllowedIPAddress & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiAllowedIPAddress;
    };
};

/**
 * Contains response data for the updateWikiCustomCss operation.
 */
export type UpdateWikiCustomCssResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateWikiCustomHeader operation.
 */
export type UpdateWikiCustomHeaderResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the createWikiDataSheet operation.
 */
export type CreateWikiDataSheetResponse = V1WikiDataSheet & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiDataSheet;
    };
};

/**
 * Contains response data for the createWikiDataSheetLink operation.
 */
export type CreateWikiDataSheetLinkResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateWikiDataSheet operation.
 */
export type UpdateWikiDataSheetResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateWikiHeaderImage operation.
 */
export type UpdateWikiHeaderImageResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateWikiIconImage operation.
 */
export type UpdateWikiIconImageResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateWikiMetaMediumText operation.
 */
export type UpdateWikiMetaMediumTextResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the createWikiMemberInvite operation.
 */
export type CreateWikiMemberInviteResponse = V1WikiMemberInvite & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiMemberInvite;
    };
};

/**
 * Contains response data for the approveWikiMemberRequest operation.
 */
export type ApproveWikiMemberRequestResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the rejectWikiMemberRequest operation.
 */
export type RejectWikiMemberRequestResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateWikiMemberPermission operation.
 */
export type UpdateWikiMemberPermissionResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateWikiMeta operation.
 */
export type UpdateWikiMetaResponse = V1WikiMeta & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiMeta;
    };
};

/**
 * Contains response data for the updateWikiNav operation.
 */
export type UpdateWikiNavResponse = V1ListWikiNavWithWiki & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1ListWikiNavWithWiki;
    };
};

/**
 * Contains response data for the updateWikiTooltip operation.
 */
export type UpdateWikiTooltipResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the bulkTakahiroWikiPageCategory operation.
 */
export type BulkTakahiroWikiPageCategoryResponse = V1BulkTakahiroWikiPageCategoryResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1BulkTakahiroWikiPageCategoryResult;
    };
};

/**
 * Contains response data for the createWikiPage operation.
 */
export type CreateWikiPageResponse = V1WikiPageWithTitleAndBody & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageWithTitleAndBody;
    };
};

/**
 * Contains response data for the bulkCreateWikiPage operation.
 */
export type BulkCreateWikiPageResponse = V1BulkCreateWikiPageResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1BulkCreateWikiPageResult;
    };
};

/**
 * Contains response data for the bulkUpdateWikiPage operation.
 */
export type BulkUpdateWikiPageResponse = V1BulkUpdateWikiPageResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1BulkUpdateWikiPageResult;
    };
};

/**
 * Contains response data for the bulkUpdateCategoryOfWikiPage operation.
 */
export type BulkUpdateCategoryOfWikiPageResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the bulkTakahiroWikiPage operation.
 */
export type BulkTakahiroWikiPageResponse = V1BulkTakahiroWikiPageResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1BulkTakahiroWikiPageResult;
    };
};

/**
 * Contains response data for the deleteWikiPage operation.
 */
export type DeleteWikiPageResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateWikiPage operation.
 */
export type UpdateWikiPageResponse = V1WikiPageWithTitleAndBody & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageWithTitleAndBody;
    };
};

/**
 * Contains response data for the restoreWikiPage operation.
 */
export type RestoreWikiPageResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listWikiPageCategory operation.
 */
export type ListWikiPageCategoryResponse = V1WikiPageCategoryCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageCategoryCollection;
    };
};

/**
 * Contains response data for the getWikiPageCategory operation.
 */
export type GetWikiPageCategoryResponse = V1WikiPageCategory & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageCategory;
    };
};

/**
 * Contains response data for the deleteWikiPageCategory operation.
 */
export type DeleteWikiPageCategoryResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the updateWikiPageCategory operation.
 */
export type UpdateWikiPageCategoryResponse = V1WikiPageCategory & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageCategory;
    };
};

/**
 * Contains response data for the getChatGptCompletions operation.
 */
export type GetChatGptCompletionsResponse = V1GetChatGptCompletionsResponse & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1GetChatGptCompletionsResponse;
    };
};

/**
 * Contains response data for the getChatGptCompletionsWithPrompt operation.
 */
export type GetChatGptCompletionsWithPromptResponse = V1GetChatGptCompletionsResponse & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1GetChatGptCompletionsResponse;
    };
};

/**
 * Contains response data for the configureRealtimeDatabaseRule operation.
 */
export type ConfigureRealtimeDatabaseRuleResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the createWikiDataChangelog operation.
 */
export type CreateWikiDataChangelogResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getWikiDataSheetData operation.
 */
export type GetWikiDataSheetDataResponse = V1WikiDataSheets & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiDataSheets;
    };
};

/**
 * Contains response data for the listWikiDataSheetLink operation.
 */
export type ListWikiDataSheetLinkResponse = V1WikiDataSheetLinkCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiDataSheetLinkCollection;
    };
};

/**
 * Contains response data for the deleteWikiDataSheetLink operation.
 */
export type DeleteWikiDataSheetLinkResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getWikiDataSheet operation.
 */
export type GetWikiDataSheetResponse = V1WikiDataSheet & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiDataSheet;
    };
};

/**
 * Contains response data for the deleteWikiDataSheet operation.
 */
export type DeleteWikiDataSheetResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the createWikiDomainAllowlist operation.
 */
export type CreateWikiDomainAllowlistResponse = V1WikiAllowedDomain & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiAllowedDomain;
    };
};

/**
 * Contains response data for the deleteWikiDomainAllowlist operation.
 */
export type DeleteWikiDomainAllowlistResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listWikiPageHistories operation.
 */
export type ListWikiPageHistoriesResponse = V1WikiHistoryCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiHistoryCollection;
    };
};

/**
 * Contains response data for the getWikiPageHistory operation.
 */
export type GetWikiPageHistoryResponse = V1WikiPageHistory & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageHistory;
    };
};

/**
 * Contains response data for the updateWikiPageHistoryDiff operation.
 */
export type UpdateWikiPageHistoryDiffResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listWikiMember operation.
 */
export type ListWikiMemberResponse = V1WikiMemberCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiMemberCollection;
    };
};

/**
 * Contains response data for the deleteWikiMemberInvite operation.
 */
export type DeleteWikiMemberInviteResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listWikiMemberRequest operation.
 */
export type ListWikiMemberRequestResponse = V1WikiMemberRequestCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiMemberRequestCollection;
    };
};

/**
 * Contains response data for the deleteWikiMember operation.
 */
export type DeleteWikiMemberResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listWikiPagePartial operation.
 */
export type ListWikiPagePartialResponse = V1WikiPagePartialCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPagePartialCollection;
    };
};

/**
 * Contains response data for the createWikiPagePartial operation.
 */
export type CreateWikiPagePartialResponse = V1WikiPagePartial & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPagePartial;
    };
};

/**
 * Contains response data for the listWikiPagePartialTitle operation.
 */
export type ListWikiPagePartialTitleResponse = V1WikiPagePartialNameCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPagePartialNameCollection;
    };
};

/**
 * Contains response data for the deleteWikiPagePartial operation.
 */
export type DeleteWikiPagePartialResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the restoreWikiPagePartial operation.
 */
export type RestoreWikiPagePartialResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getWikiPagePartial operation.
 */
export type GetWikiPagePartialResponse = V1WikiPagePartial & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPagePartial;
    };
};

/**
 * Contains response data for the updateWikiPagePartial operation.
 */
export type UpdateWikiPagePartialResponse = V1WikiPagePartial & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPagePartial;
    };
};

/**
 * Contains response data for the listWikiPageDraft operation.
 */
export type ListWikiPageDraftResponse = V1WikiPageDraftCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageDraftCollection;
    };
};

/**
 * Contains response data for the listWikiPageSummary operation.
 */
export type ListWikiPageSummaryResponse = V1WikiPageSummaryCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageSummaryCollection;
    };
};

/**
 * Contains response data for the listWikiPageTitleBodies operation.
 */
export type ListWikiPageTitleBodiesResponse = V1WikiPageWithTitleAndBodyCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageWithTitleAndBodyCollection;
    };
};

/**
 * Contains response data for the listWikiPageTitles operation.
 */
export type ListWikiPageTitlesResponse = V1WikiPageTitleCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageTitleCollection;
    };
};

/**
 * Contains response data for the updateLinkedWikiPageCategory operation.
 */
export type UpdateLinkedWikiPageCategoryResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getWikiPageDraft operation.
 */
export type GetWikiPageDraftResponse = V1WikiPageDraft & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageDraft;
    };
};

/**
 * Contains response data for the deleteWikiPageDraft operation.
 */
export type DeleteWikiPageDraftResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the editWikiPageDraft operation.
 */
export type EditWikiPageDraftResponse = V1WikiPageDraft & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageDraft;
    };
};

/**
 * Contains response data for the listWikiPageHistoriesByWikiPage operation.
 */
export type ListWikiPageHistoriesByWikiPageResponse = V1WikiPageHistoryCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageHistoryCollection;
    };
};

/**
 * Contains response data for the updateWikiPageRedirect operation.
 */
export type UpdateWikiPageRedirectResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getWikiSlackConfig operation.
 */
export type GetWikiSlackConfigResponse = V1WikiSlackConfig & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiSlackConfig;
    };
};

/**
 * Contains response data for the updateWikiSlackConfig operation.
 */
export type UpdateWikiSlackConfigResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listWikiPageTemplates operation.
 */
export type ListWikiPageTemplatesResponse = V1WikiPageTemplateCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageTemplateCollection;
    };
};

/**
 * Contains response data for the createWikiPageTemplate operation.
 */
export type CreateWikiPageTemplateResponse = V1WikiPageTemplate & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageTemplate;
    };
};

/**
 * Contains response data for the getWikiPageTemplate operation.
 */
export type GetWikiPageTemplateResponse = V1WikiPageTemplate & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageTemplate;
    };
};

/**
 * Contains response data for the deleteWikiPageTemplate operation.
 */
export type DeleteWikiPageTemplateResponse = V1WikiPageTemplate & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageTemplate;
    };
};

/**
 * Contains response data for the updateWikiPageTemplate operation.
 */
export type UpdateWikiPageTemplateResponse = V1WikiPageTemplate & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageTemplate;
    };
};

/**
 * Contains response data for the updateLinkedWikiPageTemplateCategory operation.
 */
export type UpdateLinkedWikiPageTemplateCategoryResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getWikiPage operation.
 */
export type GetWikiPageResponse = V1WikiPageWithTitleAndBody & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageWithTitleAndBody;
    };
};

/**
 * Contains response data for the getImage operation.
 */
export type GetImageResponse = V1Image & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1Image;
    };
};

/**
 * Contains response data for the login operation.
 */
export type LoginResponse = V1Session & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1Session;
    };
};

/**
 * Contains response data for the listGlobalDomainAllowlist operation.
 */
export type ListGlobalDomainAllowlistResponse = V1ListGlobalDomainAllowlistResponse & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1ListGlobalDomainAllowlistResponse;
    };
};

/**
 * Contains response data for the createGlobalDomainAllowlist operation.
 */
export type CreateGlobalDomainAllowlistResponse = V1GlobalAllowedDomain & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1GlobalAllowedDomain;
    };
};

/**
 * Contains response data for the deleteGlobalDomainAllowlist operation.
 */
export type DeleteGlobalDomainAllowlistResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listPermission operation.
 */
export type ListPermissionResponse = V1RuleCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1RuleCollection;
    };
};

/**
 * Contains response data for the listPublishedWiki operation.
 */
export type ListPublishedWikiResponse = V1ListWikiResponse & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1ListWikiResponse;
    };
};

/**
 * Contains response data for the getWiki operation.
 */
export type GetWikiResponse = V1Wiki & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1Wiki;
    };
};

/**
 * Contains response data for the listParentWikiPageCategory operation.
 */
export type ListParentWikiPageCategoryResponse = V1WikiPageCategoryCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageCategoryCollection;
    };
};

/**
 * Contains response data for the getWikiCustomCss operation.
 */
export type GetWikiCustomCssResponse = V1WikiCustomCss & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiCustomCss;
    };
};

/**
 * Contains response data for the listWikiCustomHeader operation.
 */
export type ListWikiCustomHeaderResponse = V1ListWikiCustomHeaderWithWiki & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1ListWikiCustomHeaderWithWiki;
    };
};

/**
 * Contains response data for the listMemcacheKey operation.
 */
export type ListMemcacheKeyResponse = V1WikiMemcacheKeyHashCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiMemcacheKeyHashCollection;
    };
};

/**
 * Contains response data for the listWikiNav operation.
 */
export type ListWikiNavResponse = V1ListWikiNavWithWiki & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1ListWikiNavWithWiki;
    };
};

/**
 * Contains response data for the listWikiPermission operation.
 */
export type ListWikiPermissionResponse = V1RuleCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1RuleCollection;
    };
};

/**
 * Contains response data for the listWikiTooltip operation.
 */
export type ListWikiTooltipResponse = V1ListWikiTooltipMessage & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1ListWikiTooltipMessage;
    };
};

/**
 * Contains response data for the createWikiMemberRequest operation.
 */
export type CreateWikiMemberRequestResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listChildrenWikiPageCategory operation.
 */
export type ListChildrenWikiPageCategoryResponse = V1WikiPageCategoryCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageCategoryCollection;
    };
};

/**
 * Contains response data for the getWikiMemberInvite operation.
 */
export type GetWikiMemberInviteResponse = V1WikiMemberInvite & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiMemberInvite;
    };
};

/**
 * Contains response data for the approveWikiMemberInvite operation.
 */
export type ApproveWikiMemberInviteResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the listWikiPages operation.
 */
export type ListWikiPagesResponse = V1WikiPageForListCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageForListCollection;
    };
};

/**
 * Contains response data for the getWikiPagePartialByName operation.
 */
export type GetWikiPagePartialByNameResponse = V1WikiPagePartial & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPagePartial;
    };
};

/**
 * Contains response data for the listWikiLatestPages operation.
 */
export type ListWikiLatestPagesResponse = V1WikiPageWithTitleAndLastUpdaterCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageWithTitleAndLastUpdaterCollection;
    };
};

/**
 * Contains response data for the listWikiPagesForSitemap operation.
 */
export type ListWikiPagesForSitemapResponse = V1WikiPageSitemapCollection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageSitemapCollection;
    };
};

/**
 * Contains response data for the getPublishedWikiPage operation.
 */
export type GetPublishedWikiPageResponse = V1WikiPageWithTitleAndBody & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: V1WikiPageWithTitleAndBody;
    };
};
