/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";

export const authorId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "authorId"
  ],
  mapper: {
    serializedName: "authorId",
    type: {
      name: "String"
    }
  }
};
export const bodybody: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "bodybody"
  ],
  mapper: {
    serializedName: "body.body",
    type: {
      name: "String"
    }
  }
};
export const bodycategoryId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "bodycategoryId"
  ],
  mapper: {
    serializedName: "body.categoryId",
    type: {
      name: "String"
    }
  }
};
export const bodydescription: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "bodydescription"
  ],
  mapper: {
    serializedName: "body.description",
    type: {
      name: "String"
    }
  }
};
export const bodykeywords: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "bodykeywords"
  ],
  mapper: {
    serializedName: "body.keywords",
    type: {
      name: "String"
    }
  }
};
export const bodytitle: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "bodytitle"
  ],
  mapper: {
    serializedName: "body.title",
    type: {
      name: "String"
    }
  }
};
export const categoryId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "categoryId"
  ],
  mapper: {
    serializedName: "categoryId",
    type: {
      name: "String"
    }
  }
};
export const condition: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "condition"
  ],
  mapper: {
    serializedName: "condition",
    type: {
      name: "String"
    }
  }
};
export const endUnixtime: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "endUnixtime"
  ],
  mapper: {
    serializedName: "endUnixtime",
    type: {
      name: "String"
    }
  }
};
export const excludeRedirected: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "excludeRedirected"
  ],
  mapper: {
    serializedName: "excludeRedirected",
    type: {
      name: "Boolean"
    }
  }
};
export const historyId: msRest.OperationURLParameter = {
  parameterPath: "historyId",
  mapper: {
    required: true,
    serializedName: "historyId",
    type: {
      name: "String"
    }
  }
};
export const id0: msRest.OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    required: true,
    serializedName: "id",
    type: {
      name: "String"
    }
  }
};
export const id1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "id"
  ],
  mapper: {
    serializedName: "id",
    type: {
      name: "String"
    }
  }
};
export const includesBodyOnSearch: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "includesBodyOnSearch"
  ],
  mapper: {
    serializedName: "includesBodyOnSearch",
    type: {
      name: "Boolean"
    }
  }
};
export const isArchived: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "isArchived"
  ],
  mapper: {
    serializedName: "isArchived",
    type: {
      name: "Boolean"
    }
  }
};
export const isDeleted: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "isDeleted"
  ],
  mapper: {
    serializedName: "isDeleted",
    type: {
      name: "Boolean"
    }
  }
};
export const isInbound: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "isInbound"
  ],
  mapper: {
    serializedName: "isInbound",
    type: {
      name: "Boolean"
    }
  }
};
export const joined: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "joined"
  ],
  mapper: {
    serializedName: "joined",
    type: {
      name: "Boolean"
    }
  }
};
export const limit: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "limit"
  ],
  mapper: {
    serializedName: "limit",
    type: {
      name: "Number"
    }
  }
};
export const name0: msRest.OperationURLParameter = {
  parameterPath: "name",
  mapper: {
    required: true,
    serializedName: "name",
    type: {
      name: "String"
    }
  }
};
export const name1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "name"
  ],
  mapper: {
    serializedName: "name",
    type: {
      name: "String"
    }
  }
};
export const offset: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "offset"
  ],
  mapper: {
    serializedName: "offset",
    type: {
      name: "Number"
    }
  }
};
export const onlyRedirected: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "onlyRedirected"
  ],
  mapper: {
    serializedName: "onlyRedirected",
    type: {
      name: "Boolean"
    }
  }
};
export const pageId0: msRest.OperationURLParameter = {
  parameterPath: "pageId",
  mapper: {
    required: true,
    serializedName: "pageId",
    type: {
      name: "String"
    }
  }
};
export const pageId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "pageId"
  ],
  mapper: {
    serializedName: "pageId",
    type: {
      name: "String"
    }
  }
};
export const searchWords: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "searchWords"
  ],
  mapper: {
    serializedName: "searchWords",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  },
  collectionFormat: msRest.QueryCollectionFormat.Multi
};
export const sheetId: msRest.OperationURLParameter = {
  parameterPath: "sheetId",
  mapper: {
    required: true,
    serializedName: "sheetId",
    type: {
      name: "String"
    }
  }
};
export const sheetName: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sheetName"
  ],
  mapper: {
    serializedName: "sheetName",
    type: {
      name: "String"
    }
  }
};
export const sortedBy: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sortedBy"
  ],
  mapper: {
    serializedName: "sortedBy",
    type: {
      name: "String"
    }
  }
};
export const sortOrder: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sortOrder"
  ],
  mapper: {
    serializedName: "sortOrder",
    type: {
      name: "String"
    }
  }
};
export const spreadsheetId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "spreadsheetId"
  ],
  mapper: {
    serializedName: "spreadsheetId",
    type: {
      name: "String"
    }
  }
};
export const startUnixtime: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "startUnixtime"
  ],
  mapper: {
    serializedName: "startUnixtime",
    type: {
      name: "String"
    }
  }
};
export const templateId: msRest.OperationURLParameter = {
  parameterPath: "templateId",
  mapper: {
    required: true,
    serializedName: "templateId",
    type: {
      name: "String"
    }
  }
};
export const url: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "url"
  ],
  mapper: {
    serializedName: "url",
    type: {
      name: "String"
    }
  }
};
export const userid: msRest.OperationURLParameter = {
  parameterPath: "userid",
  mapper: {
    required: true,
    serializedName: "user.id",
    type: {
      name: "String"
    }
  }
};
export const userId: msRest.OperationURLParameter = {
  parameterPath: "userId",
  mapper: {
    required: true,
    serializedName: "userId",
    type: {
      name: "String"
    }
  }
};
export const userIds: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "userIds"
  ],
  mapper: {
    serializedName: "userIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  },
  collectionFormat: msRest.QueryCollectionFormat.Multi
};
export const wikiId0: msRest.OperationURLParameter = {
  parameterPath: "wikiId",
  mapper: {
    required: true,
    serializedName: "wikiId",
    type: {
      name: "String"
    }
  }
};
export const wikiId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "wikiId"
  ],
  mapper: {
    serializedName: "wikiId",
    type: {
      name: "String"
    }
  }
};
export const wikiname: msRest.OperationURLParameter = {
  parameterPath: "wikiname",
  mapper: {
    required: true,
    serializedName: "wiki.name",
    type: {
      name: "String"
    }
  }
};
export const wikiName: msRest.OperationURLParameter = {
  parameterPath: "wikiName",
  mapper: {
    required: true,
    serializedName: "wikiName",
    type: {
      name: "String"
    }
  }
};
export const wikiPagePartialID: msRest.OperationURLParameter = {
  parameterPath: "wikiPagePartialID",
  mapper: {
    required: true,
    serializedName: "wikiPagePartialID",
    type: {
      name: "String"
    }
  }
};
export const wikiPagePartialName: msRest.OperationURLParameter = {
  parameterPath: "wikiPagePartialName",
  mapper: {
    required: true,
    serializedName: "wikiPagePartialName",
    type: {
      name: "String"
    }
  }
};
