var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        size: "md",
        "ok-only": "",
        centered: "",
        static: "",
        title: "キーボードショートカットのヘルプ",
        "no-fade": "",
      },
    },
    _vm._l(_vm.shortcuts, function (commandNames, commandKey) {
      return _c("div", { key: commandKey, staticClass: "command-row" }, [
        _c(
          "div",
          { staticClass: "command-key-text" },
          _vm._l(commandNames, function (commandName) {
            return _c("span", { key: commandName, staticClass: "key" }, [
              _vm._v(_vm._s(commandName)),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "command-explanation" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.convertCommandNameToExplanation(commandKey))),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }