'use strict';

const axios = require('axios');
const graphqlQueryBuilder = require('graphql-query-builder');

function buildQueryString(conditions) {
  const conditionsSet = new Set(conditions);
  const uniqConditions = Array.from(conditionsSet);

  const pagePartialSheetDataQuery = new graphqlQueryBuilder('wikiPagePartialSheetDataList', {
    conditions: uniqConditions.sort(),
  });
  pagePartialSheetDataQuery.find({ wikiPagePartialSheetDataList: ['condition', 'body', 'data'] }, 'updatedAt');

  return pagePartialSheetDataQuery;
}

function fetchPagePartialSheetData(apiUrlBase, game, conditions) {
  const apiUrl = `${apiUrlBase}/wiki/${encodeURIComponent(game)}/graphql`;
  return axios.post(apiUrl, {
    query: `{${buildQueryString(conditions)}}`,
  });
}

module.exports = fetchPagePartialSheetData;
