const dataEmbeddedMarkerPattern = /\$\(("([^"]+)" "([^"]+)" "([^"]+)")\)\{([^}]*)\}/g;
const dataCalculatedMarkerPattern = /\$\$\(("([^"]+)" "([^"]+)")\)\{([^}]*)\}/g;

const getSpreadsheetIdBySpreadsheetUrl = url => {
  return url.match(/\/spreadsheets\/d\/([^\/]+)\//)[1];
};

const getSheetIdBySpreadsheetUrl = url => {
  return url.match(/\/spreadsheets\/d\/[^\/]+\/.*#gid=([^&]+)/)[1] || 0;
};

const extractDataEmbeddedSyntax = content => {
  const result = [];

  let matches;
  while ((matches = dataEmbeddedMarkerPattern.exec(content))) {
    result.push({
      whole: matches[0],
      target: matches[1],
      url: matches[2],
      id: matches[3],
      column: matches[4],
      embedded: matches[5],
    });
  }

  return result;
};

const extractDataCalculatedSyntax = content => {
  const result = [];

  let matches;
  while ((matches = dataCalculatedMarkerPattern.exec(content))) {
    result.push({
      whole: matches[0],
      target: matches[1],
      id: matches[2],
      key: matches[3],
      embedded: matches[4],
    });
  }

  return result;
};

const extractDependingSpreadsheetsFromMarkdownText = content => {
  const urls = new Set(extractDataEmbeddedSyntax(content).map(v => v.url));

  extractDataCalculatedSyntax(content).forEach(v => {
    const calculator = templateCode.get(v.key);
    if (!calculator) {
      return;
    }

    calculator.fetchUrls().forEach(url => {
      urls.add(url);
    });
  });

  return Array.from(urls);
};

module.exports = {
  getSpreadsheetIdBySpreadsheetUrl,
  getSheetIdBySpreadsheetUrl,
  extractDataEmbeddedSyntax,
  extractDataCalculatedSyntax,
  extractDependingSpreadsheetsFromMarkdownText,
};
