


















































import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';

import { V1Wiki, V1WikiMemberInvite } from '@/api-client/generated/models';

@Component({
  name: 'wiki-create',
})
export default class WikiInvite extends KamigameVue {
  loaded = false;
  errored = false;

  wiki: V1Wiki = {};
  wikiMemberInvite: V1WikiMemberInvite = {};

  mounted() {
    Promise.all([this.api.getWiki(this.wikiName), this.api.getWikiMemberInvite(this.wikiName, this.inviteId)])
      .then((results) => {
        this.loaded = true;

        [this.wiki, this.wikiMemberInvite] = results;
      })
      .catch((e) => {
        this.loaded = true;
        this.errored = true;
      });
  }

  async join() {
    this.api
      .approveWikiMemberInvite(this.wikiName, this.inviteId)
      .then(() => this.$store.getters.getAbility(this.wikiName, true))
      .then(() => {
        this.setFlashMessage('success', '招待を受諾しました');

        this.router.push({
          name: 'wiki_dashboard',
          params: { name: this.wikiName },
        });
      });
  }

  get inviteId(): string {
    return this.route.params.id || '';
  }
}
