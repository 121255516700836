var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { cols: _vm.cols } },
    [
      _c(
        "b-card",
        { attrs: { "header-tag": "header", "footer-tag": "footer" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("strong", [_vm._v("基本情報")]),
          ]),
          _vm._v(" "),
          _c(
            "table",
            {
              staticClass: "base-config-table",
              attrs: { border: "1", bordercolor: "#979494" },
            },
            _vm._l(_vm.baseConfig, function (baseConfigElement) {
              return _c("tr", { key: baseConfigElement.head }, [
                _c("th", [_vm._v(_vm._s(baseConfigElement.head))]),
                _vm._v(" "),
                baseConfigElement.head === "アイコン"
                  ? _c(
                      "td",
                      [_c("b-img", { attrs: { src: baseConfigElement.data } })],
                      1
                    )
                  : _c("td", [_vm._v(_vm._s(baseConfigElement.data))]),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }