
























































import Component from 'vue-class-component';
import KamigameVue from '@/KamigameVue';
import 'bootstrap-icons/font/bootstrap-icons.css';
import PinchZoom from 'vue-pinch-zoom';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

@Component({
  name: 'main-image-modal',
  components: {
    PinchZoom,
    VueCropper,
  },
  props: {
    imageUrl: {
      type: String,
    },
    file: {
      type: Array as () => File[],
    },
  },
})
export default class MainImageModal extends KamigameVue {
  sections: string[] = [];
  blobURL = '';

  onShown() {
    this.cropper.replace(this.$props.imageUrl);
  }

  flipX() {
    const dom = this.$refs.flipX;
    let scale = (dom as any).getAttribute('data-scale');
    scale = scale ? -scale : -1;
    (this.$refs.cropper as any).scaleX(scale);
    (dom as any).setAttribute('data-scale', scale);
  }

  onReady() {
    this.cropper.initCrop();
  }

  addSection() {
    this.sections.push('');
  }

  zoom(percent: number) {
    (this.$refs.cropper as any).relativeZoom(percent);
  }

  show() {
    const mainImageModal = this.$refs.mainImageModal as any;
    mainImageModal.show();
  }

  hide() {
    const imageUploaderModal = this.$refs.mainImageModal as any;
    imageUploaderModal.hide();
  }

  private get cropper() {
    return this.$refs.cropper as any;
  }
}
