var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.modal-main",
              modifiers: { "modal-main": true },
            },
          ],
          attrs: { block: "" },
        },
        [_vm._v("画像一括変換")]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-main",
            title: "画像一括変換",
            size: "lg",
            "no-close-on-backdrop": "",
            static: "",
          },
          on: { ok: _vm.bulkReplaceImageUrl },
        },
        [
          _c("p", [
            _vm._v("変換に使用するスプレッドシートのURLを入力してください。"),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n      シートの列名が以下になっている必要があります。"),
            _c("br"),
            _vm._v("\n      ・"),
            _c("strong", [_vm._v("Image URL")]),
            _vm._v("：変換前の画像URL"),
            _c("br"),
            _vm._v("\n      ・"),
            _c("strong", [_vm._v("New Image URL")]),
            _vm._v("：変換後の画像URL"),
            _c("br"),
          ]),
          _vm._v(" "),
          _c(
            "p",
            [
              _vm._v("\n      スプレッドシートURL：\n      "),
              _c("b-form-input", {
                model: {
                  value: _vm.spreadsheetUrl,
                  callback: function ($$v) {
                    _vm.spreadsheetUrl = $$v
                  },
                  expression: "spreadsheetUrl",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            [
              _vm._v("\n      シート名：\n      "),
              _c("b-form-input", {
                model: {
                  value: _vm.sheetTitle,
                  callback: function ($$v) {
                    _vm.sheetTitle = $$v
                  },
                  expression: "sheetTitle",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }