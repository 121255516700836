var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn wiki-page-import" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { "header-tag": "header", "footer-tag": "footer" } },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("strong", [_vm._v("記事一括作成・更新")]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "複数の Markdown ファイルをアップロードして一括で記事を作成または更新できます。"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          上書き更新をする場合は Markdown ファイルの FrontMatter 部分に\n          "
                    ),
                    _c("code", [_vm._v("url: 上書きしたい記事のURL")]),
                    _vm._v(" を記載してください。\n        "),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("\n          例: "),
                    _c("code", [_vm._v("url: " + _vm._s(_vm.exampleURL))]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "選択された記事が多い場合、処理に時間がかかることがあります。"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        disabled: _vm.uploading,
                        label: "アップロードする記事ファイル (Markdown)",
                        description:
                          "アップロードする記事ファイル (Markdown) が入ったフォルダを選択します",
                      },
                    },
                    [
                      _c("b-form-file", {
                        attrs: {
                          state: _vm.files.length > 0,
                          placeholder: "ファイルを選択してください",
                          directory: "",
                          multiple: "",
                          accept: ".md",
                        },
                        model: {
                          value: _vm.files,
                          callback: function ($$v) {
                            _vm.files = $$v
                          },
                          expression: "files",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.files.length > 0
                    ? _c("div", { staticClass: "mt-3" }, [
                        _vm.uploading
                          ? _c(
                              "div",
                              [
                                _c("p", [
                                  _vm._v(
                                    "アップロード中です。しばらくお待ちください... (" +
                                      _vm._s(_vm.proceeded) +
                                      " / " +
                                      _vm._s(_vm.files.length) +
                                      ")"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-progress",
                                  { attrs: { max: _vm.files.length } },
                                  [
                                    _c("b-progress-bar", {
                                      attrs: {
                                        value: _vm.proceeded,
                                        variant: "primary",
                                        striped: "",
                                        animated: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "b-input-group",
                                  { staticClass: "mt-3" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mr-sm-2",
                                        attrs: { for: "categoryConfig" },
                                      },
                                      [_vm._v("カテゴリ")]
                                    ),
                                    _vm._v(" "),
                                    _vm.selectedCategory.displayName
                                      ? _c(
                                          "b-button-group",
                                          [
                                            _c(
                                              "b-btn",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.categoryMenu",
                                                    modifiers: {
                                                      categoryMenu: true,
                                                    },
                                                  },
                                                ],
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-folder",
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                   \n                  " +
                                                    _vm._s(
                                                      _vm.selectedCategory
                                                        .displayName
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-btn",
                                              {
                                                on: {
                                                  click: _vm.clearCategory,
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-close",
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "b-btn",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.categoryMenu",
                                                modifiers: {
                                                  categoryMenu: true,
                                                },
                                              },
                                            ],
                                            attrs: {
                                              variant: "outline-secondary",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-plus",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                            _vm._v(" カテゴリを設定する"),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("kamigame-category-tree-select-modal", {
                                  attrs: {
                                    modalId: "categoryMenu",
                                    modalTitle: "カテゴリを設定",
                                  },
                                  on: { selectionSaved: _vm.onCategorySaved },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "mt-3",
                                    attrs: {
                                      label: "アップロードする記事の公開設定",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-radio",
                                      {
                                        attrs: { value: "1" },
                                        model: {
                                          value: _vm.published,
                                          callback: function ($$v) {
                                            _vm.published = $$v
                                          },
                                          expression: "published",
                                        },
                                      },
                                      [_vm._v("すぐに公開する")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-form-radio",
                                      {
                                        attrs: { value: "0" },
                                        model: {
                                          value: _vm.published,
                                          callback: function ($$v) {
                                            _vm.published = $$v
                                          },
                                          expression: "published",
                                        },
                                      },
                                      [_vm._v("非公開")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-form-radio",
                                      {
                                        attrs: { value: "2" },
                                        model: {
                                          value: _vm.published,
                                          callback: function ($$v) {
                                            _vm.published = $$v
                                          },
                                          expression: "published",
                                        },
                                      },
                                      [_vm._v("指定日時に公開")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.published === "2"
                                  ? _c(
                                      "b-form-group",
                                      {
                                        staticClass: "mt-3",
                                        attrs: { label: "公開日時" },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "w-25" },
                                          [
                                            _c("b-form-input", {
                                              attrs: { type: "date" },
                                              model: {
                                                value: _vm.publishedDate,
                                                callback: function ($$v) {
                                                  _vm.publishedDate = $$v
                                                },
                                                expression: "publishedDate",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("b-form-input", {
                                              attrs: { type: "time" },
                                              model: {
                                                value: _vm.publishedTime,
                                                callback: function ($$v) {
                                                  _vm.publishedTime = $$v
                                                },
                                                expression: "publishedTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "現在、 " +
                                      _vm._s(_vm.files.length) +
                                      " 個のファイルが選択されています。"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      type: "submit",
                                      variant: "primary",
                                    },
                                    on: { click: _vm.upload },
                                  },
                                  [_vm._v("アップロードする")]
                                ),
                              ],
                              1
                            ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.bulkCreateWikiPageRequestArticles.length > 0 ||
              _vm.bulkUpdateWikiPageRequestArticles.length > 0 ||
              _vm.errorCandidates.length > 0
                ? _c(
                    "b-card",
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("アップロード内容")]),
                      ]),
                      _vm._v(" "),
                      (_vm.bulkCreateWikiPageRequestArticles.length > 0 ||
                        _vm.bulkUpdateWikiPageRequestArticles.length > 0) &&
                      _vm.titleDetectCount === 0
                        ? _c(
                            "b-alert",
                            { attrs: { variant: "info", show: "" } },
                            [
                              _vm._v(
                                "以下の内容でアップロードします。よろしい場合は「保存する」のボタンを押下してください。\n          ※記事タイトルが【...】で始まる場合削除されています"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.titleDetectCount > 0
                        ? _c(
                            "b-alert",
                            { attrs: { variant: "danger", show: "" } },
                            [
                              _vm._v(
                                "タイトルが取得できない記事が " +
                                  _vm._s(_vm.titleDetectCount) +
                                  "件\n          あるため、アップロードできません！修正後、再アップロードしてください。"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bulkUpdateWikiPageRequestArticles.length > 0
                        ? _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-card-header",
                                {
                                  staticClass: "p-1",
                                  attrs: { "header-tag": "header" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.bulk-update-candidates",
                                          modifiers: {
                                            "bulk-update-candidates": true,
                                          },
                                        },
                                      ],
                                      attrs: { block: "", variant: "light" },
                                    },
                                    [
                                      _vm._v("更新 "),
                                      _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            pill: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .bulkUpdateWikiPageRequestArticles
                                                .length
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "bulk-update-candidates",
                                    visible: "",
                                  },
                                },
                                [
                                  _c(
                                    "b-list-group",
                                    _vm._l(
                                      _vm.bulkUpdateWikiPageRequestArticles,
                                      function (article) {
                                        return _c(
                                          "b-list-group-item",
                                          { key: article.file },
                                          [
                                            article.title === ""
                                              ? _c(
                                                  "font",
                                                  { attrs: { color: "red" } },
                                                  [_vm._v(_vm._s(article.file))]
                                                )
                                              : _c("span", [
                                                  _vm._v(_vm._s(article.file)),
                                                ]),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              { staticClass: "text-muted" },
                                              [
                                                _c("br"),
                                                _vm._v(_vm._s(article.title)),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bulkCreateWikiPageRequestArticles.length > 0
                        ? _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-card-header",
                                {
                                  staticClass: "p-1",
                                  attrs: { "header-tag": "header" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.bulk-create-candidates",
                                          modifiers: {
                                            "bulk-create-candidates": true,
                                          },
                                        },
                                      ],
                                      attrs: { block: "", variant: "light" },
                                    },
                                    [
                                      _vm._v("作成 "),
                                      _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            pill: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .bulkCreateWikiPageRequestArticles
                                                .length
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "bulk-create-candidates",
                                    visible: "",
                                  },
                                },
                                [
                                  _c(
                                    "b-list-group",
                                    _vm._l(
                                      _vm.bulkCreateWikiPageRequestArticles,
                                      function (article) {
                                        return _c(
                                          "b-list-group-item",
                                          { key: article.file },
                                          [
                                            article.title === ""
                                              ? _c(
                                                  "font",
                                                  { attrs: { color: "red" } },
                                                  [_vm._v(_vm._s(article.file))]
                                                )
                                              : _c("span", [
                                                  _vm._v(_vm._s(article.file)),
                                                ]),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              { staticClass: "text-muted" },
                                              [
                                                _c("br"),
                                                _vm._v(_vm._s(article.title)),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorCandidates.length > 0
                        ? _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-card-header",
                                {
                                  staticClass: "p-1",
                                  attrs: { "header-tag": "header" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.error-candidates",
                                          modifiers: {
                                            "error-candidates": true,
                                          },
                                        },
                                      ],
                                      attrs: { block: "", variant: "light" },
                                    },
                                    [
                                      _vm._v("エラー "),
                                      _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            variant: "danger",
                                            pill: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.errorCandidates.length)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "error-candidates",
                                    visible: "",
                                  },
                                },
                                [
                                  _c(
                                    "b-list-group",
                                    _vm._l(
                                      _vm.errorCandidates,
                                      function (article) {
                                        return _c(
                                          "b-list-group-item",
                                          { key: article.name },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(article.name) +
                                                "\n                "
                                            ),
                                            _vm._l(
                                              article.errors,
                                              function (message) {
                                                return _c(
                                                  "small",
                                                  {
                                                    key:
                                                      article.name +
                                                      "-" +
                                                      message,
                                                    staticClass: "text-muted",
                                                  },
                                                  [
                                                    _c("br"),
                                                    _vm._v(_vm._s(message)),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            type: "submit",
                            variant: "primary",
                            disabled:
                              (_vm.bulkCreateWikiPageRequestArticles.length ===
                                0 &&
                                _vm.bulkUpdateWikiPageRequestArticles.length ===
                                  0) ||
                              _vm.titleDetectCount > 0,
                          },
                          on: {
                            click: function ($event) {
                              _vm.duplicateTitles &&
                              _vm.duplicateTitles.length > 0
                                ? _vm.openConfirmDuplicateTitlesModal()
                                : _vm.save()
                            },
                          },
                        },
                        [_vm._v("保存する")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        { attrs: { type: "submit" }, on: { click: _vm.reset } },
                        [_vm._v("キャンセル")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.completed
                ? _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("アップロード結果")]),
                      ]),
                      _vm._v(" "),
                      _c("b-alert", { attrs: { variant: "info", show: "" } }, [
                        _vm._v(
                          "アップロードが完了しました。アップロード結果は以下のとおりです。"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.successUpdated.length > 0
                        ? _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-card-header",
                                {
                                  staticClass: "p-1",
                                  attrs: { "header-tag": "header" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.result-success-update",
                                          modifiers: {
                                            "result-success-update": true,
                                          },
                                        },
                                      ],
                                      attrs: { block: "", variant: "light" },
                                    },
                                    [
                                      _vm._v("更新成功 "),
                                      _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            pill: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.successUpdated.length)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "result-success-update",
                                    visible: "",
                                  },
                                },
                                [
                                  _c(
                                    "b-list-group",
                                    _vm._l(_vm.successUpdated, function (file) {
                                      return _c(
                                        "b-list-group-item",
                                        { key: file.id },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(file.name) +
                                              "\n                "
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [
                                              _c("br"),
                                              _vm._v(_vm._s(file.title)),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          file.previousTitle != file.newTitle
                                            ? _c(
                                                "small",
                                                { staticClass: "text-muted" },
                                                [
                                                  _c("br"),
                                                  _vm._v(
                                                    "タイトルが「" +
                                                      _vm._s(
                                                        file.previousTitle
                                                      ) +
                                                      "」から「" +
                                                      _vm._s(file.newTitle) +
                                                      "」へ変更されました\n                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.successCreated.length > 0
                        ? _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-card-header",
                                {
                                  staticClass: "p-1",
                                  attrs: { "header-tag": "header" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.result-success-create",
                                          modifiers: {
                                            "result-success-create": true,
                                          },
                                        },
                                      ],
                                      attrs: { block: "", variant: "light" },
                                    },
                                    [
                                      _vm._v("アップロード成功 "),
                                      _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            pill: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.successCreated.length)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-collapse",
                                {
                                  attrs: {
                                    id: "result-success-create",
                                    visible: "",
                                  },
                                },
                                [
                                  _c(
                                    "b-list-group",
                                    _vm._l(_vm.successCreated, function (file) {
                                      return _c(
                                        "b-list-group-item",
                                        { key: file.id },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(file.name) +
                                              "\n                "
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [
                                              _c("br"),
                                              _vm._v(_vm._s(file.title)),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.error.length > 0
                        ? _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-card-header",
                                {
                                  staticClass: "p-1",
                                  attrs: { "header-tag": "header" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName: "v-b-toggle.result-error",
                                          modifiers: { "result-error": true },
                                        },
                                      ],
                                      attrs: { block: "", variant: "light" },
                                    },
                                    [
                                      _vm._v("アップロード失敗 "),
                                      _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            variant: "danger",
                                            pill: "",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.error.length))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-collapse",
                                { attrs: { id: "result-error", visible: "" } },
                                [
                                  _c(
                                    "b-list-group",
                                    _vm._l(_vm.error, function (file) {
                                      return _c(
                                        "b-list-group-item",
                                        { key: file.name },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(file.name) +
                                              "\n                "
                                          ),
                                          _vm._l(
                                            file.errors,
                                            function (message) {
                                              return _c(
                                                "small",
                                                {
                                                  key:
                                                    file.name + "-" + message,
                                                  staticClass: "text-muted",
                                                },
                                                [
                                                  _c("br"),
                                                  _vm._v(_vm._s(message)),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "confirmDuplicateTitlesModal",
          attrs: { "cancel-title": "キャンセル" },
          on: { ok: _vm.save, cancel: _vm.reset },
        },
        [
          _c("p", [
            _vm._v(
              "同じタイトルの記事を新規作成しようとしています。続行しますか？"
            ),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { attrs: { id: "duplicate-title-list" } },
            _vm._l(_vm.duplicateTitles, function (t, idx) {
              return _c("li", { key: idx }, [
                _vm._v("\n        " + _vm._s(t.path)),
                _c("br"),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(t.title)),
                ]),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }