




































































import Component from 'vue-class-component';
import KamigameVue from '@/KamigameVue';
import { applyOCRToRegions, applyPatternMatchingToRegions } from '@/lib/image-scanner';

@Component({
  name: 'kamigame-image-scanner-ocr-demo',
})
export default class ImageScannerOcrDemo extends KamigameVue {
  ocrFile = null;
  ocrRegion = '';
  ocrResult: {
    result: boolean;
    ocr: Record<string, { best_threshold: number; text: string }>;
    requested?: { threshold: number; image: string }[];
  } | null = null;
  binaryImages: { threshold: number; image: string }[] = [];

  templateTargetFile: File | null = null;
  templateRegion = '';
  templateFiles: FileList | null = null;
  templateMatchResult: Record<string, Record<string, number>> | null = null;

  mounted() {
    let script = document.createElement('script');
    script.src = 'https://docs.opencv.org/4.8.0/opencv.js';
    script.async = true;

    document.body.appendChild(script);
  }

  async processTemplateMatching() {
    if (!this.templateTargetFile || !this.templateRegion || !this.templateFiles || this.templateFiles.length === 0) {
      alert('画像ファイル、処理対象の領域、テンプレート画像を指定してください');
      return;
    }

    const regions = this.parseRegionInput(this.templateRegion);
    if (!regions) {
      alert('領域の入力が不正です (x, y, w, h, threshold)');
      return;
    }

    const image = await this.loadImage(this.templateTargetFile!);
    const templateImages = await Promise.all(Array.from(this.templateFiles).map((file) => this.loadImage(file)));

    console.log({ image, templateImages, regions });

    this.templateMatchResult = await applyPatternMatchingToRegions(image, templateImages, regions);
  }

  async processOcrImage() {
    if (!this.ocrFile || !this.ocrRegion) {
      alert('画像ファイルと処理対象の領域を指定してください');
      return;
    }

    const regions = this.parseRegionInput(this.ocrRegion);
    if (!regions) {
      alert('領域の入力が不正です (x, y, w, h, threshold)');
      return;
    }

    const image = await this.loadImage(this.ocrFile!);

    this.ocrResult = await applyOCRToRegions(image, regions);
    if (this.ocrResult && this.ocrResult.requested) {
      this.binaryImages = this.ocrResult.requested;
    }
  }

  parseRegionInput(regionString: string): Record<string, [number, number, number, number, number]> | undefined {
    const values = regionString.split(',').map((v) => parseInt(v.trim(), 10));
    if (values.length !== 5) {
      alert('領域の入力が不正です (x, y, w, h, threshold)');
      return undefined;
    }

    return { region1: values as [number, number, number, number, number] };
  }

  loadImage(ocrFile: File): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error('画像の読み込みに失敗しました。'));
        if (e.target && e.target.result) {
          img.src = e.target.result as string;
        } else {
          reject(new Error('ファイルの読み込みに失敗しました。'));
        }
      };
      reader.onerror = () => reject(new Error('ファイルの読み込みに失敗しました。'));
      reader.readAsDataURL(ocrFile);
    });
  }
}
