const escapeHtml = require('escape-html');

// REF: https://app.asana.com/0/0/1203256665345319/1204851188080409/f
const TITLES_NOT_IN_TOC = [
  '関連リンク',
  'おすすめ記事',
  'おすすめ攻略記事',
  '更新者情報',
  '編集者情報',
  'ライタープロフィール',
  '関連情報',
  'おすすめ情報',
];

// REF: https://app.asana.com/0/0/1205474516694533/1205646633611363/f
const TEXTS_NOT_INCLUDED_TOC_TITLE = ['記事一覧', '関連記事'];

function makeTOC(
  article,
  navId = 'contents',
  navClass = 'kamigame-table-of-contents',
  h2Id = 'table_of_contents',
  h2Title = '目次'
) {
  if (article.is_top_page) {
    return '';
  }

  if (article.article_toc && article.article_toc.length > 0) {
    return makeCustomTOC(article.article_toc, navId, navClass, h2Id);
  }

  if (article.article_bodies && Object.keys(article.article_bodies).length > 1) {
    const tocs = article.article_bodies
      ? Object.keys(article.article_bodies).filter(
          (b) => !TITLES_NOT_IN_TOC.includes(b) && !TEXTS_NOT_INCLUDED_TOC_TITLE.some((t) => b.includes(t))
        )
      : [];
    return `
      <nav id="${navId}" class="${navClass}">
        <h2 id="${h2Id}">${h2Title}</h2>
        <ul>
          ${tocs
            .map((k) => {
              return ` <li><a href="#${escapeId(k)}">${k}</a></li> `;
            })
            .join('')}
        </ul>
      </nav>
    `;
  }

  return '';
}

function escapeId(id) {
  return id.replaceAll('"', '&quot;');
}

function makeCustomTOC(items, navId = 'contents', navClass = 'kamigame-table-of-contents', h2Id = 'table_of_contents') {
  return `
    <nav id="${navId}" class="${navClass}">
      <h2 id="${h2Id}">目次</h2>
      ${_makeCustomTOC(items, false)}
    </nav>
  `;
}

function makeCustomTOCInclugingImg(item, nested) {
  const link = escapeHtml(item.link);
  let includingImg = '';
  item.textIncludingImg.forEach((v) => {
    const key = Object.keys(v)[0];
    if (key === 'text') {
      includingImg += escapeHtml(v[key]);
      return;
    }

    if (key === 'img') {
      includingImg += `<img src="${escapeHtml(v[key].src)}" alt="${escapeHtml(v[key].alt)}">`;
      return;
    }
  });

  return `
    ${
      link
        ? `<a class="${nested ? 'nested' : ''}" href="${link}">${includingImg}</a>`
        : `<span class="separator">${includingImg}</span>`
    }
  `;
}

function _makeCustomTOC(items, nested) {
  return `
    <ul>
      ${items
        .map(
          (i) => `
            <li>
              ${
                i.textIncludingImg.length > 0
                  ? makeCustomTOCInclugingImg(i, nested)
                  : i.link
                  ? `<a class="${nested ? 'nested' : ''}" href="${escapeHtml(i.link)}">${escapeHtml(i.text)}</a>`
                  : `<span class="separator">${escapeHtml(i.text)}</span>`
              }
              ${i.children.length > 0 ? _makeCustomTOC(i.children, true) : ''}
            </li>
          `
        )
        .join('')}
    </ul>
  `;
}

module.exports = { makeCustomTOC, makeTOC };
