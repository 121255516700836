/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "./models";
import * as Mappers from "./models/mappers";
import * as Parameters from "./models/parameters";
import { KamigameWikiApiContext } from "./kamigameWikiApiContext";

class KamigameWikiApi extends KamigameWikiApiContext {
  /**
   * Initializes a new instance of the KamigameWikiApi class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, options?: Models.KamigameWikiApiOptions) {
    super(credentials, options);
  }

  /**
   * 全ての Wiki の情報を取得します。
   * @summary 全ての Wiki の情報を取得する
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllWikiResponse>
   */
  getAllWiki(options?: msRest.RequestOptionsBase): Promise<Models.GetAllWikiResponse>;
  /**
   * @param callback The callback
   */
  getAllWiki(callback: msRest.ServiceCallback<Models.V1WikiCollection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllWiki(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiCollection>): void;
  getAllWiki(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiCollection>, callback?: msRest.ServiceCallback<Models.V1WikiCollection>): Promise<Models.GetAllWikiResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllWikiOperationSpec,
      callback) as Promise<Models.GetAllWikiResponse>;
  }

  /**
   * 新規に Wiki を作成します。
   * @summary Wiki 作成
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWikiResponse>
   */
  createWiki(body: Models.V1CreateWikiRequest, options?: msRest.RequestOptionsBase): Promise<Models.CreateWikiResponse>;
  /**
   * @param body
   * @param callback The callback
   */
  createWiki(body: Models.V1CreateWikiRequest, callback: msRest.ServiceCallback<Models.V1Wiki>): void;
  /**
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createWiki(body: Models.V1CreateWikiRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1Wiki>): void;
  createWiki(body: Models.V1CreateWikiRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1Wiki>, callback?: msRest.ServiceCallback<Models.V1Wiki>): Promise<Models.CreateWikiResponse> {
    return this.sendOperationRequest(
      {
        body,
        options
      },
      createWikiOperationSpec,
      callback) as Promise<Models.CreateWikiResponse>;
  }

  /**
   * 指定されたユーザが参加している Wiki の一覧を取得します。
   * @summary ユーザが参加している Wiki 一覧取得
   * @param [options] The optional parameters
   * @returns Promise<Models.ListJoinedWikiResponse>
   */
  listJoinedWiki(options?: Models.KamigameWikiApiListJoinedWikiOptionalParams): Promise<Models.ListJoinedWikiResponse>;
  /**
   * @param callback The callback
   */
  listJoinedWiki(callback: msRest.ServiceCallback<Models.V1WikiCollection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listJoinedWiki(options: Models.KamigameWikiApiListJoinedWikiOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiCollection>): void;
  listJoinedWiki(options?: Models.KamigameWikiApiListJoinedWikiOptionalParams | msRest.ServiceCallback<Models.V1WikiCollection>, callback?: msRest.ServiceCallback<Models.V1WikiCollection>): Promise<Models.ListJoinedWikiResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listJoinedWikiOperationSpec,
      callback) as Promise<Models.ListJoinedWikiResponse>;
  }

  /**
   * 指定された Wiki の情報を編集します
   * @summary Wiki 基本情報編集
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiResponse>
   */
  updateWiki(name: string, body: Models.V1EditWikiRequest, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param callback The callback
   */
  updateWiki(name: string, body: Models.V1EditWikiRequest, callback: msRest.ServiceCallback<Models.V1Wiki>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWiki(name: string, body: Models.V1EditWikiRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1Wiki>): void;
  updateWiki(name: string, body: Models.V1EditWikiRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1Wiki>, callback?: msRest.ServiceCallback<Models.V1Wiki>): Promise<Models.UpdateWikiResponse> {
    return this.sendOperationRequest(
      {
        name,
        body,
        options
      },
      updateWikiOperationSpec,
      callback) as Promise<Models.UpdateWikiResponse>;
  }

  /**
   * 指定された Wiki のアクセス数を返します
   * @summary Wiki アクセス解析取得
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiAccessAnalyticsResponse>
   */
  getWikiAccessAnalytics(name: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiAccessAnalyticsResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  getWikiAccessAnalytics(name: string, callback: msRest.ServiceCallback<Models.V1WikiAccessAnalytics>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiAccessAnalytics(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiAccessAnalytics>): void;
  getWikiAccessAnalytics(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiAccessAnalytics>, callback?: msRest.ServiceCallback<Models.V1WikiAccessAnalytics>): Promise<Models.GetWikiAccessAnalyticsResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      getWikiAccessAnalyticsOperationSpec,
      callback) as Promise<Models.GetWikiAccessAnalyticsResponse>;
  }

  /**
   * Wiki テンプレートコード実行用に投機的に作られたスプレッドシート群のうち、最終的に選ばれたシートを除きすべて削除し、関連付けを変更します
   * @summary Wiki テンプレートコード実行用に投機的に作られたスプレッドシート群を整理
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.AdjustSpeculativeWikiDataSheetsResponse>
   */
  adjustSpeculativeWikiDataSheets(name: string, body: Models.V1AdjustSpeculativeWikiDataSheetsRequest, options?: msRest.RequestOptionsBase): Promise<Models.AdjustSpeculativeWikiDataSheetsResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param callback The callback
   */
  adjustSpeculativeWikiDataSheets(name: string, body: Models.V1AdjustSpeculativeWikiDataSheetsRequest, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  adjustSpeculativeWikiDataSheets(name: string, body: Models.V1AdjustSpeculativeWikiDataSheetsRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  adjustSpeculativeWikiDataSheets(name: string, body: Models.V1AdjustSpeculativeWikiDataSheetsRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.AdjustSpeculativeWikiDataSheetsResponse> {
    return this.sendOperationRequest(
      {
        name,
        body,
        options
      },
      adjustSpeculativeWikiDataSheetsOperationSpec,
      callback) as Promise<Models.AdjustSpeculativeWikiDataSheetsResponse>;
  }

  /**
   * Wiki の閲覧を許可された IP アドレス群 (改行区切りの文字列) を取得します
   * @summary Wiki 閲覧許可 IP アドレス群の取得
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiAllowedIPAddressResponse>
   */
  getWikiAllowedIPAddress(name: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiAllowedIPAddressResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  getWikiAllowedIPAddress(name: string, callback: msRest.ServiceCallback<Models.V1WikiAllowedIPAddress>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiAllowedIPAddress(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiAllowedIPAddress>): void;
  getWikiAllowedIPAddress(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiAllowedIPAddress>, callback?: msRest.ServiceCallback<Models.V1WikiAllowedIPAddress>): Promise<Models.GetWikiAllowedIPAddressResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      getWikiAllowedIPAddressOperationSpec,
      callback) as Promise<Models.GetWikiAllowedIPAddressResponse>;
  }

  /**
   * Wiki のアーカイブ設定を更新します
   * @summary Wiki アーカイブ設定
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiIsArchivedResponse>
   */
  updateWikiIsArchived(name: string, body: Models.V1UpdateWikiArchiveConfigRequest, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiIsArchivedResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param callback The callback
   */
  updateWikiIsArchived(name: string, body: Models.V1UpdateWikiArchiveConfigRequest, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiIsArchived(name: string, body: Models.V1UpdateWikiArchiveConfigRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiIsArchived(name: string, body: Models.V1UpdateWikiArchiveConfigRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiIsArchivedResponse> {
    return this.sendOperationRequest(
      {
        name,
        body,
        options
      },
      updateWikiIsArchivedOperationSpec,
      callback) as Promise<Models.UpdateWikiIsArchivedResponse>;
  }

  /**
   * WikiPageCategory を作成します。
   * @summary WikiPageCategory 作成
   * @param name カテゴリ名です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWikiPageCategoryResponse>
   */
  createWikiPageCategory(name: string, body: Models.V1WikiPageCategoryCreateRequest, options?: msRest.RequestOptionsBase): Promise<Models.CreateWikiPageCategoryResponse>;
  /**
   * @param name カテゴリ名です
   * @param body
   * @param callback The callback
   */
  createWikiPageCategory(name: string, body: Models.V1WikiPageCategoryCreateRequest, callback: msRest.ServiceCallback<Models.V1WikiPageCategory>): void;
  /**
   * @param name カテゴリ名です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createWikiPageCategory(name: string, body: Models.V1WikiPageCategoryCreateRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageCategory>): void;
  createWikiPageCategory(name: string, body: Models.V1WikiPageCategoryCreateRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageCategory>, callback?: msRest.ServiceCallback<Models.V1WikiPageCategory>): Promise<Models.CreateWikiPageCategoryResponse> {
    return this.sendOperationRequest(
      {
        name,
        body,
        options
      },
      createWikiPageCategoryOperationSpec,
      callback) as Promise<Models.CreateWikiPageCategoryResponse>;
  }

  /**
   * 指定された Wiki のダッシュボード用の情報を取得します。
   * @summary Wiki のダッシュボード用の情報取得
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiForDashboardResponse>
   */
  getWikiForDashboard(name: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiForDashboardResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  getWikiForDashboard(name: string, callback: msRest.ServiceCallback<Models.V1WikiForDashboard>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiForDashboard(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiForDashboard>): void;
  getWikiForDashboard(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiForDashboard>, callback?: msRest.ServiceCallback<Models.V1WikiForDashboard>): Promise<Models.GetWikiForDashboardResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      getWikiForDashboardOperationSpec,
      callback) as Promise<Models.GetWikiForDashboardResponse>;
  }

  /**
   * 指定された Wiki のデータシート一覧を取得します
   * @summary Wiki データシート一覧取得
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiDataSheetResponse>
   */
  listWikiDataSheet(name: string, options?: msRest.RequestOptionsBase): Promise<Models.ListWikiDataSheetResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiDataSheet(name: string, callback: msRest.ServiceCallback<Models.V1WikiDataSheetCollection>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiDataSheet(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiDataSheetCollection>): void;
  listWikiDataSheet(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiDataSheetCollection>, callback?: msRest.ServiceCallback<Models.V1WikiDataSheetCollection>): Promise<Models.ListWikiDataSheetResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      listWikiDataSheetOperationSpec,
      callback) as Promise<Models.ListWikiDataSheetResponse>;
  }

  /**
   * Wiki データを GCS からダウンロードするための URL を発行します
   * @summary Wiki データ GCS ダウンロード URL 発行
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.PublishWikiDataDownloadURLResponse>
   */
  publishWikiDataDownloadURL(name: string, body: Models.V1PublishWikiDataDownloadURLRequest, options?: msRest.RequestOptionsBase): Promise<Models.PublishWikiDataDownloadURLResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param callback The callback
   */
  publishWikiDataDownloadURL(name: string, body: Models.V1PublishWikiDataDownloadURLRequest, callback: msRest.ServiceCallback<Models.V1WikiDataDownloadURL>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  publishWikiDataDownloadURL(name: string, body: Models.V1PublishWikiDataDownloadURLRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiDataDownloadURL>): void;
  publishWikiDataDownloadURL(name: string, body: Models.V1PublishWikiDataDownloadURLRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiDataDownloadURL>, callback?: msRest.ServiceCallback<Models.V1WikiDataDownloadURL>): Promise<Models.PublishWikiDataDownloadURLResponse> {
    return this.sendOperationRequest(
      {
        name,
        body,
        options
      },
      publishWikiDataDownloadURLOperationSpec,
      callback) as Promise<Models.PublishWikiDataDownloadURLResponse>;
  }

  /**
   * Wiki データを GCS へアップロードするための URL を発行します
   * @summary Wiki データ GCS アップロード URL 発行
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.PublishWikiDataUploadURLResponse>
   */
  publishWikiDataUploadURL(name: string, body: Models.V1WithWikiRequest, options?: msRest.RequestOptionsBase): Promise<Models.PublishWikiDataUploadURLResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param callback The callback
   */
  publishWikiDataUploadURL(name: string, body: Models.V1WithWikiRequest, callback: msRest.ServiceCallback<Models.V1WikiDataUploadURL>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  publishWikiDataUploadURL(name: string, body: Models.V1WithWikiRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiDataUploadURL>): void;
  publishWikiDataUploadURL(name: string, body: Models.V1WithWikiRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiDataUploadURL>, callback?: msRest.ServiceCallback<Models.V1WikiDataUploadURL>): Promise<Models.PublishWikiDataUploadURLResponse> {
    return this.sendOperationRequest(
      {
        name,
        body,
        options
      },
      publishWikiDataUploadURLOperationSpec,
      callback) as Promise<Models.PublishWikiDataUploadURLResponse>;
  }

  /**
   * WikiDomainAllowlist の中から指定された wiki の WikiAllowedDomain の一覧を取得します
   * @summary WikiDomainAllowlist の一覧を取得
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiDomainAllowlistResponse>
   */
  listWikiDomainAllowlist(name: string, options?: msRest.RequestOptionsBase): Promise<Models.ListWikiDomainAllowlistResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiDomainAllowlist(name: string, callback: msRest.ServiceCallback<Models.V1ListWikiDomainAllowlistResponse>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiDomainAllowlist(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1ListWikiDomainAllowlistResponse>): void;
  listWikiDomainAllowlist(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1ListWikiDomainAllowlistResponse>, callback?: msRest.ServiceCallback<Models.V1ListWikiDomainAllowlistResponse>): Promise<Models.ListWikiDomainAllowlistResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      listWikiDomainAllowlistOperationSpec,
      callback) as Promise<Models.ListWikiDomainAllowlistResponse>;
  }

  /**
   * Wiki におけるメンバー招待の一覧を取得します
   * @summary Wiki メンバー招待一覧
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiMemberInviteResponse>
   */
  listWikiMemberInvite(name: string, options?: msRest.RequestOptionsBase): Promise<Models.ListWikiMemberInviteResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiMemberInvite(name: string, callback: msRest.ServiceCallback<Models.V1WikiMemberInviteCollection>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiMemberInvite(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiMemberInviteCollection>): void;
  listWikiMemberInvite(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiMemberInviteCollection>, callback?: msRest.ServiceCallback<Models.V1WikiMemberInviteCollection>): Promise<Models.ListWikiMemberInviteResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      listWikiMemberInviteOperationSpec,
      callback) as Promise<Models.ListWikiMemberInviteResponse>;
  }

  /**
   * 指定された Wiki の公開設定を編集します
   * @summary Wiki 公開設定編集
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiPublishConfigResponse>
   */
  updateWikiPublishConfig(name: string, body: Models.V1EditWikiPublishConfigRequest, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiPublishConfigResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param callback The callback
   */
  updateWikiPublishConfig(name: string, body: Models.V1EditWikiPublishConfigRequest, callback: msRest.ServiceCallback<Models.V1Wiki>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiPublishConfig(name: string, body: Models.V1EditWikiPublishConfigRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1Wiki>): void;
  updateWikiPublishConfig(name: string, body: Models.V1EditWikiPublishConfigRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1Wiki>, callback?: msRest.ServiceCallback<Models.V1Wiki>): Promise<Models.UpdateWikiPublishConfigResponse> {
    return this.sendOperationRequest(
      {
        name,
        body,
        options
      },
      updateWikiPublishConfigOperationSpec,
      callback) as Promise<Models.UpdateWikiPublishConfigResponse>;
  }

  /**
   * Wiki テンプレートコード実行を予約します
   * @summary Wiki テンプレートコード実行予約
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.RequestTemplateCodeExecutionResponse>
   */
  requestTemplateCodeExecution(name: string, body: Models.V1RequestTemplateCodeExecutionRequest, options?: msRest.RequestOptionsBase): Promise<Models.RequestTemplateCodeExecutionResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param callback The callback
   */
  requestTemplateCodeExecution(name: string, body: Models.V1RequestTemplateCodeExecutionRequest, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  requestTemplateCodeExecution(name: string, body: Models.V1RequestTemplateCodeExecutionRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  requestTemplateCodeExecution(name: string, body: Models.V1RequestTemplateCodeExecutionRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.RequestTemplateCodeExecutionResponse> {
    return this.sendOperationRequest(
      {
        name,
        body,
        options
      },
      requestTemplateCodeExecutionOperationSpec,
      callback) as Promise<Models.RequestTemplateCodeExecutionResponse>;
  }

  /**
   * その Wiki でテンプレートコード実行のために連携しているスプレッドシートの一覧を取得します
   * @summary Wiki テンプレートコード実行用スプレッドシート群の取得
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiTakahiroTemplateCodeSpreadsheetURLResponse>
   */
  listWikiTakahiroTemplateCodeSpreadsheetURL(name: string, options?: msRest.RequestOptionsBase): Promise<Models.ListWikiTakahiroTemplateCodeSpreadsheetURLResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiTakahiroTemplateCodeSpreadsheetURL(name: string, callback: msRest.ServiceCallback<Models.V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiTakahiroTemplateCodeSpreadsheetURL(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki>): void;
  listWikiTakahiroTemplateCodeSpreadsheetURL(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki>, callback?: msRest.ServiceCallback<Models.V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki>): Promise<Models.ListWikiTakahiroTemplateCodeSpreadsheetURLResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      listWikiTakahiroTemplateCodeSpreadsheetURLOperationSpec,
      callback) as Promise<Models.ListWikiTakahiroTemplateCodeSpreadsheetURLResponse>;
  }

  /**
   * 指定された Wiki で登録されているページテンプレートのタイトルと本文の一覧を取得します
   * @summary Wiki ページテンプレートタイトル本文一覧
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPageTemplateTitleBodiesResponse>
   */
  listWikiPageTemplateTitleBodies(name: string, options?: msRest.RequestOptionsBase): Promise<Models.ListWikiPageTemplateTitleBodiesResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiPageTemplateTitleBodies(name: string, callback: msRest.ServiceCallback<Models.V1WikiPageTemplateTitleBodyCollection>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPageTemplateTitleBodies(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageTemplateTitleBodyCollection>): void;
  listWikiPageTemplateTitleBodies(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageTemplateTitleBodyCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageTemplateTitleBodyCollection>): Promise<Models.ListWikiPageTemplateTitleBodiesResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      listWikiPageTemplateTitleBodiesOperationSpec,
      callback) as Promise<Models.ListWikiPageTemplateTitleBodiesResponse>;
  }

  /**
   * Wiki の閲覧を許可された IP アドレス群 (改行区切りの文字列) を編集します
   * @summary Wiki 閲覧許可 IP アドレス群の編集
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiAllowedIPAddressResponse>
   */
  updateWikiAllowedIPAddress(wikiname: string, body: Models.V1WikiAllowedIPAddress, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiAllowedIPAddressResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  updateWikiAllowedIPAddress(wikiname: string, body: Models.V1WikiAllowedIPAddress, callback: msRest.ServiceCallback<Models.V1WikiAllowedIPAddress>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiAllowedIPAddress(wikiname: string, body: Models.V1WikiAllowedIPAddress, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiAllowedIPAddress>): void;
  updateWikiAllowedIPAddress(wikiname: string, body: Models.V1WikiAllowedIPAddress, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiAllowedIPAddress>, callback?: msRest.ServiceCallback<Models.V1WikiAllowedIPAddress>): Promise<Models.UpdateWikiAllowedIPAddressResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      updateWikiAllowedIPAddressOperationSpec,
      callback) as Promise<Models.UpdateWikiAllowedIPAddressResponse>;
  }

  /**
   * 指定された Wiki のカスタム CSS を編集します
   * @summary Wiki カスタム CSS 編集
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiCustomCssResponse>
   */
  updateWikiCustomCss(wikiname: string, body: Models.V1WikiCustomCss, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiCustomCssResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  updateWikiCustomCss(wikiname: string, body: Models.V1WikiCustomCss, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiCustomCss(wikiname: string, body: Models.V1WikiCustomCss, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiCustomCss(wikiname: string, body: Models.V1WikiCustomCss, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiCustomCssResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      updateWikiCustomCssOperationSpec,
      callback) as Promise<Models.UpdateWikiCustomCssResponse>;
  }

  /**
   * 指定された Wiki のカスタムヘッダを編集します
   * @summary Wiki カスタムヘッダ編集
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiCustomHeaderResponse>
   */
  updateWikiCustomHeader(wikiname: string, body: Models.V1ListWikiCustomHeaderWithWiki, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiCustomHeaderResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  updateWikiCustomHeader(wikiname: string, body: Models.V1ListWikiCustomHeaderWithWiki, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiCustomHeader(wikiname: string, body: Models.V1ListWikiCustomHeaderWithWiki, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiCustomHeader(wikiname: string, body: Models.V1ListWikiCustomHeaderWithWiki, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiCustomHeaderResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      updateWikiCustomHeaderOperationSpec,
      callback) as Promise<Models.UpdateWikiCustomHeaderResponse>;
  }

  /**
   * 指定された Wiki のデータシートを作成します
   * @summary Wiki データ作成
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWikiDataSheetResponse>
   */
  createWikiDataSheet(wikiname: string, body: Models.V1WikiDataSheet, options?: msRest.RequestOptionsBase): Promise<Models.CreateWikiDataSheetResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  createWikiDataSheet(wikiname: string, body: Models.V1WikiDataSheet, callback: msRest.ServiceCallback<Models.V1WikiDataSheet>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createWikiDataSheet(wikiname: string, body: Models.V1WikiDataSheet, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiDataSheet>): void;
  createWikiDataSheet(wikiname: string, body: Models.V1WikiDataSheet, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiDataSheet>, callback?: msRest.ServiceCallback<Models.V1WikiDataSheet>): Promise<Models.CreateWikiDataSheetResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      createWikiDataSheetOperationSpec,
      callback) as Promise<Models.CreateWikiDataSheetResponse>;
  }

  /**
   * 指定された Wiki のデータシートの外部参照の情報を作成します
   * @summary Wiki データシート外部参照作成
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWikiDataSheetLinkResponse>
   */
  createWikiDataSheetLink(wikiname: string, body: Models.V1WikiDataSheetLink, options?: msRest.RequestOptionsBase): Promise<Models.CreateWikiDataSheetLinkResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  createWikiDataSheetLink(wikiname: string, body: Models.V1WikiDataSheetLink, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createWikiDataSheetLink(wikiname: string, body: Models.V1WikiDataSheetLink, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  createWikiDataSheetLink(wikiname: string, body: Models.V1WikiDataSheetLink, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.CreateWikiDataSheetLinkResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      createWikiDataSheetLinkOperationSpec,
      callback) as Promise<Models.CreateWikiDataSheetLinkResponse>;
  }

  /**
   * 指定された Wiki データシートのデータを更新します
   * @summary Wiki データ更新
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param id Wiki データシートを一意に特定するための ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiDataSheetResponse>
   */
  updateWikiDataSheet(wikiname: string, id: string, body: Models.V1WikiDataSheet, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiDataSheetResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param id Wiki データシートを一意に特定するための ID です
   * @param body
   * @param callback The callback
   */
  updateWikiDataSheet(wikiname: string, id: string, body: Models.V1WikiDataSheet, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param id Wiki データシートを一意に特定するための ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiDataSheet(wikiname: string, id: string, body: Models.V1WikiDataSheet, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiDataSheet(wikiname: string, id: string, body: Models.V1WikiDataSheet, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiDataSheetResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        id,
        body,
        options
      },
      updateWikiDataSheetOperationSpec,
      callback) as Promise<Models.UpdateWikiDataSheetResponse>;
  }

  /**
   * 指定された Wiki のヘッダ画像を設定します
   * @summary Wiki ヘッダ画像設定
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiHeaderImageResponse>
   */
  updateWikiHeaderImage(wikiname: string, body: Models.V1UpdateWikiImageRequest, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiHeaderImageResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  updateWikiHeaderImage(wikiname: string, body: Models.V1UpdateWikiImageRequest, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiHeaderImage(wikiname: string, body: Models.V1UpdateWikiImageRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiHeaderImage(wikiname: string, body: Models.V1UpdateWikiImageRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiHeaderImageResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      updateWikiHeaderImageOperationSpec,
      callback) as Promise<Models.UpdateWikiHeaderImageResponse>;
  }

  /**
   * 指定された Wiki のアイコン画像を設定します
   * @summary Wiki アイコン画像設定
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiIconImageResponse>
   */
  updateWikiIconImage(wikiname: string, body: Models.V1UpdateWikiImageRequest, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiIconImageResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  updateWikiIconImage(wikiname: string, body: Models.V1UpdateWikiImageRequest, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiIconImage(wikiname: string, body: Models.V1UpdateWikiImageRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiIconImage(wikiname: string, body: Models.V1UpdateWikiImageRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiIconImageResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      updateWikiIconImageOperationSpec,
      callback) as Promise<Models.UpdateWikiIconImageResponse>;
  }

  /**
   * 指定された Wiki のサイドバーを編集します
   * @summary Wiki サイドバー編集
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiMetaMediumTextResponse>
   */
  updateWikiMetaMediumText(wikiname: string, body: Models.V1WikiMarkdownTextWidget, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiMetaMediumTextResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  updateWikiMetaMediumText(wikiname: string, body: Models.V1WikiMarkdownTextWidget, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiMetaMediumText(wikiname: string, body: Models.V1WikiMarkdownTextWidget, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiMetaMediumText(wikiname: string, body: Models.V1WikiMarkdownTextWidget, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiMetaMediumTextResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      updateWikiMetaMediumTextOperationSpec,
      callback) as Promise<Models.UpdateWikiMetaMediumTextResponse>;
  }

  /**
   * Wiki におけるメンバー招待を作成します
   * @summary Wiki メンバー招待作成
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWikiMemberInviteResponse>
   */
  createWikiMemberInvite(wikiname: string, body: Models.V1CreateWikiMemberInviteRequest, options?: msRest.RequestOptionsBase): Promise<Models.CreateWikiMemberInviteResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  createWikiMemberInvite(wikiname: string, body: Models.V1CreateWikiMemberInviteRequest, callback: msRest.ServiceCallback<Models.V1WikiMemberInvite>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createWikiMemberInvite(wikiname: string, body: Models.V1CreateWikiMemberInviteRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiMemberInvite>): void;
  createWikiMemberInvite(wikiname: string, body: Models.V1CreateWikiMemberInviteRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiMemberInvite>, callback?: msRest.ServiceCallback<Models.V1WikiMemberInvite>): Promise<Models.CreateWikiMemberInviteResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      createWikiMemberInviteOperationSpec,
      callback) as Promise<Models.CreateWikiMemberInviteResponse>;
  }

  /**
   * 指定された参加申請を承認します
   * @summary Wiki メンバー参加申請承認
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param id 申請の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ApproveWikiMemberRequestResponse>
   */
  approveWikiMemberRequest(wikiname: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.ApproveWikiMemberRequestResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param id 申請の ID です
   * @param callback The callback
   */
  approveWikiMemberRequest(wikiname: string, id: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param id 申請の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  approveWikiMemberRequest(wikiname: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  approveWikiMemberRequest(wikiname: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.ApproveWikiMemberRequestResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        id,
        options
      },
      approveWikiMemberRequestOperationSpec,
      callback) as Promise<Models.ApproveWikiMemberRequestResponse>;
  }

  /**
   * 指定された参加申請を拒否します
   * @summary Wiki メンバー参加申請拒否
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param id 申請の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.RejectWikiMemberRequestResponse>
   */
  rejectWikiMemberRequest(wikiname: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.RejectWikiMemberRequestResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param id 申請の ID です
   * @param callback The callback
   */
  rejectWikiMemberRequest(wikiname: string, id: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param id 申請の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  rejectWikiMemberRequest(wikiname: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  rejectWikiMemberRequest(wikiname: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.RejectWikiMemberRequestResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        id,
        options
      },
      rejectWikiMemberRequestOperationSpec,
      callback) as Promise<Models.RejectWikiMemberRequestResponse>;
  }

  /**
   * 特定の Wiki メンバーの権限を変更します
   * @summary Wiki メンバー権限設定
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param userid ユーザをユニークに識別するための内部的な ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiMemberPermissionResponse>
   */
  updateWikiMemberPermission(wikiname: string, userid: string, body: Models.V1UpdateWikiMemberPermissionRequest, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiMemberPermissionResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param userid ユーザをユニークに識別するための内部的な ID です
   * @param body
   * @param callback The callback
   */
  updateWikiMemberPermission(wikiname: string, userid: string, body: Models.V1UpdateWikiMemberPermissionRequest, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param userid ユーザをユニークに識別するための内部的な ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiMemberPermission(wikiname: string, userid: string, body: Models.V1UpdateWikiMemberPermissionRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiMemberPermission(wikiname: string, userid: string, body: Models.V1UpdateWikiMemberPermissionRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiMemberPermissionResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        userid,
        body,
        options
      },
      updateWikiMemberPermissionOperationSpec,
      callback) as Promise<Models.UpdateWikiMemberPermissionResponse>;
  }

  /**
   * 指定されたメタ情報を編集します。なければ作成します
   * @summary Wiki メタ情報編集
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param name メタ情報を表すキーとなる名前です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiMetaResponse>
   */
  updateWikiMeta(wikiname: string, name: string, body: Models.V1WikiMeta, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiMetaResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param name メタ情報を表すキーとなる名前です
   * @param body
   * @param callback The callback
   */
  updateWikiMeta(wikiname: string, name: string, body: Models.V1WikiMeta, callback: msRest.ServiceCallback<Models.V1WikiMeta>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param name メタ情報を表すキーとなる名前です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiMeta(wikiname: string, name: string, body: Models.V1WikiMeta, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiMeta>): void;
  updateWikiMeta(wikiname: string, name: string, body: Models.V1WikiMeta, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiMeta>, callback?: msRest.ServiceCallback<Models.V1WikiMeta>): Promise<Models.UpdateWikiMetaResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        name,
        body,
        options
      },
      updateWikiMetaOperationSpec,
      callback) as Promise<Models.UpdateWikiMetaResponse>;
  }

  /**
   * 指定された Wiki のグローバルナビを編集します
   * @summary Wiki グローバルナビ編集
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiNavResponse>
   */
  updateWikiNav(wikiname: string, body: Models.V1ListWikiNavWithWiki, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiNavResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  updateWikiNav(wikiname: string, body: Models.V1ListWikiNavWithWiki, callback: msRest.ServiceCallback<Models.V1ListWikiNavWithWiki>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiNav(wikiname: string, body: Models.V1ListWikiNavWithWiki, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1ListWikiNavWithWiki>): void;
  updateWikiNav(wikiname: string, body: Models.V1ListWikiNavWithWiki, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1ListWikiNavWithWiki>, callback?: msRest.ServiceCallback<Models.V1ListWikiNavWithWiki>): Promise<Models.UpdateWikiNavResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      updateWikiNavOperationSpec,
      callback) as Promise<Models.UpdateWikiNavResponse>;
  }

  /**
   * 指定された Wiki のツールチップを編集します
   * @summary Wiki ツールチップ編集
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiTooltipResponse>
   */
  updateWikiTooltip(wikiname: string, body: Models.V1ListWikiTooltipMessage, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiTooltipResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  updateWikiTooltip(wikiname: string, body: Models.V1ListWikiTooltipMessage, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiTooltip(wikiname: string, body: Models.V1ListWikiTooltipMessage, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiTooltip(wikiname: string, body: Models.V1ListWikiTooltipMessage, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiTooltipResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      updateWikiTooltipOperationSpec,
      callback) as Promise<Models.UpdateWikiTooltipResponse>;
  }

  /**
   * 指定された takahiro-games 由来の Wiki ページカテゴリを一括で登録します。
   * @summary Wiki ページカテゴリ一括登録 (takahiro-games)
   * @param wikiId 対象となる Wiki の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.BulkTakahiroWikiPageCategoryResponse>
   */
  bulkTakahiroWikiPageCategory(wikiId: string, body: Models.V1BulkTakahiroWikiPageCategoryRequestBody, options?: msRest.RequestOptionsBase): Promise<Models.BulkTakahiroWikiPageCategoryResponse>;
  /**
   * @param wikiId 対象となる Wiki の ID です
   * @param body
   * @param callback The callback
   */
  bulkTakahiroWikiPageCategory(wikiId: string, body: Models.V1BulkTakahiroWikiPageCategoryRequestBody, callback: msRest.ServiceCallback<Models.V1BulkTakahiroWikiPageCategoryResult>): void;
  /**
   * @param wikiId 対象となる Wiki の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  bulkTakahiroWikiPageCategory(wikiId: string, body: Models.V1BulkTakahiroWikiPageCategoryRequestBody, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1BulkTakahiroWikiPageCategoryResult>): void;
  bulkTakahiroWikiPageCategory(wikiId: string, body: Models.V1BulkTakahiroWikiPageCategoryRequestBody, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1BulkTakahiroWikiPageCategoryResult>, callback?: msRest.ServiceCallback<Models.V1BulkTakahiroWikiPageCategoryResult>): Promise<Models.BulkTakahiroWikiPageCategoryResponse> {
    return this.sendOperationRequest(
      {
        wikiId,
        body,
        options
      },
      bulkTakahiroWikiPageCategoryOperationSpec,
      callback) as Promise<Models.BulkTakahiroWikiPageCategoryResponse>;
  }

  /**
   * 指定された Wiki に対して新規のページを作成します。
   * @summary Wiki ページ作成
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWikiPageResponse>
   */
  createWikiPage(wikiId: string, body: Models.V1CreateWikiPageRequestBody, options?: Models.KamigameWikiApiCreateWikiPageOptionalParams): Promise<Models.CreateWikiPageResponse>;
  /**
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param callback The callback
   */
  createWikiPage(wikiId: string, body: Models.V1CreateWikiPageRequestBody, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): void;
  /**
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createWikiPage(wikiId: string, body: Models.V1CreateWikiPageRequestBody, options: Models.KamigameWikiApiCreateWikiPageOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): void;
  createWikiPage(wikiId: string, body: Models.V1CreateWikiPageRequestBody, options?: Models.KamigameWikiApiCreateWikiPageOptionalParams | msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>, callback?: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): Promise<Models.CreateWikiPageResponse> {
    return this.sendOperationRequest(
      {
        wikiId,
        body,
        options
      },
      createWikiPageOperationSpec,
      callback) as Promise<Models.CreateWikiPageResponse>;
  }

  /**
   * 指定された Wiki に対して新規のページを作成します。
   * @summary Wiki ページ一括作成
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.BulkCreateWikiPageResponse>
   */
  bulkCreateWikiPage(wikiId: string, body: Models.V1BulkCreateWikiPageRequestBody, options?: Models.KamigameWikiApiBulkCreateWikiPageOptionalParams): Promise<Models.BulkCreateWikiPageResponse>;
  /**
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param callback The callback
   */
  bulkCreateWikiPage(wikiId: string, body: Models.V1BulkCreateWikiPageRequestBody, callback: msRest.ServiceCallback<Models.V1BulkCreateWikiPageResult>): void;
  /**
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  bulkCreateWikiPage(wikiId: string, body: Models.V1BulkCreateWikiPageRequestBody, options: Models.KamigameWikiApiBulkCreateWikiPageOptionalParams, callback: msRest.ServiceCallback<Models.V1BulkCreateWikiPageResult>): void;
  bulkCreateWikiPage(wikiId: string, body: Models.V1BulkCreateWikiPageRequestBody, options?: Models.KamigameWikiApiBulkCreateWikiPageOptionalParams | msRest.ServiceCallback<Models.V1BulkCreateWikiPageResult>, callback?: msRest.ServiceCallback<Models.V1BulkCreateWikiPageResult>): Promise<Models.BulkCreateWikiPageResponse> {
    return this.sendOperationRequest(
      {
        wikiId,
        body,
        options
      },
      bulkCreateWikiPageOperationSpec,
      callback) as Promise<Models.BulkCreateWikiPageResponse>;
  }

  /**
   * 指定された Wiki ページを一括で編集します。
   * @summary Wiki ページ一括更新
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.BulkUpdateWikiPageResponse>
   */
  bulkUpdateWikiPage(wikiId: string, body: Models.V1BulkUpdateWikiPageRequestBody, options?: Models.KamigameWikiApiBulkUpdateWikiPageOptionalParams): Promise<Models.BulkUpdateWikiPageResponse>;
  /**
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param callback The callback
   */
  bulkUpdateWikiPage(wikiId: string, body: Models.V1BulkUpdateWikiPageRequestBody, callback: msRest.ServiceCallback<Models.V1BulkUpdateWikiPageResult>): void;
  /**
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  bulkUpdateWikiPage(wikiId: string, body: Models.V1BulkUpdateWikiPageRequestBody, options: Models.KamigameWikiApiBulkUpdateWikiPageOptionalParams, callback: msRest.ServiceCallback<Models.V1BulkUpdateWikiPageResult>): void;
  bulkUpdateWikiPage(wikiId: string, body: Models.V1BulkUpdateWikiPageRequestBody, options?: Models.KamigameWikiApiBulkUpdateWikiPageOptionalParams | msRest.ServiceCallback<Models.V1BulkUpdateWikiPageResult>, callback?: msRest.ServiceCallback<Models.V1BulkUpdateWikiPageResult>): Promise<Models.BulkUpdateWikiPageResponse> {
    return this.sendOperationRequest(
      {
        wikiId,
        body,
        options
      },
      bulkUpdateWikiPageOperationSpec,
      callback) as Promise<Models.BulkUpdateWikiPageResponse>;
  }

  /**
   * 指定された Wiki ページのカテゴリ設定を一括で変更します
   * @summary Wiki ページへのカテゴリ一括設定
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.BulkUpdateCategoryOfWikiPageResponse>
   */
  bulkUpdateCategoryOfWikiPage(wikiId: string, body: Models.V1BulkUpdateCategoryOfWikiPageRequestBody, options?: msRest.RequestOptionsBase): Promise<Models.BulkUpdateCategoryOfWikiPageResponse>;
  /**
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param callback The callback
   */
  bulkUpdateCategoryOfWikiPage(wikiId: string, body: Models.V1BulkUpdateCategoryOfWikiPageRequestBody, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  bulkUpdateCategoryOfWikiPage(wikiId: string, body: Models.V1BulkUpdateCategoryOfWikiPageRequestBody, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  bulkUpdateCategoryOfWikiPage(wikiId: string, body: Models.V1BulkUpdateCategoryOfWikiPageRequestBody, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.BulkUpdateCategoryOfWikiPageResponse> {
    return this.sendOperationRequest(
      {
        wikiId,
        body,
        options
      },
      bulkUpdateCategoryOfWikiPageOperationSpec,
      callback) as Promise<Models.BulkUpdateCategoryOfWikiPageResponse>;
  }

  /**
   * 指定された takahiro-games 由来の Wiki ページを一括で編集します。
   * @summary Wiki ページ一括更新 (takahiro-games)
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.BulkTakahiroWikiPageResponse>
   */
  bulkTakahiroWikiPage(wikiId: string, body: Models.V1BulkTakahiroWikiPageRequestBody, options?: Models.KamigameWikiApiBulkTakahiroWikiPageOptionalParams): Promise<Models.BulkTakahiroWikiPageResponse>;
  /**
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param callback The callback
   */
  bulkTakahiroWikiPage(wikiId: string, body: Models.V1BulkTakahiroWikiPageRequestBody, callback: msRest.ServiceCallback<Models.V1BulkTakahiroWikiPageResult>): void;
  /**
   * @param wikiId ページが属する Wiki の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  bulkTakahiroWikiPage(wikiId: string, body: Models.V1BulkTakahiroWikiPageRequestBody, options: Models.KamigameWikiApiBulkTakahiroWikiPageOptionalParams, callback: msRest.ServiceCallback<Models.V1BulkTakahiroWikiPageResult>): void;
  bulkTakahiroWikiPage(wikiId: string, body: Models.V1BulkTakahiroWikiPageRequestBody, options?: Models.KamigameWikiApiBulkTakahiroWikiPageOptionalParams | msRest.ServiceCallback<Models.V1BulkTakahiroWikiPageResult>, callback?: msRest.ServiceCallback<Models.V1BulkTakahiroWikiPageResult>): Promise<Models.BulkTakahiroWikiPageResponse> {
    return this.sendOperationRequest(
      {
        wikiId,
        body,
        options
      },
      bulkTakahiroWikiPageOperationSpec,
      callback) as Promise<Models.BulkTakahiroWikiPageResponse>;
  }

  /**
   * 指定された Wiki ページを削除します。
   * @summary Wiki ページ削除
   * @param wikiId Wiki の ID です
   * @param pageId Wiki ページの ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWikiPageResponse>
   */
  deleteWikiPage(wikiId: string, pageId: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWikiPageResponse>;
  /**
   * @param wikiId Wiki の ID です
   * @param pageId Wiki ページの ID です
   * @param callback The callback
   */
  deleteWikiPage(wikiId: string, pageId: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiId Wiki の ID です
   * @param pageId Wiki ページの ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWikiPage(wikiId: string, pageId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWikiPage(wikiId: string, pageId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWikiPageResponse> {
    return this.sendOperationRequest(
      {
        wikiId,
        pageId,
        options
      },
      deleteWikiPageOperationSpec,
      callback) as Promise<Models.DeleteWikiPageResponse>;
  }

  /**
   * 指定された Wiki ページを編集します。
   * @summary Wiki ページ編集
   * @param wikiId Wiki の ID です
   * @param pageId Wiki ページの ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiPageResponse>
   */
  updateWikiPage(wikiId: string, pageId: string, body: Models.V1EditWikiPageRequestBody, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiPageResponse>;
  /**
   * @param wikiId Wiki の ID です
   * @param pageId Wiki ページの ID です
   * @param body
   * @param callback The callback
   */
  updateWikiPage(wikiId: string, pageId: string, body: Models.V1EditWikiPageRequestBody, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): void;
  /**
   * @param wikiId Wiki の ID です
   * @param pageId Wiki ページの ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiPage(wikiId: string, pageId: string, body: Models.V1EditWikiPageRequestBody, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): void;
  updateWikiPage(wikiId: string, pageId: string, body: Models.V1EditWikiPageRequestBody, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>, callback?: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): Promise<Models.UpdateWikiPageResponse> {
    return this.sendOperationRequest(
      {
        wikiId,
        pageId,
        body,
        options
      },
      updateWikiPageOperationSpec,
      callback) as Promise<Models.UpdateWikiPageResponse>;
  }

  /**
   * 指定された Wiki ページを復元します。
   * @summary Wiki ページ復元
   * @param wikiId Wiki の ID です
   * @param pageId Wiki ページの ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.RestoreWikiPageResponse>
   */
  restoreWikiPage(wikiId: string, pageId: string, options?: msRest.RequestOptionsBase): Promise<Models.RestoreWikiPageResponse>;
  /**
   * @param wikiId Wiki の ID です
   * @param pageId Wiki ページの ID です
   * @param callback The callback
   */
  restoreWikiPage(wikiId: string, pageId: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiId Wiki の ID です
   * @param pageId Wiki ページの ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  restoreWikiPage(wikiId: string, pageId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  restoreWikiPage(wikiId: string, pageId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.RestoreWikiPageResponse> {
    return this.sendOperationRequest(
      {
        wikiId,
        pageId,
        options
      },
      restoreWikiPageOperationSpec,
      callback) as Promise<Models.RestoreWikiPageResponse>;
  }

  /**
   * WikiPageCategory の一覧を取得します。
   * @summary WikiPageCategory 一覧取得
   * @param wikiName 関連づいた Wiki 名です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPageCategoryResponse>
   */
  listWikiPageCategory(wikiName: string, options?: Models.KamigameWikiApiListWikiPageCategoryOptionalParams): Promise<Models.ListWikiPageCategoryResponse>;
  /**
   * @param wikiName 関連づいた Wiki 名です
   * @param callback The callback
   */
  listWikiPageCategory(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>): void;
  /**
   * @param wikiName 関連づいた Wiki 名です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPageCategory(wikiName: string, options: Models.KamigameWikiApiListWikiPageCategoryOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>): void;
  listWikiPageCategory(wikiName: string, options?: Models.KamigameWikiApiListWikiPageCategoryOptionalParams | msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>): Promise<Models.ListWikiPageCategoryResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiPageCategoryOperationSpec,
      callback) as Promise<Models.ListWikiPageCategoryResponse>;
  }

  /**
   * 指定された WikiPageCategory の情報を取得します。
   * @summary WikiPageCategory 情報取得
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiPageCategoryResponse>
   */
  getWikiPageCategory(wikiName: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiPageCategoryResponse>;
  /**
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param callback The callback
   */
  getWikiPageCategory(wikiName: string, id: string, callback: msRest.ServiceCallback<Models.V1WikiPageCategory>): void;
  /**
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiPageCategory(wikiName: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageCategory>): void;
  getWikiPageCategory(wikiName: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageCategory>, callback?: msRest.ServiceCallback<Models.V1WikiPageCategory>): Promise<Models.GetWikiPageCategoryResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        id,
        options
      },
      getWikiPageCategoryOperationSpec,
      callback) as Promise<Models.GetWikiPageCategoryResponse>;
  }

  /**
   * WikiPageCategory を削除します。
   * @summary WikiPageCategory 削除
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWikiPageCategoryResponse>
   */
  deleteWikiPageCategory(wikiName: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWikiPageCategoryResponse>;
  /**
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param callback The callback
   */
  deleteWikiPageCategory(wikiName: string, id: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWikiPageCategory(wikiName: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWikiPageCategory(wikiName: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWikiPageCategoryResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        id,
        options
      },
      deleteWikiPageCategoryOperationSpec,
      callback) as Promise<Models.DeleteWikiPageCategoryResponse>;
  }

  /**
   * WikiPageCategory を更新します。
   * @summary WikiPageCategory 更新
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiPageCategoryResponse>
   */
  updateWikiPageCategory(wikiName: string, id: string, body: Models.V1WikiPageCategoryUpdateRequest, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiPageCategoryResponse>;
  /**
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param body
   * @param callback The callback
   */
  updateWikiPageCategory(wikiName: string, id: string, body: Models.V1WikiPageCategoryUpdateRequest, callback: msRest.ServiceCallback<Models.V1WikiPageCategory>): void;
  /**
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiPageCategory(wikiName: string, id: string, body: Models.V1WikiPageCategoryUpdateRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageCategory>): void;
  updateWikiPageCategory(wikiName: string, id: string, body: Models.V1WikiPageCategoryUpdateRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageCategory>, callback?: msRest.ServiceCallback<Models.V1WikiPageCategory>): Promise<Models.UpdateWikiPageCategoryResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        id,
        body,
        options
      },
      updateWikiPageCategoryOperationSpec,
      callback) as Promise<Models.UpdateWikiPageCategoryResponse>;
  }

  /**
   * Chat GPT の completions API の結果を取得します。
   * @summary Chat GPT の completions API 実行
   * @param wikiName
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.GetChatGptCompletionsResponse>
   */
  getChatGptCompletions(wikiName: string, body: Models.V1GetChatGptCompletionsRequest, options?: msRest.RequestOptionsBase): Promise<Models.GetChatGptCompletionsResponse>;
  /**
   * @param wikiName
   * @param body
   * @param callback The callback
   */
  getChatGptCompletions(wikiName: string, body: Models.V1GetChatGptCompletionsRequest, callback: msRest.ServiceCallback<Models.V1GetChatGptCompletionsResponse>): void;
  /**
   * @param wikiName
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  getChatGptCompletions(wikiName: string, body: Models.V1GetChatGptCompletionsRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1GetChatGptCompletionsResponse>): void;
  getChatGptCompletions(wikiName: string, body: Models.V1GetChatGptCompletionsRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1GetChatGptCompletionsResponse>, callback?: msRest.ServiceCallback<Models.V1GetChatGptCompletionsResponse>): Promise<Models.GetChatGptCompletionsResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        body,
        options
      },
      getChatGptCompletionsOperationSpec,
      callback) as Promise<Models.GetChatGptCompletionsResponse>;
  }

  /**
   * Chat GPT の completions API の結果を取得します。こちらは外部のメンバー向けにサーバー側にプロンプトを設定しています
   * @summary Chat GPT の completions API 実行（外部メンバー向け）
   * @param wikiName
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.GetChatGptCompletionsWithPromptResponse>
   */
  getChatGptCompletionsWithPrompt(wikiName: string, body: Models.V1GetChatGptCompletionsWithPromptRequest, options?: msRest.RequestOptionsBase): Promise<Models.GetChatGptCompletionsWithPromptResponse>;
  /**
   * @param wikiName
   * @param body
   * @param callback The callback
   */
  getChatGptCompletionsWithPrompt(wikiName: string, body: Models.V1GetChatGptCompletionsWithPromptRequest, callback: msRest.ServiceCallback<Models.V1GetChatGptCompletionsResponse>): void;
  /**
   * @param wikiName
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  getChatGptCompletionsWithPrompt(wikiName: string, body: Models.V1GetChatGptCompletionsWithPromptRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1GetChatGptCompletionsResponse>): void;
  getChatGptCompletionsWithPrompt(wikiName: string, body: Models.V1GetChatGptCompletionsWithPromptRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1GetChatGptCompletionsResponse>, callback?: msRest.ServiceCallback<Models.V1GetChatGptCompletionsResponse>): Promise<Models.GetChatGptCompletionsWithPromptResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        body,
        options
      },
      getChatGptCompletionsWithPromptOperationSpec,
      callback) as Promise<Models.GetChatGptCompletionsWithPromptResponse>;
  }

  /**
   * 指定された Wiki のデータシートの Realtime Database におけるルールを設定します
   * @summary Realtime Database におけるルール設定
   * @param wikiName 対象の Wiki の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.ConfigureRealtimeDatabaseRuleResponse>
   */
  configureRealtimeDatabaseRule(wikiName: string, body: Models.V1ConfigureRealtimeDatabaseRuleRequest, options?: msRest.RequestOptionsBase): Promise<Models.ConfigureRealtimeDatabaseRuleResponse>;
  /**
   * @param wikiName 対象の Wiki の ID です
   * @param body
   * @param callback The callback
   */
  configureRealtimeDatabaseRule(wikiName: string, body: Models.V1ConfigureRealtimeDatabaseRuleRequest, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName 対象の Wiki の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  configureRealtimeDatabaseRule(wikiName: string, body: Models.V1ConfigureRealtimeDatabaseRuleRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  configureRealtimeDatabaseRule(wikiName: string, body: Models.V1ConfigureRealtimeDatabaseRuleRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.ConfigureRealtimeDatabaseRuleResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        body,
        options
      },
      configureRealtimeDatabaseRuleOperationSpec,
      callback) as Promise<Models.ConfigureRealtimeDatabaseRuleResponse>;
  }

  /**
   * 指定された Wiki のデータシートの更新履歴を作成します
   * @summary Wiki データシート更新履歴作成
   * @param wikiName Wiki の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWikiDataChangelogResponse>
   */
  createWikiDataChangelog(wikiName: string, body: Models.V1CreateWikiDataChangelogRequest, options?: msRest.RequestOptionsBase): Promise<Models.CreateWikiDataChangelogResponse>;
  /**
   * @param wikiName Wiki の ID です
   * @param body
   * @param callback The callback
   */
  createWikiDataChangelog(wikiName: string, body: Models.V1CreateWikiDataChangelogRequest, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createWikiDataChangelog(wikiName: string, body: Models.V1CreateWikiDataChangelogRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  createWikiDataChangelog(wikiName: string, body: Models.V1CreateWikiDataChangelogRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.CreateWikiDataChangelogResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        body,
        options
      },
      createWikiDataChangelogOperationSpec,
      callback) as Promise<Models.CreateWikiDataChangelogResponse>;
  }

  /**
   * データシート記法用に指定された Wiki のデータシートのデータを取得します
   * @summary Wiki データシートデータ取得
   * @param wikiName Wiki 名です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiDataSheetDataResponse>
   */
  getWikiDataSheetData(wikiName: string, options?: Models.KamigameWikiApiGetWikiDataSheetDataOptionalParams): Promise<Models.GetWikiDataSheetDataResponse>;
  /**
   * @param wikiName Wiki 名です
   * @param callback The callback
   */
  getWikiDataSheetData(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiDataSheets>): void;
  /**
   * @param wikiName Wiki 名です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiDataSheetData(wikiName: string, options: Models.KamigameWikiApiGetWikiDataSheetDataOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiDataSheets>): void;
  getWikiDataSheetData(wikiName: string, options?: Models.KamigameWikiApiGetWikiDataSheetDataOptionalParams | msRest.ServiceCallback<Models.V1WikiDataSheets>, callback?: msRest.ServiceCallback<Models.V1WikiDataSheets>): Promise<Models.GetWikiDataSheetDataResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      getWikiDataSheetDataOperationSpec,
      callback) as Promise<Models.GetWikiDataSheetDataResponse>;
  }

  /**
   * 指定された Wiki のデータシートの外部参照の情報の一覧を取得します
   * @summary Wiki データシート外部参照一覧取得
   * @param wikiName 取得したい Wiki の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiDataSheetLinkResponse>
   */
  listWikiDataSheetLink(wikiName: string, options?: Models.KamigameWikiApiListWikiDataSheetLinkOptionalParams): Promise<Models.ListWikiDataSheetLinkResponse>;
  /**
   * @param wikiName 取得したい Wiki の ID です
   * @param callback The callback
   */
  listWikiDataSheetLink(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiDataSheetLinkCollection>): void;
  /**
   * @param wikiName 取得したい Wiki の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiDataSheetLink(wikiName: string, options: Models.KamigameWikiApiListWikiDataSheetLinkOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiDataSheetLinkCollection>): void;
  listWikiDataSheetLink(wikiName: string, options?: Models.KamigameWikiApiListWikiDataSheetLinkOptionalParams | msRest.ServiceCallback<Models.V1WikiDataSheetLinkCollection>, callback?: msRest.ServiceCallback<Models.V1WikiDataSheetLinkCollection>): Promise<Models.ListWikiDataSheetLinkResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiDataSheetLinkOperationSpec,
      callback) as Promise<Models.ListWikiDataSheetLinkResponse>;
  }

  /**
   * 指定された Wiki のデータシートの外部参照の情報を削除します
   * @summary Wiki データシート外部参照情報削除
   * @param wikiName 指定した wiki の名称です
   * @param id id です
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWikiDataSheetLinkResponse>
   */
  deleteWikiDataSheetLink(wikiName: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWikiDataSheetLinkResponse>;
  /**
   * @param wikiName 指定した wiki の名称です
   * @param id id です
   * @param callback The callback
   */
  deleteWikiDataSheetLink(wikiName: string, id: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName 指定した wiki の名称です
   * @param id id です
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWikiDataSheetLink(wikiName: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWikiDataSheetLink(wikiName: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWikiDataSheetLinkResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        id,
        options
      },
      deleteWikiDataSheetLinkOperationSpec,
      callback) as Promise<Models.DeleteWikiDataSheetLinkResponse>;
  }

  /**
   * 指定された Wiki データシートのデータを取得します
   * @summary Wiki データシート取得
   * @param wikiName Wiki 名です
   * @param sheetId データシートの ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiDataSheetResponse>
   */
  getWikiDataSheet(wikiName: string, sheetId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiDataSheetResponse>;
  /**
   * @param wikiName Wiki 名です
   * @param sheetId データシートの ID です
   * @param callback The callback
   */
  getWikiDataSheet(wikiName: string, sheetId: string, callback: msRest.ServiceCallback<Models.V1WikiDataSheet>): void;
  /**
   * @param wikiName Wiki 名です
   * @param sheetId データシートの ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiDataSheet(wikiName: string, sheetId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiDataSheet>): void;
  getWikiDataSheet(wikiName: string, sheetId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiDataSheet>, callback?: msRest.ServiceCallback<Models.V1WikiDataSheet>): Promise<Models.GetWikiDataSheetResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        sheetId,
        options
      },
      getWikiDataSheetOperationSpec,
      callback) as Promise<Models.GetWikiDataSheetResponse>;
  }

  /**
   * 指定された Wiki データシートのデータを削除します
   * @summary Wiki データシート削除
   * @param wikiName Wiki 名です
   * @param sheetId データシートの ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWikiDataSheetResponse>
   */
  deleteWikiDataSheet(wikiName: string, sheetId: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWikiDataSheetResponse>;
  /**
   * @param wikiName Wiki 名です
   * @param sheetId データシートの ID です
   * @param callback The callback
   */
  deleteWikiDataSheet(wikiName: string, sheetId: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki 名です
   * @param sheetId データシートの ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWikiDataSheet(wikiName: string, sheetId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWikiDataSheet(wikiName: string, sheetId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWikiDataSheetResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        sheetId,
        options
      },
      deleteWikiDataSheetOperationSpec,
      callback) as Promise<Models.DeleteWikiDataSheetResponse>;
  }

  /**
   * WikiDomainAllowlist にデータを追加します
   * @summary WikiDomainAllowlist にデータを追加
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param body リクエストボディです
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWikiDomainAllowlistResponse>
   */
  createWikiDomainAllowlist(wikiName: string, body: Models.V1CreateWikiDomainAllowlistRequestBody, options?: msRest.RequestOptionsBase): Promise<Models.CreateWikiDomainAllowlistResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param body リクエストボディです
   * @param callback The callback
   */
  createWikiDomainAllowlist(wikiName: string, body: Models.V1CreateWikiDomainAllowlistRequestBody, callback: msRest.ServiceCallback<Models.V1WikiAllowedDomain>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param body リクエストボディです
   * @param options The optional parameters
   * @param callback The callback
   */
  createWikiDomainAllowlist(wikiName: string, body: Models.V1CreateWikiDomainAllowlistRequestBody, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiAllowedDomain>): void;
  createWikiDomainAllowlist(wikiName: string, body: Models.V1CreateWikiDomainAllowlistRequestBody, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiAllowedDomain>, callback?: msRest.ServiceCallback<Models.V1WikiAllowedDomain>): Promise<Models.CreateWikiDomainAllowlistResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        body,
        options
      },
      createWikiDomainAllowlistOperationSpec,
      callback) as Promise<Models.CreateWikiDomainAllowlistResponse>;
  }

  /**
   * wiki と id を指定して WikiDomainAllowlist を削除します
   * @summary wiki と id を指定して WikiDomainAllowlist を削除
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id WikiDomainAllowlist をユニークに識別するための内部的な ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWikiDomainAllowlistResponse>
   */
  deleteWikiDomainAllowlist(wikiName: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWikiDomainAllowlistResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id WikiDomainAllowlist をユニークに識別するための内部的な ID です
   * @param callback The callback
   */
  deleteWikiDomainAllowlist(wikiName: string, id: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id WikiDomainAllowlist をユニークに識別するための内部的な ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWikiDomainAllowlist(wikiName: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWikiDomainAllowlist(wikiName: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWikiDomainAllowlistResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        id,
        options
      },
      deleteWikiDomainAllowlistOperationSpec,
      callback) as Promise<Models.DeleteWikiDomainAllowlistResponse>;
  }

  /**
   * 指定された Wiki における更新履歴の一覧を取得します。
   * @summary Wiki ページ更新履歴一覧取得
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPageHistoriesResponse>
   */
  listWikiPageHistories(wikiName: string, options?: Models.KamigameWikiApiListWikiPageHistoriesOptionalParams): Promise<Models.ListWikiPageHistoriesResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiPageHistories(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiHistoryCollection>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPageHistories(wikiName: string, options: Models.KamigameWikiApiListWikiPageHistoriesOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiHistoryCollection>): void;
  listWikiPageHistories(wikiName: string, options?: Models.KamigameWikiApiListWikiPageHistoriesOptionalParams | msRest.ServiceCallback<Models.V1WikiHistoryCollection>, callback?: msRest.ServiceCallback<Models.V1WikiHistoryCollection>): Promise<Models.ListWikiPageHistoriesResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiPageHistoriesOperationSpec,
      callback) as Promise<Models.ListWikiPageHistoriesResponse>;
  }

  /**
   * 指定された更新履歴の内容を取得します。
   * @summary Wiki ページ更新履歴取得
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param historyId Wiki ページ履歴をユニークに識別する ID もしくはパス名です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiPageHistoryResponse>
   */
  getWikiPageHistory(wikiName: string, historyId: string, options?: Models.KamigameWikiApiGetWikiPageHistoryOptionalParams): Promise<Models.GetWikiPageHistoryResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param historyId Wiki ページ履歴をユニークに識別する ID もしくはパス名です
   * @param callback The callback
   */
  getWikiPageHistory(wikiName: string, historyId: string, callback: msRest.ServiceCallback<Models.V1WikiPageHistory>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param historyId Wiki ページ履歴をユニークに識別する ID もしくはパス名です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiPageHistory(wikiName: string, historyId: string, options: Models.KamigameWikiApiGetWikiPageHistoryOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageHistory>): void;
  getWikiPageHistory(wikiName: string, historyId: string, options?: Models.KamigameWikiApiGetWikiPageHistoryOptionalParams | msRest.ServiceCallback<Models.V1WikiPageHistory>, callback?: msRest.ServiceCallback<Models.V1WikiPageHistory>): Promise<Models.GetWikiPageHistoryResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        historyId,
        options
      },
      getWikiPageHistoryOperationSpec,
      callback) as Promise<Models.GetWikiPageHistoryResponse>;
  }

  /**
   * 指定された Wiki ページの更新履歴の差分行数を設定します
   * @summary Wiki ページ更新履歴の差分行数設定
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param historyId Wiki ページ履歴をユニークに識別する ID もしくはパス名です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiPageHistoryDiffResponse>
   */
  updateWikiPageHistoryDiff(wikiName: string, historyId: string, body: Models.V1UpdateWikiPageHistoryDiffRequestBody, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiPageHistoryDiffResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param historyId Wiki ページ履歴をユニークに識別する ID もしくはパス名です
   * @param body
   * @param callback The callback
   */
  updateWikiPageHistoryDiff(wikiName: string, historyId: string, body: Models.V1UpdateWikiPageHistoryDiffRequestBody, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param historyId Wiki ページ履歴をユニークに識別する ID もしくはパス名です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiPageHistoryDiff(wikiName: string, historyId: string, body: Models.V1UpdateWikiPageHistoryDiffRequestBody, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiPageHistoryDiff(wikiName: string, historyId: string, body: Models.V1UpdateWikiPageHistoryDiffRequestBody, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiPageHistoryDiffResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        historyId,
        body,
        options
      },
      updateWikiPageHistoryDiffOperationSpec,
      callback) as Promise<Models.UpdateWikiPageHistoryDiffResponse>;
  }

  /**
   * 特定の Wiki に所属するメンバーの一覧を取得します
   * @summary Wiki メンバー一覧取得
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiMemberResponse>
   */
  listWikiMember(wikiName: string, options?: Models.KamigameWikiApiListWikiMemberOptionalParams): Promise<Models.ListWikiMemberResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiMember(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiMemberCollection>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiMember(wikiName: string, options: Models.KamigameWikiApiListWikiMemberOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiMemberCollection>): void;
  listWikiMember(wikiName: string, options?: Models.KamigameWikiApiListWikiMemberOptionalParams | msRest.ServiceCallback<Models.V1WikiMemberCollection>, callback?: msRest.ServiceCallback<Models.V1WikiMemberCollection>): Promise<Models.ListWikiMemberResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiMemberOperationSpec,
      callback) as Promise<Models.ListWikiMemberResponse>;
  }

  /**
   * 特定の Wiki メンバー招待を削除します
   * @summary Wiki メンバー招待削除
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id Wiki メンバー招待をあらわす ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWikiMemberInviteResponse>
   */
  deleteWikiMemberInvite(wikiName: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWikiMemberInviteResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id Wiki メンバー招待をあらわす ID です
   * @param callback The callback
   */
  deleteWikiMemberInvite(wikiName: string, id: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id Wiki メンバー招待をあらわす ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWikiMemberInvite(wikiName: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWikiMemberInvite(wikiName: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWikiMemberInviteResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        id,
        options
      },
      deleteWikiMemberInviteOperationSpec,
      callback) as Promise<Models.DeleteWikiMemberInviteResponse>;
  }

  /**
   * 特定の Wiki に対する参加申請の一覧を取得します
   * @summary Wiki メンバー参加申請一覧取得
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiMemberRequestResponse>
   */
  listWikiMemberRequest(wikiName: string, options?: Models.KamigameWikiApiListWikiMemberRequestOptionalParams): Promise<Models.ListWikiMemberRequestResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiMemberRequest(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiMemberRequestCollection>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiMemberRequest(wikiName: string, options: Models.KamigameWikiApiListWikiMemberRequestOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiMemberRequestCollection>): void;
  listWikiMemberRequest(wikiName: string, options?: Models.KamigameWikiApiListWikiMemberRequestOptionalParams | msRest.ServiceCallback<Models.V1WikiMemberRequestCollection>, callback?: msRest.ServiceCallback<Models.V1WikiMemberRequestCollection>): Promise<Models.ListWikiMemberRequestResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiMemberRequestOperationSpec,
      callback) as Promise<Models.ListWikiMemberRequestResponse>;
  }

  /**
   * 指定したユーザーを Wiki メンバーから外します
   * @summary Wiki メンバー削除
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param userId ユーザをユニークに識別する ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWikiMemberResponse>
   */
  deleteWikiMember(wikiName: string, userId: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWikiMemberResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param userId ユーザをユニークに識別する ID です
   * @param callback The callback
   */
  deleteWikiMember(wikiName: string, userId: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param userId ユーザをユニークに識別する ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWikiMember(wikiName: string, userId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWikiMember(wikiName: string, userId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWikiMemberResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        userId,
        options
      },
      deleteWikiMemberOperationSpec,
      callback) as Promise<Models.DeleteWikiMemberResponse>;
  }

  /**
   * Wiki 毎のページ部品の一覧を取得します
   * @summary Wiki ページ部品一覧
   * @param wikiName Wiki ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPagePartialResponse>
   */
  listWikiPagePartial(wikiName: string, options?: Models.KamigameWikiApiListWikiPagePartialOptionalParams): Promise<Models.ListWikiPagePartialResponse>;
  /**
   * @param wikiName Wiki ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param callback The callback
   */
  listWikiPagePartial(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiPagePartialCollection>): void;
  /**
   * @param wikiName Wiki ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPagePartial(wikiName: string, options: Models.KamigameWikiApiListWikiPagePartialOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPagePartialCollection>): void;
  listWikiPagePartial(wikiName: string, options?: Models.KamigameWikiApiListWikiPagePartialOptionalParams | msRest.ServiceCallback<Models.V1WikiPagePartialCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPagePartialCollection>): Promise<Models.ListWikiPagePartialResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiPagePartialOperationSpec,
      callback) as Promise<Models.ListWikiPagePartialResponse>;
  }

  /**
   * Wiki ページ部品作成します
   * @summary Wiki ページ部品作成
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWikiPagePartialResponse>
   */
  createWikiPagePartial(wikiName: string, body: Models.V1CreateWikiPagePartialRequest, options?: msRest.RequestOptionsBase): Promise<Models.CreateWikiPagePartialResponse>;
  /**
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param body
   * @param callback The callback
   */
  createWikiPagePartial(wikiName: string, body: Models.V1CreateWikiPagePartialRequest, callback: msRest.ServiceCallback<Models.V1WikiPagePartial>): void;
  /**
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createWikiPagePartial(wikiName: string, body: Models.V1CreateWikiPagePartialRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPagePartial>): void;
  createWikiPagePartial(wikiName: string, body: Models.V1CreateWikiPagePartialRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPagePartial>, callback?: msRest.ServiceCallback<Models.V1WikiPagePartial>): Promise<Models.CreateWikiPagePartialResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        body,
        options
      },
      createWikiPagePartialOperationSpec,
      callback) as Promise<Models.CreateWikiPagePartialResponse>;
  }

  /**
   * Wiki 毎のページ部品タイトルの一覧を取得します
   * @summary Wiki ページ部品タイトル一覧
   * @param wikiName Wiki ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPagePartialTitleResponse>
   */
  listWikiPagePartialTitle(wikiName: string, options?: Models.KamigameWikiApiListWikiPagePartialTitleOptionalParams): Promise<Models.ListWikiPagePartialTitleResponse>;
  /**
   * @param wikiName Wiki ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param callback The callback
   */
  listWikiPagePartialTitle(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiPagePartialNameCollection>): void;
  /**
   * @param wikiName Wiki ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPagePartialTitle(wikiName: string, options: Models.KamigameWikiApiListWikiPagePartialTitleOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPagePartialNameCollection>): void;
  listWikiPagePartialTitle(wikiName: string, options?: Models.KamigameWikiApiListWikiPagePartialTitleOptionalParams | msRest.ServiceCallback<Models.V1WikiPagePartialNameCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPagePartialNameCollection>): Promise<Models.ListWikiPagePartialTitleResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiPagePartialTitleOperationSpec,
      callback) as Promise<Models.ListWikiPagePartialTitleResponse>;
  }

  /**
   * Wiki ページ部品の削除をします
   * @summary Wiki ページ部品削除
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param id ページ部品のIDです
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWikiPagePartialResponse>
   */
  deleteWikiPagePartial(wikiName: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWikiPagePartialResponse>;
  /**
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param id ページ部品のIDです
   * @param callback The callback
   */
  deleteWikiPagePartial(wikiName: string, id: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param id ページ部品のIDです
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWikiPagePartial(wikiName: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWikiPagePartial(wikiName: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWikiPagePartialResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        id,
        options
      },
      deleteWikiPagePartialOperationSpec,
      callback) as Promise<Models.DeleteWikiPagePartialResponse>;
  }

  /**
   * Wiki ページ部品の復元をします
   * @summary Wiki ページ部品復元
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param id ページ部品のIDです
   * @param [options] The optional parameters
   * @returns Promise<Models.RestoreWikiPagePartialResponse>
   */
  restoreWikiPagePartial(wikiName: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.RestoreWikiPagePartialResponse>;
  /**
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param id ページ部品のIDです
   * @param callback The callback
   */
  restoreWikiPagePartial(wikiName: string, id: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param id ページ部品のIDです
   * @param options The optional parameters
   * @param callback The callback
   */
  restoreWikiPagePartial(wikiName: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  restoreWikiPagePartial(wikiName: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.RestoreWikiPagePartialResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        id,
        options
      },
      restoreWikiPagePartialOperationSpec,
      callback) as Promise<Models.RestoreWikiPagePartialResponse>;
  }

  /**
   * @summary Wiki ページ部品取得
   * @param wikiName ページ部品が所属する Wiki の人間可読な ID です
   * @param wikiPagePartialID ページ記事部品の名称です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiPagePartialResponse>
   */
  getWikiPagePartial(wikiName: string, wikiPagePartialID: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiPagePartialResponse>;
  /**
   * @param wikiName ページ部品が所属する Wiki の人間可読な ID です
   * @param wikiPagePartialID ページ記事部品の名称です
   * @param callback The callback
   */
  getWikiPagePartial(wikiName: string, wikiPagePartialID: string, callback: msRest.ServiceCallback<Models.V1WikiPagePartial>): void;
  /**
   * @param wikiName ページ部品が所属する Wiki の人間可読な ID です
   * @param wikiPagePartialID ページ記事部品の名称です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiPagePartial(wikiName: string, wikiPagePartialID: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPagePartial>): void;
  getWikiPagePartial(wikiName: string, wikiPagePartialID: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPagePartial>, callback?: msRest.ServiceCallback<Models.V1WikiPagePartial>): Promise<Models.GetWikiPagePartialResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        wikiPagePartialID,
        options
      },
      getWikiPagePartialOperationSpec,
      callback) as Promise<Models.GetWikiPagePartialResponse>;
  }

  /**
   * Wiki ページ部品の編集・更新をします
   * @summary Wiki ページ部品編集
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param wikiPagePartialName ページ部品の名称です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiPagePartialResponse>
   */
  updateWikiPagePartial(wikiName: string, wikiPagePartialName: string, body: Models.V1EditWikiPagePartialRequest, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiPagePartialResponse>;
  /**
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param wikiPagePartialName ページ部品の名称です
   * @param body
   * @param callback The callback
   */
  updateWikiPagePartial(wikiName: string, wikiPagePartialName: string, body: Models.V1EditWikiPagePartialRequest, callback: msRest.ServiceCallback<Models.V1WikiPagePartial>): void;
  /**
   * @param wikiName ページ部品が所属する Wiki を識別する人間可読な ID です。
   * @param wikiPagePartialName ページ部品の名称です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiPagePartial(wikiName: string, wikiPagePartialName: string, body: Models.V1EditWikiPagePartialRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPagePartial>): void;
  updateWikiPagePartial(wikiName: string, wikiPagePartialName: string, body: Models.V1EditWikiPagePartialRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPagePartial>, callback?: msRest.ServiceCallback<Models.V1WikiPagePartial>): Promise<Models.UpdateWikiPagePartialResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        wikiPagePartialName,
        body,
        options
      },
      updateWikiPagePartialOperationSpec,
      callback) as Promise<Models.UpdateWikiPagePartialResponse>;
  }

  /**
   * 指定された Wiki ページにおける下書き一覧を取得します。
   * @summary Wiki ページ下書き一覧取得
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPageDraftResponse>
   */
  listWikiPageDraft(wikiName: string, options?: Models.KamigameWikiApiListWikiPageDraftOptionalParams): Promise<Models.ListWikiPageDraftResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiPageDraft(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiPageDraftCollection>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPageDraft(wikiName: string, options: Models.KamigameWikiApiListWikiPageDraftOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageDraftCollection>): void;
  listWikiPageDraft(wikiName: string, options?: Models.KamigameWikiApiListWikiPageDraftOptionalParams | msRest.ServiceCallback<Models.V1WikiPageDraftCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageDraftCollection>): Promise<Models.ListWikiPageDraftResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiPageDraftOperationSpec,
      callback) as Promise<Models.ListWikiPageDraftResponse>;
  }

  /**
   * 指定された Wiki の本文を除いたページ一覧を取得します。
   * @summary Wiki ページ情報一覧取得
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPageSummaryResponse>
   */
  listWikiPageSummary(wikiName: string, options?: Models.KamigameWikiApiListWikiPageSummaryOptionalParams): Promise<Models.ListWikiPageSummaryResponse>;
  /**
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param callback The callback
   */
  listWikiPageSummary(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiPageSummaryCollection>): void;
  /**
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPageSummary(wikiName: string, options: Models.KamigameWikiApiListWikiPageSummaryOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageSummaryCollection>): void;
  listWikiPageSummary(wikiName: string, options?: Models.KamigameWikiApiListWikiPageSummaryOptionalParams | msRest.ServiceCallback<Models.V1WikiPageSummaryCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageSummaryCollection>): Promise<Models.ListWikiPageSummaryResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiPageSummaryOperationSpec,
      callback) as Promise<Models.ListWikiPageSummaryResponse>;
  }

  /**
   * 指定された Wiki のページ一覧をタイトルと本文を伴って取得します
   * @summary タイトルを本文を含めた Wiki ページ一覧取得
   * @param wikiName 取得したい Wiki の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPageTitleBodiesResponse>
   */
  listWikiPageTitleBodies(wikiName: string, options?: Models.KamigameWikiApiListWikiPageTitleBodiesOptionalParams): Promise<Models.ListWikiPageTitleBodiesResponse>;
  /**
   * @param wikiName 取得したい Wiki の ID です
   * @param callback The callback
   */
  listWikiPageTitleBodies(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBodyCollection>): void;
  /**
   * @param wikiName 取得したい Wiki の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPageTitleBodies(wikiName: string, options: Models.KamigameWikiApiListWikiPageTitleBodiesOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBodyCollection>): void;
  listWikiPageTitleBodies(wikiName: string, options?: Models.KamigameWikiApiListWikiPageTitleBodiesOptionalParams | msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBodyCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBodyCollection>): Promise<Models.ListWikiPageTitleBodiesResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiPageTitleBodiesOperationSpec,
      callback) as Promise<Models.ListWikiPageTitleBodiesResponse>;
  }

  /**
   * 指定された Wiki のページのタイトル一覧を取得します。
   * @summary Wiki ページのタイトル一覧取得
   * @param wikiName 取得したい Wiki の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPageTitlesResponse>
   */
  listWikiPageTitles(wikiName: string, options?: Models.KamigameWikiApiListWikiPageTitlesOptionalParams): Promise<Models.ListWikiPageTitlesResponse>;
  /**
   * @param wikiName 取得したい Wiki の ID です
   * @param callback The callback
   */
  listWikiPageTitles(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiPageTitleCollection>): void;
  /**
   * @param wikiName 取得したい Wiki の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPageTitles(wikiName: string, options: Models.KamigameWikiApiListWikiPageTitlesOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageTitleCollection>): void;
  listWikiPageTitles(wikiName: string, options?: Models.KamigameWikiApiListWikiPageTitlesOptionalParams | msRest.ServiceCallback<Models.V1WikiPageTitleCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageTitleCollection>): Promise<Models.ListWikiPageTitlesResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiPageTitlesOperationSpec,
      callback) as Promise<Models.ListWikiPageTitlesResponse>;
  }

  /**
   * 指定された Wiki ページに紐づけるカテゴリを更新します
   * @summary Wiki ページカテゴリ設定
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param pageId Wiki ページの ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateLinkedWikiPageCategoryResponse>
   */
  updateLinkedWikiPageCategory(wikiName: string, pageId: string, body: Models.V1UpdateLinkedWikiPageCategoryRequest, options?: msRest.RequestOptionsBase): Promise<Models.UpdateLinkedWikiPageCategoryResponse>;
  /**
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param pageId Wiki ページの ID です
   * @param body
   * @param callback The callback
   */
  updateLinkedWikiPageCategory(wikiName: string, pageId: string, body: Models.V1UpdateLinkedWikiPageCategoryRequest, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param pageId Wiki ページの ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLinkedWikiPageCategory(wikiName: string, pageId: string, body: Models.V1UpdateLinkedWikiPageCategoryRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateLinkedWikiPageCategory(wikiName: string, pageId: string, body: Models.V1UpdateLinkedWikiPageCategoryRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateLinkedWikiPageCategoryResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        pageId,
        body,
        options
      },
      updateLinkedWikiPageCategoryOperationSpec,
      callback) as Promise<Models.UpdateLinkedWikiPageCategoryResponse>;
  }

  /**
   * 指定された Wiki ページにおける下書きを取得します。
   * @summary Wiki ページ下書き取得
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiPageDraftResponse>
   */
  getWikiPageDraft(wikiName: string, pageId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiPageDraftResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param callback The callback
   */
  getWikiPageDraft(wikiName: string, pageId: string, callback: msRest.ServiceCallback<Models.V1WikiPageDraft>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiPageDraft(wikiName: string, pageId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageDraft>): void;
  getWikiPageDraft(wikiName: string, pageId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageDraft>, callback?: msRest.ServiceCallback<Models.V1WikiPageDraft>): Promise<Models.GetWikiPageDraftResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        pageId,
        options
      },
      getWikiPageDraftOperationSpec,
      callback) as Promise<Models.GetWikiPageDraftResponse>;
  }

  /**
   * 指定された Wiki ページにおける下書きを削除します
   * @summary Wiki ページ下書き削除
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWikiPageDraftResponse>
   */
  deleteWikiPageDraft(wikiName: string, pageId: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteWikiPageDraftResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param callback The callback
   */
  deleteWikiPageDraft(wikiName: string, pageId: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWikiPageDraft(wikiName: string, pageId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteWikiPageDraft(wikiName: string, pageId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteWikiPageDraftResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        pageId,
        options
      },
      deleteWikiPageDraftOperationSpec,
      callback) as Promise<Models.DeleteWikiPageDraftResponse>;
  }

  /**
   * 指定された Wiki ページの下書きを編集します。
   * @summary Wiki ページ下書き編集
   * @param wikiName 下書きが所属する Wiki の人間可読な id です
   * @param pageId 下書きのもととなる Wiki ページの id です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.EditWikiPageDraftResponse>
   */
  editWikiPageDraft(wikiName: string, pageId: string, body: Models.V1EditWikiPageDraftRequestBody, options?: Models.KamigameWikiApiEditWikiPageDraftOptionalParams): Promise<Models.EditWikiPageDraftResponse>;
  /**
   * @param wikiName 下書きが所属する Wiki の人間可読な id です
   * @param pageId 下書きのもととなる Wiki ページの id です
   * @param body
   * @param callback The callback
   */
  editWikiPageDraft(wikiName: string, pageId: string, body: Models.V1EditWikiPageDraftRequestBody, callback: msRest.ServiceCallback<Models.V1WikiPageDraft>): void;
  /**
   * @param wikiName 下書きが所属する Wiki の人間可読な id です
   * @param pageId 下書きのもととなる Wiki ページの id です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  editWikiPageDraft(wikiName: string, pageId: string, body: Models.V1EditWikiPageDraftRequestBody, options: Models.KamigameWikiApiEditWikiPageDraftOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageDraft>): void;
  editWikiPageDraft(wikiName: string, pageId: string, body: Models.V1EditWikiPageDraftRequestBody, options?: Models.KamigameWikiApiEditWikiPageDraftOptionalParams | msRest.ServiceCallback<Models.V1WikiPageDraft>, callback?: msRest.ServiceCallback<Models.V1WikiPageDraft>): Promise<Models.EditWikiPageDraftResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        pageId,
        body,
        options
      },
      editWikiPageDraftOperationSpec,
      callback) as Promise<Models.EditWikiPageDraftResponse>;
  }

  /**
   * 指定された Wiki ページにおける更新履歴の一覧を取得します。
   * @summary Wiki ページごとのページ更新履歴一覧取得
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPageHistoriesByWikiPageResponse>
   */
  listWikiPageHistoriesByWikiPage(wikiName: string, pageId: string, options?: Models.KamigameWikiApiListWikiPageHistoriesByWikiPageOptionalParams): Promise<Models.ListWikiPageHistoriesByWikiPageResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param callback The callback
   */
  listWikiPageHistoriesByWikiPage(wikiName: string, pageId: string, callback: msRest.ServiceCallback<Models.V1WikiPageHistoryCollection>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPageHistoriesByWikiPage(wikiName: string, pageId: string, options: Models.KamigameWikiApiListWikiPageHistoriesByWikiPageOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageHistoryCollection>): void;
  listWikiPageHistoriesByWikiPage(wikiName: string, pageId: string, options?: Models.KamigameWikiApiListWikiPageHistoriesByWikiPageOptionalParams | msRest.ServiceCallback<Models.V1WikiPageHistoryCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageHistoryCollection>): Promise<Models.ListWikiPageHistoriesByWikiPageResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        pageId,
        options
      },
      listWikiPageHistoriesByWikiPageOperationSpec,
      callback) as Promise<Models.ListWikiPageHistoriesByWikiPageResponse>;
  }

  /**
   * 指定された Wiki ページのリダイレクト先を設定します
   * @summary Wiki ページリダイレクト設定
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param pageId Wiki ページの ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiPageRedirectResponse>
   */
  updateWikiPageRedirect(wikiName: string, pageId: string, body: Models.V1UpdateWikiPageRedirectRequestBody, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiPageRedirectResponse>;
  /**
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param pageId Wiki ページの ID です
   * @param body
   * @param callback The callback
   */
  updateWikiPageRedirect(wikiName: string, pageId: string, body: Models.V1UpdateWikiPageRedirectRequestBody, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param pageId Wiki ページの ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiPageRedirect(wikiName: string, pageId: string, body: Models.V1UpdateWikiPageRedirectRequestBody, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiPageRedirect(wikiName: string, pageId: string, body: Models.V1UpdateWikiPageRedirectRequestBody, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiPageRedirectResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        pageId,
        body,
        options
      },
      updateWikiPageRedirectOperationSpec,
      callback) as Promise<Models.UpdateWikiPageRedirectResponse>;
  }

  /**
   * 指定された Wiki のSlack通知設定情報を取得します。
   * @summary Wiki Slack通知設定情報取得
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiSlackConfigResponse>
   */
  getWikiSlackConfig(wikiName: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiSlackConfigResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  getWikiSlackConfig(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiSlackConfig>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiSlackConfig(wikiName: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiSlackConfig>): void;
  getWikiSlackConfig(wikiName: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiSlackConfig>, callback?: msRest.ServiceCallback<Models.V1WikiSlackConfig>): Promise<Models.GetWikiSlackConfigResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      getWikiSlackConfigOperationSpec,
      callback) as Promise<Models.GetWikiSlackConfigResponse>;
  }

  /**
   * 指定された Wiki の情報を編集します
   * @summary Wiki Slack通知設定編集
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiSlackConfigResponse>
   */
  updateWikiSlackConfig(wikiName: string, body: Models.V1WikiSlackConfig, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiSlackConfigResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param callback The callback
   */
  updateWikiSlackConfig(wikiName: string, body: Models.V1WikiSlackConfig, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiSlackConfig(wikiName: string, body: Models.V1WikiSlackConfig, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateWikiSlackConfig(wikiName: string, body: Models.V1WikiSlackConfig, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateWikiSlackConfigResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        body,
        options
      },
      updateWikiSlackConfigOperationSpec,
      callback) as Promise<Models.UpdateWikiSlackConfigResponse>;
  }

  /**
   * 指定された Wiki で登録されているページテンプレートの一覧を取得します。
   * @summary Wiki ページテンプレート一覧
   * @param wikiName Wiki を識別するための人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPageTemplatesResponse>
   */
  listWikiPageTemplates(wikiName: string, options?: Models.KamigameWikiApiListWikiPageTemplatesOptionalParams): Promise<Models.ListWikiPageTemplatesResponse>;
  /**
   * @param wikiName Wiki を識別するための人間可読の ID です
   * @param callback The callback
   */
  listWikiPageTemplates(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiPageTemplateCollection>): void;
  /**
   * @param wikiName Wiki を識別するための人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPageTemplates(wikiName: string, options: Models.KamigameWikiApiListWikiPageTemplatesOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageTemplateCollection>): void;
  listWikiPageTemplates(wikiName: string, options?: Models.KamigameWikiApiListWikiPageTemplatesOptionalParams | msRest.ServiceCallback<Models.V1WikiPageTemplateCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageTemplateCollection>): Promise<Models.ListWikiPageTemplatesResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiPageTemplatesOperationSpec,
      callback) as Promise<Models.ListWikiPageTemplatesResponse>;
  }

  /**
   * 指定された Wiki にページテンプレートを追加します。
   * @summary Wiki ページテンプレート作成
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWikiPageTemplateResponse>
   */
  createWikiPageTemplate(wikiName: string, body: Models.V1CreateWikiPageTemplateRequestBody, options?: msRest.RequestOptionsBase): Promise<Models.CreateWikiPageTemplateResponse>;
  /**
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param body
   * @param callback The callback
   */
  createWikiPageTemplate(wikiName: string, body: Models.V1CreateWikiPageTemplateRequestBody, callback: msRest.ServiceCallback<Models.V1WikiPageTemplate>): void;
  /**
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createWikiPageTemplate(wikiName: string, body: Models.V1CreateWikiPageTemplateRequestBody, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageTemplate>): void;
  createWikiPageTemplate(wikiName: string, body: Models.V1CreateWikiPageTemplateRequestBody, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageTemplate>, callback?: msRest.ServiceCallback<Models.V1WikiPageTemplate>): Promise<Models.CreateWikiPageTemplateResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        body,
        options
      },
      createWikiPageTemplateOperationSpec,
      callback) as Promise<Models.CreateWikiPageTemplateResponse>;
  }

  /**
   * 指定された Wiki ページテンプレートを取得します。
   * @summary Wiki ページテンプレート取得
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param templateId テンプレートの ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiPageTemplateResponse>
   */
  getWikiPageTemplate(wikiName: string, templateId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiPageTemplateResponse>;
  /**
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param templateId テンプレートの ID です
   * @param callback The callback
   */
  getWikiPageTemplate(wikiName: string, templateId: string, callback: msRest.ServiceCallback<Models.V1WikiPageTemplate>): void;
  /**
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param templateId テンプレートの ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiPageTemplate(wikiName: string, templateId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageTemplate>): void;
  getWikiPageTemplate(wikiName: string, templateId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageTemplate>, callback?: msRest.ServiceCallback<Models.V1WikiPageTemplate>): Promise<Models.GetWikiPageTemplateResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        templateId,
        options
      },
      getWikiPageTemplateOperationSpec,
      callback) as Promise<Models.GetWikiPageTemplateResponse>;
  }

  /**
   * 指定された Wiki ページテンプレートを削除します。
   * @summary Wiki ページテンプレート削除
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param templateId テンプレートの ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteWikiPageTemplateResponse>
   */
  deleteWikiPageTemplate(wikiName: string, templateId: string, options?: Models.KamigameWikiApiDeleteWikiPageTemplateOptionalParams): Promise<Models.DeleteWikiPageTemplateResponse>;
  /**
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param templateId テンプレートの ID です
   * @param callback The callback
   */
  deleteWikiPageTemplate(wikiName: string, templateId: string, callback: msRest.ServiceCallback<Models.V1WikiPageTemplate>): void;
  /**
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param templateId テンプレートの ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteWikiPageTemplate(wikiName: string, templateId: string, options: Models.KamigameWikiApiDeleteWikiPageTemplateOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageTemplate>): void;
  deleteWikiPageTemplate(wikiName: string, templateId: string, options?: Models.KamigameWikiApiDeleteWikiPageTemplateOptionalParams | msRest.ServiceCallback<Models.V1WikiPageTemplate>, callback?: msRest.ServiceCallback<Models.V1WikiPageTemplate>): Promise<Models.DeleteWikiPageTemplateResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        templateId,
        options
      },
      deleteWikiPageTemplateOperationSpec,
      callback) as Promise<Models.DeleteWikiPageTemplateResponse>;
  }

  /**
   * 指定された Wiki ページテンプレートを編集します。
   * @summary Wiki ページテンプレート編集
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param templateId テンプレートの ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWikiPageTemplateResponse>
   */
  updateWikiPageTemplate(wikiName: string, templateId: string, body: Models.V1EditWikiPageTemplateRequestBody, options?: msRest.RequestOptionsBase): Promise<Models.UpdateWikiPageTemplateResponse>;
  /**
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param templateId テンプレートの ID です
   * @param body
   * @param callback The callback
   */
  updateWikiPageTemplate(wikiName: string, templateId: string, body: Models.V1EditWikiPageTemplateRequestBody, callback: msRest.ServiceCallback<Models.V1WikiPageTemplate>): void;
  /**
   * @param wikiName テンプレートが属する Wiki の ID です
   * @param templateId テンプレートの ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWikiPageTemplate(wikiName: string, templateId: string, body: Models.V1EditWikiPageTemplateRequestBody, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageTemplate>): void;
  updateWikiPageTemplate(wikiName: string, templateId: string, body: Models.V1EditWikiPageTemplateRequestBody, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageTemplate>, callback?: msRest.ServiceCallback<Models.V1WikiPageTemplate>): Promise<Models.UpdateWikiPageTemplateResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        templateId,
        body,
        options
      },
      updateWikiPageTemplateOperationSpec,
      callback) as Promise<Models.UpdateWikiPageTemplateResponse>;
  }

  /**
   * 指定された Wiki ページに紐づけるカテゴリを更新します
   * @summary Wiki ページテンプレートカテゴリ設定
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param templateId Wiki ページの ID です
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateLinkedWikiPageTemplateCategoryResponse>
   */
  updateLinkedWikiPageTemplateCategory(wikiName: string, templateId: string, body: Models.V1UpdateLinkedWikiPageTemplateCategoryRequest, options?: msRest.RequestOptionsBase): Promise<Models.UpdateLinkedWikiPageTemplateCategoryResponse>;
  /**
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param templateId Wiki ページの ID です
   * @param body
   * @param callback The callback
   */
  updateLinkedWikiPageTemplateCategory(wikiName: string, templateId: string, body: Models.V1UpdateLinkedWikiPageTemplateCategoryRequest, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki を識別するための人間可読な ID です
   * @param templateId Wiki ページの ID です
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLinkedWikiPageTemplateCategory(wikiName: string, templateId: string, body: Models.V1UpdateLinkedWikiPageTemplateCategoryRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  updateLinkedWikiPageTemplateCategory(wikiName: string, templateId: string, body: Models.V1UpdateLinkedWikiPageTemplateCategoryRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.UpdateLinkedWikiPageTemplateCategoryResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        templateId,
        body,
        options
      },
      updateLinkedWikiPageTemplateCategoryOperationSpec,
      callback) as Promise<Models.UpdateLinkedWikiPageTemplateCategoryResponse>;
  }

  /**
   * 指定された Wiki ページの情報を取得します。
   * @summary Wiki ページ取得
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiPageResponse>
   */
  getWikiPage(wikiName: string, pageId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiPageResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param callback The callback
   */
  getWikiPage(wikiName: string, pageId: string, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiPage(wikiName: string, pageId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): void;
  getWikiPage(wikiName: string, pageId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>, callback?: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): Promise<Models.GetWikiPageResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        pageId,
        options
      },
      getWikiPageOperationSpec,
      callback) as Promise<Models.GetWikiPageResponse>;
  }

  /**
   * 指定された画像の情報を取得します。
   * @summary 画像情報取得 (public)
   * @param id 画像をユニークに識別する ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetImageResponse>
   */
  getImage(id: string, options?: Models.KamigameWikiApiGetImageOptionalParams): Promise<Models.GetImageResponse>;
  /**
   * @param id 画像をユニークに識別する ID です
   * @param callback The callback
   */
  getImage(id: string, callback: msRest.ServiceCallback<Models.V1Image>): void;
  /**
   * @param id 画像をユニークに識別する ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  getImage(id: string, options: Models.KamigameWikiApiGetImageOptionalParams, callback: msRest.ServiceCallback<Models.V1Image>): void;
  getImage(id: string, options?: Models.KamigameWikiApiGetImageOptionalParams | msRest.ServiceCallback<Models.V1Image>, callback?: msRest.ServiceCallback<Models.V1Image>): Promise<Models.GetImageResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      getImageOperationSpec,
      callback) as Promise<Models.GetImageResponse>;
  }

  /**
   * 認証サーバからの情報を元にサイトへのログインをおこないます。
   * アカウントがなければ作成します。
   * @summary ログイン
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.LoginResponse>
   */
  login(body: Models.V1LoginRequest, options?: msRest.RequestOptionsBase): Promise<Models.LoginResponse>;
  /**
   * @param body
   * @param callback The callback
   */
  login(body: Models.V1LoginRequest, callback: msRest.ServiceCallback<Models.V1Session>): void;
  /**
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  login(body: Models.V1LoginRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1Session>): void;
  login(body: Models.V1LoginRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1Session>, callback?: msRest.ServiceCallback<Models.V1Session>): Promise<Models.LoginResponse> {
    return this.sendOperationRequest(
      {
        body,
        options
      },
      loginOperationSpec,
      callback) as Promise<Models.LoginResponse>;
  }

  /**
   * GlobalDomainAllowlist の一覧を取得します
   * @summary GlobalDomainAllowlist の一覧を取得
   * @param [options] The optional parameters
   * @returns Promise<Models.ListGlobalDomainAllowlistResponse>
   */
  listGlobalDomainAllowlist(options?: msRest.RequestOptionsBase): Promise<Models.ListGlobalDomainAllowlistResponse>;
  /**
   * @param callback The callback
   */
  listGlobalDomainAllowlist(callback: msRest.ServiceCallback<Models.V1ListGlobalDomainAllowlistResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listGlobalDomainAllowlist(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1ListGlobalDomainAllowlistResponse>): void;
  listGlobalDomainAllowlist(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1ListGlobalDomainAllowlistResponse>, callback?: msRest.ServiceCallback<Models.V1ListGlobalDomainAllowlistResponse>): Promise<Models.ListGlobalDomainAllowlistResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listGlobalDomainAllowlistOperationSpec,
      callback) as Promise<Models.ListGlobalDomainAllowlistResponse>;
  }

  /**
   * GlobalDomainAllowlist にデータを追加します
   * @summary GlobalDomainAllowlist にデータを追加
   * @param body リクエストボディです
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateGlobalDomainAllowlistResponse>
   */
  createGlobalDomainAllowlist(body: Models.V1CreateGlobalDomainAllowlistRequestBody, options?: msRest.RequestOptionsBase): Promise<Models.CreateGlobalDomainAllowlistResponse>;
  /**
   * @param body リクエストボディです
   * @param callback The callback
   */
  createGlobalDomainAllowlist(body: Models.V1CreateGlobalDomainAllowlistRequestBody, callback: msRest.ServiceCallback<Models.V1GlobalAllowedDomain>): void;
  /**
   * @param body リクエストボディです
   * @param options The optional parameters
   * @param callback The callback
   */
  createGlobalDomainAllowlist(body: Models.V1CreateGlobalDomainAllowlistRequestBody, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1GlobalAllowedDomain>): void;
  createGlobalDomainAllowlist(body: Models.V1CreateGlobalDomainAllowlistRequestBody, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1GlobalAllowedDomain>, callback?: msRest.ServiceCallback<Models.V1GlobalAllowedDomain>): Promise<Models.CreateGlobalDomainAllowlistResponse> {
    return this.sendOperationRequest(
      {
        body,
        options
      },
      createGlobalDomainAllowlistOperationSpec,
      callback) as Promise<Models.CreateGlobalDomainAllowlistResponse>;
  }

  /**
   * GlobalDomainAllowlist から指定した id のものを削除します
   * @summary GlobalDomainAllowlist から指定した id のものを削除
   * @param id GlobalAllowedDomain をユニークに識別するための内部的な ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteGlobalDomainAllowlistResponse>
   */
  deleteGlobalDomainAllowlist(id: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteGlobalDomainAllowlistResponse>;
  /**
   * @param id GlobalAllowedDomain をユニークに識別するための内部的な ID です
   * @param callback The callback
   */
  deleteGlobalDomainAllowlist(id: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param id GlobalAllowedDomain をユニークに識別するための内部的な ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteGlobalDomainAllowlist(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  deleteGlobalDomainAllowlist(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DeleteGlobalDomainAllowlistResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      deleteGlobalDomainAllowlistOperationSpec,
      callback) as Promise<Models.DeleteGlobalDomainAllowlistResponse>;
  }

  /**
   * それぞれのアクションに対する現在ログイン中のユーザの有する権限を一覧します
   * @summary 現在のユーザの権限一覧取得
   * @param [options] The optional parameters
   * @returns Promise<Models.ListPermissionResponse>
   */
  listPermission(options?: msRest.RequestOptionsBase): Promise<Models.ListPermissionResponse>;
  /**
   * @param callback The callback
   */
  listPermission(callback: msRest.ServiceCallback<Models.V1RuleCollection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listPermission(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1RuleCollection>): void;
  listPermission(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1RuleCollection>, callback?: msRest.ServiceCallback<Models.V1RuleCollection>): Promise<Models.ListPermissionResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listPermissionOperationSpec,
      callback) as Promise<Models.ListPermissionResponse>;
  }

  /**
   * 公開された Wiki の一覧を取得します。
   * @summary 公開された Wiki 一覧取得
   * @param [options] The optional parameters
   * @returns Promise<Models.ListPublishedWikiResponse>
   */
  listPublishedWiki(options?: msRest.RequestOptionsBase): Promise<Models.ListPublishedWikiResponse>;
  /**
   * @param callback The callback
   */
  listPublishedWiki(callback: msRest.ServiceCallback<Models.V1ListWikiResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listPublishedWiki(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1ListWikiResponse>): void;
  listPublishedWiki(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1ListWikiResponse>, callback?: msRest.ServiceCallback<Models.V1ListWikiResponse>): Promise<Models.ListPublishedWikiResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      listPublishedWikiOperationSpec,
      callback) as Promise<Models.ListPublishedWikiResponse>;
  }

  /**
   * 指定された Wiki の情報を取得します。
   * @summary Wiki 情報取得 (public)
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiResponse>
   */
  getWiki(name: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  getWiki(name: string, callback: msRest.ServiceCallback<Models.V1Wiki>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWiki(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1Wiki>): void;
  getWiki(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1Wiki>, callback?: msRest.ServiceCallback<Models.V1Wiki>): Promise<Models.GetWikiResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      getWikiOperationSpec,
      callback) as Promise<Models.GetWikiResponse>;
  }

  /**
   * WikiPageCategory の親のカテゴリの一覧を取得します。
   * @summary WikiPageCategory の親カテゴリ一覧取得
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListParentWikiPageCategoryResponse>
   */
  listParentWikiPageCategory(name: string, options?: msRest.RequestOptionsBase): Promise<Models.ListParentWikiPageCategoryResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listParentWikiPageCategory(name: string, callback: msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listParentWikiPageCategory(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>): void;
  listParentWikiPageCategory(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>): Promise<Models.ListParentWikiPageCategoryResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      listParentWikiPageCategoryOperationSpec,
      callback) as Promise<Models.ListParentWikiPageCategoryResponse>;
  }

  /**
   * 指定された Wiki のカスタム CSS を取得します
   * @summary Wiki カスタム CSS 取得 (public)
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiCustomCssResponse>
   */
  getWikiCustomCss(name: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiCustomCssResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  getWikiCustomCss(name: string, callback: msRest.ServiceCallback<Models.V1WikiCustomCss>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiCustomCss(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiCustomCss>): void;
  getWikiCustomCss(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiCustomCss>, callback?: msRest.ServiceCallback<Models.V1WikiCustomCss>): Promise<Models.GetWikiCustomCssResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      getWikiCustomCssOperationSpec,
      callback) as Promise<Models.GetWikiCustomCssResponse>;
  }

  /**
   * 指定された Wiki のカスタムヘッダを取得します
   * @summary Wiki カスタムヘッダ取得 (public)
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiCustomHeaderResponse>
   */
  listWikiCustomHeader(name: string, options?: msRest.RequestOptionsBase): Promise<Models.ListWikiCustomHeaderResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiCustomHeader(name: string, callback: msRest.ServiceCallback<Models.V1ListWikiCustomHeaderWithWiki>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiCustomHeader(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1ListWikiCustomHeaderWithWiki>): void;
  listWikiCustomHeader(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1ListWikiCustomHeaderWithWiki>, callback?: msRest.ServiceCallback<Models.V1ListWikiCustomHeaderWithWiki>): Promise<Models.ListWikiCustomHeaderResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      listWikiCustomHeaderOperationSpec,
      callback) as Promise<Models.ListWikiCustomHeaderResponse>;
  }

  /**
   * 指定された Wiki のメモキャッシュの一覧を取得します
   * @summary Wiki メモキャッシュキー一覧取得
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListMemcacheKeyResponse>
   */
  listMemcacheKey(name: string, options?: msRest.RequestOptionsBase): Promise<Models.ListMemcacheKeyResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listMemcacheKey(name: string, callback: msRest.ServiceCallback<Models.V1WikiMemcacheKeyHashCollection>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listMemcacheKey(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiMemcacheKeyHashCollection>): void;
  listMemcacheKey(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiMemcacheKeyHashCollection>, callback?: msRest.ServiceCallback<Models.V1WikiMemcacheKeyHashCollection>): Promise<Models.ListMemcacheKeyResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      listMemcacheKeyOperationSpec,
      callback) as Promise<Models.ListMemcacheKeyResponse>;
  }

  /**
   * 指定された Wiki のグローバルナビを取得します
   * @summary Wiki グローバルナビ取得 (public)
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiNavResponse>
   */
  listWikiNav(name: string, options?: msRest.RequestOptionsBase): Promise<Models.ListWikiNavResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiNav(name: string, callback: msRest.ServiceCallback<Models.V1ListWikiNavWithWiki>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiNav(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1ListWikiNavWithWiki>): void;
  listWikiNav(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1ListWikiNavWithWiki>, callback?: msRest.ServiceCallback<Models.V1ListWikiNavWithWiki>): Promise<Models.ListWikiNavResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      listWikiNavOperationSpec,
      callback) as Promise<Models.ListWikiNavResponse>;
  }

  /**
   * Wiki における、それぞれのアクションに対する現在ログイン中のユーザの有する権限を一覧します
   * @summary 現在のユーザの権限一覧取得
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPermissionResponse>
   */
  listWikiPermission(name: string, options?: msRest.RequestOptionsBase): Promise<Models.ListWikiPermissionResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiPermission(name: string, callback: msRest.ServiceCallback<Models.V1RuleCollection>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPermission(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1RuleCollection>): void;
  listWikiPermission(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1RuleCollection>, callback?: msRest.ServiceCallback<Models.V1RuleCollection>): Promise<Models.ListWikiPermissionResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      listWikiPermissionOperationSpec,
      callback) as Promise<Models.ListWikiPermissionResponse>;
  }

  /**
   * 指定された Wiki のツールチップを取得します
   * @summary Wiki ツールチップ取得 (public)
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiTooltipResponse>
   */
  listWikiTooltip(name: string, options?: msRest.RequestOptionsBase): Promise<Models.ListWikiTooltipResponse>;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiTooltip(name: string, callback: msRest.ServiceCallback<Models.V1ListWikiTooltipMessage>): void;
  /**
   * @param name Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiTooltip(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1ListWikiTooltipMessage>): void;
  listWikiTooltip(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1ListWikiTooltipMessage>, callback?: msRest.ServiceCallback<Models.V1ListWikiTooltipMessage>): Promise<Models.ListWikiTooltipResponse> {
    return this.sendOperationRequest(
      {
        name,
        options
      },
      listWikiTooltipOperationSpec,
      callback) as Promise<Models.ListWikiTooltipResponse>;
  }

  /**
   * 特定の Wiki に対する参加申請をおこないます
   * @summary Wiki メンバー参加申請
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateWikiMemberRequestResponse>
   */
  createWikiMemberRequest(wikiname: string, body: Models.V1CreateWikiMemberRequestRequest, options?: msRest.RequestOptionsBase): Promise<Models.CreateWikiMemberRequestResponse>;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param callback The callback
   */
  createWikiMemberRequest(wikiname: string, body: Models.V1CreateWikiMemberRequestRequest, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiname Wiki をユニークに識別する人間可読の ID です。この値は URL の一部に含まれます
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  createWikiMemberRequest(wikiname: string, body: Models.V1CreateWikiMemberRequestRequest, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  createWikiMemberRequest(wikiname: string, body: Models.V1CreateWikiMemberRequestRequest, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.CreateWikiMemberRequestResponse> {
    return this.sendOperationRequest(
      {
        wikiname,
        body,
        options
      },
      createWikiMemberRequestOperationSpec,
      callback) as Promise<Models.CreateWikiMemberRequestResponse>;
  }

  /**
   * WikiPageCategory の特定の親のカテゴリに関連づいた子カテゴリの一覧を取得します。
   * @summary WikiPageCategory の子カテゴリ一覧取得
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListChildrenWikiPageCategoryResponse>
   */
  listChildrenWikiPageCategory(wikiName: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.ListChildrenWikiPageCategoryResponse>;
  /**
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param callback The callback
   */
  listChildrenWikiPageCategory(wikiName: string, id: string, callback: msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>): void;
  /**
   * @param wikiName 関連づいた Wiki 名です
   * @param id WikiPageCategoryをユニークに識別するための内部的な ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listChildrenWikiPageCategory(wikiName: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>): void;
  listChildrenWikiPageCategory(wikiName: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageCategoryCollection>): Promise<Models.ListChildrenWikiPageCategoryResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        id,
        options
      },
      listChildrenWikiPageCategoryOperationSpec,
      callback) as Promise<Models.ListChildrenWikiPageCategoryResponse>;
  }

  /**
   * 特定の Wiki メンバー招待に関する情報を取得します
   * @summary Wiki メンバー招待取得
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id Wiki メンバー招待をあらわす ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiMemberInviteResponse>
   */
  getWikiMemberInvite(wikiName: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiMemberInviteResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id Wiki メンバー招待をあらわす ID です
   * @param callback The callback
   */
  getWikiMemberInvite(wikiName: string, id: string, callback: msRest.ServiceCallback<Models.V1WikiMemberInvite>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id Wiki メンバー招待をあらわす ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiMemberInvite(wikiName: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiMemberInvite>): void;
  getWikiMemberInvite(wikiName: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiMemberInvite>, callback?: msRest.ServiceCallback<Models.V1WikiMemberInvite>): Promise<Models.GetWikiMemberInviteResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        id,
        options
      },
      getWikiMemberInviteOperationSpec,
      callback) as Promise<Models.GetWikiMemberInviteResponse>;
  }

  /**
   * 特定の Wiki メンバー招待に対する承諾をおこないます
   * @summary Wiki メンバー招待承諾
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id Wiki メンバー招待をあらわす ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ApproveWikiMemberInviteResponse>
   */
  approveWikiMemberInvite(wikiName: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.ApproveWikiMemberInviteResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id Wiki メンバー招待をあらわす ID です
   * @param callback The callback
   */
  approveWikiMemberInvite(wikiName: string, id: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param id Wiki メンバー招待をあらわす ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  approveWikiMemberInvite(wikiName: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  approveWikiMemberInvite(wikiName: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.ApproveWikiMemberInviteResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        id,
        options
      },
      approveWikiMemberInviteOperationSpec,
      callback) as Promise<Models.ApproveWikiMemberInviteResponse>;
  }

  /**
   * 指定された Wiki のページ一覧を取得します。
   * @summary Wiki ページ一覧取得 (public)
   * @param wikiName 取得したい Wiki の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPagesResponse>
   */
  listWikiPages(wikiName: string, options?: Models.KamigameWikiApiListWikiPagesOptionalParams): Promise<Models.ListWikiPagesResponse>;
  /**
   * @param wikiName 取得したい Wiki の ID です
   * @param callback The callback
   */
  listWikiPages(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiPageForListCollection>): void;
  /**
   * @param wikiName 取得したい Wiki の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPages(wikiName: string, options: Models.KamigameWikiApiListWikiPagesOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageForListCollection>): void;
  listWikiPages(wikiName: string, options?: Models.KamigameWikiApiListWikiPagesOptionalParams | msRest.ServiceCallback<Models.V1WikiPageForListCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageForListCollection>): Promise<Models.ListWikiPagesResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiPagesOperationSpec,
      callback) as Promise<Models.ListWikiPagesResponse>;
  }

  /**
   * 指定された Wiki ページ部品を記事部品の名前で取得します
   * @summary Wiki ページ部品取得 (public)
   * @param wikiName ページ部品が所属する Wiki の人間可読な ID です
   * @param wikiPagePartialName ページ記事部品の名称です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWikiPagePartialByNameResponse>
   */
  getWikiPagePartialByName(wikiName: string, wikiPagePartialName: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWikiPagePartialByNameResponse>;
  /**
   * @param wikiName ページ部品が所属する Wiki の人間可読な ID です
   * @param wikiPagePartialName ページ記事部品の名称です
   * @param callback The callback
   */
  getWikiPagePartialByName(wikiName: string, wikiPagePartialName: string, callback: msRest.ServiceCallback<Models.V1WikiPagePartial>): void;
  /**
   * @param wikiName ページ部品が所属する Wiki の人間可読な ID です
   * @param wikiPagePartialName ページ記事部品の名称です
   * @param options The optional parameters
   * @param callback The callback
   */
  getWikiPagePartialByName(wikiName: string, wikiPagePartialName: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPagePartial>): void;
  getWikiPagePartialByName(wikiName: string, wikiPagePartialName: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPagePartial>, callback?: msRest.ServiceCallback<Models.V1WikiPagePartial>): Promise<Models.GetWikiPagePartialByNameResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        wikiPagePartialName,
        options
      },
      getWikiPagePartialByNameOperationSpec,
      callback) as Promise<Models.GetWikiPagePartialByNameResponse>;
  }

  /**
   * 指定された Wiki の最新ページ一覧を取得します。
   * @summary 最新 Wiki ページ一覧取得 (public)
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiLatestPagesResponse>
   */
  listWikiLatestPages(wikiName: string, options?: Models.KamigameWikiApiListWikiLatestPagesOptionalParams): Promise<Models.ListWikiLatestPagesResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param callback The callback
   */
  listWikiLatestPages(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndLastUpdaterCollection>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiLatestPages(wikiName: string, options: Models.KamigameWikiApiListWikiLatestPagesOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndLastUpdaterCollection>): void;
  listWikiLatestPages(wikiName: string, options?: Models.KamigameWikiApiListWikiLatestPagesOptionalParams | msRest.ServiceCallback<Models.V1WikiPageWithTitleAndLastUpdaterCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndLastUpdaterCollection>): Promise<Models.ListWikiLatestPagesResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiLatestPagesOperationSpec,
      callback) as Promise<Models.ListWikiLatestPagesResponse>;
  }

  /**
   * 指定された Wiki のサイトマップ向けページ一覧を取得します。
   * @summary Wiki ページサイトマップ向け一覧取得 (public)
   * @param wikiName 取得したい Wiki の ID です
   * @param [options] The optional parameters
   * @returns Promise<Models.ListWikiPagesForSitemapResponse>
   */
  listWikiPagesForSitemap(wikiName: string, options?: Models.KamigameWikiApiListWikiPagesForSitemapOptionalParams): Promise<Models.ListWikiPagesForSitemapResponse>;
  /**
   * @param wikiName 取得したい Wiki の ID です
   * @param callback The callback
   */
  listWikiPagesForSitemap(wikiName: string, callback: msRest.ServiceCallback<Models.V1WikiPageSitemapCollection>): void;
  /**
   * @param wikiName 取得したい Wiki の ID です
   * @param options The optional parameters
   * @param callback The callback
   */
  listWikiPagesForSitemap(wikiName: string, options: Models.KamigameWikiApiListWikiPagesForSitemapOptionalParams, callback: msRest.ServiceCallback<Models.V1WikiPageSitemapCollection>): void;
  listWikiPagesForSitemap(wikiName: string, options?: Models.KamigameWikiApiListWikiPagesForSitemapOptionalParams | msRest.ServiceCallback<Models.V1WikiPageSitemapCollection>, callback?: msRest.ServiceCallback<Models.V1WikiPageSitemapCollection>): Promise<Models.ListWikiPagesForSitemapResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        options
      },
      listWikiPagesForSitemapOperationSpec,
      callback) as Promise<Models.ListWikiPagesForSitemapResponse>;
  }

  /**
   * 指定された Wiki ページの公開情報を取得します。
   * @summary Wiki ページ取得 (public)
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPublishedWikiPageResponse>
   */
  getPublishedWikiPage(wikiName: string, pageId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPublishedWikiPageResponse>;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param callback The callback
   */
  getPublishedWikiPage(wikiName: string, pageId: string, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): void;
  /**
   * @param wikiName Wiki をユニークに識別する人間可読の ID です
   * @param pageId Wiki ページをユニークに識別する ID もしくはパス名です
   * @param options The optional parameters
   * @param callback The callback
   */
  getPublishedWikiPage(wikiName: string, pageId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): void;
  getPublishedWikiPage(wikiName: string, pageId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>, callback?: msRest.ServiceCallback<Models.V1WikiPageWithTitleAndBody>): Promise<Models.GetPublishedWikiPageResponse> {
    return this.sendOperationRequest(
      {
        wikiName,
        pageId,
        options
      },
      getPublishedWikiPageOperationSpec,
      callback) as Promise<Models.GetPublishedWikiPageResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getAllWikiOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki",
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createWikiOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki",
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1CreateWikiRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1Wiki
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listJoinedWikiOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/joined",
  queryParameters: [
    Parameters.joined
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{name}",
  urlParameters: [
    Parameters.name0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1EditWikiRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1Wiki
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiAccessAnalyticsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{name}/access-analytics",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiAccessAnalytics
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const adjustSpeculativeWikiDataSheetsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{name}/adjust-speculative-wiki-data",
  urlParameters: [
    Parameters.name0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1AdjustSpeculativeWikiDataSheetsRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiAllowedIPAddressOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{name}/allowed-ip",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiAllowedIPAddress
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiIsArchivedOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{name}/archive-config",
  urlParameters: [
    Parameters.name0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1UpdateWikiArchiveConfigRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createWikiPageCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{name}/category",
  urlParameters: [
    Parameters.name0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1WikiPageCategoryCreateRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageCategory
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiForDashboardOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{name}/dashboard/wiki",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiForDashboard
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiDataSheetOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{name}/data-sheet",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiDataSheetCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const publishWikiDataDownloadURLOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{name}/data-sheet/download-url",
  urlParameters: [
    Parameters.name0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1PublishWikiDataDownloadURLRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiDataDownloadURL
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const publishWikiDataUploadURLOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{name}/data-sheet/upload-url",
  urlParameters: [
    Parameters.name0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1WithWikiRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiDataUploadURL
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiDomainAllowlistOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{name}/domain-allowlist",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1ListWikiDomainAllowlistResponse
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiMemberInviteOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{name}/member-invite",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiMemberInviteCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiPublishConfigOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "admin/wiki/{name}/publish-config",
  urlParameters: [
    Parameters.name0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1EditWikiPublishConfigRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1Wiki
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const requestTemplateCodeExecutionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{name}/request-template-code",
  urlParameters: [
    Parameters.name0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1RequestTemplateCodeExecutionRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiTakahiroTemplateCodeSpreadsheetURLOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{name}/takahiro-template-code-spreadsheet-url",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPageTemplateTitleBodiesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{name}/template/title-bodies",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageTemplateTitleBodyCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiAllowedIPAddressOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wiki.name}/allowed-ip",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1WikiAllowedIPAddress,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiAllowedIPAddress
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiCustomCssOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wiki.name}/custom-css",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1WikiCustomCss,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiCustomHeaderOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wiki.name}/custom-header",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1ListWikiCustomHeaderWithWiki,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createWikiDataSheetOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wiki.name}/data-sheet",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1WikiDataSheet,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiDataSheet
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createWikiDataSheetLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wiki.name}/data-sheet-link",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1WikiDataSheetLink,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiDataSheetOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wiki.name}/data-sheet/{id}",
  urlParameters: [
    Parameters.wikiname,
    Parameters.id0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1WikiDataSheet,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiHeaderImageOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wiki.name}/header",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1UpdateWikiImageRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiIconImageOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wiki.name}/icon",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1UpdateWikiImageRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiMetaMediumTextOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wiki.name}/markdown-text-widget",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1WikiMarkdownTextWidget,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createWikiMemberInviteOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wiki.name}/member-invite",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1CreateWikiMemberInviteRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiMemberInvite
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const approveWikiMemberRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wiki.name}/member-request/{id}/approve",
  urlParameters: [
    Parameters.wikiname,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const rejectWikiMemberRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wiki.name}/member-request/{id}/reject",
  urlParameters: [
    Parameters.wikiname,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiMemberPermissionOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wiki.name}/member/{user.id}/permission",
  urlParameters: [
    Parameters.wikiname,
    Parameters.userid
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1UpdateWikiMemberPermissionRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiMetaOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wiki.name}/meta/{name}",
  urlParameters: [
    Parameters.wikiname,
    Parameters.name0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1WikiMeta,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiMeta
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiNavOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wiki.name}/nav",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1ListWikiNavWithWiki,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1ListWikiNavWithWiki
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiTooltipOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wiki.name}/tooltip",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1ListWikiTooltipMessage,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const bulkTakahiroWikiPageCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiId}/category/bulk-takahiro",
  urlParameters: [
    Parameters.wikiId0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1BulkTakahiroWikiPageCategoryRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1BulkTakahiroWikiPageCategoryResult
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createWikiPageOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiId}/page",
  urlParameters: [
    Parameters.wikiId0
  ],
  queryParameters: [
    Parameters.authorId
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1CreateWikiPageRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageWithTitleAndBody
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const bulkCreateWikiPageOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiId}/page/bulk",
  urlParameters: [
    Parameters.wikiId0
  ],
  queryParameters: [
    Parameters.authorId
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1BulkCreateWikiPageRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1BulkCreateWikiPageResult
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const bulkUpdateWikiPageOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiId}/page/bulk",
  urlParameters: [
    Parameters.wikiId0
  ],
  queryParameters: [
    Parameters.authorId
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1BulkUpdateWikiPageRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1BulkUpdateWikiPageResult
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const bulkUpdateCategoryOfWikiPageOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiId}/page/bulk-category",
  urlParameters: [
    Parameters.wikiId0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1BulkUpdateCategoryOfWikiPageRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const bulkTakahiroWikiPageOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiId}/page/bulk-takahiro",
  urlParameters: [
    Parameters.wikiId0
  ],
  queryParameters: [
    Parameters.authorId
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1BulkTakahiroWikiPageRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1BulkTakahiroWikiPageResult
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const deleteWikiPageOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "admin/wiki/{wikiId}/page/{pageId}",
  urlParameters: [
    Parameters.wikiId0,
    Parameters.pageId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiPageOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiId}/page/{pageId}",
  urlParameters: [
    Parameters.wikiId0,
    Parameters.pageId0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1EditWikiPageRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageWithTitleAndBody
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const restoreWikiPageOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiId}/page/{pageId}/restore",
  urlParameters: [
    Parameters.wikiId0,
    Parameters.pageId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPageCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/category",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.limit,
    Parameters.offset,
    Parameters.searchWords,
    Parameters.sortedBy,
    Parameters.sortOrder
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageCategoryCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiPageCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/category/{id}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageCategory
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const deleteWikiPageCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "admin/wiki/{wikiName}/category/{id}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiPageCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiName}/category/{id}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.id0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1WikiPageCategoryUpdateRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageCategory
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getChatGptCompletionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiName}/chat-gpt/completions",
  urlParameters: [
    Parameters.wikiName
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1GetChatGptCompletionsRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1GetChatGptCompletionsResponse
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getChatGptCompletionsWithPromptOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiName}/chat-gpt/completions-with-prompt",
  urlParameters: [
    Parameters.wikiName
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1GetChatGptCompletionsWithPromptRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1GetChatGptCompletionsResponse
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const configureRealtimeDatabaseRuleOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiName}/configure-realtime-database-rule",
  urlParameters: [
    Parameters.wikiName
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1ConfigureRealtimeDatabaseRuleRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createWikiDataChangelogOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiName}/data-changelog",
  urlParameters: [
    Parameters.wikiName
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1CreateWikiDataChangelogRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiDataSheetDataOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/data-sheet-data",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.condition
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiDataSheets
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiDataSheetLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/data-sheet-link",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.sheetName,
    Parameters.spreadsheetId,
    Parameters.isInbound
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiDataSheetLinkCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const deleteWikiDataSheetLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "admin/wiki/{wikiName}/data-sheet-link/{id}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiDataSheetOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/data-sheet/{sheetId}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.sheetId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiDataSheet
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const deleteWikiDataSheetOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "admin/wiki/{wikiName}/data-sheet/{sheetId}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.sheetId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createWikiDomainAllowlistOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiName}/domain-allowlist",
  urlParameters: [
    Parameters.wikiName
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1CreateWikiDomainAllowlistRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiAllowedDomain
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const deleteWikiDomainAllowlistOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "admin/wiki/{wikiName}/domain-allowlist/{id}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPageHistoriesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/history",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.limit,
    Parameters.offset,
    Parameters.searchWords,
    Parameters.sortedBy,
    Parameters.sortOrder,
    Parameters.startUnixtime,
    Parameters.endUnixtime,
    Parameters.userIds
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiHistoryCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiPageHistoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/history/{historyId}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.historyId
  ],
  queryParameters: [
    Parameters.pageId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageHistory
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiPageHistoryDiffOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiName}/history/{historyId}/diff-lines",
  urlParameters: [
    Parameters.wikiName,
    Parameters.historyId
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1UpdateWikiPageHistoryDiffRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiMemberOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/member",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.limit,
    Parameters.offset,
    Parameters.searchWords,
    Parameters.sortedBy,
    Parameters.sortOrder
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiMemberCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const deleteWikiMemberInviteOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "admin/wiki/{wikiName}/member-invite/{id}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiMemberRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/member-request",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.pageId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiMemberRequestCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const deleteWikiMemberOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "admin/wiki/{wikiName}/member/{userId}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.userId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPagePartialOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/page-partial",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.limit,
    Parameters.offset,
    Parameters.searchWords,
    Parameters.sortedBy,
    Parameters.sortOrder,
    Parameters.isArchived
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPagePartialCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createWikiPagePartialOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiName}/page-partial",
  urlParameters: [
    Parameters.wikiName
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1CreateWikiPagePartialRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPagePartial
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPagePartialTitleOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/page-partial/titles",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.isArchived
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPagePartialNameCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const deleteWikiPagePartialOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "admin/wiki/{wikiName}/page-partial/{id}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const restoreWikiPagePartialOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiName}/page-partial/{id}/restore",
  urlParameters: [
    Parameters.wikiName,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiPagePartialOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/page-partial/{wikiPagePartialID}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.wikiPagePartialID
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPagePartial
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiPagePartialOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiName}/page-partial/{wikiPagePartialName}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.wikiPagePartialName
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1EditWikiPagePartialRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPagePartial
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPageDraftOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/page/draft",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.pageId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageDraftCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPageSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/page/summary",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.isDeleted
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageSummaryCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPageTitleBodiesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/page/title-bodies",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.isDeleted,
    Parameters.limit,
    Parameters.offset,
    Parameters.searchWords,
    Parameters.sortedBy,
    Parameters.sortOrder,
    Parameters.categoryId,
    Parameters.onlyRedirected,
    Parameters.excludeRedirected,
    Parameters.includesBodyOnSearch
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageWithTitleAndBodyCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPageTitlesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/page/titles",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.isDeleted,
    Parameters.limit,
    Parameters.offset,
    Parameters.searchWords,
    Parameters.sortedBy,
    Parameters.sortOrder,
    Parameters.categoryId,
    Parameters.onlyRedirected,
    Parameters.excludeRedirected,
    Parameters.includesBodyOnSearch
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageTitleCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateLinkedWikiPageCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiName}/page/{pageId}/category",
  urlParameters: [
    Parameters.wikiName,
    Parameters.pageId0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1UpdateLinkedWikiPageCategoryRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiPageDraftOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/page/{pageId}/draft",
  urlParameters: [
    Parameters.wikiName,
    Parameters.pageId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageDraft
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const deleteWikiPageDraftOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "admin/wiki/{wikiName}/page/{pageId}/draft",
  urlParameters: [
    Parameters.wikiName,
    Parameters.pageId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const editWikiPageDraftOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiName}/page/{pageId}/draft",
  urlParameters: [
    Parameters.wikiName,
    Parameters.pageId0
  ],
  queryParameters: [
    Parameters.id1
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1EditWikiPageDraftRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageDraft
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPageHistoriesByWikiPageOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/page/{pageId}/history",
  urlParameters: [
    Parameters.wikiName,
    Parameters.pageId0
  ],
  queryParameters: [
    Parameters.limit,
    Parameters.offset,
    Parameters.searchWords,
    Parameters.sortedBy,
    Parameters.sortOrder
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageHistoryCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiPageRedirectOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiName}/page/{pageId}/redirect",
  urlParameters: [
    Parameters.wikiName,
    Parameters.pageId0
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1UpdateWikiPageRedirectRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiSlackConfigOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/slack-config",
  urlParameters: [
    Parameters.wikiName
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiSlackConfig
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiSlackConfigOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiName}/slack-config",
  urlParameters: [
    Parameters.wikiName
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1WikiSlackConfig,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPageTemplatesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/template",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.limit,
    Parameters.offset,
    Parameters.searchWords,
    Parameters.sortedBy,
    Parameters.sortOrder
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageTemplateCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createWikiPageTemplateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "admin/wiki/{wikiName}/template",
  urlParameters: [
    Parameters.wikiName
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1CreateWikiPageTemplateRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageTemplate
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiPageTemplateOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/template/{templateId}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.templateId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageTemplate
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const deleteWikiPageTemplateOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "admin/wiki/{wikiName}/template/{templateId}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.templateId
  ],
  queryParameters: [
    Parameters.bodytitle,
    Parameters.bodybody,
    Parameters.bodycategoryId,
    Parameters.bodykeywords,
    Parameters.bodydescription
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageTemplate
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateWikiPageTemplateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiName}/template/{templateId}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.templateId
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1EditWikiPageTemplateRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageTemplate
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const updateLinkedWikiPageTemplateCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "admin/wiki/{wikiName}/template/{templateId}/category",
  urlParameters: [
    Parameters.wikiName,
    Parameters.templateId
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1UpdateLinkedWikiPageTemplateCategoryRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiPageOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "admin/wiki/{wikiName}/{pageId}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.pageId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageWithTitleAndBody
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getImageOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "image/{id}",
  urlParameters: [
    Parameters.id0
  ],
  queryParameters: [
    Parameters.url,
    Parameters.name1,
    Parameters.wikiId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1Image
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const loginOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "login",
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1LoginRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1Session
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listGlobalDomainAllowlistOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "manage/domain-allowlist",
  responses: {
    200: {
      bodyMapper: Mappers.V1ListGlobalDomainAllowlistResponse
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createGlobalDomainAllowlistOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "manage/domain-allowlist",
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1CreateGlobalDomainAllowlistRequestBody,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.V1GlobalAllowedDomain
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const deleteGlobalDomainAllowlistOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "manage/domain-allowlist/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listPermissionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "permission",
  responses: {
    200: {
      bodyMapper: Mappers.V1RuleCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listPublishedWikiOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki",
  responses: {
    200: {
      bodyMapper: Mappers.V1ListWikiResponse
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{name}",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1Wiki
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listParentWikiPageCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{name}/category",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageCategoryCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiCustomCssOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{name}/custom-css",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiCustomCss
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiCustomHeaderOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{name}/custom-header",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1ListWikiCustomHeaderWithWiki
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listMemcacheKeyOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{name}/memcache-key-hash",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiMemcacheKeyHashCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiNavOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{name}/nav",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1ListWikiNavWithWiki
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPermissionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{name}/permission",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1RuleCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiTooltipOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{name}/tooltip",
  urlParameters: [
    Parameters.name0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1ListWikiTooltipMessage
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const createWikiMemberRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "wiki/{wiki.name}/member-request",
  urlParameters: [
    Parameters.wikiname
  ],
  requestBody: {
    parameterPath: "body",
    mapper: {
      ...Mappers.V1CreateWikiMemberRequestRequest,
      required: true
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listChildrenWikiPageCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{wikiName}/category/{id}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageCategoryCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiMemberInviteOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{wikiName}/member-invite/{id}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiMemberInvite
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const approveWikiMemberInviteOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "wiki/{wikiName}/member-invite/{id}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPagesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{wikiName}/page",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.isDeleted,
    Parameters.limit,
    Parameters.offset,
    Parameters.searchWords,
    Parameters.sortedBy,
    Parameters.sortOrder,
    Parameters.categoryId,
    Parameters.onlyRedirected,
    Parameters.excludeRedirected,
    Parameters.includesBodyOnSearch
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageForListCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getWikiPagePartialByNameOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{wikiName}/page-partial/{wikiPagePartialName}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.wikiPagePartialName
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPagePartial
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiLatestPagesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{wikiName}/page/latest",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.pageId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageWithTitleAndLastUpdaterCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const listWikiPagesForSitemapOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{wikiName}/page/sitemap",
  urlParameters: [
    Parameters.wikiName
  ],
  queryParameters: [
    Parameters.isDeleted,
    Parameters.limit,
    Parameters.offset,
    Parameters.searchWords,
    Parameters.sortedBy,
    Parameters.sortOrder,
    Parameters.categoryId,
    Parameters.onlyRedirected,
    Parameters.excludeRedirected,
    Parameters.includesBodyOnSearch
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageSitemapCollection
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

const getPublishedWikiPageOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "wiki/{wikiName}/{pageId}",
  urlParameters: [
    Parameters.wikiName,
    Parameters.pageId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.V1WikiPageWithTitleAndBody
    },
    default: {
      bodyMapper: Mappers.RpcStatus
    }
  },
  serializer
};

export {
  KamigameWikiApi,
  KamigameWikiApiContext,
  Models as KamigameWikiApiModels,
  Mappers as KamigameWikiApiMappers
};
