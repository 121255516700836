var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("ツールチップ設定")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "kamigame-sortable-table",
                        {
                          staticClass: "wiki-nav-table",
                          scopedSlots: _vm._u([
                            {
                              key: "row",
                              fn: function (ref) {
                                var id = ref.id
                                var data = ref.data
                                var index = ref.index
                                return [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "outline-danger" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeRow(id)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash",
                                          }),
                                          _vm._v(" 削除"),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          state: _vm.isNotEmpty(data.word),
                                          placeholder:
                                            "ツールチップにする単語を入力",
                                        },
                                        model: {
                                          value: data.word,
                                          callback: function ($$v) {
                                            _vm.$set(data, "word", $$v)
                                          },
                                          expression: "data.word",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          state: _vm.isNotEmpty(data.title),
                                          placeholder:
                                            "ツールチップで表示するタイトルを入力",
                                        },
                                        model: {
                                          value: data.title,
                                          callback: function ($$v) {
                                            _vm.$set(data, "title", $$v)
                                          },
                                          expression: "data.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("b-form-textarea", {
                                        attrs: {
                                          state: _vm.isNotEmpty(
                                            data.description
                                          ),
                                          rows: "3",
                                        },
                                        model: {
                                          value: data.description,
                                          callback: function ($$v) {
                                            _vm.$set(data, "description", $$v)
                                          },
                                          expression: "data.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { attrs: { colspan: "2" } },
                                    [
                                      _c("b-input", {
                                        model: {
                                          value: data.url,
                                          callback: function ($$v) {
                                            _vm.$set(data, "url", $$v)
                                          },
                                          expression: "data.url",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.items,
                            callback: function ($$v) {
                              _vm.items = $$v
                            },
                            expression: "items",
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("thead", { staticClass: "thead-light" }, [
                              _c("tr", [
                                _c("th", { staticClass: "operation" }),
                                _vm._v(" "),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("単語"),
                                ]),
                                _vm._v(" "),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("タイトル"),
                                ]),
                                _vm._v(" "),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("説明"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  { attrs: { colspan: "2", scope: "col" } },
                                  [_vm._v("リンク先URL")]
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm._v(" "),
                          _c("template", { slot: "footer" }, [
                            _c("tfoot", [
                              _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "6" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "outline-primary" },
                                        on: { click: _vm.addRow },
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-plus" }),
                                        _vm._v(" 項目を追加"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "footer" }, slot: "footer" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "success" },
                              on: { click: _vm.save },
                            },
                            [_vm._v("保存する")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-unsaved-changes-confirmer", {
        ref: "kamigameUnsavedChangesConfirmer",
        attrs: { existsUnsavedChanges: _vm.existsUnsavedChanges },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }