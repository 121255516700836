import { KamigameWikiApi } from '@/api-client/generated/kamigameWikiApi';
import { V1WikiPageDraft, V1WikiPageWithTitleAndBody } from '@/api-client/generated/models';
import { chunk } from '@/lib/chunk';
import { bulkReplace } from '@/lib/replace';
import { RestError } from '@azure/ms-rest-js';
import { wait } from '@/lib/wait';

export async function fetchAllPublishedWikiPageIds(
  api: KamigameWikiApi,
  wikiName: string,
  isDeleted = false
): Promise<string[]> {
  const pageTitlesResponse = await api.listWikiPageTitles(wikiName, {
    isDeleted,
    excludesRedirected: false,
  });

  if (!pageTitlesResponse._response.parsedBody.wikiPageTitles) {
    throw new Error('ページの取得に失敗しました。');
  }

  const titles = pageTitlesResponse._response.parsedBody.wikiPageTitles;
  const pageIds = titles.map((page) => page.id).filter((id): id is string => typeof id === 'string');

  return pageIds;
}

export async function existsDraft(api: KamigameWikiApi, wikiName: string): Promise<boolean> {
  const draftsResponse = await api.listWikiPageDraft(wikiName, { limit: 1 }).catch((err: RestError) => err);

  if (draftsResponse instanceof Error) {
    // NOTE: 下書きがない場合は404が返ってくる
    if (draftsResponse.response?.status === 404) {
      return false;
    }

    throw new Error('下書きのチェックに失敗しました。再度やり直すかエンジニアに連絡してください。');
  }

  if (!draftsResponse._response.parsedBody.drafts) {
    return false;
  }

  return true;
}

export async function fetchPublishedWikiPage(api: KamigameWikiApi, wikiName: string, pageId: string) {
  const res = await api.getWikiPage(wikiName, pageId);
  return res;
}

export async function editWikiPageDraft(
  api: KamigameWikiApi,
  wikiName: string,
  publishedPage: V1WikiPageWithTitleAndBody,
  draft: {
    title?: string;
    body?: string;
    description?: string;
  }
) {
  const pageId = publishedPage.wikiPage?.id;

  if (!wikiName || !pageId) {
    throw new Error('wikiName または pageId が存在しません。');
  }

  await api.editWikiPageDraft(wikiName, pageId, {
    title: draft.title ?? publishedPage.title,
    body: draft.body ?? publishedPage.body,
    description: draft.description ?? publishedPage.wikiPage?.description,
    keywords: publishedPage.wikiPage?.keywords,
    metaOgpImageURL: publishedPage.wikiPage?.metaOgpImageURL,
    metaThumbnailImageAutoSelect: publishedPage.wikiPage?.metaThumbnailImageAutoSelect,
    metaThumbnailImageURL: publishedPage.wikiPage?.metaThumbnailImageURL,
    noindex: publishedPage.wikiPage?.noindex,
    titlePrefix: publishedPage.wikiPage?.titlePrefix,
    categoryId: publishedPage.wikiPage?.category?.id,
  });
}

export async function replaceWikiPageDraft(
  api: KamigameWikiApi,
  wikiName: string,
  pageId: string,
  replacements: Replacement[]
) {
  const publishedPage = await fetchPublishedWikiPage(api, wikiName, pageId);

  if (!publishedPage.title || !publishedPage.body) {
    throw new Error('編集先の title または body が空です。');
  }

  const replacedTitle = bulkReplace(publishedPage.title, replacements);
  const replacedBody = bulkReplace(publishedPage.body, replacements);
  const replacedDescription = bulkReplace(publishedPage.wikiPage?.description ?? '', replacements);

  if (
    replacedTitle === publishedPage.title &&
    replacedBody === publishedPage.body &&
    replacedDescription === publishedPage.wikiPage?.description
  ) {
    return;
  }

  await editWikiPageDraft(api, wikiName, publishedPage, {
    title: bulkReplace(publishedPage.title, replacements),
    body: bulkReplace(publishedPage.body, replacements),
    description: bulkReplace(publishedPage.wikiPage?.description ?? '', replacements),
  });
}

export async function bulkReplaceWikiPageDrafts(api: KamigameWikiApi, wikiName: string, replacements: Replacement[]) {
  const pageIds = await fetchAllPublishedWikiPageIds(api, wikiName);
  const pageIdsChunk = chunk<string>(pageIds, 20);

  for (const pageIds of pageIdsChunk) {
    const promises = pageIds.map((pageId) => replaceWikiPageDraft(api, wikiName, pageId, replacements));

    await Promise.all(promises);

    wait(1000);
  }
}

async function publishDraft(api: KamigameWikiApi, wikiName: string, draft: V1WikiPageDraft) {
  if (!draft.wikiPage?.id) {
    throw new Error('下書きの id が存在しません。');
  }

  const resPublish = await api.updateWikiPage(wikiName, draft.wikiPage?.id, {
    body: draft.body,
    description: draft.description || ' ', // NOTE: 雑談掲示板など意図的に description を空にしている記事があるが、空だと更新できないので空白を入れる
    categoryId: draft.category?.id || '',
    keywords: draft.keywords,
    metaOgpImageURL: draft.metaOgpImageURL,
    metaThumbnailImageAutoSelect: draft.metaThumbnailImageAutoSelect,
    metaThumbnailImageURL: draft.metaThumbnailImageURL,
    noindex: draft.noindex,
    spreadsheetURL: [],
    title: draft.title,
    titlePrefix: draft.titlePrefix,
    editPermission: 'OBJ_default',
    publishedAt: new Date(),
  });

  const resDelete = await api.deleteWikiPageDraft(wikiName, draft.wikiPage.id).catch((err: RestError) => err);
  if (resDelete instanceof Error && resDelete.response?.status !== 404) {
    throw new Error('下書きの削除に失敗しました。');
  }

  return resPublish;
}

export async function bulkPublishDrafts(api: KamigameWikiApi, wikiName: string) {
  const errors: Error[] = [];

  const resDrafts = await api.listWikiPageDraft(wikiName);

  const drafts = resDrafts._response.parsedBody.drafts;
  if (!drafts) {
    throw new Error('下書きの取得に失敗しました。');
  }

  const draftsChunk = chunk(drafts, 20);
  for (const drafts of draftsChunk) {
    const promises = drafts.map((draft) => publishDraft(api, wikiName, draft));

    await Promise.all(promises);
    wait(1000);
  }
}

async function replaceDraftContentHeader(api: KamigameWikiApi, wikiName: string, contentHeader: ContentHeader) {
  const publishedWikiPage = await fetchPublishedWikiPage(api, wikiName, contentHeader.pageId);

  if (!publishedWikiPage.body) {
    throw new Error('編集先の body が存在しません。');
  }

  const newBody = contentHeader.h1
    ? publishedWikiPage.body.replace(/^#\s*.+/m, `# ${contentHeader.h1}`)
    : publishedWikiPage.body;

  await editWikiPageDraft(api, wikiName, publishedWikiPage, {
    title: contentHeader.title,
    body: newBody,
    description: contentHeader.description,
  });
}

export async function bulkReplaceDraftsContentHeader(
  api: KamigameWikiApi,
  wikiName: string,
  contentHeaders: ContentHeader[]
) {
  const contentHeadersChunk = chunk(contentHeaders, 20);

  for (const contentHeader of contentHeadersChunk) {
    const promises = contentHeader.map(async (c) => replaceDraftContentHeader(api, wikiName, c));

    await Promise.all(promises);
    wait(1000);
  }
}
