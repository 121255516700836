var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { cols: _vm.cols } },
    [
      _c(
        "b-card",
        { attrs: { "header-tag": "header", "footer-tag": "footer" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("strong", [_vm._v("記事更新履歴")]),
          ]),
          _vm._v(" "),
          _c("b-table", {
            attrs: {
              id: "dashboard-history",
              fields: _vm.tableFields,
              items: _vm.wikiPageHistories,
              "per-page": _vm.limit,
              "current-page": "1",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(createdAt)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.format(data.item.createdAt, "yyyy/MM/dd HH:mm:ss")
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(author)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(data.item.author.nickname) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "b-link",
                      {
                        attrs: {
                          to: {
                            name: "wiki_page_history_list",
                            params: { pageId: data.item.wikiPage.id },
                          },
                        },
                      },
                      [_vm._v(_vm._s(data.item.title))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }