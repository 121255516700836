var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("Slack通知設定")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "Can",
                        { attrs: { I: "", do: "ACTION_wiki_slack_config" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                label: "通知項目",
                                description:
                                  "新規作成及び更新時に通知する項目を設定します。",
                              },
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  model: {
                                    value: _vm.notifyArticleCreateAndUpdate,
                                    callback: function ($$v) {
                                      _vm.notifyArticleCreateAndUpdate = $$v
                                    },
                                    expression: "notifyArticleCreateAndUpdate",
                                  },
                                },
                                [_vm._v("記事(公開)")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  model: {
                                    value: _vm.notifyArticleDelete,
                                    callback: function ($$v) {
                                      _vm.notifyArticleDelete = $$v
                                    },
                                    expression: "notifyArticleDelete",
                                  },
                                },
                                [_vm._v("記事(リダイレクト/削除)")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  model: {
                                    value: _vm.notifyArticleDraftUpdate,
                                    callback: function ($$v) {
                                      _vm.notifyArticleDraftUpdate = $$v
                                    },
                                    expression: "notifyArticleDraftUpdate",
                                  },
                                },
                                [_vm._v("記事下書き(新規作成/更新)")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  model: {
                                    value: _vm.notifyPartialCreateAndUpdate,
                                    callback: function ($$v) {
                                      _vm.notifyPartialCreateAndUpdate = $$v
                                    },
                                    expression: "notifyPartialCreateAndUpdate",
                                  },
                                },
                                [_vm._v("記事部品(新規作成/更新)")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  model: {
                                    value: _vm.notifyCategoryCreateAndUpdate,
                                    callback: function ($$v) {
                                      _vm.notifyCategoryCreateAndUpdate = $$v
                                    },
                                    expression: "notifyCategoryCreateAndUpdate",
                                  },
                                },
                                [_vm._v("カテゴリ(新規作成/更新)")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  model: {
                                    value: _vm.notifyDatasheetsCreateAndUpdate,
                                    callback: function ($$v) {
                                      _vm.notifyDatasheetsCreateAndUpdate = $$v
                                    },
                                    expression:
                                      "notifyDatasheetsCreateAndUpdate",
                                  },
                                },
                                [_vm._v("データシート(新規作成/更新)")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                label: "通知するチャンネル",
                                description:
                                  "通知するチャンネルを設定します。通知できるチャンネルはLighthouse StudioのSlack内の存在するチャンネルに限ります。通知先はオープンチャンネルを設定してください。先頭の#は抜いてください。空の場合はどこにも通知されません。",
                              },
                            },
                            [
                              _c(
                                "b-alert",
                                { attrs: { show: "", variant: "warning" } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  ※注意！\n                  仕様上、Slack上でアプリを追加しないと通知されません。Slackの通知したいチャンネルで以下の操作を行って下さい。\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n                  ショートカットボタンを押す -> 「このチャンネルにアプリを追加」を選択 ->\n                  "
                                    ),
                                    _c("strong", [
                                      _vm._v("「kamigame-wiki通知アプリ」"),
                                    ]),
                                    _vm._v(" を追加\n                "),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-img-lazy", {
                                    attrs: { src: "../img/slack-manual.png" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-input-group",
                                { attrs: { prepend: "#" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "チャンネル名(例: 'gw-wiki')",
                                    },
                                    model: {
                                      value: _vm.notifyChannelName,
                                      callback: function ($$v) {
                                        _vm.notifyChannelName = $$v
                                      },
                                      expression: "notifyChannelName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "footer" }, slot: "footer" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "success",
                                disabled: _vm.saveDisabled,
                              },
                              on: { click: _vm.save },
                            },
                            [_vm._v("保存する")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-unsaved-changes-confirmer", {
        ref: "kamigameUnsavedChangesConfirmer",
        attrs: { existsUnsavedChanges: _vm.existsUnsavedChanges },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }