var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("アイコン画像設定")]),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("この Wiki のアイコンとなる画像を設定します。"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "custom-file-container icon-uploader",
                          attrs: { "data-upload-id": "icon" },
                        },
                        [
                          _c("label", [
                            _vm._v("アイコン画像\n              "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.hasUploadedIcon,
                                    expression: "hasUploadedIcon",
                                  },
                                ],
                              },
                              [
                                _vm._v("("),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "custom-file-container__image-clear",
                                    attrs: { href: "javascript:void(0)" },
                                    on: { click: _vm.clearIconImage },
                                  },
                                  [_vm._v("画像の選択を解除")]
                                ),
                                _vm._v(")"),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-file-container__custom-file",
                            },
                            [
                              _c("input", {
                                staticClass:
                                  "custom-file-container__custom-file__custom-file-input",
                                attrs: {
                                  type: "file",
                                  accept: "image/png,image/jpeg,image/gif",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass:
                                  "custom-file-container__custom-file__custom-file-control",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-form-text", [
                            _vm._v(
                              "5 MB 以下 の JPEG, PNG, GIF のみ。 160x160 px の正方形の画像を推奨します。"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "kamigame-file-draggable custom-file-container__image-preview",
                            attrs: { id: "iconUploaderPreview" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled:
                              _vm.iconUploadDisabled || _vm.iconUploading,
                          },
                          on: { click: _vm.uploadIcon },
                        },
                        [
                          _vm.iconUploading
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "fa fa-spinner fa-pulse",
                                }),
                                _vm._v("アップロード中..."),
                              ])
                            : _c("span", [
                                _vm._v("この画像をアイコン画像として設定する"),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("ヘッダ画像設定")]),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "この Wiki のヘッダ部に表示される画像を設定します。"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "このヘッダ画像は画面幅全体に表示され、高さ 200px になるように上下が切り取られます。"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n            幅や高さが足りない場合は引き伸ばされますので、充分な大きさ (たとえば 2000x500px ほど)\n            の大きさの画像を設定することを推奨します。\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "custom-file-container header-uploader",
                          attrs: { "data-upload-id": "header" },
                        },
                        [
                          _c("label", [
                            _vm._v("ヘッダ画像\n              "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.hasUploadedHeader,
                                    expression: "hasUploadedHeader",
                                  },
                                ],
                              },
                              [
                                _vm._v("("),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "custom-file-container__image-clear",
                                    attrs: { href: "javascript:void(0)" },
                                    on: { click: _vm.clearHeaderImage },
                                  },
                                  [_vm._v("画像の選択を解除")]
                                ),
                                _vm._v(")"),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-file-container__custom-file",
                            },
                            [
                              _c("input", {
                                staticClass:
                                  "custom-file-container__custom-file__custom-file-input",
                                attrs: {
                                  type: "file",
                                  accept: "image/png,image/jpeg,image/gif",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass:
                                  "custom-file-container__custom-file__custom-file-control",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-form-text", [
                            _vm._v("5 MB 以下 の JPEG, PNG, GIF のみ。"),
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "kamigame-file-draggable custom-file-container__image-preview",
                            attrs: { id: "headerUploaderPreview" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled:
                              _vm.headerUploadDisabled || _vm.headerUploading,
                          },
                          on: { click: _vm.uploadHeader },
                        },
                        [
                          _vm.headerUploading
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "fa fa-spinner fa-pulse",
                                }),
                                _vm._v("アップロード中..."),
                              ])
                            : _c("span", [
                                _vm._v("この画像をヘッダ画像として設定する"),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-unsaved-changes-confirmer", {
        ref: "kamigameUnsavedChangesConfirmer",
        attrs: { existsUnsavedChanges: _vm.existsUnsavedChanges },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }