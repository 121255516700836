






import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'kamigame-footer',
})
export default class Footer extends Vue {}
