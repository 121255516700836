var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { class: _vm.classList },
    [
      _vm.wrapper && _vm.wrapper.element
        ? [
            _c(
              _vm.wrapper.element,
              _vm._b(
                { tag: "component" },
                "component",
                _vm.wrapper.attributes,
                false
              ),
              [_vm._v("\n      " + _vm._s(_vm.name) + "\n    ")]
            ),
          ]
        : [_vm._v("\n    " + _vm._s(_vm.name) + "\n  ")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }