

















import { V1WikiForDashboard, V1WikiMemberRequest, V1WikiPageHistory } from '@/api-client/generated/models';
import BaseConfigCard from '@/components/Dashboard/BaseConfigCard.vue';
import MemberRequestListCard from '@/components/Dashboard/MemberRequestListCard.vue';
import Histories from '@/components/Dashboard/Histories.vue';
import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';

@Component({
  name: 'dashboard',

  components: {
    'base-config-card': BaseConfigCard,
    'member-request-list-card': MemberRequestListCard,
    histories: Histories,
  },
})
export default class Dashboard extends KamigameVue {
  wikiForDashboard: V1WikiForDashboard = {};
  wikiMemberRequestArray: V1WikiMemberRequest[] = [];
  wikiPageHistories: V1WikiPageHistory[] = [];

  readonly wikiPageHistoriesLimit = 10;

  async mounted() {
    const getWikiForDashboardResponse = await this.api.getWikiForDashboard(this.wikiName);
    this.wikiForDashboard = getWikiForDashboardResponse._response.parsedBody;

    const listWikiMemberRequestResponse = await this.api.listWikiMemberRequest(this.wikiName);
    this.wikiMemberRequestArray = listWikiMemberRequestResponse.requests ?? [];

    const listWikiPageHistoriesResponse = await this.api.listWikiPageHistories(this.wikiName, {
      limit: this.wikiPageHistoriesLimit,
    });
    this.wikiPageHistories = listWikiPageHistoriesResponse.history ?? [];
  }
}
