var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.modalId,
        title: _vm.modalTitle || "カテゴリを選択",
        static: "",
      },
      on: { ok: _vm.onSelectionSaved, cancel: _vm.onSelectionCanceled },
    },
    [
      _c(
        "b-input-group",
        [
          _vm.selectedParentName
            ? _c(
                "b-input-group-prepend",
                [
                  _c("b-btn", { on: { click: _vm.clear } }, [
                    _c("i", {
                      staticClass: "fa fa-close",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(
                      "\n         \n        " +
                        _vm._s(_vm.selectedParentName) +
                        "/\n      "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: { type: "text", placeholder: "カテゴリ名で絞り込む" },
            model: {
              value: _vm.filter,
              callback: function ($$v) {
                _vm.filter = $$v
              },
              expression: "filter",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "text-right" },
        [
          _c(
            "b-btn",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.categoryCreate",
                  modifiers: { categoryCreate: true },
                },
              ],
              attrs: { variant: "link" },
            },
            [
              _c("i", {
                staticClass: "fa fa-plus",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" カテゴリを作成する"),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { staticClass: "mt-2", attrs: { id: "categoryCreate" } },
        [
          _c(
            "b-card",
            [
              _c("p", [
                _vm.selectedParentName
                  ? _c("span", [
                      _c("strong", [
                        _vm._v("「" + _vm._s(_vm.selectedParentName) + "」"),
                      ]),
                      _vm._v("カテゴリ以下に"),
                    ])
                  : _vm._e(),
                _vm._v(
                  "新たに作成するカテゴリの名前を入力してください。\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    model: {
                      value: _vm.newCategoryName,
                      callback: function ($$v) {
                        _vm.newCategoryName = $$v
                      },
                      expression: "newCategoryName",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "Can",
                    { attrs: { I: "", do: "ACTION_wiki_category_update" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "save",
                          attrs: {
                            variant: "primary",
                            disabled: !_vm.newCategoryName,
                          },
                          on: { click: _vm.onSaveNewCategory },
                        },
                        [_vm._v("カテゴリを作成")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.saveNewCategoryMessage
                ? _c("span", { class: _vm.saveNewCategoryClass }, [
                    _vm._v(_vm._s(_vm.saveNewCategoryMessage)),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("tree", {
        ref: "categoryTree",
        attrs: { filter: _vm.prefixedFilter, options: _vm.categoryTreeOptions },
        on: { "node:selected": _vm.onCategorySelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }