var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn wiki-page-history-list" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm.pageId
                    ? _c(
                        "strong",
                        [
                          _c(
                            "b-link",
                            {
                              attrs: {
                                to: {
                                  name: "wiki_page_edit",
                                  params: { paths: _vm.pageId },
                                },
                              },
                            },
                            [
                              _vm._v(
                                "「" +
                                  _vm._s(
                                    _vm.histories[0]
                                      ? _vm.histories[0].title
                                      : ""
                                  ) +
                                  "」"
                              ),
                            ]
                          ),
                          _vm._v("\n            のページ編集履歴\n          "),
                        ],
                        1
                      )
                    : _c("strong", [_vm._v(" Wiki 編集履歴 ")]),
                ]),
                _vm._v(" "),
                _c(
                  "b-container",
                  { staticClass: "mb-3", attrs: { fluid: "" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { lg: "6" } },
                          [
                            _c("b-form-select", {
                              staticClass: "w-100 mt-3",
                              attrs: { options: _vm.sortConditions },
                              on: {
                                input: function ($event) {
                                  return _vm.getHistories()
                                },
                              },
                              model: {
                                value: _vm.selectedSortCondition,
                                callback: function ($$v) {
                                  _vm.selectedSortCondition = $$v
                                },
                                expression: "selectedSortCondition",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { lg: "6" } },
                          [
                            _c(
                              "b-form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.getHistories()
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  { staticClass: "mt-3" },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: "検索ワードを入力",
                                      },
                                      model: {
                                        value: _vm.searchString,
                                        callback: function ($$v) {
                                          _vm.searchString = $$v
                                        },
                                        expression: "searchString",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      { attrs: { type: "submit" } },
                                      [_vm._v("検索")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "kamigame-paging",
                  {
                    ref: "kamigamePaging",
                    attrs: {
                      dataLength: _vm.totalHisotiesNum,
                      "parent-per-page": _vm.perRequestHistoriesNum,
                    },
                    on: { "change-data-range": _vm.changeDataRange },
                  },
                  [
                    _c(
                      "b-table",
                      {
                        attrs: {
                          slot: "data-list",
                          responsive: "",
                          fields: _vm.historyFields,
                          items: _vm.histories,
                          "empty-text": "表示可能な履歴が見つかりません",
                          "show-empty": "",
                          busy: _vm.loading,
                        },
                        slot: "data-list",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(author)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data.item.author.nickname) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(createdAt)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.dateFormat(
                                          data.item.createdAt,
                                          "yyyy/MM/dd HH:mm:ss"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(title)",
                              fn: function (data) {
                                return [
                                  !_vm.pageId
                                    ? _c(
                                        "b-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "wiki_page_history_list",
                                              params: {
                                                pageId: data.item.wikiPage.id,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              data.item.title ||
                                                "(タイトルなし)"
                                            )
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            data.item.title || "(タイトルなし)"
                                          )
                                        ),
                                      ]),
                                ]
                              },
                            },
                            {
                              key: "cell(body)",
                              fn: function (data) {
                                return data.item.body
                                  ? [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(data.item.body.slice(0, 100)) +
                                          "\n            "
                                      ),
                                    ]
                                  : undefined
                              },
                            },
                            {
                              key: "cell(_operation)",
                              fn: function (data) {
                                return [
                                  data.item.isDeleted
                                    ? _c(
                                        "b-row",
                                        { staticClass: "text-danger" },
                                        [_vm._v("※この記事は削除されました")]
                                      )
                                    : data.item.isRestored
                                    ? _c(
                                        "b-row",
                                        { staticClass: "text-success" },
                                        [_vm._v("※この記事は復元されました")]
                                      )
                                    : _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                size: "sm",
                                                variant: "outline-primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.navigateToDetail(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-check",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                              _vm._v(" 変更内容を確認"),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "Can",
                                    {
                                      attrs: {
                                        I: "",
                                        do: "ACTION_wiki_page_update",
                                        of: data.item.wikiPage.editPermission,
                                      },
                                    },
                                    [
                                      data.index !== 0 && _vm.pageId
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    size: "sm",
                                                    variant: "outline-danger",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showRevertModal(
                                                        data.index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-history",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                  _vm._v(" この版に戻す"),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-modal",
                                    {
                                      ref: "alertRevert_" + data.index,
                                      attrs: {
                                        "hide-header": "",
                                        centered: "",
                                        "no-fade": "",
                                        lazy: "",
                                      },
                                      on: {
                                        ok: function ($event) {
                                          return _vm.revert(data.item)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "指定したバージョンまで記事の内容を戻します。"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "本当に続行してもよろしいですか？"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-center my-2",
                            attrs: { slot: "table-busy" },
                            slot: "table-busy",
                          },
                          [
                            _c("b-spinner", { staticClass: "align-middle" }),
                            _vm._v(" "),
                            _c("strong", [_vm._v("読み込み中...")]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }