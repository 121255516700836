var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          !!_vm.takahiroCustomHeaderBody
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-card",
                        {
                          attrs: {
                            "header-tag": "header",
                            "footer-tag": "footer",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("strong", [
                                _vm._v(
                                  "「特別な」カスタムヘッダー設定 (takahiro-games からの移行版)"
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n            takahiro-games から移行されたフォルダーにカスタムヘッダー (custom-header.md)\n            が存在したため、以下の特別なカスタムヘッダー設定が有効になっています。\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-form-textarea", {
                            attrs: {
                              value: _vm.takahiroCustomHeaderBody,
                              readonly: "",
                              rows: "5",
                              "max-rows": "10",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "Wiki 向けのカスタムヘッダー設定に切り替えるためには、以下のステップで移行作業をおこなってください。"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("ol", [
                            _c("li", [
                              _vm._v(
                                "\n              takahiro-games\n              から移行したカスタムヘッダーを参考にしながら、通常の「カスタムヘッダー設定」の設定を完了する\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                "以下のボタンから takahio-games から移行したカスタムヘッダー設定を削除する"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "outline-danger" },
                                  on: { click: _vm.deleteCustomHeaderTakahiro },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-trash" }),
                                  _vm._v(
                                    " takahiro-games から移行されたカスタムヘッダー設定を削除する\n              (この操作は取り消すことができません)"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n            takahiro-games\n            形式のカスタムヘッダーを編集することはできません。カスタムヘッダーの設定を変更したい場合は、必ず通常のカスタムヘッダー設定をしてから、\n            takahiro-games から移行したカスタムヘッダー設定を削除することによっておこなってください。\n          "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("カスタムヘッダー設定")]),
                      ]),
                      _vm._v(" "),
                      !!_vm.takahiroCustomHeaderBody
                        ? _c(
                            "b-alert",
                            { attrs: { show: "", variant: "warning" } },
                            [
                              _vm._v(
                                "\n            takahiro-games\n            から移行されたカスタムヘッダーによって、通常のカスタムヘッダー設定は一時的に無効になっています。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            以下のフォームから設定できるカスタムヘッダーを有効にするには、 takahiro-games\n            形式のヘッダー設定を削除してください。\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "ヘッダタイトル",
                            description:
                              "各記事の共通ヘッダのタイトルを指定します。",
                          },
                        },
                        [
                          _c("b-input", {
                            model: {
                              value: _vm.headerTitle,
                              callback: function ($$v) {
                                _vm.headerTitle = $$v
                              },
                              expression: "headerTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "ヘッダ項目",
                            description:
                              "項目をドラッグすると順番の入れ替えができます。",
                          },
                        },
                        [
                          _c(
                            "kamigame-sortable-table",
                            {
                              staticClass: "wiki-custom-header-table",
                              scopedSlots: _vm._u([
                                {
                                  key: "row",
                                  fn: function (ref) {
                                    var id = ref.id
                                    var data = ref.data
                                    var index = ref.index
                                    return [
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "outline-danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeRow(id)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-trash",
                                              }),
                                              _vm._v(" 削除"),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c("b-input", {
                                            model: {
                                              value: data.name,
                                              callback: function ($$v) {
                                                _vm.$set(data, "name", $$v)
                                              },
                                              expression: "data.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                data.url || data.wikiPageTitle
                                              ) +
                                              "\n                  ("
                                          ),
                                          _c(
                                            "b-link",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showSetLinkWikiPageModal(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("遷移先を変更する")]
                                          ),
                                          _vm._v(")\n                "),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-modal",
                                        {
                                          ref: "modalSelectWikiPage_" + index,
                                          attrs: {
                                            okDisabled:
                                              !_vm.isWikiPageTitleSelected &&
                                              !(
                                                _vm.inputtedModalURL &&
                                                _vm.inputtedModalTitle
                                              ),
                                            "hide-header": "",
                                            centered: "",
                                            "no-fade": "",
                                            lazy: "",
                                          },
                                          on: {
                                            ok: function ($event) {
                                              return _vm.setLinkWikiPage(id)
                                            },
                                            hide: _vm.resetSearchModal,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-tabs",
                                            [
                                              _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    title:
                                                      "リンク記事を選択する",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "kamigame-wiki-page-titles-search-modal",
                                                    {
                                                      ref: "selectWikiPageTitle",
                                                      attrs: {
                                                        data: _vm.wikiPageTitles,
                                                      },
                                                      on: {
                                                        wikiPageTitleSelected:
                                                          _vm.onWikiPageTitleSet,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    title: "直接URLを入力する",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb-3" },
                                                    [
                                                      _c("label", [
                                                        _vm._v("項目名"),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("b-form-input", {
                                                        attrs: { type: "text" },
                                                        model: {
                                                          value:
                                                            _vm.inputtedModalTitle,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.inputtedModalTitle =
                                                              $$v
                                                          },
                                                          expression:
                                                            "inputtedModalTitle",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("label", [
                                                        _vm._v("URL"),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("b-form-input", {
                                                        attrs: { type: "text" },
                                                        model: {
                                                          value:
                                                            _vm.inputtedModalURL,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.inputtedModalURL =
                                                              $$v
                                                          },
                                                          expression:
                                                            "inputtedModalURL",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.items,
                                callback: function ($$v) {
                                  _vm.items = $$v
                                },
                                expression: "items",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("thead", { staticClass: "thead-light" }, [
                                  _c("tr", [
                                    _c("th", { staticClass: "operation" }),
                                    _vm._v(" "),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v("項目名"),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v("リンク先"),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm._v(" "),
                              _c("template", { slot: "footer" }, [
                                _c("tfoot", [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      { attrs: { colspan: "3" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "outline-primary",
                                            },
                                            on: {
                                              click:
                                                _vm.showAddLinkWikiPageModal,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-plus",
                                            }),
                                            _vm._v(" 項目を追加"),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-modal",
                                          {
                                            ref: "modalSelectWikiPage_new",
                                            attrs: {
                                              okDisabled:
                                                !_vm.isWikiPageTitleSelected &&
                                                !(
                                                  _vm.inputtedModalURL &&
                                                  _vm.inputtedModalTitle
                                                ),
                                              "hide-header": "",
                                              centered: "",
                                              "no-fade": "",
                                              lazy: "",
                                            },
                                            on: {
                                              ok: _vm.addRow,
                                              hide: _vm.resetSearchNewModal,
                                            },
                                          },
                                          [
                                            _c(
                                              "b-tabs",
                                              [
                                                _c(
                                                  "b-tab",
                                                  {
                                                    attrs: {
                                                      title:
                                                        "リンク記事を選択する",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "kamigame-wiki-page-titles-search-modal",
                                                      {
                                                        ref: "selectWikiPageTitleNew",
                                                        attrs: {
                                                          data: _vm.wikiPageTitles,
                                                        },
                                                        on: {
                                                          wikiPageTitleSelected:
                                                            _vm.onWikiPageTitleSet,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-tab",
                                                  {
                                                    attrs: {
                                                      title:
                                                        "直接URLを入力する",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mb-3" },
                                                      [
                                                        _c("label", [
                                                          _vm._v("項目名"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.inputtedModalTitle,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.inputtedModalTitle =
                                                                $$v
                                                            },
                                                            expression:
                                                              "inputtedModalTitle",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("label", [
                                                          _vm._v("URL"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.inputtedModalURL,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.inputtedModalURL =
                                                                $$v
                                                            },
                                                            expression:
                                                              "inputtedModalURL",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "footer" }, slot: "footer" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "success" },
                              on: { click: _vm.save },
                            },
                            [_vm._v("保存する")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-unsaved-changes-confirmer", {
        ref: "kamigameUnsavedChangesConfirmer",
        attrs: { existsUnsavedChanges: _vm.existsUnsavedChanges },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }