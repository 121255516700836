






import KamigameVue from '@/KamigameVue';
import { getToken } from '@/service/GoogleApiClient';
import { fetchTransferredWikiStateForGameVillage } from '@/service/GoogleCloudStorageApi';
import { chunk } from '@/lib/chunk';
import Component from 'vue-class-component';
import { createApiClientWithTokenByURI } from '@/service/WikiAPIClientFactory';
import { calcDiffLines } from '@/service/CalcDiffLines';
import format from 'date-fns/format';
import { convertObjectsToCSV } from '@/lib/csv';
import FileSaver from 'file-saver';
import * as StringConverter from '@/service/StringConverter';
import { wait } from '@/lib/wait';

type CsvRowForPage = {
  ID: string;
  currentPageTitle: string;
  sourcePageTitle: string;
  currentPageUrl: string;
  sourcePageUrl: string;
  transferredSourceLastUpdatedAt: string;
  sourceLastUpdatedAt: string;
  sourceIsDeleted: boolean;
  sourceDiffUrl: string;
  sourceDiffLines: number;
  isUpdated: boolean;
};

const formatDate = (date: string | Date) => {
  return format(new Date(date), 'yyyy/MM/dd HH:mm:ss');
};

@Component({
  name: 'button-download-csv-for-deference-from-source',
})
export default class ButtonDownloadCsvForDeferenceFromSource extends KamigameVue {
  loadingText = '';

  async clickButton() {
    await getToken();

    try {
      this.loadingText = '(実行中)';
      await this.downloadCsvForDeferenceFromSource();
    } catch (e) {
      console.error(e);
      this.setFlashMessage('danger', 'エラーが発生しました。エンジニアに連絡してください。');
    }

    this.loadingText = '';
  }

  async downloadCsvForDeferenceFromSource() {
    const csvRowsForPage: CsvRowForPage[] = [];
    const baseKamigameUrl =
      process.env.NODE_ENV === 'production' ? 'https://admin.wiki.kamigame.jp' : 'http://localhost:3001';

    const transferredWikiState = await fetchTransferredWikiStateForGameVillage(this.wikiName).catch((e: Error) => {
      return e;
    });

    if (transferredWikiState instanceof Error) {
      this.setFlashMessage('danger', '状態の取得に失敗しました。エンジニアに連絡してください。');
      console.error(transferredWikiState);
      return;
    }

    const loginButton = document.getElementById('kamigameLoginButton') as IdKamigameLoginButton;
    const u = await loginButton.getUser();

    const sourceApiWithAnonymous = createApiClientWithTokenByURI(transferredWikiState.sourceWiki.apiBaseUrl);
    const sourceSession = await sourceApiWithAnonymous.login({ idToken: u.id_token });

    const sourceApi = createApiClientWithTokenByURI(transferredWikiState.sourceWiki.apiBaseUrl, sourceSession.id);

    const pageStates = Object.values(transferredWikiState.pages.byId);
    const pageStatesChunk = chunk(pageStates, 10);

    for (const pageStatesInChunk of pageStatesChunk) {
      const promises = pageStatesInChunk.map(async (pageState) => {
        const sourceHistoriesResponse = await sourceApi.listWikiPageHistoriesByWikiPage(
          transferredWikiState.sourceWiki.name,
          pageState.sourceId,
          {
            limit: 1,
            sortedBy: 'lastUpdatedAt',
            sortedOrder: 'DESC',
          }
        );

        if (sourceHistoriesResponse instanceof Error) {
          console.error(sourceHistoriesResponse);
          return;
        }

        if (!sourceHistoriesResponse._response.parsedBody.history) {
          return;
        }

        const sourceHistories = sourceHistoriesResponse._response.parsedBody.history;
        const sourceLatestHistory = sourceHistories[0];

        const transferredSourceHistory = await sourceApi
          .getWikiPageHistory(transferredWikiState.sourceWiki.name, pageState.sourceHistoryId)
          .catch((e: Error) => e);

        if (transferredSourceHistory instanceof Error) {
          console.error(transferredSourceHistory);
          return;
        }

        if (!sourceLatestHistory.body || !transferredSourceHistory.body || !sourceLatestHistory.createdAt) {
          return;
        }

        const currentPage = await this.api.getWikiPage(this.wikiName, pageState.id).catch((e: Error) => e);
        if (currentPage instanceof Error) {
          console.error(currentPage);
          return;
        }

        csvRowsForPage.push({
          ID: pageState.id,
          currentPageTitle: currentPage.title ?? '',
          sourcePageTitle: transferredSourceHistory.title ?? '',
          currentPageUrl: `${location.origin}/admin/${this.wikiName}/page/edit/${pageState.id}`,
          sourcePageUrl: `${baseKamigameUrl}/${transferredWikiState.sourceWiki.name}/page/edit/${pageState.sourceId}`,
          transferredSourceLastUpdatedAt: formatDate(pageState.sourceLastUpdatedAt),
          sourceLastUpdatedAt: formatDate(sourceLatestHistory.createdAt), // NOTE: mysql.kw_wiki_page の更新日はデータシートの更新も含まれるので、記事本文の更新日を確認する場合は編集履歴を参照するしかない
          sourceIsDeleted: sourceLatestHistory.isDeleted,
          sourceDiffUrl: `${baseKamigameUrl}/${transferredWikiState.sourceWiki.name}/page/${pageState.sourceId}/history/diff/${sourceLatestHistory.id}/${pageState.sourceHistoryId}`,
          sourceDiffLines: calcDiffLines(sourceLatestHistory.body, transferredSourceHistory.body),
          isUpdated: sourceLatestHistory.createdAt.getTime() > new Date(pageState.sourceLastUpdatedAt).getTime(),
        });
      });

      // TODO: エラー時の処理
      await Promise.all(promises);
      await wait();
    }

    const sortedCsvRowsForPageById = csvRowsForPage.sort((a, b) => a.ID.localeCompare(b.ID));

    const pageBlob = new Blob(
      [StringConverter.convertStringToUTF16LEArray(convertObjectsToCSV(sortedCsvRowsForPageById))],
      {
        type: 'text/csv;charset=utf-16',
      }
    );

    FileSaver.saveAs(pageBlob, `${this.wikiName}-page-diff-source-${format(new Date(), 'yyyyMMddHHmmss')}.csv`);

    this.setFlashMessage('success', '更新チェックの結果をダウンロードしました');
  }
}
