var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { cols: _vm.cols } },
    [
      _c(
        "b-card",
        { attrs: { "header-tag": "header", "footer-tag": "footer" } },
        [
          _c(
            "div",
            {
              staticClass: "member-request-list-card-header",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("strong", [
                _vm._v(
                  "Wiki 編集メンバー参加申請一覧 (" +
                    _vm._s(_vm.memberRequestTableItems.length) +
                    ")"
                ),
              ]),
              _vm._v(" "),
              _c("b-link", { attrs: { to: { name: "wiki_member_list" } } }, [
                _vm._v("メンバー管理画面へ"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.memberRequestTableItems.length > 0
            ? _c("b-table", {
                attrs: {
                  fields: _vm.memberRequestFields,
                  items: _vm.memberRequestTableItems,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(createdAt)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.dateFormat(
                                  data.value,
                                  "yyyy/MM/dd HH:mm:ss"
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1832924113
                ),
              })
            : _c("p", [_vm._v("参加申請はありません")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }