




import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';

@Component({
  name: 'manage-dashboard',
})
export default class ManageDashboard extends KamigameVue {}
