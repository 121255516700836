
















































































import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import { MarkdownEditor, CategoryTreeSelectModal } from '@/components';
import Component from 'vue-class-component';

import {
  V1WikiPageTemplate,
  V1CreateWikiPageTemplateRequestBody,
  V1WikiPageCategory,
  V1EditWikiPageTemplateRequestBody,
} from '@/api-client/generated/models';

@Component({
  name: 'wiki-page-template-edit',
  components: {
    'kamigame-markdown-editor': MarkdownEditor,
    'kamigame-category-tree-select-modal': CategoryTreeSelectModal,
  },
})
export default class WikiPageTemplateEdit extends KamigameVue {
  title = '';
  description = '';
  keywords = '';
  text = '';
  templateTitle = '';
  templateText = '';
  disabled = false;
  editable = true;
  selectedCategory: V1WikiPageCategory = {};

  async mounted() {
    await this.api.getWikiPageTemplate(this.wikiName, this.templateId).then((response: V1WikiPageTemplate) => {
      this.title = response.title || '';
      this.text = response.body || '';
      this.description = response.description || '';
      this.keywords = response.keywords || '';
      this.selectedCategory = response.category || {};
      this.templateTitle = this.title;
      this.templateText = this.text;
    });

    this.setTemplateDocumentTitle();
    this.editable = this.$ability.can('ACTION_wiki_page_template_update', 'OBJ_default');
  }

  async save() {
    if (this.disabled) {
      return;
    }
    this.disabled = true;

    const params: V1EditWikiPageTemplateRequestBody = {
      title: this.title,
      body: this.text,
      keywords: this.keywords,
      description: this.description,
    };

    if (this.selectedCategory.id) {
      params.categoryId = this.selectedCategory.id;
    }

    await this.api.updateWikiPageTemplate(this.wikiName, this.templateId, params).then(() => {
      this.templateTitle = this.title;
      this.templateText = this.text;
      this.router.push(
        {
          name: 'wiki_page_template_list',
        },
        () => {
          this.setFlashMessage('success', 'テンプレートを更新しました。');
        }
      );
    });

    this.disabled = false;
  }

  isEditorClean() {
    return this.title === this.templateTitle && this.text === this.templateText;
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    if (this.isEditorClean()) {
      next();
      return;
    }

    if (window.confirm('変更が保存されていませんが、このページから離れますか？')) {
      next();
    }
  }

  clearCategory() {
    this.selectedCategory = {};
  }

  onCategorySaved(category: V1WikiPageCategory) {
    this.selectedCategory = category;
  }

  setTemplateDocumentTitle() {
    if (!this.templateTitle) {
      return;
    }

    this.setDocumentTitle(`${this.templateTitle} | ${this.routeDocumentTitle}`);
  }

  get templateId() {
    return this.route.params.templateId;
  }
}
