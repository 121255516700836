var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar",
      class: { "gamevillage-background-color": _vm.isLocaleEn },
    },
    [
      _c("SidebarHeader"),
      _vm._v(" "),
      _c("SidebarForm"),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "sidebar-nav" },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "nav" },
            [
              _vm._l(_vm.navItems, function (item, index) {
                return [
                  item.title
                    ? [
                        _c("SidebarNavTitle", {
                          attrs: {
                            name: item.name,
                            classes: item.class,
                            wrapper: item.wrapper,
                          },
                        }),
                      ]
                    : item.divider
                    ? [
                        _c("SidebarNavDivider", {
                          attrs: { classes: item.class },
                        }),
                      ]
                    : item.label
                    ? [
                        _c("SidebarNavLabel", {
                          attrs: {
                            name: item.name,
                            url: item.url,
                            icon: item.icon,
                            label: item.label,
                            classes: item.class,
                          },
                        }),
                      ]
                    : [
                        item.children
                          ? [
                              _c(
                                "SidebarNavDropdown",
                                {
                                  attrs: {
                                    name: item.name,
                                    url: item.url,
                                    icon: item.icon,
                                    classes: item.class,
                                  },
                                },
                                [
                                  _vm._l(
                                    item.children,
                                    function (childL1, index) {
                                      return [
                                        childL1.children
                                          ? [
                                              _c(
                                                "SidebarNavDropdown",
                                                {
                                                  attrs: {
                                                    name: childL1.name,
                                                    url: childL1.url,
                                                    icon: childL1.icon,
                                                  },
                                                },
                                                _vm._l(
                                                  childL1.children,
                                                  function (childL2, index) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c("SidebarNavLink", {
                                                          attrs: {
                                                            name: childL2.name,
                                                            url: childL2.url,
                                                            icon: childL2.icon,
                                                            badge:
                                                              childL2.badge,
                                                            variant:
                                                              childL2.variant,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          : [
                                              _c(
                                                "SidebarNavItem",
                                                {
                                                  attrs: {
                                                    classes: item.class,
                                                  },
                                                },
                                                [
                                                  _c("SidebarNavLink", {
                                                    attrs: {
                                                      name: childL1.name,
                                                      url: childL1.url,
                                                      icon: childL1.icon,
                                                      badge: childL1.badge,
                                                      variant: childL1.variant,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          : [
                              _c(
                                "SidebarNavItem",
                                { attrs: { classes: item.class } },
                                [
                                  _c("SidebarNavLink", {
                                    attrs: {
                                      name: item.name,
                                      url: item.url,
                                      icon: item.icon,
                                      badge: item.badge,
                                      variant: item.variant,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
      _vm._v(" "),
      _c("SidebarFooter"),
      _vm._v(" "),
      _c("SidebarMinimizer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }