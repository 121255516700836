










































































































import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { Watch } from 'vue-property-decorator';
import { RestError } from '@azure/ms-rest-js';

import KamigameVue from '@/KamigameVue';
import { Paging } from '@/components';
import { V1WikiPageHistoryCollection, V1WikiPageHistory, V1WikiPage } from '@/api-client/generated/models';
import { convertSearchQueryStringToArray } from '@/service/SearchQueryStringConverter';

@Component({
  name: 'wiki-page-history-list',
  components: {
    'kamigame-paging': Paging,
  },
})
export default class WikiPageHistoryList extends KamigameVue {
  dateFormat = format;

  loading = false;
  histories: V1WikiPageHistory[] = [];
  wikiPage?: V1WikiPage;
  historyFields = [
    { key: 'createdAt', label: '日時' },
    { thStyle: { width: '25%' }, key: 'title', label: 'タイトル' },
    { thStyle: { width: '45%' }, key: 'body', label: '本文' },
    { key: 'author', label: '編集者' },
    { key: '_operation', label: '操作' },
  ];
  sortConditions = [
    { text: '最終更新日が新しい順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' } },
    { text: '最終更新日が古い順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'ASC' } },
    { text: 'タイトル順', value: { sortedBy: 'title', sortOrder: 'ASC' } },
  ];
  perRequestHistoriesNum = 20;
  totalHisotiesNum = 0;
  searchString = '';
  selectedSortCondition = { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' };
  kamigamePaging = this.$refs.kamigamePaging as Paging;

  @Watch('pageId')
  onPageIdChanged() {
    this.getHistories();
  }

  async mounted() {
    this.kamigamePaging = this.$refs.kamigamePaging as Paging;
    this.getHistories();
  }

  async getHistories(startAt: number = 0) {
    this.loading = true;

    const getHistoriesParams = {
      limit: this.perRequestHistoriesNum,
      offset: startAt,
      searchWords: this.searchWords,
      sortedBy: this.selectedSortCondition.sortedBy,
      sortOrder: this.selectedSortCondition.sortOrder,
    };
    const fetcher = this.pageId
      ? this.api.listWikiPageHistoriesByWikiPage(this.wikiName, this.pageId, getHistoriesParams)
      : this.api.listWikiPageHistories(this.wikiName, getHistoriesParams);
    fetcher
      .then((response: V1WikiPageHistoryCollection) => {
        this.loading = false;
        this.histories = response.history || [];
        this.wikiPage = response.wikiPage;
        this.totalHisotiesNum = response.numOfTotalHistories || 0;
        if (startAt === 0) {
          this.kamigamePaging.resetPaging();
        }
      })
      .catch((e) => {
        if (e instanceof RestError && e.statusCode === 404) {
          this.histories = [];
          this.loading = false;
        }
      });
  }

  changeDataRange(startAt: number) {
    this.getHistories(startAt);
  }

  navigateToDetail(history: V1WikiPageHistory) {
    const a = history.parentId;
    const b = history.id;

    if (b) {
      const routeName = this.pageId ? 'wiki_page_history' : 'wiki_history';
      const params: any = {
        b,
        pageId: this.pageId,
      };
      if (a) {
        params.a = a;
      }
      this.router.push({ name: routeName, params });
    }
  }

  showRevertModal(index: number) {
    const modal = this.$refs[`alertRevert_${index}`] as BModal;
    modal.show();
  }

  async revert(history: V1WikiPageHistory) {
    if (!this.wikiPage) {
      return;
    }

    const defaultPublishedDate = new Date(`${format(new Date(), 'yyyy-MM-dd')} 00:00`);
    this.api
      .updateWikiPage(this.wikiName, this.pageId, {
        title: history.title,
        body: history.body,
        categoryId: this.wikiPage.category ? this.wikiPage.category.id : '',
        editPermission: this.wikiPage.editPermission,
        publishedAt: this.wikiPage ? this.wikiPage.publishedAt || defaultPublishedDate : defaultPublishedDate,
        keywords: this.wikiPage.keywords ?? '',
        description: this.wikiPage.description ?? '',
        noindex: this.wikiPage.noindex ?? false,
        metaOgpImageURL: this.wikiPage.metaOgpImageURL ?? '',
        metaThumbnailImageURL: this.wikiPage.metaThumbnailImageURL ?? '',
        metaThumbnailImageAutoSelect: this.wikiPage.metaThumbnailImageAutoSelect ?? false,
        titlePrefix: this.wikiPage.titlePrefix ?? '',
      })
      .then((response) => {
        this.setFlashMessage('success', '記事の内容を指定されたバージョンまで戻しました。');
      });
  }

  get wikiName() {
    return this.route.params.name;
  }

  get pageId() {
    return this.route.params.pageId;
  }

  get searchWords() {
    return convertSearchQueryStringToArray(this.searchString);
  }
}
