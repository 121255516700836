var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modalRef",
      attrs: { title: "記事部品コード生成", lazy: "", centered: "" },
      on: {
        ok: _vm.onSelectionSaved,
        cancel: _vm.onSelectionCanceled,
        hide: _vm.clear,
      },
    },
    [
      _vm.errorMessage !== ""
        ? _c("b-alert", { attrs: { variant: "danger", show: "" } }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
          ])
        : _c(
            "div",
            [
              _c(
                "b-form-select",
                {
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                },
                _vm._l(_vm.datasheetsNames, function (items, datasheets) {
                  return _c(
                    "b-form-select-option",
                    { key: datasheets, attrs: { value: datasheets } },
                    [_vm._v(_vm._s(datasheets))]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _vm.selected !== null
                ? _c("b-form-group", {
                    staticClass: "group",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var ariaDescribedby = ref.ariaDescribedby
                            return [
                              _c(
                                "b-form-checkbox-group",
                                {
                                  attrs: {
                                    "aria-describedby": ariaDescribedby,
                                    stacked: "",
                                  },
                                  model: {
                                    value: _vm.itemsSelected,
                                    callback: function ($$v) {
                                      _vm.itemsSelected = $$v
                                    },
                                    expression: "itemsSelected",
                                  },
                                },
                                _vm._l(
                                  _vm.datasheetsNames[_vm.selected],
                                  function (i) {
                                    return _c(
                                      "b-form-checkbox",
                                      {
                                        key: i,
                                        staticClass: "item",
                                        attrs: { value: i },
                                      },
                                      [_vm._v(_vm._s(i))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      572305502
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }