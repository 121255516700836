var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "app-footer" }, [
      _c("span", [
        _c("a", { attrs: { href: "https://genesisui.com" } }, [_vm._v("Real")]),
        _vm._v(" © 2018 creativeLabs."),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "ml-auto" }, [
        _vm._v("Powered by "),
        _c("a", { attrs: { href: "https://genesisui.com" } }, [
          _vm._v("GenesisUI"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }