





















































































































import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { RestError } from '@azure/ms-rest-js';

import KamigameVue from '@/KamigameVue';
import { Paging, CategoryTreeSelectModal } from '@/components';
import {
  V1WikiPageTemplateCollection,
  V1WikiPageTemplate,
  V1WikiPageCategory,
  V1EditWikiPageTemplateRequestBody,
} from '@/api-client/generated/models';
import { convertSearchQueryStringToArray } from '@/service/SearchQueryStringConverter';

@Component({
  name: 'wiki-page-template-list',
  components: {
    'kamigame-category-tree-select-modal': CategoryTreeSelectModal,
    'kamigame-paging': Paging,
  },
})
export default class WikiPageTemplateList extends KamigameVue {
  dateFormat = format;

  loading = false;
  templates: V1WikiPageTemplateCollection = { template: [] };
  fields = [
    { thStyle: { width: '45%' }, key: 'title', label: 'テンプレート名' },
    { key: 'category', label: 'カテゴリ' },
    { key: 'lastUpdatedAt', label: '最終更新日時' },
    { key: 'creator', label: '作成者' },
    { key: 'lastAuthor', label: '最終更新者' },
    { thStyle: { width: '20%' }, key: '_operation', label: '操作' },
  ];
  sortConditions = [
    { text: '最終更新日が新しい順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' } },
    { text: '最終更新日が古い順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'ASC' } },
    { text: 'テンプレート名順', value: { sortedBy: 'title', sortOrder: 'ASC' } },
  ];
  perRequestWikiPageTemplatesNum = 20;
  totalTemplatesNum = 0;
  searchString = '';
  selectedSortCondition = { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' };
  kamigamePaging = this.$refs.kamigamePaging as Paging;
  categorySeclectTemplateItemIndex = 0;

  async mounted() {
    this.kamigamePaging = this.$refs.kamigamePaging as Paging;
    this.getWikiPageTemplates();
  }

  async getWikiPageTemplates(startAt: number = 0) {
    this.loading = true;
    await this.api
      .listWikiPageTemplates(this.wikiName, {
        limit: this.perRequestWikiPageTemplatesNum,
        offset: startAt,
        searchWords: this.searchWords,
        sortedBy: this.selectedSortCondition.sortedBy,
        sortOrder: this.selectedSortCondition.sortOrder,
      })
      .then((res: V1WikiPageTemplateCollection) => {
        this.loading = false;
        this.templates.template = res.template;
        this.totalTemplatesNum = res.numOfTotalTemplates || 0;
        if (startAt === 0) {
          this.kamigamePaging.resetPaging();
        }
      })
      .catch((e) => {
        if (e instanceof RestError && e.statusCode === 404) {
          this.templates.template = [];
          this.loading = false;
        }
      });
  }

  showDeleteModal(index: number) {
    const modal = this.$refs[`alertDelete_${index}`] as BModal;
    modal.show();
  }

  async deleteTemplate(template: V1WikiPageTemplate) {
    if (!template.id) {
      return;
    }

    this.api.deleteWikiPageTemplate(this.wikiName, template.id).then((response) => {
      this.setFlashMessage('success', 'テンプレートを削除しました。');

      if (this.templates.template) {
        this.templates.template = this.templates.template.filter((t) => t.id !== template.id);
      }
    });
  }

  changeDataRange(startAt: number) {
    this.getWikiPageTemplates(startAt);
  }

  onPageCategorySelected(category: V1WikiPageCategory, itemIndex: number) {
    if (!this.templates.template || !this.templates.template[itemIndex] || !this.templates.template[itemIndex].id) {
      return;
    }

    const templateId = this.templates.template[itemIndex].id!;
    this.api.updateLinkedWikiPageTemplateCategory(this.wikiName, templateId, { categoryId: category.id }).then(() => {
      this.setFlashMessage('success', 'カテゴリを設定しました。');
      if (!this.templates.template || !this.templates.template[itemIndex]) {
        return;
      }

      this.$set(this.templates.template[itemIndex], 'category', category);
    });
  }

  unlinkWikiPageCategory(template: V1WikiPageTemplate) {
    if (!template.id) {
      return;
    }

    this.api.updateLinkedWikiPageTemplateCategory(this.wikiName, template.id, { categoryId: '0' }).then(() => {
      this.setFlashMessage('success', 'カテゴリ設定を解除しました。');
      template.category = undefined;
    });
  }

  get searchWords() {
    return convertSearchQueryStringToArray(this.searchString);
  }
}
