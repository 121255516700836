





























import format from 'date-fns/format';

import KamigameVue from '@/KamigameVue';
import { fetchSpreadsheetSheetByTitle, insertRowToSheetByTitle } from '@/service/SpreadsheetApi';
import Component from 'vue-class-component';
import { can } from '@/service/can';

@Component({
  name: 'kamigame-chat-gpt-proofreading',
})
export default class ChatGptProofreed extends KamigameVue {
  proofreadedText = '';
  proofreadingResult = '';

  spreadsheetId = '149ktIiM47ZvwLrZkM_V5XjP6e31tl8h-ExpUw9kSbz8';
  settingSheetName = '設定複数回質問';
  recordSheetName = '複数回記録';

  isProofReading = false;

  async fetchUserMessagesFromSpreadsheet(): Promise<string[][]> {
    const settingSheet = (await fetchSpreadsheetSheetByTitle(this.spreadsheetId, this.settingSheetName)).result
      .values as string[][];

    const minimumLengthOfPreMessages = 2;
    if (settingSheet.length < minimumLengthOfPreMessages) {
      return [];
    }

    return settingSheet.slice(1);
  }

  async proofreadText() {
    this.isProofReading = true;

    try {
      this.proofreadingResult = can(this.$ability, 'ACTION_proofreading_by_chat_gpt_for_staff')
        ? (this.proofreadingResult = await this.getChatGptCompletionsWithRecord())
        : (this.proofreadingResult = await this.getChatGptCompletionsForNonStaff());
    } finally {
      this.isProofReading = false;
    }
  }

  async recordToSpreadsheet(preMessages: { role: string; content: string }[]) {
    const date = format(new Date(), 'yyyy/MM/dd HH:mm:ss');
    const newRecord = [
      date,
      this.user.profile.name,
      this.user.profile.email,
      this.proofreadedText,
      preMessages.map((message) => `${message.role}: ${message.content}`).join('\n---\n'),
      this.proofreadingResult,
    ];

    await insertRowToSheetByTitle(newRecord, this.spreadsheetId, this.recordSheetName);
  }

  async getChatGptCompletionsWithRecord() {
    const userMessages = await this.fetchUserMessagesFromSpreadsheet();

    const initializeSettingMessageNum = 2;
    const messages = userMessages.slice(0, initializeSettingMessageNum).map((row) => {
      const placeholder = '{input}';
      const regexp = new RegExp(placeholder, 'g');
      return {
        role: row[0],
        content: row[1].replace(regexp, this.proofreadedText),
      };
    });

    let res = await this.api.getChatGptCompletions(this.wikiName, {
      model: 'gpt-3.5-turbo',
      messages: messages,
    });

    messages.push({ role: 'assistant', content: res.content || '' });

    const stepMessages = userMessages.slice(initializeSettingMessageNum).map((row) => {
      return {
        role: row[0],
        content: row[1],
      };
    });

    for (let stepMessage of stepMessages) {
      const placeholder = '{completions}';
      stepMessage.content = stepMessage.content.replace(placeholder, res.content || '');
      messages.push(stepMessage);

      res = await this.api.getChatGptCompletions(this.wikiName, {
        model: 'gpt-3.5-turbo',
        messages: messages,
      });
      messages.push({ role: 'assistant', content: res.content || '' });
    }

    await this.recordToSpreadsheet(messages);

    return messages[messages.length - 1].content;
  }

  async getChatGptCompletionsForNonStaff() {
    const res = await this.api.getChatGptCompletionsWithPrompt(this.wikiName, {
      content: this.proofreadedText,
    });

    return res.content || '';
  }
}
