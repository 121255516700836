var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("カスタム CSS")]),
                      ]),
                      _vm._v(" "),
                      _c("b-form-textarea", {
                        attrs: { rows: "10" },
                        model: {
                          value: _vm.css,
                          callback: function ($$v) {
                            _vm.css = $$v
                          },
                          expression: "css",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "footer" }, slot: "footer" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "success" },
                              on: { click: _vm.save },
                            },
                            [_vm._v("保存する")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-unsaved-changes-confirmer", {
        ref: "kamigameUnsavedChangesConfirmer",
        attrs: { existsUnsavedChanges: _vm.existsUnsavedChanges },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }