var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("グローバルナビ設定")]),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "項目をドラッグすると順番の入れ替えができます。"
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "kamigame-sortable-table",
                        {
                          staticClass: "wiki-nav-table",
                          scopedSlots: _vm._u([
                            {
                              key: "row",
                              fn: function (ref) {
                                var id = ref.id
                                var data = ref.data
                                var index = ref.index
                                return [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "outline-danger" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeRow(id)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash",
                                          }),
                                          _vm._v(" 削除"),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("b-input", {
                                        model: {
                                          value: data.name,
                                          callback: function ($$v) {
                                            _vm.$set(data, "name", $$v)
                                          },
                                          expression: "data.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(data.wikiPageTitle)),
                                      ]),
                                      _vm._v(" "),
                                      data.wikiPageTitle
                                        ? _c(
                                            "span",
                                            [
                                              _vm._v("("),
                                              _c(
                                                "b-link",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showSetLinkWikiPageModal(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("リンク先を変更する")]
                                              ),
                                              _vm._v(")"),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "b-modal",
                                        {
                                          ref: "modalSelectWikiPage_" + index,
                                          attrs: {
                                            "hide-header": "",
                                            centered: "",
                                            "no-fade": "",
                                            lazy: "",
                                          },
                                          on: {
                                            ok: function ($event) {
                                              return _vm.setLinkWikiPage(id)
                                            },
                                            hide: _vm.resetSearchModal,
                                          },
                                        },
                                        [
                                          _c(
                                            "kamigame-wiki-page-titles-search-modal",
                                            {
                                              ref: "selectWikiPageTitle",
                                              attrs: {
                                                data: _vm.wikiPageTitles,
                                              },
                                              on: {
                                                wikiPageTitleSelected:
                                                  _vm.onWikiPageTitleSet,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.items,
                            callback: function ($$v) {
                              _vm.items = $$v
                            },
                            expression: "items",
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("thead", { staticClass: "thead-light" }, [
                              _c("tr", [
                                _c("th", { staticClass: "operation" }),
                                _vm._v(" "),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("項目名"),
                                ]),
                                _vm._v(" "),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("リンク先"),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm._v(" "),
                          _c("template", { slot: "footer" }, [
                            _c("tfoot", [
                              _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "3" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "outline-primary" },
                                        on: {
                                          click: _vm.showAddLinkWikiPageModal,
                                        },
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-plus" }),
                                        _vm._v(" 項目を追加"),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-modal",
                                      {
                                        ref: "modalSelectWikiPage_new",
                                        attrs: {
                                          "hide-header": "",
                                          centered: "",
                                          "no-fade": "",
                                          lazy: "",
                                        },
                                        on: {
                                          hide: _vm.resetSearchNewModal,
                                          ok: _vm.addRow,
                                        },
                                      },
                                      [
                                        _c(
                                          "kamigame-wiki-page-titles-search-modal",
                                          {
                                            ref: "selectWikiPageTitleNew",
                                            attrs: { data: _vm.wikiPageTitles },
                                            on: {
                                              wikiPageTitleSelected:
                                                _vm.onWikiPageTitleSet,
                                            },
                                          }
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "footer" }, slot: "footer" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "success" },
                              on: { click: _vm.save },
                            },
                            [_vm._v("保存する")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Can",
                { attrs: { I: "", do: "ACTION_wiki_page_import" } },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-card",
                        {
                          attrs: {
                            "header-tag": "header",
                            "footer-tag": "footer",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("strong", [
                                _vm._v(
                                  "takahiro-games のグローバルナビをインポート"
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "takahiro-games 時代に設定されていたナビメニューからこの Wiki に項目をインポートします。"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _vm._v(
                                "\n              この操作をおこなう前に、先に\n              "
                              ),
                              _c(
                                "b-link",
                                {
                                  attrs: {
                                    to: { name: "wiki_import_takahiro" },
                                  },
                                },
                                [_vm._v("takahiro-games からの記事移行")]
                              ),
                              _vm._v(
                                "\n              を実施してください。\n            "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.takahiroGames.length <= 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-button",
                                    { on: { click: _vm.loadTakahiroMenu } },
                                    [
                                      _vm._v(
                                        "takahiro-games のナビメニュー設定を読み込む"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c("div", [
                                _c(
                                  "p",
                                  [
                                    _c("b-form-select", {
                                      attrs: { options: _vm.takahiroGames },
                                      model: {
                                        value: _vm.selectedImportTakahiroGame,
                                        callback: function ($$v) {
                                          _vm.selectedImportTakahiroGame = $$v
                                        },
                                        expression:
                                          "selectedImportTakahiroGame",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          disabled:
                                            !_vm.selectedImportTakahiroGame,
                                          variant: "success",
                                        },
                                        on: { click: _vm.importFromTakahiro },
                                      },
                                      [_vm._v("インポートする")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-unsaved-changes-confirmer", {
        ref: "kamigameUnsavedChangesConfirmer",
        attrs: { existsUnsavedChanges: _vm.existsUnsavedChanges },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }