















































































































































































import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { Watch } from 'vue-property-decorator';
import { RestError } from '@azure/ms-rest-js';

import KamigameVue from '@/KamigameVue';
import { CategoryTreeSelectModal, Paging } from '@/components';
import {
  V1WikiPageForListCollection,
  V1WikiPageForList,
  V1WikiPageCategory,
  V1WikiPage,
} from '@/api-client/generated/models';
import { start } from 'repl';
import { convertSearchQueryStringToArray } from '@/service/SearchQueryStringConverter';

@Component({
  name: 'wiki-redirected-page-list',
  components: {
    'kamigame-paging': Paging,
    'kamigame-category-tree-select-modal': CategoryTreeSelectModal,
  },
})
export default class WikiPageList extends KamigameVue {
  dateFormat = format;

  loading = false;
  filteredByEmptyCategory = false;

  pages: V1WikiPageForListCollection = { wikiPage: [] };
  fields = [
    { thStyle: { width: '35%' }, key: 'title', label: '記事名' },
    { thStyle: { width: '15%' }, key: 'category', label: 'カテゴリ' },
    { key: 'published', label: '公開状態' },
    { key: 'privilege', label: '編集権限' },
    { key: 'lastUpdatedAt', label: '最終更新日時' },
    { key: 'history', label: '更新履歴' },
    { key: '_operation', label: '操作' },
  ];
  sortConditions = [
    { text: '最終更新日が新しい順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' } },
    { text: '最終更新日が古い順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'ASC' } },
    { text: 'タイトル順', value: { sortedBy: 'title', sortOrder: 'ASC' } },
  ];
  selectedCategory: V1WikiPageCategory = {};
  category: V1WikiPageCategory[] = [];
  perRequestWikiPageNum = 20;
  totalPageNum = 0;
  searchString = '';
  selectedSortCondition = { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' };
  kamigamePaging = this.$refs.kamigamePaging as Paging;

  async mounted() {
    this.kamigamePaging = this.$refs.kamigamePaging as Paging;
    this.getWikiPages();
  }

  async getWikiPages(startAt: number = 0) {
    this.loading = true;

    await this.api
      .listWikiPages(this.wikiName, {
        limit: this.perRequestWikiPageNum,
        offset: startAt,
        searchWords: this.searchWords,
        sortedBy: this.selectedSortCondition.sortedBy,
        sortOrder: this.selectedSortCondition.sortOrder || '',
        categoryId: (this.filteredByEmptyCategory ? '-1' : this.selectedCategory.id) || '',
        onlyRedirected: true,
      })
      .then((res: V1WikiPageForListCollection) => {
        this.loading = false;
        this.pages.wikiPage = (res.wikiPage || []).map((wikiPage: V1WikiPageForList) => {
          if (!wikiPage.wikiPage) {
            return wikiPage;
          }

          if (wikiPage.wikiPage.category) {
            return wikiPage;
          }

          // displayName が存在しないと変更に追従できないためデフォルトでいれておく
          const initCategory: V1WikiPageCategory = { displayName: '' };
          wikiPage.wikiPage.category = initCategory;

          return wikiPage;
        });

        this.totalPageNum = res.numOfTotalWikiPages || 0;
        if (startAt === 0) {
          this.kamigamePaging.resetPaging();
        }
      })
      .catch((e) => {
        if (e instanceof RestError && e.statusCode === 404) {
          this.pages.wikiPage = [];
          this.totalPageNum = 0;
          this.loading = false;
        }
      });
  }

  isPublished(obj: V1WikiPageForList) {
    if (!obj.wikiPage) {
      return false;
    }

    return obj.wikiPage.publishedAt && obj.wikiPage.publishedAt < new Date();
  }

  getWikiUrl(pageId: string) {
    return `${WIKI_URL_BASE}/${encodeURIComponent(this.wikiName)}/page/${pageId}.html`;
  }

  clearCategory() {
    this.selectedCategory = {};
    this.getWikiPages();
  }

  onCategorySaved(category: V1WikiPageCategory) {
    this.selectedCategory = category;
    this.getWikiPages();
  }

  async deletePage(pageId: string, index: number) {
    this.api.deleteWikiPage(this.wikiName, pageId).then(() => {
      if (this.pages.wikiPage === undefined) {
        return;
      }

      this.pages.wikiPage.splice(index, 1);
      this.setFlashMessage('success', 'ページを削除しました。');
    });
  }

  showDeleteModal(index: number) {
    const modal = this.$refs[`deletePage_${index}`] as BModal;
    modal.show();
  }

  changeDataRange(startAt: number) {
    this.getWikiPages(startAt);
  }

  get searchWords() {
    return convertSearchQueryStringToArray(this.searchString);
  }

  @Watch('filteredByEmptyCategory')
  onFilteredByEmptyCategoryChanges() {
    this.getWikiPages();
  }
}
