









import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';
import { V1Wiki } from '@/api-client/generated/models';
import * as StringConverter from '@/service/StringConverter';
import FileSaver from 'file-saver';
import format from 'date-fns/format';

const toCsvBlob = (wikis: V1Wiki[]) => {
  const csvHeader = ['タイトル', 'ゲーム', 'パス', 'NoIndex', 'Private'];

  const csvData = wikis.map((wiki) => {
    const noIndexStr = wiki.noindex ? '有効' : '無効';
    const isPrivateStr = wiki.isPrivate ? '有効' : '無効';

    return [wiki.title || '', wiki.game || '', wiki.name || '', noIndexStr, isPrivateStr];
  });

  const csvRows = [csvHeader, ...csvData];
  const csv = csvRows.map((row) => row.join()).join('\n');

  return new Blob([StringConverter.convertStringToUTF16LEArray(csv)], {
    type: 'text/csv;charset=utf-16',
  });
};

@Component({
  name: 'manage-wiki-list',
})
export default class ManageWikiList extends KamigameVue {
  async exportCsv() {
    const res = await this.api.getAllWiki().catch((e) => {
      console.error(e);
      return undefined;
    });

    if (!res || !res.wiki) {
      this.setFlashMessage('danger', 'Wiki 情報の取得に失敗しました。');
      return;
    }

    const wikis = res.wiki;
    const csvBlob = toCsvBlob(wikis);

    FileSaver.saveAs(csvBlob, 'wiki概要一覧-' + format(new Date(), 'yyyy-MMdd') + '.csv');
  }
}
