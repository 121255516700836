import { KamigameWikiApi } from '@/api-client/generated/kamigameWikiApi';
import { gptMarkdownTranslator } from '@/lib/translator/gpt';
import {
  systemInstruction,
  userInstructionForArticleBody,
  userInstructionForArticleDescription,
  userInstructionForArticleTitle,
} from './gptInstruction';

export const translateArticleBody = async (api: KamigameWikiApi, wikiName: string, body: string) => {
  return gptMarkdownTranslator(api, wikiName, body, systemInstruction, userInstructionForArticleBody);
};

export const translateArticleTitle = async (api: KamigameWikiApi, wikiName: string, title: string) => {
  return gptMarkdownTranslator(api, wikiName, title, systemInstruction, userInstructionForArticleTitle);
};

export const translateArticleDescription = async (api: KamigameWikiApi, wikiName: string, description: string) => {
  return gptMarkdownTranslator(api, wikiName, description, systemInstruction, userInstructionForArticleDescription);
};
