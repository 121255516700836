import { TokenCredentials } from '@azure/ms-rest-js';

import { KamigameWikiApi } from '@/api-client/generated/kamigameWikiApi';

const anonymousToken = 'anonymous';

export function createApiClientWithToken(token?: string): KamigameWikiApi {
  return new KamigameWikiApi(new TokenCredentials(token || anonymousToken), {
    baseUri: `${API_URL_BASE}/`,
    noRetryPolicy: true,
  });
}

export function createApiClientWithTokenByURI(uri: string, token?: string): KamigameWikiApi {
  return new KamigameWikiApi(new TokenCredentials(token || anonymousToken), {
    baseUri: `${uri}/`,
    noRetryPolicy: true,
  });
}
