var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isExternalLink
    ? _c("div", [
        _c(
          "a",
          { class: _vm.classList, attrs: { href: _vm.url } },
          [
            _c("i", { class: _vm.icon }),
            _vm._v(" "),
            _c("span", { staticClass: "nav-item-title" }, [
              _vm._v(_vm._s(_vm.name)),
            ]),
            _vm._v(" "),
            _vm.badge && _vm.badge.text
              ? _c("b-badge", { attrs: { variant: _vm.badge.variant } }, [
                  _vm._v(_vm._s(_vm.badge.text)),
                ])
              : _vm._e(),
          ],
          1
        ),
      ])
    : _c(
        "div",
        [
          _c(
            "router-link",
            { class: _vm.classList, attrs: { to: _vm.url } },
            [
              _c("i", { class: _vm.icon }),
              _vm._v(" "),
              _c("span", { staticClass: "nav-item-title" }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
              _vm._v(" "),
              _vm.badge && _vm.badge.text
                ? _c("b-badge", { attrs: { variant: _vm.badge.variant } }, [
                    _vm._v(_vm._s(_vm.badge.text)),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }