var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("strong", [_vm._v("画像認識（ルール追加画面）")]),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("複数の画像ファイルをアップロードできます。")]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      disabled: _vm.uploading,
                      label: "アップロードする画像ファイル",
                      description:
                        "アップロードする画像ファイルが入ったフォルダを選択します",
                    },
                  },
                  [
                    _c("b-form-file", {
                      attrs: {
                        state: _vm.files.length > 0,
                        placeholder: "ファイルを選択してください",
                        directory: "",
                        multiple: "",
                        accept: "image/*",
                      },
                      on: { change: _vm.handleFileChange },
                      model: {
                        value: _vm.files,
                        callback: function ($$v) {
                          _vm.files = $$v
                        },
                        expression: "files",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.files.length > 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "submit", variant: "primary" },
                            on: { click: _vm.openModal },
                          },
                          [_vm._v("選択したファイル群の文字認識を行う")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("main-image-modal", {
                  ref: "mainImageModal",
                  attrs: { file: _vm.files, imageUrl: _vm.imageUrl },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }