var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("strong", [_vm._v("記事中で使用できるドメイン一覧")]),
                ]),
                _vm._v(" "),
                _c(
                  "Can",
                  {
                    attrs: { I: "", do: "ACTION_wiki_domain_allowlist_create" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "add-domain-input-and-button-div" },
                      [
                        _c(
                          "div",
                          { staticClass: "add-domain-input-wrapper" },
                          [
                            _c("b-input", {
                              model: {
                                value: _vm.inputDomain,
                                callback: function ($$v) {
                                  _vm.inputDomain = $$v
                                },
                                expression: "inputDomain",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary" },
                            on: { click: _vm.handleAddDomain },
                          },
                          [
                            _c("i", { staticClass: "fa fa-plus" }),
                            _vm._v(" ドメインを追加\n            "),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("b-table", {
                  attrs: {
                    fields: _vm.tableFields,
                    items: _vm.tableItems,
                    "sort-by": _vm.sortBy,
                    "sort-desc": _vm.sortDesc,
                    "sort-icon-left": "",
                  },
                  on: {
                    "update:sortBy": function ($event) {
                      _vm.sortBy = $event
                    },
                    "update:sort-by": function ($event) {
                      _vm.sortBy = $event
                    },
                    "update:sortDesc": function ($event) {
                      _vm.sortDesc = $event
                    },
                    "update:sort-desc": function ($event) {
                      _vm.sortDesc = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(_operation)",
                      fn: function (data) {
                        return [
                          _c(
                            "Can",
                            {
                              attrs: {
                                I: "",
                                do: "ACTION_wiki_domain_allowlist_delete",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "outline-danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemoveDomain(
                                        data.item._id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-trash-o" }),
                                  _vm._v(" 削除\n              "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }