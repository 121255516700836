import { Route, RouteConfig } from 'vue-router';

import Dashboard from '@/views/Dashboard.vue';
import Setting from '@/views/Setting.vue';
import WikiAccessAnalytics from '@/views/Wiki/WikiAccessAnalytics.vue';
import WikiAllowedIPAddressConfig from '@/views/Wiki/WikiAllowedIPAddressConfig.vue';
import WikiArchiveConfig from '@/views/Wiki/WikiArchiveConfig.vue';
import WikiBaseConfig from '@/views/Wiki/WikiBaseConfig.vue';
import WikiCustomCss from '@/views/Wiki/WikiCustomCss.vue';
import WikiCustomHeader from '@/views/Wiki/WikiCustomHeader.vue';
import WikiDataCommon from '@/views/WikiData/WikiDataCommon.vue';
import WikiDataSpreadsheetSync from '@/views/WikiData/WikiDataSpreadsheetSync.vue';
import WikiDeletedPageList from '@/views/WikiPage/WikiDeletedPageList.vue';
import WikiDomainAllowlist from '@/views/Wiki/WikiDomainAllowlist.vue';
import WikiEditPage from '@/views/WikiPage/WikiEditPage.vue';
import WikiIconAndHeaderImage from '@/views/Wiki/WikiIconAndHeaderImage.vue';
import WikiTooltip from '@/views/Wiki/WikiTooltip.vue';
import WikiImageBulkUpload from '@/views/WikiPage/WikiImageBulkUpload.vue';
import WikiImportTakahiro from '@/views/Wiki/WikiImportTakahiro.vue';
import WikiMemberInviteList from '@/views/WikiMember/WikiMemberInviteList.vue';
import WikiMemberList from '@/views/WikiMember/WikiMemberList.vue';
import WikiMenuEdit from '@/views/Wiki/WikiMenuEdit.vue';
import WikiNav from '@/views/Wiki/WikiNav.vue';
import WikiPageCategoryCreate from '@/views/WikiPageCategory/WikiPageCategoryCreate.vue';
import WikiPageCategoryList from '@/views/WikiPageCategory/WikiPageCategoryList.vue';
import WikiPageDraftList from '@/views/WikiPage/WikiPageDraftList.vue';
import WikiPageHistory from '@/views/WikiPage/WikiPageHistory.vue';
import WikiPageHistoryList from '@/views/WikiPage/WikiPageHistoryList.vue';
import WikiPageHistoryWrapper from '@/views/WikiPage/WikiPageHistoryWrapper.vue';
import WikiPageImport from '@/views/WikiPage/WikiPageImport.vue';
import WikiPageList from '@/views/WikiPage/WikiPageList.vue';
import WikiPageSearch from '@/views/WikiPage/WikiPageSearch.vue';
import WikiPagePartialEdit from '@/views/WikiPage/WikiPagePartialEdit.vue';
import WikiPagePartialList from '@/views/WikiPage/WikiPagePartialList.vue';
import WikiPageTemplateEdit from '@/views/WikiPage/WikiPageTemplateEdit.vue';
import WikiPageTemplateList from '@/views/WikiPage/WikiPageTemplateList.vue';
import WikiPublishConfig from '@/views/Wiki/WikiPublishConfig.vue';
import WikiSlackConfig from '@/views/Wiki/WikiSlackConfig.vue';
import WikiRedirectedPageList from '@/views/WikiPage/WikiRedirectedPageList.vue';
import WikiReport from '@/views/WorkReport.vue';
import WikiTemplateCode from '@/views/Wiki/WikiTemplateCode.vue';
import WikiPageAdviceList from '@/views/Wiki/WikiPageAdviceList.vue';
import WikiScreenshotScanner from '@/views/Wiki/WikiScreenshotScanner.vue';
import ChatGPTProofreading from '@/views/Tool/ChatGptProofreading.vue';
import ImageScannerOcrDemo from '@/views/Tool/ImageScannerOcrDemo.vue';
import SetUpGameVillage from '@/views/Tool/SetUpGameVillage.vue';

const routes = [
  {
    path: '/:name/dashboard',
    component: Dashboard,
    meta: {
      label: 'ダッシュボード',
    },
    name: 'wiki_dashboard',
  },
  {
    path: '/:name/setting',
    component: Setting,
    meta: {
      label: '設定',
      requiredPermission: 'ACTION_wiki_meta_update',
    },
    name: 'wiki_setting',
  },
  {
    path: '/:name/data/common',
    component: WikiDataCommon,
    meta: {
      label: 'データ編集',
    },
    name: 'wiki_data_common',
  },
  {
    path: '/:name/data/spreadsheet-sync',
    component: WikiDataSpreadsheetSync,
    meta: {
      label: 'データ編集（内部向け）',
    },
    name: 'wiki_data_spreadsheet_sync',
  },
  {
    path: '/:name/member',
    component: WikiMemberList,
    meta: {
      label: 'Wiki メンバー管理',
      requiredPermission: 'ACTION_wiki_member_list_read',
    },
    name: 'wiki_member_list',
  },
  {
    path: '/:name/member/invite',
    component: WikiMemberInviteList,
    meta: {
      label: 'Wiki メンバー招待',
      requiredPermission: 'ACTION_wiki_member_list_read',
    },
    name: 'wiki_member_invite_list',
  },
  {
    path: '/:name/page/new',
    component: WikiEditPage,
    meta: {
      label: '新規記事作成',
      requiredPermission: 'ACTION_wiki_page_create',
    },
    name: 'wiki_page_create',
  },
  {
    path: '/:name/page/edit/:paths+',
    component: WikiEditPage,
    meta: {
      label: 'ページ編集',
      requiredPermission: 'ACTION_wiki_page_update',
    },
    name: 'wiki_page_edit',
  },
  {
    path: '/:name/page/import',
    component: WikiPageImport,
    meta: {
      label: '記事一括作成・更新',
      requiredPermission: 'ACTION_wiki_page_import',
    },
    name: 'wiki_page_import',
  },
  {
    path: '/:name/page/image-upload',
    component: WikiImageBulkUpload,
    meta: {
      label: '画像一括アップロード',
      requiredPermission: 'ACTION_image_upload',
    },
    name: 'wiki_image_bulk_upload',
  },
  {
    path: '/:name/page',
    component: WikiPageList,
    meta: {
      label: '記事一覧',
    },
    name: 'wiki_page_list',
  },
  {
    path: '/:name/page/search',
    component: WikiPageSearch,
    meta: {
      label: '全記事検索',
    },
    name: 'wiki_page_search',
  },
  {
    path: '/:name/draft',
    component: WikiPageDraftList,
    meta: {
      label: '下書き一覧',
    },
    name: 'wiki_page_draft_list',
  },
  {
    path: '/:name/deleted-page',
    component: WikiDeletedPageList,
    meta: {
      label: '削除記事一覧',
    },
    name: 'wiki_deleted_page_list',
  },
  {
    path: '/:name/template',
    component: WikiPageTemplateList,
    meta: {
      label: '記事テンプレート一覧',
      requiredPermission: 'ACTION_wiki_page_template_use',
    },
    name: 'wiki_page_template_list',
  },
  {
    path: '/:name/template/:templateId',
    component: WikiPageTemplateEdit,
    meta: {
      label: '記事テンプレート編集',
    },
    name: 'wiki_page_template_edit',
  },
  {
    path: '/:name/history/',
    component: WikiPageHistoryWrapper,
    name: 'wiki_history_wrapper',
    meta: {
      label: '編集履歴一覧',
    },
    redirect: { name: 'wiki_history_list' },
    children: [
      {
        path: '',
        component: WikiPageHistoryList,
        name: 'wiki_history_list',
        meta: {
          label: '編集履歴一覧',
          index: true,
        },
      },
      {
        path: 'diff/:b/:a?',
        component: WikiPageHistory,
        meta: {
          label: '編集履歴',
        },
        name: 'wiki_history',
      },
    ],
  },
  {
    path: '/:name/page/:pageId/history',
    component: WikiPageHistoryWrapper,
    name: 'wiki_page_history_wrapper',
    redirect: { name: 'wiki_page_history_list' },
    meta: {
      label: 'ページ編集履歴一覧',
    },
    children: [
      {
        path: '',
        component: WikiPageHistoryList,
        name: 'wiki_page_history_list',
        meta: {
          label: 'ページ編集履歴一覧',
          index: true,
        },
      },
      {
        path: 'diff/:b/:a?',
        component: WikiPageHistory,
        meta: {
          label: 'ページ編集履歴',
        },
        name: 'wiki_page_history',
      },
    ],
  },
  {
    path: '/:name/page-partial/:target(archived)?',
    component: WikiPagePartialList,
    meta: {
      label: '記事部品一覧',
      requiredPermission: 'ACTION_wiki_page_partial_use',
    },
    name: 'wiki_page_partial_list',
  },
  {
    path: '/:name/page-partial/new',
    component: WikiPagePartialEdit,
    meta: {
      label: '記事部品作成',
      requiredPermission: 'ACTION_wiki_page_partial_create',
    },
    name: 'wiki_page_partial_new',
  },
  {
    path: '/:name/page-partial/edit/:wikiPagePartialID',
    component: WikiPagePartialEdit,
    meta: {
      label: '記事部品編集',
    },
    name: 'wiki_page_partial_edit',
  },
  {
    path: '/:name/redirected-page',
    component: WikiRedirectedPageList,
    meta: {
      label: 'リダイレクト済み',
    },
    name: 'wiki_redirected_page_list',
  },
  {
    path: '/:name/base-config',
    component: WikiBaseConfig,
    meta: {
      label: '基本情報設定',
      requiredPermission: 'ACTION_wiki_update',
    },
    name: 'wiki_base_config',
  },
  {
    path: '/:name/publish-config',
    component: WikiPublishConfig,
    meta: {
      label: '公開設定',
      requiredPermission: 'ACTION_wiki_publish_config_update',
    },
    name: 'wiki_publish_config',
  },
  {
    path: '/:name/slack-config',
    component: WikiSlackConfig,
    meta: {
      label: 'Slack通知設定',
      requiredPermission: 'ACTION_wiki_update',
    },
    name: 'wiki_slack_config',
  },
  {
    path: '/:name/access-analytics',
    component: WikiAccessAnalytics,
    meta: {
      label: 'Wiki アクセス数',
      requiredPermission: 'ACTION_wiki_access_analytics_read',
    },
    name: 'wiki_access_analytics',
  },
  {
    path: '/:name/wiki-page-advice',
    component: WikiPageAdviceList,
    meta: {
      label: '記事指摘',
      requiredPermission: 'ACTION_wiki_page_update',
    },
    name: 'wiki_page_advice_list',
  },
  {
    path: '/:name/page/category',
    component: WikiPageCategoryList,
    meta: {
      label: 'カテゴリ一覧',
    },
    name: 'wiki_page_category_list',
  },
  {
    path: '/:name/page/category/new',
    component: WikiPageCategoryCreate,
    meta: {
      label: 'Wiki ページカテゴリ作成',
    },
    name: 'wiki_page_category_create',
  },
  {
    path: '/:name/nav',
    component: WikiNav,
    meta: {
      label: 'グローバルナビ設定',
      requiredPermission: 'ACTION_wiki_meta_update',
    },
    name: 'wiki_nav',
  },
  {
    path: '/:name/custom-header',
    component: WikiCustomHeader,
    meta: {
      label: 'カスタムヘッダー設定',
      requiredPermission: 'ACTION_wiki_meta_update',
    },
    name: 'wiki_custom_header',
  },
  {
    path: '/:name/custom-css',
    component: WikiCustomCss,
    meta: {
      label: 'カスタム CSS 設定',
      requiredPermission: 'ACTION_wiki_custom_css_update',
    },
    name: 'wiki_custom_css',
  },
  {
    path: '/:name/meta/:target(left-menu|right-menu|priority-menu|copyright|custom-footer)',
    component: WikiMenuEdit,
    meta: {
      lazyLabel: (route: Route) => {
        if (!route.params) {
          return '';
        }
        const prefix = new Map<string, string>([
          ['left-menu', '左メニュー設定'],
          ['right-menu', '右メニュー設定'],
          ['priority-menu', '優先メニュー設定'],
          ['copyright', '権利表記設定'],
          ['custom-footer', 'カスタムフッター設定'],
        ]);
        return prefix.get(route.params.target) || '';
      },
      requiredPermission: 'ACTION_wiki_meta_update',
    },
    name: 'wiki_edit_menu',
  },
  {
    path: '/:name/icon-header-image',
    component: WikiIconAndHeaderImage,
    meta: {
      label: '画像設定',
      requiredPermission: 'ACTION_wiki_meta_update',
    },
    name: 'wiki_icon_and_header_image',
  },
  {
    path: '/:name/screenshot-scanner',
    component: WikiScreenshotScanner,
    meta: {
      label: '画像認識設定',
      requiredPermission: 'ACTION_wiki_meta_update',
    },
    name: 'wiki_screenshot_scanner',
  },
  {
    path: '/:name/tooltip',
    component: WikiTooltip,
    meta: {
      label: 'ツールチップ設定',
      requiredPermission: 'ACTION_wiki_meta_update',
    },
    name: 'wiki_tooltip',
  },
  {
    path: '/:name/allowed-ip',
    component: WikiAllowedIPAddressConfig,
    meta: {
      label: 'IP アドレス設定',
      requiredPermission: 'ACTION_wiki_allowed_ip_address_configure',
    },
    name: 'wiki_allowed_ip_address_config',
  },
  {
    path: '/:name/archive-config',
    component: WikiArchiveConfig,
    meta: {
      label: 'アーカイブ設定',
      requiredPermission: 'ACTION_wiki_config_is_archived',
    },
    name: 'wiki_archive_config',
  },
  {
    path: '/:name/domain-allowlist',
    component: WikiDomainAllowlist,
    meta: {
      label: 'ドメイン許可設定',
      requiredPermission: 'ACTION_wiki_domain_allowlist_read',
    },
    name: 'wiki_domain_allowlist',
  },
  {
    path: '/:name/work-report',
    component: WikiReport,
    meta: {
      label: '作業レポート出力',
      requiredPermission: 'ACTION_wiki_work_report_use',
    },
    name: 'wiki_work_report',
  },
  {
    path: '/:name/import-takahiro',
    component: WikiImportTakahiro,
    meta: {
      label: '記事インポート',
      requiredPermission: 'ACTION_takahiro',
    },
    name: 'wiki_import_takahiro',
  },
  {
    path: '/:name/template-code',
    component: WikiTemplateCode,
    meta: {
      label: 'テンプレートコード実行',
      requiredPermission: 'ACTION_takahiro',
    },
    name: 'wiki_template_code',
  },
  {
    path: '/:name/tool/chatgpt-proofreading',
    component: ChatGPTProofreading,
    meta: {
      label: 'ChatGPT校正ツール',
    },
    name: 'chatgpt_proofreading',
  },
  {
    path: '/:name/tool/image-scanner-ocr-demo',
    component: ImageScannerOcrDemo,
    meta: {
      label: 'Image Scanner OCR Demo',
      requiredPermission: 'ACTION_wiki_update',
    },
    name: 'image_scanner_ocr_demo',
  },
] as RouteConfig[];

if (LOCALE === 'en') {
  routes.push({
    path: '/:name/tool/set-up-gamevillage',
    component: SetUpGameVillage,
    meta: {
      label: 'GameVillage 立ち上げ',
      requiredPermission: 'ACTION_set_up_gamevillage',
    },
  });
}

export default routes;
