







































import Component from 'vue-class-component';
import FileUploadWithPreview from 'file-upload-with-preview';

import KamigameVue from '@/KamigameVue';
import MainImageModal from '../../components/screenshotScanner/MainImageModal.vue';

@Component({
  components: {
    'main-image-modal': MainImageModal,
  },
})
export default class WikiScreenshotScanner extends KamigameVue {
  iconUpload?: FileUploadWithPreview;
  iconUploadDisabled = true;
  iconUploading = false;
  files: File[] = [];
  imageUrl = '';
  uploading: boolean = false;

  hasUploadedIcon = false;
  hasUploadedHeader = false;

  existsUnsavedIcon = false;
  existsUnsavedHeader = false;

  mounted() {
    this.iconUpload = new FileUploadWithPreview('icon');

    window.addEventListener('fileUploadWithPreview:imagesAdded', (e: any) => {
      if (e.detail.uploadId === 'icon') {
        this.iconUploadDisabled = false;
        this.hasUploadedIcon = true;
        this.existsUnsavedIcon = true;
      } else if (e.detail.uploadId === 'header') {
        this.hasUploadedHeader = true;
        this.existsUnsavedHeader = true;
      }
    });

    const iconUploaderPreview = document.getElementById('iconUploaderPreview');
    if (iconUploaderPreview) {
      iconUploaderPreview.addEventListener('kamigame:fileDropped', (e: any) => {
        if (e.detail.files && this.iconUpload) {
          this.iconUpload.addFiles(e.detail.files);
        }
      });
    }
  }

  openModal() {
    const modal = this.$refs.mainImageModal as any;
    modal.show();
  }

  handleFileChange(event: Event) {
    const selectedFile = (event.target as HTMLInputElement).files![0];
    this.imageUrl = URL.createObjectURL(selectedFile);
  }
}
