
























import Component from 'vue-class-component';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';

@Component({
  name: 'wiki-custom-css',
  components: {
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiCustomCss extends KamigameVue {
  css: string = '';
  savedCss: string = '';

  mounted() {
    this.api.getWikiCustomCss(this.wikiName).then(res => {
      this.css = res.css || '';
      this.savedCss = this.css;
    });
  }

  async save() {
    this.api
      .updateWikiCustomCss(this.wikiName, {
        css: this.css,
      })
      .then(() => {
        this.savedCss = this.css;
        this.setFlashMessage(
          'success',
          'カスタム CSS の設定が完了しました。',
          this.wikiTopUrl,
          'サイト上で内容を確認する',
          true
        );
      })
      .catch(() => {
        this.setFlashMessage('danger', 'カスタム CSS の設定に失敗しました。');
      });
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    return this.css !== this.savedCss;
  }
}
