





















import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';
import { V1Wiki } from '@/api-client/generated/models';

@Component({
  name: 'wiki-archive-config',
  components: {},
})
export default class WikiBaseConfig extends KamigameVue {
  isArchived: boolean = false;
  wiki: V1Wiki = {};

  mounted() {
    this.$store.getters.getWiki(this.wikiName).then((wiki: V1Wiki) => {
      this.wiki = wiki;
      this.isArchived = !!wiki.isArchived;
    });
  }

  async toggleArchive() {
    const newIsArchived = !this.isArchived;

    this.wiki.isArchived = newIsArchived;
    try {
      await this.api.updateWikiIsArchived(this.wiki.name ?? '', { isArchived: newIsArchived });
    } catch (e) {
      console.error(e);
      this.setFlashMessage('danger', 'アーカイブ設定の変更に失敗しました');
      return;
    }

    this.$store.commit('setWiki', { wiki: this.wiki });

    const route = this.$router.resolve({ name: 'wiki_dashboard', params: { name: this.wiki.name! } });
    window.location.href = route.href;
  }
}
