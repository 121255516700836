










































































































































import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { RestError } from '@azure/ms-rest-js';

import KamigameVue from '@/KamigameVue';
import { CategoryTreeSelectModal, Paging } from '@/components';
import { V1WikiPageCategory, V1WikiPageDraftCollection, V1WikiPageDraft } from '@/api-client/generated/models';

type SortedBy = 'lastUpdatedAt' | 'title';
type SortOrder = 'ASC' | 'DESC';
type SortCondition = { sortedBy: SortedBy; sortOrder: SortOrder };

@Component({
  name: 'wiki-page-draft-list',
  components: {
    'kamigame-category-tree-select-modal': CategoryTreeSelectModal,
    'kamigame-paging': Paging,
  },
})
export default class WikiPageDraftList extends KamigameVue {
  dateFormat = format;

  loading = false;
  filteredByKeywords = false;
  filteredByEmptyCategory = false;

  drafts: V1WikiPageDraft[] = [];
  fields = [
    { thStyle: { width: '35%' }, key: 'title', label: '記事名' },
    { thStyle: { width: '15%' }, key: 'category', label: 'カテゴリ' },
    { key: 'privilege', label: '編集権限' },
    { key: 'lastUpdatedAt', label: '最終更新日時' },
    { key: '_operation', label: '操作' },
  ];
  sortConditions: { text: string; value: SortCondition }[] = [
    { text: '最終更新日が新しい順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' } },
    { text: '最終更新日が古い順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'ASC' } },
    { text: 'タイトル順', value: { sortedBy: 'title', sortOrder: 'ASC' } },
  ];
  selectedCategory: V1WikiPageCategory = {};
  perPageNum = 20;
  inputWords = '';
  searchWords = '';
  selectedSortCondition: SortCondition = { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' };
  startAt = 0;

  async mounted() {
    this.loading = true;

    try {
      const res = await this.api.listWikiPageDraft(this.wikiName);
      this.drafts = res.drafts || [];
    } catch (e) {
      if (e instanceof RestError && e.statusCode === 404) {
        this.drafts = [];
      }
    } finally {
      this.loading = false;
    }
  }

  clearCategory() {
    this.selectedCategory = {};
  }

  onCategorySelected(category: V1WikiPageCategory) {
    this.selectedCategory = category;
  }

  async deleteDraft(pageId: string, index: number) {
    await this.api.deleteWikiPageDraft(this.wikiName, pageId);
    this.drafts.splice(index, 1);
    this.setFlashMessage('success', '下書きを削除しました。');
  }

  showDeleteModal(index: number) {
    const modal = this.$refs[`deleteDraft_${index}`] as BModal;
    modal.show();
  }

  changeDataRange(startAt: number) {
    this.startAt = startAt;
  }

  get totalPageNum() {
    return this.drafts.length || 0;
  }

  get endAt() {
    return this.startAt + this.perPageNum;
  }

  get selectedCategoryId() {
    return this.selectedCategory.id ?? '';
  }

  get pagedDrafts() {
    const categoryFilter = (draft: V1WikiPageDraft) => {
      if (this.filteredByEmptyCategory) {
        return !draft.category;
      }

      if (this.selectedCategory.id) {
        return draft.category?.id === this.selectedCategory.id;
      }

      return true;
    };

    const keywordFilter = (draft: V1WikiPageDraft) => {
      return draft.title?.includes(this.searchWords);
    };

    const sortFn = (a: V1WikiPageDraft, b: V1WikiPageDraft) => {
      const diff = {
        lastUpdatedAt: () => {
          if (!a.updatedAt || !b.updatedAt) {
            return 0;
          }

          return a.updatedAt.getTime() - b.updatedAt.getTime();
        },
        title: () => {
          if (!a.title || !b.title) {
            return 0;
          }

          return a.title.localeCompare(b.title);
        },
      }[this.selectedSortCondition.sortedBy]();

      return this.selectedSortCondition.sortOrder === 'ASC' ? diff : -diff;
    };

    return this.drafts.filter(d => categoryFilter(d) && keywordFilter(d)).sort(sortFn);
  }
}
