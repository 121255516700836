var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn wiki-page-history" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c(
                  "div",
                  { attrs: { slot: "header" }, slot: "header" },
                  [
                    _c("strong", [_vm._v("ページ編集履歴")]),
                    _vm._v(" ["),
                    _vm.pageId
                      ? _c(
                          "b-link",
                          {
                            attrs: {
                              to: {
                                name: "wiki_page_edit",
                                params: { paths: _vm.pageId },
                              },
                            },
                          },
                          [_vm._v("この記事を編集する")]
                        )
                      : _vm._e(),
                    _vm._v("]\n          "),
                    _c(
                      "table",
                      { staticClass: "table table-responsive-sm mt-3" },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("編集者")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("最終更新日時")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.lastUpdater))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.dateFormat(
                                    _vm.lastUpdatedAt,
                                    "yyyy/MM/dd HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "table",
                  _vm._l(_vm.diffLines, function (line, index) {
                    return _c("tr", { key: index, class: line.type }, [
                      _c("td", [_vm._v(_vm._s(line.value || " "))]),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }