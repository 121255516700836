




























































































































































import Component from 'vue-class-component';
import LiquorTree from 'vls-liquor-tree';
import { BModal } from 'bootstrap-vue';
import { RestError } from '@azure/ms-rest-js';

import KamigameVue from '@/KamigameVue';
import { WikiPageTitleSearchModal, Paging } from '@/components';
import {
  V1WikiPageCategoryCollection,
  V1WikiPageCategory,
  V1WikiPageTitleCollection,
  V1WikiPageTitle,
} from '@/api-client/generated/models';
import { convertSearchQueryStringToArray } from '@/service/SearchQueryStringConverter';

@Component({
  name: 'wiki-page-category-list',
  components: {
    [LiquorTree.name]: LiquorTree,
    'kamigame-wiki-page-titles-search-modal': WikiPageTitleSearchModal,
    'kamigame-paging': Paging,
  },
})
export default class WikiPageCategoryList extends KamigameVue {
  loading = false;
  updatedCategoryName = '';
  filter = '';
  wikiPageTitles: V1WikiPageTitle[] = [];
  selectedWikiPageTitle: V1WikiPageTitle = {};
  categories: V1WikiPageCategoryCollection = { wikiPageCategories: [] };
  parentCategories: V1WikiPageCategoryCollection = { wikiPageCategories: [] };
  categoryIndexSettingParentCategory = 0;
  categoryTreeFilter = '';
  categoryTreeOptions = {
    filter: {
      emptyText: '指定されたカテゴリが見つかりませんでした',
      matcher: (query: string, node: any) => {
        return node.data.name.startsWith(query);
      },
    },
  };

  fields = [
    { thStyle: { width: '35%' }, key: 'name', label: 'カテゴリ名' },
    { thStyle: { width: '30%' }, key: 'parent_name', label: '親カテゴリ' },
    { key: 'category_page', label: 'カテゴリ記事' },
    { key: '_operation', label: '操作' },
  ];
  sortConditions = [{ text: 'カテゴリ名順', value: { sortedBy: 'name', sortOrder: 'ASC' } }];

  perRequestWikiPageCategoriesNum = 20;
  totalCategoriesNum = 0;
  searchString = '';
  selectedSortCondition = { sortedBy: 'name', sortOrder: 'ASC' };
  kamigamePaging = this.$refs.kamigamePaging as Paging;

  async mounted() {
    this.kamigamePaging = this.$refs.kamigamePaging as Paging;
    this.getWikiPageCategories();
    this.getParentWikiPageCategories();
  }

  async getWikiPageCategories(startAt: number = 0) {
    this.loading = true;
    await this.api
      .listWikiPageCategory(this.wikiName, {
        limit: this.perRequestWikiPageCategoriesNum,
        offset: startAt,
        searchWords: this.searchWords,
        sortedBy: this.selectedSortCondition.sortedBy,
        sortOrder: this.selectedSortCondition.sortOrder,
      })
      .then((res: V1WikiPageCategoryCollection) => {
        this.loading = false;
        this.categories.wikiPageCategories = (res.wikiPageCategories || []).map((c) => {
          // Vue.js needs empty property to track its changes (omitted properties will not be worked)
          return Object.assign({ wikiPageTitle: '' }, c);
        });
        this.totalCategoriesNum = res.numOfTotalWikiPageCategories || 0;
        if (startAt === 0) {
          this.kamigamePaging.resetPaging();
        }
      })
      .catch((e) => {
        if (e instanceof RestError && e.statusCode === 404) {
          this.categories.wikiPageCategories = [];
          this.loading = false;
        }
      });
  }

  async getParentWikiPageCategories() {
    const response = await this.api.listParentWikiPageCategory(this.wikiName);
    this.parentCategories.wikiPageCategories = response.wikiPageCategories;
  }

  changeDataRange(startAt: number) {
    this.getWikiPageCategories(startAt);
  }

  async updateCategoryName(item: V1WikiPageCategory) {
    if (!item.id) {
      return;
    }

    const updatedDisplayName =
      item.parentID && item.displayName
        ? `${this.getParentCategoryName(item)}/${this.updatedCategoryName}`
        : this.updatedCategoryName;
    const category = {
      name: this.updatedCategoryName,
      displayName: updatedDisplayName,
      wikiPageID: item.wikiPageID,
      parentID: item.parentID,
    };
    this.api
      .updateWikiPageCategory(this.wikiName, item.id, category)
      .then(() => {
        item.name = this.updatedCategoryName;
        item.displayName = updatedDisplayName;

        (this.categories.wikiPageCategories || []).forEach((c: V1WikiPageCategory) => {
          if (c.parentID !== item.id) {
            return;
          }
          c.displayName = `${item.name}/${c.name}`;
        });

        this.setFlashMessage('success', 'カテゴリを更新しました');
      })
      .catch(() => {
        this.setFlashMessage('danger', 'カテゴリの更新に失敗しました');
      });
  }

  async deleteCategory(index: number, item: V1WikiPageCategory) {
    if (!item.id) {
      return;
    }

    this.api.deleteWikiPageCategory(this.wikiName, item.id).then(() => {
      if (this.categories.wikiPageCategories === undefined) {
        return;
      }

      this.categories.wikiPageCategories.splice(index, 1);
      this.setFlashMessage('success', 'カテゴリを削除しました');
    });
  }

  async setCategoryPage(item: V1WikiPageCategory) {
    if (!this.selectedWikiPageTitle) {
      return;
    }

    const category = {
      name: item.name,
      displayName: item.displayName,
      wikiPageID: this.selectedWikiPageTitle.id,
      parentID: item.parentID,
    };
    this.api.updateWikiPageCategory(this.wikiName, item.id || '0', category).then(() => {
      item.wikiPageTitle = this.selectedWikiPageTitle.title;

      this.setFlashMessage('success', 'カテゴリに記事を設定しました');
    });
  }

  onWikiPageTitleSet(selectedWikiPageTitle: V1WikiPageTitle) {
    this.selectedWikiPageTitle = selectedWikiPageTitle;
  }

  async showSetCategoryPageModal(index: number) {
    if (!this.wikiPageTitles || this.wikiPageTitles.length === 0) {
      await this.api.listWikiPageTitles(this.wikiName).then((response) => {
        if (!response.wikiPageTitles) {
          return;
        }

        this.wikiPageTitles = response.wikiPageTitles;
      });
    }

    const modal = this.$refs[`alertSettingPage_${index}`] as BModal;
    modal.show();
  }

  showUpdateModal(index: number) {
    const modal = this.$refs[`alertUpdate_${index}`] as BModal;
    modal.show();
  }

  showDeleteModal(index: number) {
    const modal = this.$refs[`alertDelete_${index}`] as BModal;
    modal.show();
  }

  getParentCategoryName(category: V1WikiPageCategory) {
    if (!category.displayName) {
      return;
    }

    return category.parentID ? category.displayName.split('/').slice(0, -1).join('/') : '';
  }

  removeParentCategory(category: V1WikiPageCategory) {
    const newDisplayName = (category.displayName || '').split('/').pop();
    const param = {
      name: category.name,
      displayName: newDisplayName,
      wikiPageID: category.wikiPageID,
    };

    this.api.updateWikiPageCategory(this.wikiName, category.id || '0', param).then(() => {
      category.displayName = newDisplayName;
      category.parentID = undefined;

      this.setFlashMessage('success', '親カテゴリを解除しました');
    });
  }

  onParentCategorySelected(node: any) {
    (this.$refs.setParentCategoryModal as BModal).hide();

    const parentCategory = node.data;
    const item = (this.categories.wikiPageCategories || [])[this.categoryIndexSettingParentCategory];
    if (!item) {
      return;
    }

    const newDisplayName = `${parentCategory.displayName}/${item.displayName}`;
    const param = {
      name: item.name,
      displayName: newDisplayName,
      wikiPageID: this.selectedWikiPageTitle.id,
      parentID: parentCategory.id,
    };

    this.api
      .updateWikiPageCategory(this.wikiName, item.id || '0', param)
      .then(() => {
        item.displayName = newDisplayName;
        item.parentID = parentCategory.id;

        this.setFlashMessage('success', '親カテゴリを設定しました');
      })
      .catch((e) => {
        this.setFlashMessage(
          'danger',
          '親カテゴリの設定に失敗しました。「自分に子カテゴリが存在する」「親に設定しようとしているカテゴリに親がいる」場合親カテゴリを設定できません'
        );
        console.error(e);
      });
  }

  get wikiPageTitleTree() {
    return this.$refs.wikiPageTitles as any;
  }

  openSetParentCategoryModal(index: number) {
    this.categoryIndexSettingParentCategory = index;
    const categories = this.categories.wikiPageCategories || [];
    const selectedCategory = categories[index];
    if (!selectedCategory) {
      return;
    }

    const parentCategories = (this.parentCategories.wikiPageCategories ?? []).map((c) => {
      return {
        id: c.id,
        text: c.name,
        data: c,
        isBatch: false,
      };
    });

    // LiquorTree の data プロパティがリアクティブではないので作者が提案しているやり方で対応
    // ref: https://github.com/amsik/liquor-tree/issues/119#issuecomment-486148265
    (this.$refs.tree as any).setModel(parentCategories);

    (this.$refs.setParentCategoryModal as BModal).show();
  }

  get searchWords() {
    return convertSearchQueryStringToArray(this.searchString);
  }
}
