


import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';

@Component({
  name: 'kamigame-unsaved-changes-confirmer',
  components: {},
})
export default class UnsavedChangesConfirmer extends KamigameVue {
  @Prop()
  existsUnsavedChanges!: boolean;

  beforeMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload(e: any) {
    if (!this.existsUnsavedChanges) {
      return;
    }

    e.preventDefault();
    e.returnValue = '変更が保存されていませんが、このページから離れますか？';
  }

  handleBeforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    if (!this.existsUnsavedChanges) {
      next();
      return;
    }

    if (window.confirm('変更が保存されていませんが、このページから離れますか？')) {
      next();
    }
  }
}
