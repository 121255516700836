




















































import KamigameVue from '@/KamigameVue';
import format from 'date-fns/format';
import Component from 'vue-class-component';

interface TableItem {
  id: string;
  domainName: string;
  creatorNickname: string;
  createdAt: string;
}

@Component({
  name: 'global-domain-allowlist',
  components: {},
})
export default class GlobalDomainAllowlist extends KamigameVue {
  tableFields = [
    { key: 'domainName', label: 'ドメイン名', sortable: true },
    { key: 'creatorNickname', label: '追加者', sortable: true },
    { key: 'createdAt', label: '追加日時', sortable: true },
    { key: '_operation', label: '操作' },
  ];
  tableItems: TableItem[] = [];
  perPage = 20;
  currentPage = 1;
  sortBy = 'createdAt';
  sortDesc = true;

  inputDomain: string = '';

  async mounted(): Promise<void> {
    const listGlobalDomainAllowlistResponse = await this.api.listGlobalDomainAllowlist().catch(() => {
      this.$bvToast.toast(`ドメインリストの取得に失敗しました`, {
        title: '取得',
        autoHideDelay: 5000,
        variant: 'danger',
      });
    });
    if (!listGlobalDomainAllowlistResponse) {
      console.error('listGlobalDomainAllowlistResponse is falsy');
      return;
    }

    this.tableItems = listGlobalDomainAllowlistResponse.globalAllowedDomains.map((globalAllowedDomain) => {
      const tableItem: TableItem = {
        id: globalAllowedDomain.id,
        domainName: globalAllowedDomain.name,
        creatorNickname: globalAllowedDomain.creator?.nickname ?? '',
        createdAt: format(globalAllowedDomain.createdAt, 'yyyy/MM/dd HH:mm:ss'),
      };
      return tableItem;
    });
  }

  async handleAddDomain(): Promise<void> {
    if (this.inputDomain === '') {
      return;
    }

    const allowedDomain = await this.api.createGlobalDomainAllowlist({ domain: this.inputDomain }).catch((e: Error) => {
      console.error(e);
      if (e.message.includes('Duplicate entry')) {
        this.$bvToast.toast(`すでに登録されているドメインです`, {
          title: '追加',
          autoHideDelay: 5000,
          variant: 'warning',
        });
        return;
      }

      this.$bvToast.toast(`ドメインの追加に失敗しました`, {
        title: '追加',
        autoHideDelay: 5000,
        variant: 'danger',
      });
    });
    if (!allowedDomain) {
      console.error('allowedDomain is falsy');
      return;
    }

    this.tableItems.push({
      id: allowedDomain.id,
      domainName: allowedDomain.name,
      creatorNickname: allowedDomain.creator?.nickname ?? '',
      createdAt: format(allowedDomain.createdAt, 'yyyy/MM/dd HH:mm:ss'),
    });
    this.inputDomain = '';
    this.$bvToast.toast(`「${allowedDomain.name}」を追加しました`, {
      title: '追加',
      autoHideDelay: 5000,
      variant: 'success',
    });
  }

  async handleRemoveDomain(id: string): Promise<void> {
    const deletedItem = this.tableItems.find((tableItem) => tableItem.id === id);
    if (!deletedItem) {
      console.error('deletedItem is falsy');
      return;
    }

    const deleteGlobalDomainAllowlistResponse = await this.api.deleteGlobalDomainAllowlist(id).catch(() => {
      this.$bvToast.toast(`ドメインの削除に失敗しました`, {
        title: '削除',
        autoHideDelay: 5000,
        variant: 'danger',
      });
    });
    if (!deleteGlobalDomainAllowlistResponse) {
      console.error('deleteGlobalDomainAllowlistResponse is falsy');
      return;
    }

    this.tableItems = this.tableItems.filter((tableItem) => tableItem.id !== id);
    this.$bvToast.toast(`「${deletedItem.domainName}」を削除しました`, {
      title: '削除',
      autoHideDelay: 5000,
      variant: 'success',
    });
  }
}
