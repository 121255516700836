


















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'setting'
})
export default class Setting extends Vue {
}
