




















import Component from 'vue-class-component';
import LiquorTree from 'vls-liquor-tree';
import { Prop } from 'vue-property-decorator';

import KamigameVue from '@/KamigameVue';
import { V1WikiPageTitle } from '@/api-client/generated/models';

@Component({
  name: 'kamigame-wiki-page-title-search-modal',
  components: {
    [LiquorTree.name]: LiquorTree,
  },
})
export default class WikiPageTitleSearchModal extends KamigameVue {
  @Prop()
  data!: V1WikiPageTitle[];

  searchString = '';
  filter = '';
  wikiPageTitles: any[] = [];
  wikiPageTitlesOptions = {
    filter: {
      emptyText: '指定されたページが見つかりませんでした',
      matcher: (query: string, node: any) => node.text.includes(query),
    },
  };

  created() {
    if (!this.data) {
      return;
    }

    this.wikiPageTitles = this.data.map(e => {
      return this.transformWikiPageTitlesToTreeItem(e);
    });
  }

  transformWikiPageTitlesToTreeItem(wikiPageTitle: V1WikiPageTitle) {
    return {
      text: wikiPageTitle.title,
      data: wikiPageTitle,
    };
  }

  focusTree(event: KeyboardEvent) {
    if (event.isComposing) {
      return;
    }

    const firstNode = this.wikiPageTitleTree.find({ state: { visible: true } })[0];
    if (!firstNode) {
      return;
    }

    firstNode.vm.focus();
  }

  onWikiPageTitleSelected(node: any) {
    this.searchString = node.text;

    this.$emit('wikiPageTitleSelected', node.data);
  }

  onSearchStringChanged() {
    const input = this.$refs.input as Vue;
    if (document.activeElement !== input.$el) {
      return;
    }

    this.filter = this.searchString;
  }

  reset() {
    this.filter = '';
    this.searchString = '';

    const selected = this.wikiPageTitleTree.selected();
    if (selected) {
      selected.unselect();
    }
  }

  get wikiPageTitleTree() {
    return this.$refs.wikiPageTitles as any;
  }
}
