










































































































































































import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import VueRouter from 'vue-router';

import { V1WikiCustomHeader, V1ListWikiCustomHeaderWithWiki, V1WikiPageTitle } from '@/api-client/generated/models';
import { SortableTable } from '@/components';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';
import KamigameVue from '@/KamigameVue';

import { WikiPageTitleSearchModal } from '@/components';
import { Watch } from 'vue-property-decorator';

@Component({
  name: 'wiki-custom-header',
  components: {
    'kamigame-sortable-table': SortableTable,
    'kamigame-wiki-page-titles-search-modal': WikiPageTitleSearchModal,
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiCustomHeader extends KamigameVue {
  headerTitle: string = '';
  items: [symbol, V1WikiCustomHeader][] = [];
  wikiPageTitles: V1WikiPageTitle[] = [];
  selectedWikiPageTitle: V1WikiPageTitle = {};
  takahiroCustomHeaderBody: string = '';
  inputtedModalURL: string = '';
  inputtedModalTitle: string = '';

  savedHeaderTitle: string = '';
  draftItems: V1WikiCustomHeader[] = [];
  savedItems: V1WikiCustomHeader[] = [];

  @Watch('items', { deep: true })
  onUpdateItems(newValue: [symbol, V1WikiCustomHeader][]) {
    this.draftItems = JSON.parse(JSON.stringify(newValue.map(([_, v]) => v)));
  }

  async mounted() {
    this.api.listWikiCustomHeader(this.wikiName).then(response => {
      this.headerTitle = response.title || '';
      this.items = (response.customHeader || []).map(
        customHeader => [Symbol(), customHeader] as [symbol, V1WikiCustomHeader]
      );

      this.savedHeaderTitle = this.headerTitle;
      const itemsJson = JSON.stringify(this.items.map(([_, v]) => v));
      this.savedItems = JSON.parse(itemsJson);
      this.draftItems = JSON.parse(itemsJson);
    });

    const wikiConfig = await this.api.getWiki(this.wikiName);
    const takahiroCustomHeader = (wikiConfig?.markdownTextWidget || []).filter(w => w.name === 'custom-header').pop();
    if (takahiroCustomHeader) {
      this.takahiroCustomHeaderBody = takahiroCustomHeader.body || '';
    }
  }

  addRow() {
    this.items.push([
      Symbol(),
      {
        name: this.inputtedModalTitle || this.selectedWikiPageTitle.title,
        wikiPageID: this.selectedWikiPageTitle.id,
        wikiPageTitle: this.selectedWikiPageTitle.title,
        url: this.inputtedModalURL,
      },
    ]);

    this.selectedWikiPageTitle = {};
    this.inputtedModalURL = '';
    this.inputtedModalTitle = '';
  }

  removeRow(id: symbol) {
    const renew = new Map(this.items);
    const toBeRemoved = renew.get(id);
    if (
      !toBeRemoved ||
      !window.confirm(
        `本当にこの項目を削除してもよろしいですか？\n項目名: ${toBeRemoved.name}\nリンク先: ${toBeRemoved.wikiPageTitle}`
      )
    ) {
      return;
    }
    renew.delete(id);

    this.items = Array.from(renew.entries());
  }

  async save() {
    const headerList: V1ListWikiCustomHeaderWithWiki = {
      customHeader: Array.from(new Map(this.items).values()).map((v, k) => Object.assign(v, { sortScore: k })),
      title: this.headerTitle,
    };
    this.api.updateWikiCustomHeader(this.wikiName, headerList).then(() => {
      this.savedHeaderTitle = this.headerTitle;
      const itemsJson = JSON.stringify(this.items.map(([_, v]) => v));
      this.savedItems = JSON.parse(itemsJson);
      this.draftItems = JSON.parse(itemsJson);
      this.setFlashMessage('success', '変更を保存しました。', this.wikiTopUrl, 'サイト上で内容を確認する', true);
    });
  }

  async deleteCustomHeaderTakahiro() {
    if (!window.confirm('本当に削除してもよろしいですか？ (この操作を取り消すことはできません)')) {
      return;
    }

    this.api.updateWikiMetaMediumText(this.wikiName, { name: 'custom-header', body: '' }).then(() => {
      this.takahiroCustomHeaderBody = '';
      this.setFlashMessage('success', '変更を保存しました。', this.wikiTopUrl, 'サイト上で内容を確認する', true);
    });
  }

  async loadWikiPageTitles() {
    if (this.wikiPageTitles && this.wikiPageTitles.length > 0) {
      return;
    }

    return this.api.listWikiPageTitles(this.wikiName).then(response => {
      if (!response.wikiPageTitles) {
        return;
      }

      this.wikiPageTitles = response.wikiPageTitles;
    });
  }

  async showAddLinkWikiPageModal() {
    await this.loadWikiPageTitles();

    const modal = this.$refs.modalSelectWikiPage_new as BModal;
    modal.show();
  }

  async showSetLinkWikiPageModal(index: number) {
    await this.loadWikiPageTitles();

    const modal = this.$refs[`modalSelectWikiPage_${index}`] as BModal;
    modal.show();
  }

  onWikiPageTitleSet(selectedWikiPageTitle: V1WikiPageTitle) {
    this.inputtedModalTitle = '';
    this.inputtedModalURL = '';
    this.selectedWikiPageTitle = selectedWikiPageTitle;
  }

  setLinkWikiPage(id: symbol) {
    const items = new Map(this.items);
    const toBeChangedLinkPage = items.get(id);
    if (!toBeChangedLinkPage) {
      return;
    }

    toBeChangedLinkPage.name = this.inputtedModalTitle || this.selectedWikiPageTitle.title;
    toBeChangedLinkPage.wikiPageID = this.selectedWikiPageTitle.id;
    toBeChangedLinkPage.wikiPageTitle = this.selectedWikiPageTitle.title;
    toBeChangedLinkPage.url = this.inputtedModalURL;

    this.items = Array.from(items.entries());
    this.selectedWikiPageTitle = {};
    this.inputtedModalURL = '';
    this.inputtedModalTitle = '';
  }

  resetSearchModal() {
    this.selectedWikiPageTitle = {};
    this.inputtedModalURL = '';
    this.inputtedModalTitle = '';
    (this.$refs.selectWikiPageTitle as WikiPageTitleSearchModal).reset();
  }

  resetSearchNewModal() {
    (this.$refs.selectWikiPageTitleNew as WikiPageTitleSearchModal).reset();
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    if (this.headerTitle !== this.savedHeaderTitle) {
      return true;
    }

    if (this.draftItems.length !== this.savedItems.length) {
      return true;
    }

    for (let i = 0; i < this.draftItems.length; ++i) {
      if (
        this.draftItems[i].name !== this.savedItems[i].name ||
        this.draftItems[i].wikiPageID !== this.savedItems[i].wikiPageID ||
        this.draftItems[i].wikiPageTitle !== this.savedItems[i].wikiPageTitle ||
        this.draftItems[i].url !== this.savedItems[i].url
      ) {
        return true;
      }
    }

    return false;
  }

  get isWikiPageTitleSelected() {
    return Object.keys(this.selectedWikiPageTitle).length > 0;
  }
}
