/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from "@azure/ms-rest-js";


export const BulkTakahiroWikiPageCategoryRequestCategory: msRest.CompositeMapper = {
  serializedName: "BulkTakahiroWikiPageCategoryRequestCategory",
  type: {
    name: "Composite",
    className: "BulkTakahiroWikiPageCategoryRequestCategory",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parentName: {
        serializedName: "parentName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ColumnNameListPerSheetsColumns: msRest.CompositeMapper = {
  serializedName: "ColumnNameListPerSheetsColumns",
  type: {
    name: "Composite",
    className: "ColumnNameListPerSheetsColumns",
    modelProperties: {
      columns: {
        serializedName: "columns",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const DataRow: msRest.CompositeMapper = {
  serializedName: "DataRow",
  type: {
    name: "Composite",
    className: "DataRow",
    modelProperties: {
      row: {
        serializedName: "row",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const GetChatGptCompletionsRequestmessage: msRest.CompositeMapper = {
  serializedName: "GetChatGptCompletionsRequestmessage",
  type: {
    name: "Composite",
    className: "GetChatGptCompletionsRequestmessage",
    modelProperties: {
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WikiAccessAnalyticsOneEntry: msRest.CompositeMapper = {
  serializedName: "WikiAccessAnalyticsOneEntry",
  type: {
    name: "Composite",
    className: "WikiAccessAnalyticsOneEntry",
    modelProperties: {
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      num: {
        serializedName: "num",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WikiDataSheetsData: msRest.CompositeMapper = {
  serializedName: "WikiDataSheetsData",
  type: {
    name: "Composite",
    className: "WikiDataSheetsData",
    modelProperties: {
      rows: {
        serializedName: "rows",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DataRow"
            }
          }
        }
      }
    }
  }
};

export const ProtobufAny: msRest.CompositeMapper = {
  serializedName: "protobufAny",
  type: {
    name: "Composite",
    className: "ProtobufAny",
    modelProperties: {
      typeUrl: {
        serializedName: "typeUrl",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const RpcStatus: msRest.CompositeMapper = {
  serializedName: "rpcStatus",
  type: {
    name: "Composite",
    className: "RpcStatus",
    modelProperties: {
      code: {
        serializedName: "code",
        type: {
          name: "Number"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProtobufAny"
            }
          }
        }
      }
    }
  }
};

export const V1AdjustSpeculativeWikiDataSheetsRequest: msRest.CompositeMapper = {
  serializedName: "v1AdjustSpeculativeWikiDataSheetsRequest",
  type: {
    name: "Composite",
    className: "V1AdjustSpeculativeWikiDataSheetsRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      primaryID: {
        serializedName: "primaryID",
        type: {
          name: "String"
        }
      },
      cancellationID: {
        serializedName: "cancellationID",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const V1BulkCreateWikiPageRequestArticle: msRest.CompositeMapper = {
  serializedName: "v1BulkCreateWikiPageRequestArticle",
  type: {
    name: "Composite",
    className: "V1BulkCreateWikiPageRequestArticle",
    modelProperties: {
      file: {
        serializedName: "file",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "String"
        }
      },
      editPermission: {
        serializedName: "editPermission",
        type: {
          name: "String"
        }
      },
      publishedAt: {
        serializedName: "publishedAt",
        type: {
          name: "DateTime"
        }
      },
      keywords: {
        serializedName: "keywords",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      spreadsheetURL: {
        serializedName: "spreadsheetURL",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      noindex: {
        serializedName: "noindex",
        type: {
          name: "Boolean"
        }
      },
      metaOgpImageURL: {
        serializedName: "metaOgpImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageURL: {
        serializedName: "metaThumbnailImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageAutoSelect: {
        serializedName: "metaThumbnailImageAutoSelect",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1BulkCreateWikiPageRequestBody: msRest.CompositeMapper = {
  serializedName: "v1BulkCreateWikiPageRequestBody",
  type: {
    name: "Composite",
    className: "V1BulkCreateWikiPageRequestBody",
    modelProperties: {
      articles: {
        serializedName: "articles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1BulkCreateWikiPageRequestArticle"
            }
          }
        }
      }
    }
  }
};

export const V1BulkCreateWikiPageResultResult: msRest.CompositeMapper = {
  serializedName: "v1BulkCreateWikiPageResultResult",
  type: {
    name: "Composite",
    className: "V1BulkCreateWikiPageResultResult",
    modelProperties: {
      file: {
        serializedName: "file",
        type: {
          name: "String"
        }
      },
      error: {
        serializedName: "error",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1BulkCreateWikiPageResult: msRest.CompositeMapper = {
  serializedName: "v1BulkCreateWikiPageResult",
  type: {
    name: "Composite",
    className: "V1BulkCreateWikiPageResult",
    modelProperties: {
      result: {
        serializedName: "result",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1BulkCreateWikiPageResultResult"
            }
          }
        }
      }
    }
  }
};

export const V1BulkTakahiroWikiPageCategoryRequestBody: msRest.CompositeMapper = {
  serializedName: "v1BulkTakahiroWikiPageCategoryRequestBody",
  type: {
    name: "Composite",
    className: "V1BulkTakahiroWikiPageCategoryRequestBody",
    modelProperties: {
      categories: {
        serializedName: "categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "BulkTakahiroWikiPageCategoryRequestCategory"
            }
          }
        }
      }
    }
  }
};

export const V1BulkTakahiroWikiPageCategoryResultResult: msRest.CompositeMapper = {
  serializedName: "v1BulkTakahiroWikiPageCategoryResultResult",
  type: {
    name: "Composite",
    className: "V1BulkTakahiroWikiPageCategoryResultResult",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      error: {
        serializedName: "error",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1BulkTakahiroWikiPageCategoryResult: msRest.CompositeMapper = {
  serializedName: "v1BulkTakahiroWikiPageCategoryResult",
  type: {
    name: "Composite",
    className: "V1BulkTakahiroWikiPageCategoryResult",
    modelProperties: {
      result: {
        serializedName: "result",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1BulkTakahiroWikiPageCategoryResultResult"
            }
          }
        }
      }
    }
  }
};

export const V1BulkTakahiroWikiPageRequestArticle: msRest.CompositeMapper = {
  serializedName: "v1BulkTakahiroWikiPageRequestArticle",
  type: {
    name: "Composite",
    className: "V1BulkTakahiroWikiPageRequestArticle",
    modelProperties: {
      file: {
        serializedName: "file",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      editPermission: {
        serializedName: "editPermission",
        type: {
          name: "String"
        }
      },
      publishedAt: {
        serializedName: "publishedAt",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      keywords: {
        serializedName: "keywords",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      spreadsheetURL: {
        serializedName: "spreadsheetURL",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      noindex: {
        serializedName: "noindex",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1BulkTakahiroWikiPageRequestBody: msRest.CompositeMapper = {
  serializedName: "v1BulkTakahiroWikiPageRequestBody",
  type: {
    name: "Composite",
    className: "V1BulkTakahiroWikiPageRequestBody",
    modelProperties: {
      articles: {
        serializedName: "articles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1BulkTakahiroWikiPageRequestArticle"
            }
          }
        }
      }
    }
  }
};

export const V1BulkTakahiroWikiPageResultResult: msRest.CompositeMapper = {
  serializedName: "v1BulkTakahiroWikiPageResultResult",
  type: {
    name: "Composite",
    className: "V1BulkTakahiroWikiPageResultResult",
    modelProperties: {
      file: {
        serializedName: "file",
        type: {
          name: "String"
        }
      },
      error: {
        serializedName: "error",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1BulkTakahiroWikiPageResult: msRest.CompositeMapper = {
  serializedName: "v1BulkTakahiroWikiPageResult",
  type: {
    name: "Composite",
    className: "V1BulkTakahiroWikiPageResult",
    modelProperties: {
      result: {
        serializedName: "result",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1BulkTakahiroWikiPageResultResult"
            }
          }
        }
      }
    }
  }
};

export const V1BulkUpdateCategoryOfWikiPageRequestBody: msRest.CompositeMapper = {
  serializedName: "v1BulkUpdateCategoryOfWikiPageRequestBody",
  type: {
    name: "Composite",
    className: "V1BulkUpdateCategoryOfWikiPageRequestBody",
    modelProperties: {
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "String"
        }
      },
      pageId: {
        serializedName: "pageId",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const V1BulkUpdateWikiPageRequestArticle: msRest.CompositeMapper = {
  serializedName: "v1BulkUpdateWikiPageRequestArticle",
  type: {
    name: "Composite",
    className: "V1BulkUpdateWikiPageRequestArticle",
    modelProperties: {
      file: {
        serializedName: "file",
        type: {
          name: "String"
        }
      },
      pageId: {
        serializedName: "pageId",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "String"
        }
      },
      editPermission: {
        serializedName: "editPermission",
        type: {
          name: "String"
        }
      },
      publishedAt: {
        serializedName: "publishedAt",
        type: {
          name: "DateTime"
        }
      },
      keywords: {
        serializedName: "keywords",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      spreadsheetURL: {
        serializedName: "spreadsheetURL",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      noindex: {
        serializedName: "noindex",
        type: {
          name: "Boolean"
        }
      },
      metaOgpImageURL: {
        serializedName: "metaOgpImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageURL: {
        serializedName: "metaThumbnailImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageAutoSelect: {
        serializedName: "metaThumbnailImageAutoSelect",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1BulkUpdateWikiPageRequestBody: msRest.CompositeMapper = {
  serializedName: "v1BulkUpdateWikiPageRequestBody",
  type: {
    name: "Composite",
    className: "V1BulkUpdateWikiPageRequestBody",
    modelProperties: {
      articles: {
        serializedName: "articles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1BulkUpdateWikiPageRequestArticle"
            }
          }
        }
      }
    }
  }
};

export const V1BulkUpdateWikiPageResultResult: msRest.CompositeMapper = {
  serializedName: "v1BulkUpdateWikiPageResultResult",
  type: {
    name: "Composite",
    className: "V1BulkUpdateWikiPageResultResult",
    modelProperties: {
      file: {
        serializedName: "file",
        type: {
          name: "String"
        }
      },
      error: {
        serializedName: "error",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1BulkUpdateWikiPageResult: msRest.CompositeMapper = {
  serializedName: "v1BulkUpdateWikiPageResult",
  type: {
    name: "Composite",
    className: "V1BulkUpdateWikiPageResult",
    modelProperties: {
      result: {
        serializedName: "result",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1BulkUpdateWikiPageResultResult"
            }
          }
        }
      }
    }
  }
};

export const V1ColumnNameListPerSheets: msRest.CompositeMapper = {
  serializedName: "v1ColumnNameListPerSheets",
  type: {
    name: "Composite",
    className: "V1ColumnNameListPerSheets",
    modelProperties: {
      sheets: {
        serializedName: "sheets",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Composite",
              className: "ColumnNameListPerSheetsColumns"
            }
          }
        }
      }
    }
  }
};

export const V1RealtimeDatabaseRule: msRest.CompositeMapper = {
  serializedName: "v1RealtimeDatabaseRule",
  type: {
    name: "Composite",
    className: "V1RealtimeDatabaseRule",
    modelProperties: {
      sheetName: {
        serializedName: "sheetName",
        type: {
          name: "String"
        }
      },
      indexOn: {
        serializedName: "indexOn",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const V1ConfigureRealtimeDatabaseRuleRequest: msRest.CompositeMapper = {
  serializedName: "v1ConfigureRealtimeDatabaseRuleRequest",
  type: {
    name: "Composite",
    className: "V1ConfigureRealtimeDatabaseRuleRequest",
    modelProperties: {
      wikiName: {
        serializedName: "wikiName",
        type: {
          name: "String"
        }
      },
      rules: {
        serializedName: "rules",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1RealtimeDatabaseRule"
            }
          }
        }
      }
    }
  }
};

export const V1CreateGlobalDomainAllowlistRequestBody: msRest.CompositeMapper = {
  serializedName: "v1CreateGlobalDomainAllowlistRequestBody",
  type: {
    name: "Composite",
    className: "V1CreateGlobalDomainAllowlistRequestBody",
    modelProperties: {
      domain: {
        required: true,
        serializedName: "domain",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiDataChangelogSheet: msRest.CompositeMapper = {
  serializedName: "v1WikiDataChangelogSheet",
  type: {
    name: "Composite",
    className: "V1WikiDataChangelogSheet",
    modelProperties: {
      spreadsheetId: {
        serializedName: "spreadsheetId",
        type: {
          name: "String"
        }
      },
      sheetName: {
        serializedName: "sheetName",
        type: {
          name: "String"
        }
      },
      cellCount: {
        serializedName: "cellCount",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1CreateWikiDataChangelogRequest: msRest.CompositeMapper = {
  serializedName: "v1CreateWikiDataChangelogRequest",
  type: {
    name: "Composite",
    className: "V1CreateWikiDataChangelogRequest",
    modelProperties: {
      wikiName: {
        serializedName: "wikiName",
        type: {
          name: "String"
        }
      },
      sheets: {
        serializedName: "sheets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiDataChangelogSheet"
            }
          }
        }
      }
    }
  }
};

export const V1CreateWikiDomainAllowlistRequestBody: msRest.CompositeMapper = {
  serializedName: "v1CreateWikiDomainAllowlistRequestBody",
  type: {
    name: "Composite",
    className: "V1CreateWikiDomainAllowlistRequestBody",
    modelProperties: {
      domain: {
        required: true,
        serializedName: "domain",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiMeta: msRest.CompositeMapper = {
  serializedName: "v1WikiMeta",
  type: {
    name: "Composite",
    className: "V1WikiMeta",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiNav: msRest.CompositeMapper = {
  serializedName: "v1WikiNav",
  type: {
    name: "Composite",
    className: "V1WikiNav",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      wikiPageID: {
        serializedName: "wikiPageID",
        type: {
          name: "String"
        }
      },
      wikiPageTitle: {
        serializedName: "wikiPageTitle",
        type: {
          name: "String"
        }
      },
      sortScore: {
        serializedName: "sortScore",
        type: {
          name: "Number"
        }
      },
      wikiPageCanonicalName: {
        serializedName: "wikiPageCanonicalName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiCustomHeader: msRest.CompositeMapper = {
  serializedName: "v1WikiCustomHeader",
  type: {
    name: "Composite",
    className: "V1WikiCustomHeader",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      wikiPageID: {
        serializedName: "wikiPageID",
        type: {
          name: "String"
        }
      },
      wikiPageTitle: {
        serializedName: "wikiPageTitle",
        type: {
          name: "String"
        }
      },
      wikiPageCanonicalName: {
        serializedName: "wikiPageCanonicalName",
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      sortScore: {
        serializedName: "sortScore",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1ListWikiCustomHeaderWithWiki: msRest.CompositeMapper = {
  serializedName: "v1ListWikiCustomHeaderWithWiki",
  type: {
    name: "Composite",
    className: "V1ListWikiCustomHeaderWithWiki",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      customHeader: {
        serializedName: "customHeader",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiCustomHeader"
            }
          }
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiMarkdownTextWidget: msRest.CompositeMapper = {
  serializedName: "v1WikiMarkdownTextWidget",
  type: {
    name: "Composite",
    className: "V1WikiMarkdownTextWidget",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1Wiki: msRest.CompositeMapper = {
  serializedName: "v1Wiki",
  type: {
    name: "Composite",
    className: "V1Wiki",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      game: {
        serializedName: "game",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      threadId: {
        serializedName: "threadId",
        type: {
          name: "String"
        }
      },
      meta: {
        serializedName: "meta",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiMeta"
            }
          }
        }
      },
      nav: {
        serializedName: "nav",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiNav"
            }
          }
        }
      },
      customHeader: {
        serializedName: "customHeader",
        type: {
          name: "Composite",
          className: "V1ListWikiCustomHeaderWithWiki"
        }
      },
      iconImgURL: {
        serializedName: "iconImgURL",
        type: {
          name: "String"
        }
      },
      headerImgURL: {
        serializedName: "headerImgURL",
        type: {
          name: "String"
        }
      },
      noindex: {
        serializedName: "noindex",
        type: {
          name: "Boolean"
        }
      },
      isPrivate: {
        serializedName: "isPrivate",
        type: {
          name: "Boolean"
        }
      },
      isRootPath: {
        serializedName: "isRootPath",
        type: {
          name: "Boolean"
        }
      },
      titleSuffix: {
        serializedName: "titleSuffix",
        type: {
          name: "String"
        }
      },
      teamName: {
        serializedName: "teamName",
        type: {
          name: "String"
        }
      },
      teamSNSURL: {
        serializedName: "teamSNSURL",
        type: {
          name: "String"
        }
      },
      markdownTextWidget: {
        serializedName: "markdownTextWidget",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiMarkdownTextWidget"
            }
          }
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      shouldSitemapShrink: {
        serializedName: "shouldSitemapShrink",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1CreateWikiMemberInviteRequest: msRest.CompositeMapper = {
  serializedName: "v1CreateWikiMemberInviteRequest",
  type: {
    name: "Composite",
    className: "V1CreateWikiMemberInviteRequest",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      limitCount: {
        serializedName: "limitCount",
        type: {
          name: "String"
        }
      },
      expiredAt: {
        serializedName: "expiredAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const V1CreateWikiMemberRequestRequest: msRest.CompositeMapper = {
  serializedName: "v1CreateWikiMemberRequestRequest",
  type: {
    name: "Composite",
    className: "V1CreateWikiMemberRequestRequest",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1CreateWikiPagePartialRequest: msRest.CompositeMapper = {
  serializedName: "v1CreateWikiPagePartialRequest",
  type: {
    name: "Composite",
    className: "V1CreateWikiPagePartialRequest",
    modelProperties: {
      wikiName: {
        serializedName: "wikiName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      columns: {
        serializedName: "columns",
        type: {
          name: "Composite",
          className: "V1ColumnNameListPerSheets"
        }
      }
    }
  }
};

export const V1CreateWikiPageRequestBody: msRest.CompositeMapper = {
  serializedName: "v1CreateWikiPageRequestBody",
  type: {
    name: "Composite",
    className: "V1CreateWikiPageRequestBody",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "String"
        }
      },
      editPermission: {
        serializedName: "editPermission",
        type: {
          name: "String"
        }
      },
      publishedAt: {
        serializedName: "publishedAt",
        type: {
          name: "DateTime"
        }
      },
      keywords: {
        serializedName: "keywords",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      spreadsheetURL: {
        serializedName: "spreadsheetURL",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      noindex: {
        serializedName: "noindex",
        type: {
          name: "Boolean"
        }
      },
      metaOgpImageURL: {
        serializedName: "metaOgpImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageURL: {
        serializedName: "metaThumbnailImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageAutoSelect: {
        serializedName: "metaThumbnailImageAutoSelect",
        type: {
          name: "Boolean"
        }
      },
      titlePrefix: {
        serializedName: "titlePrefix",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1CreateWikiPageTemplateRequestBody: msRest.CompositeMapper = {
  serializedName: "v1CreateWikiPageTemplateRequestBody",
  type: {
    name: "Composite",
    className: "V1CreateWikiPageTemplateRequestBody",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "String"
        }
      },
      keywords: {
        serializedName: "keywords",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1CreateWikiRequest: msRest.CompositeMapper = {
  serializedName: "v1CreateWikiRequest",
  type: {
    name: "Composite",
    className: "V1CreateWikiRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        constraints: {
          MaxLength: 24,
          Pattern: /^[^\s\/]+$/
        },
        type: {
          name: "String"
        }
      },
      game: {
        serializedName: "game",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1EditWikiPageDraftRequestBody: msRest.CompositeMapper = {
  serializedName: "v1EditWikiPageDraftRequestBody",
  type: {
    name: "Composite",
    className: "V1EditWikiPageDraftRequestBody",
    modelProperties: {
      lastAuthorId: {
        serializedName: "lastAuthorId",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "String"
        }
      },
      keywords: {
        serializedName: "keywords",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      spreadsheetURL: {
        serializedName: "spreadsheetURL",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      noindex: {
        serializedName: "noindex",
        type: {
          name: "Boolean"
        }
      },
      metaOgpImageURL: {
        serializedName: "metaOgpImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageURL: {
        serializedName: "metaThumbnailImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageAutoSelect: {
        serializedName: "metaThumbnailImageAutoSelect",
        type: {
          name: "Boolean"
        }
      },
      titlePrefix: {
        serializedName: "titlePrefix",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1EditWikiPagePartialRequest: msRest.CompositeMapper = {
  serializedName: "v1EditWikiPagePartialRequest",
  type: {
    name: "Composite",
    className: "V1EditWikiPagePartialRequest",
    modelProperties: {
      wikiName: {
        serializedName: "wikiName",
        type: {
          name: "String"
        }
      },
      wikiPagePartialName: {
        serializedName: "wikiPagePartialName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      columns: {
        serializedName: "columns",
        type: {
          name: "Composite",
          className: "V1ColumnNameListPerSheets"
        }
      }
    }
  }
};

export const V1EditWikiPageRequestBody: msRest.CompositeMapper = {
  serializedName: "v1EditWikiPageRequestBody",
  type: {
    name: "Composite",
    className: "V1EditWikiPageRequestBody",
    modelProperties: {
      authorId: {
        serializedName: "authorId",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "String"
        }
      },
      editPermission: {
        serializedName: "editPermission",
        type: {
          name: "String"
        }
      },
      publishedAt: {
        serializedName: "publishedAt",
        type: {
          name: "DateTime"
        }
      },
      keywords: {
        serializedName: "keywords",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      spreadsheetURL: {
        serializedName: "spreadsheetURL",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      noindex: {
        serializedName: "noindex",
        type: {
          name: "Boolean"
        }
      },
      metaOgpImageURL: {
        serializedName: "metaOgpImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageURL: {
        serializedName: "metaThumbnailImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageAutoSelect: {
        serializedName: "metaThumbnailImageAutoSelect",
        type: {
          name: "Boolean"
        }
      },
      titlePrefix: {
        serializedName: "titlePrefix",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1EditWikiPageTemplateRequestBody: msRest.CompositeMapper = {
  serializedName: "v1EditWikiPageTemplateRequestBody",
  type: {
    name: "Composite",
    className: "V1EditWikiPageTemplateRequestBody",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "String"
        }
      },
      keywords: {
        serializedName: "keywords",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1NullableBool: msRest.CompositeMapper = {
  serializedName: "v1NullableBool",
  type: {
    name: "Composite",
    className: "V1NullableBool",
    modelProperties: {
      value: {
        serializedName: "value",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1EditWikiPublishConfigRequest: msRest.CompositeMapper = {
  serializedName: "v1EditWikiPublishConfigRequest",
  type: {
    name: "Composite",
    className: "V1EditWikiPublishConfigRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      noindex: {
        serializedName: "noindex",
        type: {
          name: "Composite",
          className: "V1NullableBool"
        }
      },
      isPrivate: {
        serializedName: "isPrivate",
        type: {
          name: "Composite",
          className: "V1NullableBool"
        }
      },
      isRootPath: {
        serializedName: "isRootPath",
        type: {
          name: "Composite",
          className: "V1NullableBool"
        }
      },
      shouldSitemapShrink: {
        serializedName: "shouldSitemapShrink",
        type: {
          name: "Composite",
          className: "V1NullableBool"
        }
      }
    }
  }
};

export const V1EditWikiRequest: msRest.CompositeMapper = {
  serializedName: "v1EditWikiRequest",
  type: {
    name: "Composite",
    className: "V1EditWikiRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      titleSuffix: {
        serializedName: "titleSuffix",
        type: {
          name: "String"
        }
      },
      teamName: {
        serializedName: "teamName",
        type: {
          name: "String"
        }
      },
      teamSNSURL: {
        serializedName: "teamSNSURL",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1GetChatGptCompletionsRequest: msRest.CompositeMapper = {
  serializedName: "v1GetChatGptCompletionsRequest",
  type: {
    name: "Composite",
    className: "V1GetChatGptCompletionsRequest",
    modelProperties: {
      model: {
        serializedName: "model",
        type: {
          name: "String"
        }
      },
      messages: {
        serializedName: "messages",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "GetChatGptCompletionsRequestmessage"
            }
          }
        }
      },
      wikiName: {
        serializedName: "wikiName",
        type: {
          name: "String"
        }
      },
      temperature: {
        serializedName: "temperature",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1GetChatGptCompletionsResponse: msRest.CompositeMapper = {
  serializedName: "v1GetChatGptCompletionsResponse",
  type: {
    name: "Composite",
    className: "V1GetChatGptCompletionsResponse",
    modelProperties: {
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1GetChatGptCompletionsWithPromptRequest: msRest.CompositeMapper = {
  serializedName: "v1GetChatGptCompletionsWithPromptRequest",
  type: {
    name: "Composite",
    className: "V1GetChatGptCompletionsWithPromptRequest",
    modelProperties: {
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      wikiName: {
        serializedName: "wikiName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1User: msRest.CompositeMapper = {
  serializedName: "v1User",
  type: {
    name: "Composite",
    className: "V1User",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      authUserId: {
        serializedName: "authUserId",
        type: {
          name: "Number"
        }
      },
      nickname: {
        serializedName: "nickname",
        type: {
          name: "String"
        }
      },
      imageUrl: {
        serializedName: "imageUrl",
        type: {
          name: "String"
        }
      },
      lastLoggedInAt: {
        serializedName: "lastLoggedInAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const V1GlobalAllowedDomain: msRest.CompositeMapper = {
  serializedName: "v1GlobalAllowedDomain",
  type: {
    name: "Composite",
    className: "V1GlobalAllowedDomain",
    modelProperties: {
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      name: {
        required: true,
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      createdAt: {
        required: true,
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const V1Image: msRest.CompositeMapper = {
  serializedName: "v1Image",
  type: {
    name: "Composite",
    className: "V1Image",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      wikiId: {
        serializedName: "wikiId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1ListGlobalDomainAllowlistResponse: msRest.CompositeMapper = {
  serializedName: "v1ListGlobalDomainAllowlistResponse",
  type: {
    name: "Composite",
    className: "V1ListGlobalDomainAllowlistResponse",
    modelProperties: {
      globalAllowedDomains: {
        required: true,
        serializedName: "GlobalAllowedDomains",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1GlobalAllowedDomain"
            }
          }
        }
      }
    }
  }
};

export const V1WikiAllowedDomain: msRest.CompositeMapper = {
  serializedName: "v1WikiAllowedDomain",
  type: {
    name: "Composite",
    className: "V1WikiAllowedDomain",
    modelProperties: {
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wikiId: {
        required: true,
        serializedName: "wikiId",
        type: {
          name: "String"
        }
      },
      name: {
        required: true,
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      createdAt: {
        required: true,
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const V1ListWikiDomainAllowlistResponse: msRest.CompositeMapper = {
  serializedName: "v1ListWikiDomainAllowlistResponse",
  type: {
    name: "Composite",
    className: "V1ListWikiDomainAllowlistResponse",
    modelProperties: {
      wikiAllowedDomains: {
        required: true,
        serializedName: "wikiAllowedDomains",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiAllowedDomain"
            }
          }
        }
      }
    }
  }
};

export const V1ListWikiNavWithWiki: msRest.CompositeMapper = {
  serializedName: "v1ListWikiNavWithWiki",
  type: {
    name: "Composite",
    className: "V1ListWikiNavWithWiki",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      nav: {
        serializedName: "nav",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiNav"
            }
          }
        }
      }
    }
  }
};

export const V1ListWikiResponse: msRest.CompositeMapper = {
  serializedName: "v1ListWikiResponse",
  type: {
    name: "Composite",
    className: "V1ListWikiResponse",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1Wiki"
            }
          }
        }
      }
    }
  }
};

export const V1WikiTakahiroTemplateCodeSpreadsheetURL: msRest.CompositeMapper = {
  serializedName: "v1WikiTakahiroTemplateCodeSpreadsheetURL",
  type: {
    name: "Composite",
    className: "V1WikiTakahiroTemplateCodeSpreadsheetURL",
    modelProperties: {
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      lastUpdatedAt: {
        serializedName: "lastUpdatedAt",
        type: {
          name: "DateTime"
        }
      },
      wikiDataID: {
        serializedName: "wikiDataID",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki: msRest.CompositeMapper = {
  serializedName: "v1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki",
  type: {
    name: "Composite",
    className: "V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      spreadsheet: {
        serializedName: "spreadsheet",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiTakahiroTemplateCodeSpreadsheetURL"
            }
          }
        }
      }
    }
  }
};

export const V1WikiTooltip: msRest.CompositeMapper = {
  serializedName: "v1WikiTooltip",
  type: {
    name: "Composite",
    className: "V1WikiTooltip",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      word: {
        serializedName: "word",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1ListWikiTooltipMessage: msRest.CompositeMapper = {
  serializedName: "v1ListWikiTooltipMessage",
  type: {
    name: "Composite",
    className: "V1ListWikiTooltipMessage",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      tooltip: {
        serializedName: "tooltip",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiTooltip"
            }
          }
        }
      }
    }
  }
};

export const V1LoginRequest: msRest.CompositeMapper = {
  serializedName: "v1LoginRequest",
  type: {
    name: "Composite",
    className: "V1LoginRequest",
    modelProperties: {
      idToken: {
        serializedName: "idToken",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1PublishWikiDataDownloadURLRequest: msRest.CompositeMapper = {
  serializedName: "v1PublishWikiDataDownloadURLRequest",
  type: {
    name: "Composite",
    className: "V1PublishWikiDataDownloadURLRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      bucketPath: {
        serializedName: "bucketPath",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1RequestTemplateCodeExecutionRequest: msRest.CompositeMapper = {
  serializedName: "v1RequestTemplateCodeExecutionRequest",
  type: {
    name: "Composite",
    className: "V1RequestTemplateCodeExecutionRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      spreadsheetURL: {
        serializedName: "spreadsheetURL",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const V1Rule: msRest.CompositeMapper = {
  serializedName: "v1Rule",
  type: {
    name: "Composite",
    className: "V1Rule",
    modelProperties: {
      action: {
        serializedName: "action",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      inverted: {
        serializedName: "inverted",
        type: {
          name: "Boolean"
        }
      },
      conditions: {
        serializedName: "conditions",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Composite",
              className: "ProtobufAny"
            }
          }
        }
      },
      reason: {
        serializedName: "reason",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1RuleCollection: msRest.CompositeMapper = {
  serializedName: "v1RuleCollection",
  type: {
    name: "Composite",
    className: "V1RuleCollection",
    modelProperties: {
      rules: {
        serializedName: "rules",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1Rule"
            }
          }
        }
      }
    }
  }
};

export const V1Session: msRest.CompositeMapper = {
  serializedName: "v1Session",
  type: {
    name: "Composite",
    className: "V1Session",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      exp: {
        serializedName: "exp",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const V1UpdateLinkedWikiPageCategoryRequest: msRest.CompositeMapper = {
  serializedName: "v1UpdateLinkedWikiPageCategoryRequest",
  type: {
    name: "Composite",
    className: "V1UpdateLinkedWikiPageCategoryRequest",
    modelProperties: {
      wikiName: {
        serializedName: "wikiName",
        type: {
          name: "String"
        }
      },
      pageId: {
        serializedName: "pageId",
        type: {
          name: "String"
        }
      },
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1UpdateLinkedWikiPageTemplateCategoryRequest: msRest.CompositeMapper = {
  serializedName: "v1UpdateLinkedWikiPageTemplateCategoryRequest",
  type: {
    name: "Composite",
    className: "V1UpdateLinkedWikiPageTemplateCategoryRequest",
    modelProperties: {
      wikiName: {
        serializedName: "wikiName",
        type: {
          name: "String"
        }
      },
      templateId: {
        serializedName: "templateId",
        type: {
          name: "String"
        }
      },
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1UpdateWikiArchiveConfigRequest: msRest.CompositeMapper = {
  serializedName: "v1UpdateWikiArchiveConfigRequest",
  type: {
    name: "Composite",
    className: "V1UpdateWikiArchiveConfigRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1UpdateWikiImageRequest: msRest.CompositeMapper = {
  serializedName: "v1UpdateWikiImageRequest",
  type: {
    name: "Composite",
    className: "V1UpdateWikiImageRequest",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      image: {
        serializedName: "image",
        type: {
          name: "Composite",
          className: "V1Image"
        }
      }
    }
  }
};

export const V1UpdateWikiMemberPermissionRequest: msRest.CompositeMapper = {
  serializedName: "v1UpdateWikiMemberPermissionRequest",
  type: {
    name: "Composite",
    className: "V1UpdateWikiMemberPermissionRequest",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      permission: {
        serializedName: "permission",
        defaultValue: 'MEMBER',
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1UpdateWikiPageHistoryDiffRequestBody: msRest.CompositeMapper = {
  serializedName: "v1UpdateWikiPageHistoryDiffRequestBody",
  type: {
    name: "Composite",
    className: "V1UpdateWikiPageHistoryDiffRequestBody",
    modelProperties: {
      diffLines: {
        serializedName: "diffLines",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1UpdateWikiPageRedirectRequestBody: msRest.CompositeMapper = {
  serializedName: "v1UpdateWikiPageRedirectRequestBody",
  type: {
    name: "Composite",
    className: "V1UpdateWikiPageRedirectRequestBody",
    modelProperties: {
      redirectPageId: {
        serializedName: "redirectPageId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiAccessAnalytics: msRest.CompositeMapper = {
  serializedName: "v1WikiAccessAnalytics",
  type: {
    name: "Composite",
    className: "V1WikiAccessAnalytics",
    modelProperties: {
      analyticsEntries: {
        serializedName: "AnalyticsEntries",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WikiAccessAnalyticsOneEntry"
            }
          }
        }
      }
    }
  }
};

export const V1WikiAllowedIPAddress: msRest.CompositeMapper = {
  serializedName: "v1WikiAllowedIPAddress",
  type: {
    name: "Composite",
    className: "V1WikiAllowedIPAddress",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      ipAddress: {
        serializedName: "ipAddress",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiCollection",
  type: {
    name: "Composite",
    className: "V1WikiCollection",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1Wiki"
            }
          }
        }
      }
    }
  }
};

export const V1WikiCustomCss: msRest.CompositeMapper = {
  serializedName: "v1WikiCustomCss",
  type: {
    name: "Composite",
    className: "V1WikiCustomCss",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      css: {
        serializedName: "css",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiDataDownloadURL: msRest.CompositeMapper = {
  serializedName: "v1WikiDataDownloadURL",
  type: {
    name: "Composite",
    className: "V1WikiDataDownloadURL",
    modelProperties: {
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiDataSheet: msRest.CompositeMapper = {
  serializedName: "v1WikiDataSheet",
  type: {
    name: "Composite",
    className: "V1WikiDataSheet",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      sheetName: {
        serializedName: "sheetName",
        type: {
          name: "String"
        }
      },
      sheetData: {
        serializedName: "sheetData",
        type: {
          name: "String"
        }
      },
      originBucketPath: {
        serializedName: "originBucketPath",
        type: {
          name: "String"
        }
      },
      spreadsheetId: {
        serializedName: "spreadsheetId",
        type: {
          name: "String"
        }
      },
      sheetDataHash: {
        serializedName: "sheetDataHash",
        type: {
          name: "String"
        }
      },
      isForTemplateCode: {
        serializedName: "isForTemplateCode",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1WikiDataSheetCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiDataSheetCollection",
  type: {
    name: "Composite",
    className: "V1WikiDataSheetCollection",
    modelProperties: {
      sheets: {
        serializedName: "sheets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiDataSheet"
            }
          }
        }
      }
    }
  }
};

export const V1WikiDataSheetLink: msRest.CompositeMapper = {
  serializedName: "v1WikiDataSheetLink",
  type: {
    name: "Composite",
    className: "V1WikiDataSheetLink",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      sheetName: {
        serializedName: "sheetName",
        type: {
          name: "String"
        }
      },
      spreadsheetId: {
        serializedName: "spreadsheetId",
        type: {
          name: "String"
        }
      },
      linkSheetName: {
        serializedName: "linkSheetName",
        type: {
          name: "String"
        }
      },
      linkSheetSpreadsheetId: {
        serializedName: "linkSheetSpreadsheetId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiDataSheetLinkCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiDataSheetLinkCollection",
  type: {
    name: "Composite",
    className: "V1WikiDataSheetLinkCollection",
    modelProperties: {
      linkSheets: {
        serializedName: "linkSheets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiDataSheetLink"
            }
          }
        }
      }
    }
  }
};

export const V1WikiDataSheets: msRest.CompositeMapper = {
  serializedName: "v1WikiDataSheets",
  type: {
    name: "Composite",
    className: "V1WikiDataSheets",
    modelProperties: {
      sheets: {
        serializedName: "sheets",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Composite",
              className: "WikiDataSheetsData"
            }
          }
        }
      }
    }
  }
};

export const V1WikiDataUploadURL: msRest.CompositeMapper = {
  serializedName: "v1WikiDataUploadURL",
  type: {
    name: "Composite",
    className: "V1WikiDataUploadURL",
    modelProperties: {
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      bucketPath: {
        serializedName: "bucketPath",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiForDashboard: msRest.CompositeMapper = {
  serializedName: "v1WikiForDashboard",
  type: {
    name: "Composite",
    className: "V1WikiForDashboard",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      iconImgURL: {
        serializedName: "iconImgURL",
        type: {
          name: "String"
        }
      },
      noindex: {
        serializedName: "noindex",
        type: {
          name: "Boolean"
        }
      },
      isPrivate: {
        serializedName: "isPrivate",
        type: {
          name: "Boolean"
        }
      },
      author: {
        serializedName: "author",
        type: {
          name: "Composite",
          className: "V1User"
        }
      }
    }
  }
};

export const V1WikiPageCategory: msRest.CompositeMapper = {
  serializedName: "v1WikiPageCategory",
  type: {
    name: "Composite",
    className: "V1WikiPageCategory",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parentID: {
        serializedName: "parentID",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      wikiPageID: {
        serializedName: "wikiPageID",
        type: {
          name: "String"
        }
      },
      wikiPageTitle: {
        serializedName: "wikiPageTitle",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiPageTitle: msRest.CompositeMapper = {
  serializedName: "v1WikiPageTitle",
  type: {
    name: "Composite",
    className: "V1WikiPageTitle",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      published: {
        serializedName: "published",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1WikiPage: msRest.CompositeMapper = {
  serializedName: "v1WikiPage",
  type: {
    name: "Composite",
    className: "V1WikiPage",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      author: {
        serializedName: "author",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      thumbnailImageUrl: {
        serializedName: "thumbnailImageUrl",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Composite",
          className: "V1WikiPageCategory"
        }
      },
      meta: {
        serializedName: "meta",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Object"
            }
          }
        }
      },
      lastUpdatedAt: {
        serializedName: "lastUpdatedAt",
        type: {
          name: "DateTime"
        }
      },
      editPermission: {
        serializedName: "editPermission",
        type: {
          name: "String"
        }
      },
      publishedAt: {
        required: true,
        serializedName: "publishedAt",
        type: {
          name: "DateTime"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      keywords: {
        serializedName: "keywords",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      redirectPageTitle: {
        serializedName: "redirectPageTitle",
        type: {
          name: "Composite",
          className: "V1WikiPageTitle"
        }
      },
      noindex: {
        serializedName: "noindex",
        type: {
          name: "Boolean"
        }
      },
      metaOgpImageURL: {
        serializedName: "metaOgpImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageURL: {
        serializedName: "metaThumbnailImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageAutoSelect: {
        serializedName: "metaThumbnailImageAutoSelect",
        type: {
          name: "Boolean"
        }
      },
      titlePrefix: {
        serializedName: "titlePrefix",
        type: {
          name: "String"
        }
      },
      isDeleted: {
        serializedName: "isDeleted",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1WikiPageHistory: msRest.CompositeMapper = {
  serializedName: "v1WikiPageHistory",
  type: {
    name: "Composite",
    className: "V1WikiPageHistory",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wikiPage: {
        serializedName: "wikiPage",
        type: {
          name: "Composite",
          className: "V1WikiPage"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      author: {
        serializedName: "author",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      parentId: {
        serializedName: "parentId",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      isDeleted: {
        required: true,
        serializedName: "isDeleted",
        type: {
          name: "Boolean"
        }
      },
      isRestored: {
        required: true,
        serializedName: "isRestored",
        type: {
          name: "Boolean"
        }
      },
      diffLines: {
        serializedName: "diffLines",
        type: {
          name: "String"
        }
      },
      titlePrefix: {
        serializedName: "titlePrefix",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiHistoryCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiHistoryCollection",
  type: {
    name: "Composite",
    className: "V1WikiHistoryCollection",
    modelProperties: {
      history: {
        serializedName: "history",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageHistory"
            }
          }
        }
      },
      numOfTotalHistories: {
        serializedName: "numOfTotalHistories",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1WikiMember: msRest.CompositeMapper = {
  serializedName: "v1WikiMember",
  type: {
    name: "Composite",
    className: "V1WikiMember",
    modelProperties: {
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      isAdmin: {
        serializedName: "isAdmin",
        type: {
          name: "Boolean"
        }
      },
      isSubAdmin: {
        serializedName: "isSubAdmin",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1WikiMemberCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiMemberCollection",
  type: {
    name: "Composite",
    className: "V1WikiMemberCollection",
    modelProperties: {
      members: {
        serializedName: "members",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiMember"
            }
          }
        }
      },
      numOfTotalMembers: {
        serializedName: "numOfTotalMembers",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1WikiMemberInvite: msRest.CompositeMapper = {
  serializedName: "v1WikiMemberInvite",
  type: {
    name: "Composite",
    className: "V1WikiMemberInvite",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      usedCount: {
        serializedName: "usedCount",
        type: {
          name: "String"
        }
      },
      limitCount: {
        serializedName: "limitCount",
        type: {
          name: "String"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      expiredAt: {
        serializedName: "expiredAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const V1WikiMemberInviteCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiMemberInviteCollection",
  type: {
    name: "Composite",
    className: "V1WikiMemberInviteCollection",
    modelProperties: {
      invites: {
        serializedName: "invites",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiMemberInvite"
            }
          }
        }
      }
    }
  }
};

export const V1WikiMemberRequest: msRest.CompositeMapper = {
  serializedName: "v1WikiMemberRequest",
  type: {
    name: "Composite",
    className: "V1WikiMemberRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const V1WikiMemberRequestCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiMemberRequestCollection",
  type: {
    name: "Composite",
    className: "V1WikiMemberRequestCollection",
    modelProperties: {
      requests: {
        serializedName: "requests",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiMemberRequest"
            }
          }
        }
      }
    }
  }
};

export const V1WikiMemcacheKeyHash: msRest.CompositeMapper = {
  serializedName: "v1WikiMemcacheKeyHash",
  type: {
    name: "Composite",
    className: "V1WikiMemcacheKeyHash",
    modelProperties: {
      modelName: {
        serializedName: "modelName",
        type: {
          name: "String"
        }
      },
      hash: {
        serializedName: "hash",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiMemcacheKeyHashCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiMemcacheKeyHashCollection",
  type: {
    name: "Composite",
    className: "V1WikiMemcacheKeyHashCollection",
    modelProperties: {
      hashList: {
        serializedName: "hashList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiMemcacheKeyHash"
            }
          }
        }
      }
    }
  }
};

export const V1WikiPageCategoryCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPageCategoryCollection",
  type: {
    name: "Composite",
    className: "V1WikiPageCategoryCollection",
    modelProperties: {
      wikiPageCategories: {
        serializedName: "wikiPageCategories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageCategory"
            }
          }
        }
      },
      numOfTotalWikiPageCategories: {
        serializedName: "numOfTotalWikiPageCategories",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1WikiPageCategoryCreateRequest: msRest.CompositeMapper = {
  serializedName: "v1WikiPageCategoryCreateRequest",
  type: {
    name: "Composite",
    className: "V1WikiPageCategoryCreateRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wikiName: {
        serializedName: "wikiName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parentID: {
        serializedName: "parentID",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      wikiPageID: {
        serializedName: "wikiPageID",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiPageCategoryUpdateRequest: msRest.CompositeMapper = {
  serializedName: "v1WikiPageCategoryUpdateRequest",
  type: {
    name: "Composite",
    className: "V1WikiPageCategoryUpdateRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wikiName: {
        serializedName: "wikiName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parentID: {
        serializedName: "parentID",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      wikiPageID: {
        serializedName: "wikiPageID",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiPageDraft: msRest.CompositeMapper = {
  serializedName: "v1WikiPageDraft",
  type: {
    name: "Composite",
    className: "V1WikiPageDraft",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      wikiPage: {
        serializedName: "wikiPage",
        type: {
          name: "Composite",
          className: "V1WikiPage"
        }
      },
      lastAuthor: {
        serializedName: "lastAuthor",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Composite",
          className: "V1WikiPageCategory"
        }
      },
      keywords: {
        serializedName: "keywords",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      noindex: {
        serializedName: "noindex",
        type: {
          name: "Boolean"
        }
      },
      metaOgpImageURL: {
        serializedName: "metaOgpImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageURL: {
        serializedName: "metaThumbnailImageURL",
        type: {
          name: "String"
        }
      },
      metaThumbnailImageAutoSelect: {
        serializedName: "metaThumbnailImageAutoSelect",
        type: {
          name: "Boolean"
        }
      },
      titlePrefix: {
        serializedName: "titlePrefix",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiPageDraftCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPageDraftCollection",
  type: {
    name: "Composite",
    className: "V1WikiPageDraftCollection",
    modelProperties: {
      drafts: {
        serializedName: "drafts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageDraft"
            }
          }
        }
      }
    }
  }
};

export const V1WikiPageForList: msRest.CompositeMapper = {
  serializedName: "v1WikiPageForList",
  type: {
    name: "Composite",
    className: "V1WikiPageForList",
    modelProperties: {
      wikiPage: {
        serializedName: "wikiPage",
        type: {
          name: "Composite",
          className: "V1WikiPage"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      lastUpdater: {
        serializedName: "lastUpdater",
        type: {
          name: "String"
        }
      },
      hasDraft: {
        serializedName: "hasDraft",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1WikiPageForListCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPageForListCollection",
  type: {
    name: "Composite",
    className: "V1WikiPageForListCollection",
    modelProperties: {
      wikiPage: {
        serializedName: "wikiPage",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageForList"
            }
          }
        }
      },
      numOfTotalWikiPages: {
        serializedName: "numOfTotalWikiPages",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1WikiPageHistoryCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPageHistoryCollection",
  type: {
    name: "Composite",
    className: "V1WikiPageHistoryCollection",
    modelProperties: {
      wikiPage: {
        serializedName: "wikiPage",
        type: {
          name: "Composite",
          className: "V1WikiPage"
        }
      },
      history: {
        serializedName: "history",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageHistory"
            }
          }
        }
      },
      numOfTotalHistories: {
        serializedName: "numOfTotalHistories",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1WikiPageImageSize: msRest.CompositeMapper = {
  serializedName: "v1WikiPageImageSize",
  type: {
    name: "Composite",
    className: "V1WikiPageImageSize",
    modelProperties: {
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      height: {
        serializedName: "height",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1WikiPagePartial: msRest.CompositeMapper = {
  serializedName: "v1WikiPagePartial",
  type: {
    name: "Composite",
    className: "V1WikiPagePartial",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      author: {
        serializedName: "author",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      lastAuthor: {
        serializedName: "lastAuthor",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      lastUpdatedAt: {
        serializedName: "lastUpdatedAt",
        type: {
          name: "DateTime"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1WikiPagePartialCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPagePartialCollection",
  type: {
    name: "Composite",
    className: "V1WikiPagePartialCollection",
    modelProperties: {
      pagePartials: {
        serializedName: "pagePartials",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPagePartial"
            }
          }
        }
      },
      numOfTotalPagePartials: {
        serializedName: "numOfTotalPagePartials",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1WikiPagePartialName: msRest.CompositeMapper = {
  serializedName: "v1WikiPagePartialName",
  type: {
    name: "Composite",
    className: "V1WikiPagePartialName",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiPagePartialNameCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPagePartialNameCollection",
  type: {
    name: "Composite",
    className: "V1WikiPagePartialNameCollection",
    modelProperties: {
      wikiPagePartial: {
        serializedName: "wikiPagePartial",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPagePartialName"
            }
          }
        }
      }
    }
  }
};

export const V1WikiPageSitemap: msRest.CompositeMapper = {
  serializedName: "v1WikiPageSitemap",
  type: {
    name: "Composite",
    className: "V1WikiPageSitemap",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      isCategoryPage: {
        serializedName: "isCategoryPage",
        type: {
          name: "Boolean"
        }
      },
      lastUpdatedAt: {
        serializedName: "lastUpdatedAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const V1WikiPageSitemapCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPageSitemapCollection",
  type: {
    name: "Composite",
    className: "V1WikiPageSitemapCollection",
    modelProperties: {
      wikiPage: {
        serializedName: "wikiPage",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageSitemap"
            }
          }
        }
      },
      isUpdatedAtHidden: {
        serializedName: "isUpdatedAtHidden",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const V1WikiPageSummary: msRest.CompositeMapper = {
  serializedName: "v1WikiPageSummary",
  type: {
    name: "Composite",
    className: "V1WikiPageSummary",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      parentCategory: {
        serializedName: "parentCategory",
        type: {
          name: "String"
        }
      },
      childCategory: {
        serializedName: "childCategory",
        type: {
          name: "String"
        }
      },
      lastAuthor: {
        serializedName: "lastAuthor",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      lastUpdatedAt: {
        serializedName: "lastUpdatedAt",
        type: {
          name: "DateTime"
        }
      },
      redirectPageId: {
        serializedName: "redirectPageId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiPageSummaryCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPageSummaryCollection",
  type: {
    name: "Composite",
    className: "V1WikiPageSummaryCollection",
    modelProperties: {
      wikiPageSummaries: {
        serializedName: "wikiPageSummaries",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageSummary"
            }
          }
        }
      }
    }
  }
};

export const V1WikiPageTemplate: msRest.CompositeMapper = {
  serializedName: "v1WikiPageTemplate",
  type: {
    name: "Composite",
    className: "V1WikiPageTemplate",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      wiki: {
        serializedName: "wiki",
        type: {
          name: "Composite",
          className: "V1Wiki"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      lastAuthor: {
        serializedName: "lastAuthor",
        type: {
          name: "Composite",
          className: "V1User"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Composite",
          className: "V1WikiPageCategory"
        }
      },
      keywords: {
        serializedName: "keywords",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiPageTemplateCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPageTemplateCollection",
  type: {
    name: "Composite",
    className: "V1WikiPageTemplateCollection",
    modelProperties: {
      template: {
        serializedName: "template",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageTemplate"
            }
          }
        }
      },
      numOfTotalTemplates: {
        serializedName: "numOfTotalTemplates",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1WikiPageTemplateTitleAndBody: msRest.CompositeMapper = {
  serializedName: "v1WikiPageTemplateTitleAndBody",
  type: {
    name: "Composite",
    className: "V1WikiPageTemplateTitleAndBody",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiPageTemplateTitleBodyCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPageTemplateTitleBodyCollection",
  type: {
    name: "Composite",
    className: "V1WikiPageTemplateTitleBodyCollection",
    modelProperties: {
      templateTitleAndBodies: {
        serializedName: "templateTitleAndBodies",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageTemplateTitleAndBody"
            }
          }
        }
      }
    }
  }
};

export const V1WikiPageTitleCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPageTitleCollection",
  type: {
    name: "Composite",
    className: "V1WikiPageTitleCollection",
    modelProperties: {
      wikiPageTitles: {
        serializedName: "wikiPageTitles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageTitle"
            }
          }
        }
      }
    }
  }
};

export const V1WikiPageWithTitleAndBody: msRest.CompositeMapper = {
  serializedName: "v1WikiPageWithTitleAndBody",
  type: {
    name: "Composite",
    className: "V1WikiPageWithTitleAndBody",
    modelProperties: {
      wikiPage: {
        serializedName: "wikiPage",
        type: {
          name: "Composite",
          className: "V1WikiPage"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      historyId: {
        serializedName: "historyId",
        type: {
          name: "String"
        }
      },
      titlePrefix: {
        serializedName: "titlePrefix",
        type: {
          name: "String"
        }
      },
      imageSizes: {
        serializedName: "imageSizes",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Composite",
              className: "V1WikiPageImageSize"
            }
          }
        }
      }
    }
  }
};

export const V1WikiPageWithTitleAndBodyCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPageWithTitleAndBodyCollection",
  type: {
    name: "Composite",
    className: "V1WikiPageWithTitleAndBodyCollection",
    modelProperties: {
      wikiPage: {
        serializedName: "wikiPage",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageWithTitleAndBody"
            }
          }
        }
      },
      numOfTotalWikiPages: {
        serializedName: "numOfTotalWikiPages",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1WikiPageWithTitleAndLastUpdater: msRest.CompositeMapper = {
  serializedName: "v1WikiPageWithTitleAndLastUpdater",
  type: {
    name: "Composite",
    className: "V1WikiPageWithTitleAndLastUpdater",
    modelProperties: {
      wikiPage: {
        serializedName: "wikiPage",
        type: {
          name: "Composite",
          className: "V1WikiPage"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      lastUpdater: {
        serializedName: "lastUpdater",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WikiPageWithTitleAndLastUpdaterCollection: msRest.CompositeMapper = {
  serializedName: "v1WikiPageWithTitleAndLastUpdaterCollection",
  type: {
    name: "Composite",
    className: "V1WikiPageWithTitleAndLastUpdaterCollection",
    modelProperties: {
      wikiPage: {
        serializedName: "wikiPage",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "V1WikiPageWithTitleAndLastUpdater"
            }
          }
        }
      },
      numOfTotalWikiPages: {
        serializedName: "numOfTotalWikiPages",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const V1WikiSlackConfig: msRest.CompositeMapper = {
  serializedName: "v1WikiSlackConfig",
  type: {
    name: "Composite",
    className: "V1WikiSlackConfig",
    modelProperties: {
      wikiName: {
        serializedName: "wikiName",
        type: {
          name: "String"
        }
      },
      notifyArticleCreateAndUpdate: {
        serializedName: "notifyArticleCreateAndUpdate",
        type: {
          name: "Boolean"
        }
      },
      notifyArticleDelete: {
        serializedName: "notifyArticleDelete",
        type: {
          name: "Boolean"
        }
      },
      notifyArticleDraftUpdate: {
        serializedName: "NotifyArticleDraftUpdate",
        type: {
          name: "Boolean"
        }
      },
      notifyPartialCreateAndUpdate: {
        serializedName: "notifyPartialCreateAndUpdate",
        type: {
          name: "Boolean"
        }
      },
      notifyCategoryCreateAndUpdate: {
        serializedName: "notifyCategoryCreateAndUpdate",
        type: {
          name: "Boolean"
        }
      },
      notifyDatasheetsCreateAndUpdate: {
        serializedName: "notifyDatasheetsCreateAndUpdate",
        type: {
          name: "Boolean"
        }
      },
      notifyChannelName: {
        serializedName: "notifyChannelName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const V1WithWikiRequest: msRest.CompositeMapper = {
  serializedName: "v1WithWikiRequest",
  type: {
    name: "Composite",
    className: "V1WithWikiRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};
