













import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';
import { V1WikiAccessAnalytics } from '@/api-client/generated/models';
import { Chart } from 'chart.js';
import format from 'date-fns/format';

@Component({
  name: 'wiki-access-analytics',
})
export default class WikiAccessAnalytics extends KamigameVue {
  analyticsData: V1WikiAccessAnalytics = {};
  analyticsChart: any;
  analyticsIdPrefix: string = 'analyticsChartDays';
  analyticsDurations: number[] = [7, 30, 60, 90];

  async mounted() {
    const api = this.api;
    this.analyticsData = await api.getWikiAccessAnalytics(this.wikiName);
    if (!this.analyticsData || !this.analyticsData.analyticsEntries) {
      return;
    }

    this.analyticsData.analyticsEntries.reverse();
    this.createAnalyticsChart();
  }

  createAnalyticsChart() {
    this.analyticsDurations.forEach(analyticsDuration => {
      if (!this.analyticsData.analyticsEntries) {
        return;
      }

      const canvas = <HTMLCanvasElement>this.$el.querySelector(`#${this.analyticsIdPrefix}${analyticsDuration}`);
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }

      const entries =
        this.analyticsData.analyticsEntries.slice(-analyticsDuration) || this.analyticsData.analyticsEntries;

      // Chart.js のアップデートに追従できていなかったので以下のコミットで実装を削除
      // https://github.com/voyagegroup/kamigame-wiki/commit/18279ad0c84e43263e78344dd6990057488ae7f9
      // TODO: Chart.js で描画する
    });
  }
}
