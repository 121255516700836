import { TokenCredentials } from '@azure/ms-rest-js';
import * as StringConverter from '@/service/StringConverter';
import FileSaver from 'file-saver';
import axios from 'axios';

export function wikiImageUpload(
  credentials: TokenCredentials,
  wikiName: string,
  ignoreOptimizeImage: boolean,
  files: File[]
): Promise<Map<string, string>> {
  const data = new FormData();
  data.append('ignoreOptimizeImage', ignoreOptimizeImage ? 'true' : 'false');
  for (let i = 0; i < files.length; i++) {
    data.append('image', files[i]);
  }

  return ImageUpload(
    `${credentials.authorizationScheme} ${credentials.token}`,
    `${API_URL_BASE}/admin/wiki/${wikiName}/image/upload`,
    data
  );
}

export function manageImageUpload(credentials: TokenCredentials, files: File[]): Promise<Map<string, string>> {
  const data = new FormData();
  for (let i = 0; i < files.length; i++) {
    data.append('image', files[i]);
  }

  return ImageUpload(
    `${credentials.authorizationScheme} ${credentials.token}`,
    `${API_URL_BASE}/manage/image/upload`,
    data
  );
}

async function ImageUpload(authorization: string, url: string, data: FormData): Promise<Map<string, string>> {
  const res = await axios({
    method: 'POST',
    url,
    headers: {
      Authorization: authorization,
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
  const result = new Map<string, string>();
  const images = (res.data as any).images;
  Object.keys(images).forEach((k) => {
    result.set(k, images[k].id);
  });

  return await Promise.resolve(result);
}

type Result = { name: string; url: string };

export function exportImageUploadCSV(rows: Result[], saveName: string): void {
  const csvHeader = ['ファイル名', 'URL'];

  const csvData = rows.map((s) => [s.name, s.url]);
  const csvRows = [csvHeader, ...csvData];
  const csv = csvRows.map((row) => row.join('\t')).join('\r\n');

  const blob = new Blob([StringConverter.convertStringToUTF16LEArray(csv)], {
    type: 'text/csv;charset=utf-16',
  });
  FileSaver.saveAs(blob, saveName);
}

/**
 * 複数画像のアップロード時に使用、画像のバリデーション後、chunkMaximumBytesに合わせてFileをchunkする
 *
 * @param files
 * @param chunkMaximumBytes GAE リクエストサイズの上限が 32MB なので余裕を持って 30MB と設定
 * @see https://cloud.google.com/appengine/docs/standard/go111/how-requests-are-handled#quotas_and_limits
 */
export function fileValidateChunk(
  files: File[],
  chunkMaximumBytes = 30 * 1000 * 1000
): { chunkedFiles: File[][]; error: File[] } {
  const error: File[] = [];
  const chunkedFiles: File[][] = [];

  let currentChunkBytes = 0;
  let chunkIndex = 0;

  files.forEach((file) => {
    if (!file.type.startsWith('image')) {
      error.push(file);
      return;
    }
    if (currentChunkBytes + file.size > chunkMaximumBytes) {
      chunkIndex += 1;
      currentChunkBytes = 0;
    }

    if (!chunkedFiles[chunkIndex]) {
      chunkedFiles[chunkIndex] = [];
    }

    currentChunkBytes += file.size;
    chunkedFiles[chunkIndex].push(file);
  });
  return { chunkedFiles, error };
}
