var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c("Sidebar", { attrs: { navItems: _vm.generateConfigNavItems() } }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "main" },
        [
          _c("Breadcrumb", { attrs: { list: _vm.list } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container-fluid" },
            [
              _vm._l(_vm.flashes, function (flashValue, flashKey) {
                return [
                  _c(
                    "b-alert",
                    {
                      key: flashKey,
                      attrs: {
                        dismissible: "",
                        fade: "",
                        variant: flashValue.variant,
                        show: !!flashValue.variant,
                      },
                    },
                    [
                      _vm._v(_vm._s(flashValue.message) + "\n          "),
                      flashValue.linkLabel && flashValue.linkTo
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: flashValue.linkTo,
                                target: flashValue.linkNewTab
                                  ? "_blank"
                                  : "_self",
                              },
                            },
                            [_vm._v(_vm._s(flashValue.linkLabel))]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              }),
              _vm._v(" "),
              _c("router-view"),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }