




import KamigameVue from '@/KamigameVue';

import { BModal } from 'bootstrap-vue';
import { diff } from 'deep-object-diff';
import Component from 'vue-class-component';
import { b64urlEncode } from '@waiting/base64';
import firebase from '@/firebase/clientApp';

import { downloadWikiDataSheet } from '@/service/CloudStorage';
import { V1WikiDataSheet, V1WikiDataSheetCollection, V1RealtimeDatabaseRule } from '@/api-client/generated/models';

@Component({
  name: 'wiki-data',
})
export default class WikiData extends KamigameVue {
  selectedSheetIndex: number | null = null;
  data: V1WikiDataSheetCollection = {
    sheets: [],
  };
  updateData: V1WikiDataSheetCollection = {
    sheets: [],
  };
  comparedData: V1WikiDataSheetCollection = {
    sheets: [],
  };

  get currentSheet(): V1WikiDataSheet | undefined {
    const index = this.selectedSheetIndex;
    if (!this.data || !this.data.sheets || index === null) {
      return undefined;
    }

    return this.data.sheets[index];
  }

  async fetchSpecifiedSheetData(index: number) {
    if (index === this.selectedSheetIndex) {
      return;
    }

    const sheet = (this.data.sheets || []).filter((_, sheetsIndex) => index === sheetsIndex)[0];
    const sheetId = sheet.id;
    if (!sheetId) {
      return;
    }

    await this.api.getWikiDataSheet(this.wikiName, sheetId).then(async (response: V1WikiDataSheet) => {
      if (!this.data.sheets) {
        return;
      }

      if (response.originBucketPath) {
        response.sheetData = await downloadWikiDataSheet(this.api, this.wikiName, response.originBucketPath);
      }

      this.$set(this.data.sheets, index, response);
    });
  }

  showDeleteDataSheetModal() {
    const modal = this.$refs.deleteDataSheetModal as BModal;
    if (!modal) {
      return;
    }

    modal.show();
  }

  changeSelectedSheetIndex(index: number | null) {
    this.selectedSheetIndex = index;
  }

  deleteDataSheet() {
    if (!this.currentSheet || !this.currentSheet.id) {
      return;
    }

    this.api.deleteWikiDataSheet(this.wikiName, this.currentSheet.id).then(() => {
      if (!this.data.sheets || this.selectedSheetIndex === null) {
        return;
      }

      this.$delete(this.data.sheets, this.selectedSheetIndex.toString());
      this.setFlashMessage('success', 'データシートの削除に成功しました');
    });
  }

  showEditSheetDataModal() {
    const modal = this.$refs.editSheetDataModal as BModal;
    if (!modal) {
      return;
    }

    modal.show();
  }

  async modifyRealtimeDatabaseIndexes(data: V1WikiDataSheetCollection) {
    const config: V1RealtimeDatabaseRule[] = [];
    data.sheets?.forEach((sheet) => {
      if (!sheet.sheetName) {
        return;
      }

      const sheetDataObj = JSON.parse(sheet.sheetData || '') || [];
      const headers = sheetDataObj[0] || [];
      const indexCandidates: { [key: string]: boolean } = {};

      for (const row of sheetDataObj.slice(1)) {
        for (const i in headers) {
          if (headers[i].trim() === '') {
            continue;
          }

          const headerName = b64urlEncode(headers[i]);
          if (`${row[i]}`.length > 256) {
            indexCandidates[headerName] = false;
          } else if (indexCandidates[headerName] !== false) {
            indexCandidates[headerName] = true;
          }
        }
      }

      config.push({
        sheetName: b64urlEncode(sheet.sheetName),
        indexOn: Object.keys(indexCandidates).filter((k) => indexCandidates[k]),
      });
    });

    await this.api.configureRealtimeDatabaseRule(this.wikiName, { rules: config });
  }

  /**
   * Realtime Database 上にすでに存在するデータシートのデータを更新する。新旧のデータを比較し、差分のみを Realtime Database に反映する
   *
   * @param sheetName データシートの名前
   * @param sheetData JSON 形式で表現した反映したい更新を含むデータシート文字列
   * @param oldSheetData JSON 形式で表現した更新前のデータシート文字列
   */
  async updateRealtimeDatabase(sheetName: string, sheetData: string): Promise<void> {
    const database = firebase.database();
    const wiki = await this.$store.getters.getWiki(this.wikiName);

    const oldRecord = (await database.ref(`datasheet/${wiki.id}/${b64urlEncode(sheetName)}`).get()).val();
    const newRecord = this.convertToRtdbData(sheetData);
    const operations = this.generateUpdateOperations(oldRecord, newRecord);

    console.log({ oldRecord, newRecord, operations });

    await Promise.all(
      operations.map((op) => {
        switch (op.action) {
          case 'update':
            return database.ref(`datasheet/${wiki.id}/${b64urlEncode(sheetName)}/${op.row}`).update(op.payload);
          case 'delete':
            return database.ref(`datasheet/${wiki.id}/${b64urlEncode(sheetName)}/${op.row}`).remove();
        }
      })
    );
  }

  /**
   * Realtime Database 上に新しくデータシートのデータを保存する
   *
   * @param sheetName データシートの名前
   * @param sheetData JSON 形式で表現したデータシート文字列
   */
  async saveToRealtimeDatabase(sheetName: string, sheetData: string): Promise<void> {
    const records = this.convertToRtdbData(sheetData);

    const database = firebase.database();
    const wiki = await this.$store.getters.getWiki(this.wikiName);
    await database.ref(`datasheet/${wiki.id}/${b64urlEncode(sheetName)}`).set(records);
  }

  /**
   * JSON 形式のデータシートを RealtimeDatabase に格納する形式のデータに変換する
   *
   * @param {string} sheetData JSON 形式で表現したデータシート文字列
   */
  private convertToRtdbData(sheetData: string): { [key: string]: { [key: string]: string } } {
    const sheetDataObj = JSON.parse(sheetData) || [];
    const headers = sheetDataObj[0] || [];
    const records: { [key: string]: { [key: string]: string } } = {};

    let idKey = 0;
    const namedIdKey = headers.findIndex((h: string) => h.toLowerCase() == 'id');
    const suffixIdKey = headers.findIndex((h: string) => h.toLowerCase().includes('id'));
    if (namedIdKey != -1) {
      idKey = namedIdKey;
    } else if (suffixIdKey != -1) {
      idKey = suffixIdKey;
    }

    const idValues = sheetDataObj
      .slice(1)
      .map((row: string[]) => row[idKey])
      .filter((idValue: string) => idValue.trim() !== '');
    const isIdUnique = new Set(idValues).size === idValues.length;

    // ID values must not be empty, and they should be unique except for the "ID" named column. If the values against this condition, they will be replaced by sortScore value.
    // (We MUST respect the value of the "ID" named column because it will use in reading context.)
    const isIdAvailable = idValues.length > 0 && (namedIdKey != -1 || isIdUnique);

    sheetDataObj.forEach((row: string[], i: number) => {
      if (i == 0) {
        // skip header
        return;
      }
      const sortScore = `${i}`.padStart(8, '0');

      let rowKey = row[idKey];
      if (!isIdAvailable) {
        rowKey = sortScore;
      }

      if (rowKey.trim() === '') {
        return;
      }

      const encodedRowKey = b64urlEncode(rowKey);
      records[encodedRowKey] = {};

      headers.forEach((header: string, j: number) => {
        if (header.trim() === '') {
          return;
        }

        const headerName = b64urlEncode(header);
        records[encodedRowKey][headerName] = row[j];
      });
      records[encodedRowKey][b64urlEncode('_sort_score')] = sortScore;
      records[encodedRowKey][b64urlEncode('_updated_at')] = `${Math.floor(Date.now() / 1000)}`;
    });

    return records;
  }

  // データシートの古いデータと新しいデータを比較し、Realtime Database が update/delete に使える形式のデータに変換する
  private generateUpdateOperations(
    oldRecord: any,
    newRecord: any
  ): ({ action: 'delete'; row: string } | { action: 'update'; row: string; payload: any })[] {
    // deep-object-diff で生成した差分オブジェクトは Object Prototype を持たないため、Realtime Database の update メソッドに渡した場合にエラーとなる
    // injectObjectPrototype によって Object Prototype を注入することでエラーを回避する
    // https://app.asana.com/0/1203256184350557/1203901694042963/f
    const injectObjectPrototype = (objWithoutPrototype: any): any => {
      const result: any = {}; // ここで Prototype 付きオブジェクトを生成する
      for (const key in objWithoutPrototype) {
        if (objWithoutPrototype[key] !== null && typeof objWithoutPrototype[key] === 'object') {
          result[key] = injectObjectPrototype(objWithoutPrototype[key]);
        } else {
          result[key] = objWithoutPrototype[key];
        }
      }
      return result;
    };

    const diffRecord = injectObjectPrototype(diff(oldRecord, newRecord));
    const operations: ({ action: 'delete'; row: string } | { action: 'update'; row: string; payload: any })[] = [];

    for (const row in diffRecord) {
      const rowData = diffRecord[row];
      // 行単位の削除
      if (typeof rowData === 'undefined') {
        operations.push({ action: 'delete', row });
        continue;
      }

      // 差分なしの行は更新しない
      if (Object.keys(rowData).length === 0) {
        continue;
      }

      const converted: any = {};
      for (const col in rowData) {
        if (typeof rowData[col] === 'undefined') {
          // セル単位の削除
          // Realtime Database の update メソッドを使ってフィールドを削除したい場合は undefined ではなく null を指定する必要があるので変換する
          // https://firebase.google.com/docs/database/web/read-and-write?hl=ja#delete_data
          converted[col] = null;
        } else {
          converted[col] = rowData[col];
        }
      }
      converted[b64urlEncode('_updated_at')] = `${Math.floor(Date.now() / 1000)}`;
      operations.push({ action: 'update', row, payload: converted });
    }

    return operations;
  }
}
