
































import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';

import { createApiClientWithTokenByURI } from '@/service/WikiAPIClientFactory';
import { getTransferredWikiStateWithKamigameSource } from '@/service/TransferedWikiStateApi';
import { fetchAllPublishedWikiPageIds } from '@/service/ArticleApi';
import { chunk } from '@/lib/chunk';
import {
  fetchTransferredWikiStateForGameVillage,
  insertTransferredWikiStateForGameVillage,
} from '@/service/GoogleCloudStorageApi';
import { GetWikiPageResponse, V1WikiPageTitleCollection } from '@/api-client/generated/models';
import { getToken } from '@/service/GoogleApiClient';
import { isGapiRequestError } from '@/lib/isError';
import { wait } from '@/lib/wait';

@Component({
  name: 'button-bulk-transfer-articles',
})
export default class ButtonBulkTransferArticles extends KamigameVue {
  transferredWikiState: TransferredWikiState | null = null;
  sourceWikiName: string = '';
  existsSourceWikiName = false;

  async clickButton() {
    await getToken();
    await this.setTransferredWikiState();
  }

  async setTransferredWikiState() {
    try {
      this.transferredWikiState = await fetchTransferredWikiStateForGameVillage(this.wikiName);
    } catch (e) {
      if (isGapiRequestError(e) && e.status === 404) {
        const modal = this.$refs.modalBulkTransferArticles as any;
        modal.show();
        return;
      }

      this.setFlashMessage('danger', '状態ファイルの取得に失敗しました。エンジニアに連絡してください。');
      console.error(e);
      return;
    }

    this.sourceWikiName = this.transferredWikiState?.sourceWiki.name ?? '';
    if (this.sourceWikiName) {
      this.existsSourceWikiName = true;
    }

    const modal = this.$refs.modalBulkTransferArticles as any;
    modal.show();
  }

  async bulkTransferArticles() {
    const transferredWikiState = this.transferredWikiState
      ? this.transferredWikiState
      : await getTransferredWikiStateWithKamigameSource(this.api, this.wikiName, this.sourceWikiName);
    const newTransferredWikiState = structuredClone(transferredWikiState) as typeof transferredWikiState;
    const nowUTCString = new Date().toUTCString();
    const sourceRedirectIds: SourceRedirectId[] = [];

    const loginButton = document.getElementById('kamigameLoginButton') as IdKamigameLoginButton;
    const u = await loginButton.getUser();

    const sourceApiWithAnonymous = createApiClientWithTokenByURI(transferredWikiState.sourceWiki.apiBaseUrl);
    const sourceSession = await sourceApiWithAnonymous.login({ idToken: u.id_token });

    const sourceApi = createApiClientWithTokenByURI(transferredWikiState.sourceWiki.apiBaseUrl, sourceSession.id);

    const sourcePageIds = await fetchAllPublishedWikiPageIds(sourceApi, transferredWikiState.sourceWiki.name);

    const currentWikiPageTitles = await this.api
      .listWikiPageTitles(this.wikiName, { isDeleted: false, limit: 100000 })
      .catch((e) => {
        if (e.response?.status === 404) {
          return { wikiPageTitles: [] };
        }

        this.setFlashMessage('danger', '記事一覧の取得に失敗しました');
        console.error(e);
        throw e;
      });

    if (!currentWikiPageTitles.wikiPageTitles) {
      this.setFlashMessage('danger', '記事一覧の取得に失敗しました');
      return;
    }

    const sourcePageIdsChunk = chunk(sourcePageIds, 10);
    for (const sourcePageIdsInChunk of sourcePageIdsChunk) {
      const promises = sourcePageIdsInChunk.map(async (sourcePageId) => {
        const isTransferred = Object.values(transferredWikiState.pages.byId).some(
          (page) => page.sourceId === sourcePageId
        );
        if (isTransferred) {
          console.log(`移行済み: ${sourcePageId}`);
          return;
        }

        const sourcePage = await sourceApi.getWikiPage(transferredWikiState.sourceWiki.name, sourcePageId);

        if (!sourcePage.title || !sourcePage.body || !sourcePage.wikiPage?.publishedAt) {
          console.log(`skip: ${sourcePage?.wikiPage?.id}`);
          return;
        }

        if (sourcePage.wikiPage?.redirectPageTitle?.id) {
          sourceRedirectIds.push({
            sourceId: sourcePageId,
            sourceRedirectId: sourcePage.wikiPage.redirectPageTitle.id,
          });
        }

        try {
          const { transferredId } = await this.transferWikiPage(sourcePage, currentWikiPageTitles);
          newTransferredWikiState.pages.byId[transferredId] = {
            id: transferredId,
            sourceId: sourcePageId,
            transferredAt: nowUTCString,
            sourceLastUpdatedAt: sourcePage.wikiPage.lastUpdatedAt?.toUTCString() ?? '',
            sourceHistoryId: sourcePage.historyId ?? '',
          };
        } catch (e) {
          console.error(e);
        }
      });

      // TODO: エラーが発生した場合の処理を追加する
      await Promise.all(promises);
      await wait();
    }

    await insertTransferredWikiStateForGameVillage(newTransferredWikiState, this.wikiName);
    await this.bulkReplacePageLink(newTransferredWikiState);
    await this.bulkSetRedirect(newTransferredWikiState, sourceRedirectIds);

    this.setFlashMessage('success', '記事の一括移行が完了しました');
  }

  async transferWikiPage(
    sourcePage: GetWikiPageResponse,
    currentWikiPageTitles: V1WikiPageTitleCollection
  ): Promise<{ transferredId: string }> {
    // NOTE: トップページと雑談掲示板は最初からあるので移行せずに上書きする
    const topPageOrZatsudanPage = currentWikiPageTitles.wikiPageTitles?.find(
      (page) => page.path === sourcePage.wikiPage?.path && (page.path === 'index' || page.path === '掲示板')
    );

    if (topPageOrZatsudanPage && topPageOrZatsudanPage.id) {
      await this.updateWikiPageAndDelete(sourcePage, topPageOrZatsudanPage.id);
      return { transferredId: topPageOrZatsudanPage.id };
    }

    // NOTE: 既に移行済みの記事だが、状態ファイルに記録されてなかった場合は状態ファイルに追加する
    const currentPageTitlesSameSourceTitle = currentWikiPageTitles.wikiPageTitles?.find(
      (page) => page.title === sourcePage.title
    );
    if (currentPageTitlesSameSourceTitle && currentPageTitlesSameSourceTitle.id) {
      return { transferredId: currentPageTitlesSameSourceTitle.id };
    }

    const draft = await this.createWikiPageAndDelete(sourcePage);
    if (!draft.wikiPage?.id) {
      throw new Error('下書きの作成に失敗しました。下書きの ID がありません');
    }

    return { transferredId: draft.wikiPage.id };
  }

  async createWikiPageAndDelete(page: GetWikiPageResponse) {
    // NOTE: id=0 とすると新規作成になる
    const draft = await this.api.editWikiPageDraft(this.wikiName, '0', {
      title: page.title,
      description: page.wikiPage?.description ?? ' ', // NOTE: description が空だとエラーになるため空文字を設定
      body: `${page.body}\n\n<!--[source_id:${page.wikiPage?.id}]-->`, // NOTE: 検索ワード検索で元記事の ID を検索できるようにするため
      keywords: page.wikiPage?.keywords ?? '',
      noindex: page.wikiPage?.noindex ?? false,
      metaOgpImageURL: page.wikiPage?.metaOgpImageURL ?? '',
      metaThumbnailImageAutoSelect: page.wikiPage?.metaThumbnailImageAutoSelect ?? false,
      metaThumbnailImageURL: page.wikiPage?.metaThumbnailImageURL ?? '',
      titlePrefix: page.wikiPage?.titlePrefix ?? '',
      spreadsheetURL: [],
    });

    if (!draft.wikiPage?.id) {
      throw new Error('下書きの作成に失敗しました');
    }

    // NOTE: 逐次公開のために校正が終わってない記事は削除ステータスにする。理由：公開したくないが、ライターの作業を確認/保存するために下書きを編集履歴に残したいため。wiki が公開されていた場合、削除ステータスではない記事は編集履歴の最新版が公開されてしまう。公開するときは記事を復元すばいい。
    try {
      await this.api.deleteWikiPage(this.wikiName, draft.wikiPage.id);
      await this.api.deleteWikiPageDraft(this.wikiName, draft.wikiPage.id);
    } catch (e) {
      // NOTE: 下書き作成による初回の編集履歴が残ってるのででエラーにしない
      console.error(e);
    }

    return draft;
  }

  async updateWikiPageAndDelete(page: GetWikiPageResponse, pageId: string) {
    const pageWithUpdatedBody = { ...page, body: `${page.body}\n\n<!--[source_id:${page.wikiPage?.id}]-->` };
    await this.updateWikiPage(pageWithUpdatedBody, pageId);

    try {
      // NOTE: 逐次公開のために校正が終わってない記事は削除ステータスにする。理由：公開したくないが、ライターの作業を確認/保存するために下書きを編集履歴に残したいため。wiki が公開されていた場合、削除ステータスではない記事は編集履歴の最新版が公開されてしまう。公開するときは記事を復元すばいい。
      await this.api.deleteWikiPage(this.wikiName, pageId);
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return;
      }

      // NOTE: 下書き作成による初回の編集履歴が残ってるのででエラーにしない
      console.error(e);
    }
  }

  async updateWikiPage(page: GetWikiPageResponse, pageId: string) {
    await this.api.updateWikiPage(this.wikiName, pageId as string, {
      title: page.title,
      description: page.wikiPage?.description ?? ' ', // NOTE: description が空だとエラーになるため空文字を設定
      body: page.body,
      keywords: page.wikiPage?.keywords ?? '',
      noindex: page.wikiPage?.noindex ?? false,
      metaOgpImageURL: page.wikiPage?.metaOgpImageURL ?? '',
      metaThumbnailImageAutoSelect: page.wikiPage?.metaThumbnailImageAutoSelect ?? false,
      metaThumbnailImageURL: page.wikiPage?.metaThumbnailImageURL ?? '',
      titlePrefix: page.wikiPage?.titlePrefix ?? '',
      spreadsheetURL: [],
      editPermission: 'OBJ_default',
      publishedAt: new Date(),
    });
  }

  async bulkReplacePageLink(transferredWikiState: TransferredWikiState) {
    const pageIds = Object.keys(transferredWikiState.pages.byId);

    const pageIdChunks = chunk(pageIds, 10);
    for (const pageIdChunk of pageIdChunks) {
      const promises = pageIdChunk.map(async (pageId) => {
        const page = await this.api.getWikiPage(this.wikiName, pageId);
        if (!page.body) {
          console.log(`skip: ${pageId}`);
          return;
        }

        const replacedBody = Object.values(transferredWikiState.pages.byId).reduce((body, page) => {
          return this.replaceWikiPathInLink(
            this.replaceLinkPageId(body, page.sourceId, page.id),
            this.sourceWikiName,
            this.wikiName
          );
        }, page.body);

        if (replacedBody === page.body) {
          return;
        }

        await this.updateWikiPage({ ...page, body: replacedBody }, pageId);
      });

      // TODO: エラーが発生した場合の処理を追加する
      await Promise.all(promises);
      await wait();
    }
  }

  replaceLinkPageId(markdownText: string, sourcePageId: string, currentPageId: string) {
    const linkRegexp = /\/(\d+?)\.html.*?/g;

    return markdownText.replace(linkRegexp, (match, id) => {
      if (!sourcePageId || !currentPageId) {
        return match;
      }

      return match.replace(new RegExp(sourcePageId, 'g'), currentPageId);
    });
  }

  replaceWikiPathInLink(markdownText: string, sourceWikiName: string, currentWikiName: string) {
    const regexp = new RegExp(`/${sourceWikiName}/.*?\.html`, 'g');
    return markdownText.replace(regexp, (match) => {
      return match.replace(new RegExp(`/${sourceWikiName}/`, 'g'), `/${currentWikiName}/`);
    });
  }

  async bulkSetRedirect(transferredWikiState: TransferredWikiState, sourceRedirectIds: SourceRedirectId[]) {
    const sourceRedirectIdsChunk = chunk(sourceRedirectIds, 10);
    for (const sourceRedirectIdsInChunk of sourceRedirectIdsChunk) {
      const promises = sourceRedirectIdsInChunk.map(async (sourceRedirectId) => {
        const currentPageState = Object.values(transferredWikiState.pages.byId).find(
          (page) => page.sourceId === sourceRedirectId.sourceId
        );

        if (!currentPageState) {
          return;
        }

        const redirectCurrentPageState = Object.values(transferredWikiState.pages.byId).find(
          (page) => page.sourceId === sourceRedirectId.sourceRedirectId
        );

        if (!redirectCurrentPageState?.id) {
          return;
        }

        await this.api.updateWikiPageRedirect(this.wikiName, currentPageState.id, {
          redirectPageId: redirectCurrentPageState.id,
        });
      });

      // TODO: エラーが発生した場合の処理を追加する
      await Promise.all(promises);
      await wait();
    }
  }
}
