import storage from 'local-storage-fallback';
import { LOCAL_STORAGE_KEYS } from '@/constants';
import { KamigameWikiApi } from '@/api-client/generated/kamigameWikiApi';
import { wikiImageUpload } from '@/service/ImageUploader';
import { wikiGetImageWithServingURL } from '@/service/WikiGetImageWithServingURL';
import { TokenCredentials } from '@azure/ms-rest-js';
import { detectAnimationGif } from '../../lib/image';

interface ServingImage {
  id: string;
  name: string;
  url: string;
  markdownNotation: string;
}

export async function imageUpload(
  wikiName: string,
  api: KamigameWikiApi,
  editor: any,
  titleWithFiles: { title: string; files: File[] },
  onEditorChange: () => void
): Promise<void> {
  try {
    const imageFiles: File[] = [];
    const animationGifFiles: File[] = [];
    for (let file of titleWithFiles.files) {
      editor.insertText(uploadingText(file.name));
      (await detectAnimationGif(file)) ? animationGifFiles.push(file) : imageFiles.push(file);
    }

    const imageResults =
      imageFiles.length > 0
        ? await wikiImageUpload(api.credentials as TokenCredentials, wikiName, false, imageFiles)
        : [];

    const animationGifResults =
      animationGifFiles.length > 0
        ? await wikiImageUpload(api.credentials as TokenCredentials, wikiName, true, animationGifFiles)
        : [];

    const isPopupImageInsertMode = storage.getItem(LOCAL_STORAGE_KEYS.IS_POPUP_IMAGE_INSERT_MODE) === 'true';

    const imagePromises = Array.from(imageResults).map(async ([imageName, imageId]) => {
      const asyncFunc: () => Promise<ServingImage> = async function () {
        const image = await wikiGetImageWithServingURL(api, imageId);
        const url = encodeURI(image.url || '');
        const altText = escapeMarkdownBrackets(titleWithFiles.title) || image.id;
        const markdownNotation = isPopupImageInsertMode
          ? `[![${altText}](${url}=s0)](${url}=s0)`
          : `![${altText}](${url})`;

        return {
          id: imageId,
          name: imageName,
          url: url,
          markdownNotation: markdownNotation,
        };
      };

      return asyncFunc();
    });

    const animationGifPromises = Array.from(animationGifResults).map(([imageName, imageId]) => {
      const asyncFunc: () => Promise<ServingImage> = async function () {
        const image = await wikiGetImageWithServingURL(api, imageId);
        const url = encodeURI(image.url || '');
        const altText = escapeMarkdownBrackets(titleWithFiles.title) || image.id;

        return {
          id: imageId,
          name: imageName,
          url: url,
          markdownNotation: `![${altText}](${url}#video)`,
        };
      };

      return asyncFunc();
    });

    Promise.all([...imagePromises, ...animationGifPromises]).then((images) => {
      images.forEach((image) => {
        const startPos = editor.getCodeMirror().posFromIndex(editor.getMarkdown().indexOf(uploadingText(image.name)));
        const endPos = { line: startPos.line, ch: startPos.ch + uploadingText(image.name).length };
        editor.getCodeMirror().replaceRange(image.markdownNotation, startPos, endPos);
      });

      onEditorChange();
    });
  } catch (e: any) {
    throw new Error(e.message);
  }
}

function uploadingText(text: string): string {
  return `![${escapeMarkdownBrackets(text)} をアップロードしています...]()`;
}

function escapeMarkdownBrackets(text: string) {
  return text.replace(/\[/g, '［').replace(/\]/g, '］');
}
