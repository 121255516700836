var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "app-footer" }, [
      _c("span", [
        _vm._v("Copyright © 2015 Lighthouse Studio, Inc. All Rights Reserved."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }