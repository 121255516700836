





























import { V1WikiPageHistory } from '@/api-client/generated/models';
import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';
import dateFormat from 'date-fns/format';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'base-config-card',
})
export default class BaseConfigCard extends KamigameVue {
  @Prop()
  cols!: number;

  @Prop()
  limit!: number;

  @Prop()
  wikiPageHistories!: V1WikiPageHistory[];

  tableFields = [
    { key: 'createdAt', label: '日時' },
    { key: 'author', label: '編集者' },
    { key: 'title', label: 'タイトル' },
  ];

  format(date: Date) {
    return dateFormat(date, 'MM/dd HH:mm');
  }
}
