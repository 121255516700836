var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.modal-bulk-publish-drafts",
              modifiers: { "modal-bulk-publish-drafts": true },
            },
          ],
          attrs: { block: "" },
        },
        [_vm._v("全ての下書きを公開")]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-bulk-publish-drafts",
            title: "全ての下書きを公開",
            size: "lg",
            "no-close-on-backdrop": "",
            static: "",
          },
          on: { ok: _vm.bulkPublishDrafts },
        },
        [_c("p", [_vm._v("全ての下書きを公開します。")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }