












































































import KamigameVue from '@/KamigameVue';
import { bulkReplace, bulkSafeReplace, replaceLinkPageId, replaceWikiPathInLink } from '@/lib/replace';
import { mapHeaderToColumns } from '@/lib/spreadsheet';
import { translateArticleBody, translateArticleDescription, translateArticleTitle } from '@/lib/translator';
import { editWikiPageDraft } from '@/service/ArticleApi';
import { getToken } from '@/service/GoogleApiClient';
import { fetchTransferredWikiStateForGameVillage } from '@/service/GoogleCloudStorageApi';
import { fetchSpreadsheetMeta, fetchSpreadsheetSheetByTitle } from '@/service/SpreadsheetApi';
import { parseSpreadsheetIdFromUrl } from '@/service/SpreadsheetUrl';
import { createApiClientWithTokenByURI } from '@/service/WikiAPIClientFactory';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

type TranslationResult = {
  translatedText: string;
  isSuccess: boolean;
};

@Component({
  name: 'button-translate-article',
})
export default class ButtonTranslateArticle extends KamigameVue {
  buttonTitleSuffix: string = '';
  shouldTranslateBody: boolean = false;
  shouldTranslateTitle: boolean = false;
  shouldTranslateDescription: boolean = false;
  shouldSaveTranslatedBody: boolean = false;
  shouldSaveTranslatedTitle: boolean = false;
  shouldSaveTranslatedDescription: boolean = false;
  imageSpreadsheetUrl: string = '';
  wordSpreadSheetUrl: string = '';
  translatedTitleResult: TranslationResult | null = null;
  translatedDescriptionResult: TranslationResult | null = null;
  translatedBodyResult: TranslationResult | null = null;
  duplicatedWordsForReplacing: Replacement[] = [];
  uniqueWordsForReplacing: Replacement[] = [];
  imageUrlsForReplacing: Replacement[] = [];

  @Prop(String) readonly pageId!: string;
  @Prop(Boolean) readonly isReloadedOnSuccess: boolean = false;

  async clickModalOkForTranslation() {
    await getToken();
    this.buttonTitleSuffix = '(翻訳中...)';
    try {
      await this.setImageUrlForReplacing();
      await this.setWordsForReplacing();
      await this.translateArticle();
    } catch (e) {
      console.error(e);
      this.setFlashMessage('danger', 'エラーが発生しました。エンジニアに連絡してください。');
    }

    this.buttonTitleSuffix = '';
  }

  async clickModalOkForFailedTranslation() {
    this.buttonTitleSuffix = '(保存中...)';
    try {
      await this.saveTranslatedTextToDraft();
    } catch (e) {
      console.error(e);
      this.setFlashMessage('danger', 'エラーが発生しました。エンジニアに連絡してください。');
    }

    this.buttonTitleSuffix = '';
  }

  async clickModalOkForCheckDuplicatedWords() {
    this.buttonTitleSuffix = '(翻訳中...)';
    try {
      await this.translateArticle();
    } catch (e) {
      console.error(e);
      this.setFlashMessage('danger', 'エラーが発生しました。エンジニアに連絡してください。');
    }

    this.buttonTitleSuffix = '';
  }

  async translateArticle() {
    const draft = await this.api.getWikiPageDraft(this.wikiName, this.pageId).catch((e: Error) => {
      return e;
    });

    const existsDraft = !(draft instanceof Error) && draft.wikiPage?.id;
    if (existsDraft) {
      this.setFlashMessage('danger', '下書きが存在します。公開または削除してから実行してください。');
      return;
    }

    const transferredWikiState = await fetchTransferredWikiStateForGameVillage(this.wikiName).catch((e: Error) => {
      return e;
    });

    if (transferredWikiState instanceof Error) {
      this.setFlashMessage('danger', '状態ファイルの取得に失敗しました。エンジニアに連絡してください。');
      console.error(transferredWikiState);
      return;
    }

    const loginButton = document.getElementById('kamigameLoginButton') as IdKamigameLoginButton;
    const u = await loginButton.getUser();

    const sourceApiWithAnonymous = createApiClientWithTokenByURI(transferredWikiState.sourceWiki.apiBaseUrl);
    const sourceSession = await sourceApiWithAnonymous.login({ idToken: u.id_token });

    const sourceApi = createApiClientWithTokenByURI(transferredWikiState.sourceWiki.apiBaseUrl, sourceSession.id);

    const sourceWikiPageId = transferredWikiState.pages.byId[this.pageId].sourceId;
    if (!sourceWikiPageId) {
      this.setFlashMessage('danger', '元ページが見つかりませんでした。');
      return;
    }

    const sourcePage = await sourceApi.getWikiPage(transferredWikiState.sourceWiki.name, sourceWikiPageId);

    if (!sourcePage || !sourcePage.body || !sourcePage.title || !sourcePage?.wikiPage?.description) {
      this.setFlashMessage('danger', '元ページが見つかりませんでした。');
      return;
    }

    const replacedBodyLink = Object.values(transferredWikiState.pages.byId).reduce((body, page) => {
      return replaceWikiPathInLink(
        replaceLinkPageId(body, page.sourceId, page.id),
        transferredWikiState.sourceWiki.name,
        this.wikiName
      );
    }, sourcePage.body);

    const replacedBody = bulkReplace(
      bulkSafeReplace(replacedBodyLink, this.uniqueWordsForReplacing),
      this.imageUrlsForReplacing
    );

    const replacedTitle = bulkSafeReplace(sourcePage.title, this.uniqueWordsForReplacing);
    const replacedDescription = bulkSafeReplace(sourcePage.wikiPage.description, this.uniqueWordsForReplacing);

    this.translatedBodyResult = this.shouldTranslateBody ? await this.getTranslatedBody(replacedBody) : null;
    const translatedBody = this.translatedBodyResult?.translatedText ?? '';

    this.translatedTitleResult = this.shouldTranslateTitle
      ? await this.getTranslatedTitle(replacedTitle, translatedBody)
      : null;
    this.translatedDescriptionResult = this.shouldTranslateDescription
      ? await this.getTranslatedDescription(replacedDescription, translatedBody)
      : null;

    // NOTE: 以下、null だった場合を考慮して明示的に true か false を判定する
    this.shouldSaveTranslatedBody = this.translatedBodyResult?.isSuccess === true;
    this.shouldSaveTranslatedTitle = this.translatedTitleResult?.isSuccess === true;
    this.shouldSaveTranslatedDescription = this.translatedDescriptionResult?.isSuccess === true;

    if (
      this.translatedTitleResult?.isSuccess === false ||
      this.translatedDescriptionResult?.isSuccess === false ||
      this.translatedBodyResult?.isSuccess === false
    ) {
      (this.$refs.checkFailedResultModal as any).show();
      return;
    }

    await this.saveTranslatedTextToDraft();
  }

  async saveTranslatedTextToDraft() {
    const currentWikiPage = await this.api.getWikiPage(this.wikiName, this.pageId);
    const title = this.shouldSaveTranslatedTitle ? this.translatedTitleResult?.translatedText : currentWikiPage.title;
    const description = this.shouldSaveTranslatedDescription
      ? this.translatedDescriptionResult?.translatedText
      : currentWikiPage.wikiPage?.description;

    const body = this.shouldSaveTranslatedBody ? this.translatedBodyResult?.translatedText : currentWikiPage.body;
    await editWikiPageDraft(this.api, this.wikiName, currentWikiPage, {
      title,
      body,
      description,
    });

    if (this.isReloadedOnSuccess) {
      location.reload();
    }
  }

  async getTranslatedBody(body: string) {
    return await translateArticleBody(this.api, this.wikiName, body);
  }

  async getTranslatedTitle(title: string, translatedBody: string) {
    const translatedTitleMatch = /^\#\s(.*)\n/gm.exec(translatedBody);
    const translatedTitleInBody = translatedTitleMatch ? translatedTitleMatch[1] : '';

    if (translatedTitleInBody) {
      return { translatedText: translatedTitleInBody, isSuccess: true };
    }

    return await translateArticleTitle(this.api, this.wikiName, title);
  }

  async getTranslatedDescription(description: string, translatedBody: string) {
    const translatedDescriptionMatch = translatedBody.match(/\[lead\_text\](.*?)\[\/lead\_text\]/g);
    const translatedDescriptionInBody = translatedDescriptionMatch
      ? translatedDescriptionMatch[0].replace('[lead_text]', '').replace('[/lead_text]', '')
      : '';

    // NOTE: 2024/02/26 時点の運用で、lead_text と description は同じ文章が入るので、lead_text があった場合はそれを採用する
    if (translatedDescriptionMatch) {
      return { translatedText: translatedDescriptionInBody, isSuccess: true };
    }

    return await translateArticleDescription(this.api, this.wikiName, description);
  }

  async setWordsForReplacing() {
    const spreadSheetId = parseSpreadsheetIdFromUrl(this.wordSpreadSheetUrl);
    const meta = await fetchSpreadsheetMeta(spreadSheetId);
    if (!meta.result.sheets) {
      console.error('シートが見つかりませんでした。');
      return;
    }

    const sheetTitles = meta.result.sheets
      .map((sheet) => sheet.properties?.title)
      .filter((title): title is string => title !== undefined);

    const cells = (
      await Promise.all(
        sheetTitles.map(async (sheetTitle) => {
          const res = await fetchSpreadsheetSheetByTitle(spreadSheetId, sheetTitle, 'UNFORMATTED_VALUE').catch(
            (e: Error) => {
              return e;
            }
          );

          if (res instanceof Error) {
            console.error(res);
            return;
          }

          return res.result.values;
        })
      )
    ).filter((cells): cells is any => cells !== undefined);

    const headersToColumns = cells.map((c) => mapHeaderToColumns(c));

    const words = headersToColumns
      .map((h) => {
        if (!h.old || !h.new) {
          return [];
        }

        const oldRows = h.old;
        const newRows = h.new;

        const words = [];
        for (const i in oldRows) {
          const oldWord = oldRows[i];
          const newWord = newRows[i];

          if (oldWord && newWord) {
            words.push({ old: oldWord, new: newWord });
          }
        }
        return words;
      })
      .flat();

    // NOTE: 短い単語から変換すると、その単語が含まれる単語も変換されてしまうので、長い単語から変換する
    const sortedWords = words.sort((a, b) => b.old.length - a.old.length);

    const duplicates: Replacement[] = [];
    const uniques: Replacement[] = [];

    sortedWords.forEach((word) => {
      const duplicatedWords = sortedWords.filter((w) => w.old === word.old);
      if (duplicatedWords.some((d) => d.new !== word.new)) {
        duplicates.push(word);
        return;
      }

      if (uniques.some((u) => u.old === word.old)) {
        return;
      }

      uniques.push(word);
    });

    this.duplicatedWordsForReplacing = duplicates;
    this.uniqueWordsForReplacing = uniques;

    if (this.duplicatedWordsForReplacing.length > 0) {
      (this.$refs.checkDuplicatedWordsModal as any).show();
    }
  }

  async setImageUrlForReplacing() {
    const spreadSheetId = parseSpreadsheetIdFromUrl(this.imageSpreadsheetUrl);
    const meta = await fetchSpreadsheetMeta(spreadSheetId);
    if (!meta.result.sheets) {
      console.error('シートが見つかりませんでした。');
      return;
    }
    const sheetTitles = meta.result.sheets
      .map((sheet) => sheet.properties?.title)
      .filter((title): title is string => title !== undefined);

    const cells = (
      await Promise.all(
        sheetTitles.map(async (sheetTitle) => {
          const res = await fetchSpreadsheetSheetByTitle(spreadSheetId, sheetTitle, 'UNFORMATTED_VALUE').catch(
            (e: Error) => {
              return e;
            }
          );

          if (res instanceof Error) {
            console.error(res);
            return;
          }

          return res.result.values;
        })
      )
    ).filter((cells): cells is any => cells !== undefined);

    const headersToColumns = cells.map((c) => mapHeaderToColumns(c));

    const replacements = headersToColumns
      .map((h) => {
        const oldRows = h['Image URL'];
        const newRows = h['New Image URL'];
        if (!oldRows || !newRows) {
          return [];
        }

        const results = [];
        for (const i in oldRows) {
          const oldValue = oldRows[i];
          const newValue = newRows[i];

          if (oldValue && newValue) {
            results.push({
              old: oldValue
                .replace(/[\r|\n|\r\n|\s]+/g, '')
                .split('#video')[0]
                .split('=')[0],
              new: newValue.replace(/[\r|\n|\r\n|\s]+/g, ''),
            });
          }
        }
        return results;
      })
      .flat();

    this.imageUrlsForReplacing = replacements;
  }
}
