





































import format from 'date-fns/format';
import Component from 'vue-class-component';
import * as JsDiff from 'diff';

import KamigameVue from '@/KamigameVue';
import { V1WikiPageHistory } from '@/api-client/generated/models';

@Component({
  name: 'wiki-page-history',
})
export default class WikiPageHistory extends KamigameVue {
  dateFormat = format;

  aHistory?: V1WikiPageHistory;
  bHistory?: V1WikiPageHistory;

  diffLines: any[] = [];
  pageId: string = '';
  lastUpdater: string = '';
  lastUpdatedAt: Date = new Date();

  async mounted() {
    const api = this.api;

    const a = this.route.params.a;
    const b = this.route.params.b;

    let aBody = '';
    if (a) {
      this.aHistory = await api.getWikiPageHistory(this.wikiName, a);
      aBody = this.aHistory.body || '';
    }
    this.bHistory = await api.getWikiPageHistory(this.wikiName, b);

    if (this.bHistory.wikiPage && this.bHistory.wikiPage.id) {
      this.pageId = this.bHistory.wikiPage.id;
    } else {
      this.pageId = this.route.params.pageId;
    }

    if (this.bHistory.author) {
      this.lastUpdater = this.bHistory.author.nickname || '';
    }
    this.lastUpdatedAt = this.bHistory.createdAt || new Date();

    this.diffLines = JsDiff.diffLines(aBody || '', this.bHistory.body || '')
      .map(l => {
        let type = '';
        if (l.added) {
          type = 'added';
        } else if (l.removed) {
          type = 'removed';
        }

        return l.value
          .replace(/\s$/, '')
          .split(/\n/)
          .map(value => ({ type, value }));
      })
      .reduce((acc, c) => acc.concat(c), []);
  }
}
