var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "wikiPageDeleteWithRedirectionModal",
      attrs: {
        title: "リダイレクト先を設定して一覧から削除",
        "ok-disabled": !(_vm.selectedWikiPageTitleId || _vm.isDeletion),
        centered: "",
        lazy: "",
      },
      on: { ok: _vm.updateWikiPageExistanceState, hide: _vm.clear },
    },
    [
      !_vm.isDeletion
        ? [
            _c("p", [_vm._v("記事を削除するとアクセスできなくなります")]),
            _vm._v(" "),
            _c("p", [_vm._v("記事にリダイレクト先を設定してください")]),
            _vm._v(" "),
            _c("kamigame-wiki-page-title-search-modal", {
              attrs: { data: _vm.wikiPageTitles },
              on: { wikiPageTitleSelected: _vm.onWikiPageTitleSet },
            }),
          ]
        : [
            _c("span", { staticClass: "text-danger" }, [_vm._v("【注意】")]),
            _vm._v("リダイレクト設定しないとアクセスできなくなります\n    "),
            _c("p", [
              _vm._v("上記を理解した上で削除する場合は OK を押下してください"),
            ]),
          ],
      _vm._v(" "),
      _c(
        "b-form-checkbox",
        {
          staticClass: "small",
          model: {
            value: _vm.isDeletion,
            callback: function ($$v) {
              _vm.isDeletion = $$v
            },
            expression: "isDeletion",
          },
        },
        [_vm._v("リダイレクト先を設定せずに削除する")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }