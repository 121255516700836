var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c(
                  "b-card",
                  { attrs: { "header-tag": "header", "footer-tag": "footer" } },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c("i", { staticClass: "fa fa-align-justify" }),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Wiki データシート編集")]),
                    ]),
                    _vm._v(" "),
                    _vm.saving
                      ? [
                          _c("p", [
                            _vm._v(
                              "保存中です。しばらくお待ちください... (" +
                                _vm._s(_vm.proceeded) +
                                " / " +
                                _vm._s(_vm.updateData.sheets.length) +
                                ")"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-progress",
                            { attrs: { max: _vm.updateData.sheets.length } },
                            [
                              _c("b-progress-bar", {
                                attrs: {
                                  value: _vm.proceeded,
                                  variant: "primary",
                                  striped: "",
                                  animated: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "Can",
                      { attrs: { I: "", do: "ACTION_wiki_db_update" } },
                      [
                        _c("p", [
                          _vm._v(
                            "スプレッドシートと同期しているシートの管理が行えます。"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("b-btn", { on: { click: _vm.syncAllDataSheet } }, [
                          _vm._v("全てのシートを最新の情報にする"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-btn",
                          {
                            staticClass: "ml-3",
                            on: { click: _vm.showCreateTabModal },
                          },
                          [_vm._v("新しくシートを追加する")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("kamigame-wiki-data-sheet-tab-table", {
                      staticClass: "mt-3",
                      attrs: {
                        data: _vm.data,
                        isOperationableSheet: true,
                        isAddableDataSheet: false,
                      },
                      on: {
                        "need-fetching-selected-sheet-data":
                          _vm.fetchSpecifiedSheetData,
                        "on-creating-tab": _vm.showCreateTabModal,
                        "change-selected-sheet-index":
                          _vm.changeSelectedSheetIndex,
                        "delete-data-sheet": _vm.showDeleteDataSheetModal,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (slotProps) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-inline-flex align-items-start",
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      slotProps.dataSheet &&
                                      slotProps.dataSheet.spreadsheetId
                                        ? _c(
                                            "b-btn",
                                            {
                                              on: {
                                                click:
                                                  _vm.syncIndividualDataSheet,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    このシートの情報を最新にする\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "mt-3",
                                          model: {
                                            value: _vm.updatesReferencedSheets,
                                            callback: function ($$v) {
                                              _vm.updatesReferencedSheets = $$v
                                            },
                                            expression:
                                              "updatesReferencedSheets",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "このシートを参照しているシートも更新する"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  slotProps.dataSheet &&
                                  slotProps.dataSheet.spreadsheetId
                                    ? _c(
                                        "b-btn",
                                        {
                                          staticClass: "ml-3",
                                          on: {
                                            click:
                                              _vm.showEditSpreadSheetSheetModalUpdating,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  同期するシートを変更する\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c(
                      "b-modal",
                      {
                        ref: "createTabModal",
                        attrs: {
                          size: "lg",
                          "no-close-on-backdrop": "",
                          "ok-title": "取得する",
                          "ok-disabled": !_vm.spreadsheetUrl || !_vm.sheetTitle,
                          static: "",
                        },
                        on: {
                          ok: _vm.editSpreadsheetSheet,
                          hide: function ($event) {
                            _vm.hasGottenSpreadsheetSheetTitles = false
                            _vm.spreadsheetUrl = ""
                            _vm.spreadsheetSheetTitles = []
                            _vm.sheetTitle = ""
                          },
                        },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "スプレッドシートよりシートを取得します。下記に取得したいスプレッドシートの URL を入力してください。"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "その後、取得したいシートを選択してください。"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-row",
                          { staticClass: "my-1" },
                          [
                            _c("b-col", { attrs: { sm: "3" } }, [
                              _c("label", [_vm._v("スプレッドシートURL:")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { attrs: { sm: "7" } },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.spreadsheetUrl,
                                    callback: function ($$v) {
                                      _vm.spreadsheetUrl = $$v
                                    },
                                    expression: "spreadsheetUrl",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { attrs: { sm: "2" } },
                              [
                                _c(
                                  "b-btn",
                                  { on: { click: _vm.fetchSpreadsheetMeta } },
                                  [_vm._v("決定")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.hasGottenSpreadsheetSheetTitles
                          ? _c(
                              "b-row",
                              { staticClass: "my-1" },
                              [
                                _c("b-col", { attrs: { sm: "3" } }, [
                                  _c("label", [_vm._v("シート名:")]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  { attrs: { sm: "9" } },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "text",
                                        list: "spreadsheetSheetTitles",
                                      },
                                      model: {
                                        value: _vm.sheetTitle,
                                        callback: function ($$v) {
                                          _vm.sheetTitle = $$v
                                        },
                                        expression: "sheetTitle",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "datalist",
                                      {
                                        attrs: { id: "spreadsheetSheetTitles" },
                                      },
                                      _vm._l(
                                        _vm.spreadsheetSheetTitles,
                                        function (title, index) {
                                          return _c("option", { key: index }, [
                                            _vm._v(_vm._s(title)),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sheetTitle &&
                        _vm.updateSheetId &&
                        _vm.data.sheets[_vm.selectedSheetIndex] !==
                          _vm.sheetTitle
                          ? _c(
                              "p",
                              { staticClass: "text-danger font-weight-bold" },
                              [
                                _vm._v(
                                  "\n              シート名が変更されます。記事部品から参照している場合、正しく表示が行われません。確認のうえ、「取得する」を押下してください。\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-modal",
                      {
                        ref: "editSpreadsheetSheetsModal",
                        attrs: { "ok-title": "保存", size: "xl", static: "" },
                        on: { ok: _vm.save, cancel: _vm.clean },
                      },
                      [
                        _vm._v(
                          "\n            以下の内容を反映します。内容を確認し、「保存」をクリックしください\n            "
                        ),
                        !_vm.updatesReferencedSheets
                          ? _c("p", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              このシートを参照しているシートの情報が最新にならないため古い情報が掲載される可能性があります\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("kamigame-wiki-data-sheet-tab-table", {
                          attrs: {
                            data: _vm.updateData,
                            comparedData: _vm.comparedData,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.currentSheet
                      ? _c(
                          "b-modal",
                          {
                            ref: "deleteDataSheetModal",
                            attrs: { "ok-title": "削除", static: "" },
                            on: {
                              ok: _vm.deleteDataSheet,
                              hide: function ($event) {
                                _vm.confirmDeleteDataSheetText = ""
                              },
                            },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                "「" +
                                  _vm._s(_vm.currentSheet.sheetName) +
                                  "」のデータを削除します"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              上記シートを記事部品から参照している場合、記事が正しく表示されません。参照がないことを確認ください。\n            "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-modal",
                      {
                        ref: "fetchingSpreadsheetModal",
                        attrs: {
                          "hide-header": "",
                          "hide-footer": "",
                          static: "",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("p", [_vm._v("シートの情報を取得中です")]),
                            _vm._v(" "),
                            _c("b-spinner", {
                              staticStyle: { width: "3rem", height: "3rem" },
                              attrs: { label: "Large Spinner" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }