























































































































































































































































import Component from 'vue-class-component';
import { MarkdownParser } from 'kamigame-web';
import format from 'date-fns/format';
import { BModal } from 'bootstrap-vue';

import KamigameVue from '@/KamigameVue';
import {
  V1BulkCreateWikiPageRequestBody,
  V1WikiPageCategory,
  V1BulkUpdateWikiPageRequestBody,
  V1BulkCreateWikiPageRequestArticle,
  V1BulkUpdateWikiPageRequestArticle,
} from '@/api-client/generated/models';
import { CategoryTreeSelectModal } from '@/components';
import { extractDependingSpreadsheetsFromMarkdownText } from 'wiki-shared/template-code-util';
import { braceError as titleBraceError, validateWikiPageTitle } from '@/service/WikiPageTitleValidation';

enum PublishedState {
  Published = '1',
  Draft = '0',
  Reserved = '2',
}

@Component({
  name: 'wiki-page-import',
  components: {
    'kamigame-category-tree-select-modal': CategoryTreeSelectModal,
  },
})
export default class WikiPageImport extends KamigameVue {
  files: File[] = [];
  successCreated: any[] = [];
  successUpdated: any[] = [];
  error: any[] = [];
  uploading: boolean = false;
  published: string = '1';
  publishedDate: string = '';
  publishedTime: string = '';
  selectedCategory: V1WikiPageCategory = {};
  titleChangeManager: TitleChangeManager = new TitleChangeManager();
  bulkCreateWikiPageRequestArticles: V1BulkCreateWikiPageRequestArticle[] = [];
  bulkUpdateWikiPageRequestArticles: V1BulkUpdateWikiPageRequestArticle[] = [];
  errorCandidates: any[] = [];
  duplicateTitles: { title: string; path: string }[] = [];

  get proceeded() {
    return this.successCreated.length + this.successUpdated.length + this.error.length;
  }

  get titleDetectCount() {
    const allArticle = [...this.bulkCreateWikiPageRequestArticles, ...this.bulkUpdateWikiPageRequestArticles];
    return allArticle.filter((v) => v.title === undefined || v.title === '').length;
  }

  get completed() {
    return this.files.length < this.proceeded;
  }

  data() {
    return {
      files: [],
      success: [],
      error: [],
      uploading: false,
      published: PublishedState.Published,
      publishedDate: '',
      publishedTime: '',
      selectedCategory: {},
    };
  }

  clearCategory() {
    this.selectedCategory = {};
  }

  onCategorySaved(category: V1WikiPageCategory) {
    this.selectedCategory = category;
  }

  async uploadBulkCreateChunk(chunk: V1BulkCreateWikiPageRequestArticle[]) {
    const bulkCreateRequest: V1BulkCreateWikiPageRequestBody = {
      articles: chunk,
    };
    const res = await this.api.bulkCreateWikiPage(this.wikiName, bulkCreateRequest);
    (res.result || []).forEach((v) => {
      if (v.error) {
        this.error.push({ name: v.file, errors: ['サーバでの処理中に予期しないエラーが発生しました', v.error] });
      } else {
        this.successCreated.push({ title: v.title || '(タイトルなし)', name: v.file, id: v.id });
      }
    });
  }

  async uploadBulkUpdateChunk(chunk: V1BulkUpdateWikiPageRequestArticle[]) {
    const bulkUpdateRequest: V1BulkUpdateWikiPageRequestBody = {
      articles: chunk,
    };
    const res = await this.api.bulkUpdateWikiPage(this.wikiName, bulkUpdateRequest);
    (res.result || []).forEach((v) => {
      if (v.error) {
        this.error.push({ name: v.file, errors: ['サーバでの処理中に予期しないエラーが発生しました', v.error] });
      } else {
        if (v.id) {
          const titleChange = this.titleChangeManager.getTitleChange(v.id);
          this.successUpdated.push({
            title: v.title || '(タイトルなし)',
            name: v.file,
            id: v.id,
            previousTitle: titleChange.previousTitle,
            newTitle: titleChange.newTitle,
          });
        }
      }
    });
  }

  async save() {
    this.successCreated = [];
    this.successUpdated = [];
    this.error = [];

    if (
      this.titleChangeManager.titleChangedCount > 0 &&
      !confirm(
        `${this.titleChangeManager.titleChangedCount} 件の記事のタイトルが上書きによって変更されます。本当によろしいですか？`
      )
    ) {
      this.reset();
      return;
    }

    const chunkSize = 10;
    const bulkCreateChunk: V1BulkCreateWikiPageRequestArticle[][] = [];
    for (let i = 0; i < Math.ceil(this.bulkCreateWikiPageRequestArticles.length / chunkSize); i++) {
      bulkCreateChunk.push(this.bulkCreateWikiPageRequestArticles.slice(i * chunkSize, i * chunkSize + chunkSize));
    }

    const bulkUpdateChunk: V1BulkUpdateWikiPageRequestArticle[][] = [];
    for (let i = 0; i < Math.ceil(this.bulkUpdateWikiPageRequestArticles.length / chunkSize); i++) {
      bulkUpdateChunk.push(this.bulkUpdateWikiPageRequestArticles.slice(i * chunkSize, i * chunkSize + chunkSize));
    }

    await bulkCreateChunk.reduce(async (chain, chunk) => {
      await chain;
      await this.uploadBulkCreateChunk(chunk);
      return;
    }, Promise.resolve());

    await bulkUpdateChunk.reduce(async (chain, chunk) => {
      await chain;
      await this.uploadBulkUpdateChunk(chunk);
      return;
    }, Promise.resolve());

    this.reset();
  }

  async upload() {
    this.uploading = true;
    this.errorCandidates = [];

    const entries: any[] = await Promise.all(
      this.files.map(
        (f: File) =>
          new Promise((resolve) => {
            const fileName = (f as any).webkitRelativePath || f.name;

            if (!f.name.endsWith('.md')) {
              this.errorCandidates.push({ name: fileName, errors: ['Markdown ファイルではありません'] });
              resolve({});
              return;
            }
            const reader = new FileReader();
            reader.onload = (event) => {
              if (!event.target) {
                resolve({});
                return;
              }

              resolve({
                name: fileName,
                body: (event.target as any).result,
              });
            };
            reader.readAsText(f);
          })
      )
    );

    await this.translateEntriesToArticlesForCreateAndUpdate(entries);

    this.uploading = false;
  }

  async translateEntriesToArticlesForCreateAndUpdate(entries: any[]) {
    const existingTitles = await this.api.listWikiPageTitles(this.wikiName).then((titles) => {
      return titles.wikiPageTitles?.map((wikiPageTitle) => wikiPageTitle.title);
    });

    for (const entry of entries) {
      if (!entry || !entry.name || !entry.body) {
        continue;
      }
      const defaultParser = new MarkdownParser('dummy', entry.body, '', undefined);
      const name = entry.name;
      const title = defaultParser.meta.title || defaultParser.getTitle() || '';
      const body = entry.body;
      const keywords = defaultParser.meta.keywords || '';
      const description = defaultParser.meta.description || '';
      const categoryId = this.selectedCategory.id;
      const noindex: boolean | undefined = defaultParser.meta.noindex;
      let publishedAt;
      if (this.published === PublishedState.Published) {
        publishedAt = new Date();
      } else if (this.published === PublishedState.Reserved) {
        publishedAt = new Date(
          `${this.publishedDate || format(new Date(), 'yyyy-MM-dd')} ${this.publishedTime || '00:00'}`
        );
      }

      const updatedTitle = validateWikiPageTitle(title).errors.includes(titleBraceError)
        ? title.replace(/^【.+?】/, '')
        : title;

      const isOverwrite = !!defaultParser.meta.url;
      if (isOverwrite) {
        const url = defaultParser.meta.url || '';
        if (!this.isArticleURL(url)) {
          this.errorCandidates.push({
            name: entry.name,
            errors: [
              '上書き対象記事の URL が間違っている可能性があります。',
              'takahiro-gamesからの移行記事の場合は、https://kamigame.jp/[ゲームタイトル]/page/[記事ID].html の形式にする必要があります。',
            ],
          });
          continue;
        }

        const pageId = url.split('/').pop().split('.').shift();
        const response = await this.api.getWikiPage(this.wikiName, pageId).catch(() => null);
        if (!response) {
          this.errorCandidates.push({ name: entry.name, errors: ['存在しない記事に対して上書きしようとしています'] });
          continue;
        }

        if (!response.wikiPage) {
          this.errorCandidates.push({ name: entry.name, errors: ['記事の取得に失敗しました'] });
          continue;
        }

        const pageTitle = response.title || '';
        this.titleChangeManager.addTitleChange(pageId, pageTitle, updatedTitle);

        let updateKeywords = keywords;
        if (!updateKeywords && response.wikiPage.keywords) {
          updateKeywords = response.wikiPage.keywords;
        }

        let updateDescription = description;
        if (!updateDescription && response.wikiPage.description) {
          updateDescription = response.wikiPage.description;
        }

        let updateNoindex = noindex;
        if (noindex === undefined && response.wikiPage.noindex) {
          updateNoindex = response.wikiPage.noindex;
        }

        let updateCategoryId = categoryId;
        if (!updateCategoryId && response.wikiPage.category && response.wikiPage.category.id) {
          updateCategoryId = response.wikiPage.category.id;
        }

        if (response.wikiPage.publishedAt) {
          publishedAt = response.wikiPage.publishedAt;
        }

        this.bulkUpdateWikiPageRequestArticles.push({
          file: name,
          title: updatedTitle,
          pageId,
          body,
          editPermission: 'OBJ_default',
          keywords: updateKeywords,
          description: updateDescription,
          categoryId: updateCategoryId,
          publishedAt,
          noindex: updateNoindex,
          spreadsheetURL: extractDependingSpreadsheetsFromMarkdownText(body),
        });
      } else {
        if (existingTitles && existingTitles.includes(updatedTitle)) {
          this.duplicateTitles.push({
            title: updatedTitle as string,
            path: name,
          });
        }

        this.bulkCreateWikiPageRequestArticles.push({
          file: name,
          title: updatedTitle,
          body,
          editPermission: 'OBJ_default',
          keywords,
          description,
          categoryId,
          publishedAt,
          noindex,
          spreadsheetURL: extractDependingSpreadsheetsFromMarkdownText(body),
        });
      }
    }
  }

  openConfirmDuplicateTitlesModal() {
    const modal = this.$refs.confirmDuplicateTitlesModal as BModal;
    modal.show();
  }

  reset() {
    this.uploading = false;
    this.files = [];
    this.clearCategory();
    this.published = PublishedState.Published;
    this.publishedDate = '';
    this.publishedTime = '';
    this.bulkUpdateWikiPageRequestArticles = [];
    this.bulkCreateWikiPageRequestArticles = [];
    this.errorCandidates = [];
    this.duplicateTitles = [];
  }

  isArticleURL(url: string): boolean {
    try {
      const parsed = new URL(url);
      const pathnameComponents = parsed.pathname.split('/').slice(1);

      const hasWiki = pathnameComponents[0] === 'wiki';
      const urlRegExp = new RegExp(
        `${KAMIGAME_URL_BASE}${hasWiki ? '/wiki' : ''}/(${this.wikiName}|${encodeURIComponent(
          this.wikiName
        )})/page/[0-9]+.html`
      );

      return urlRegExp.test(url);
    } catch {
      return false;
    }
  }

  get exampleURL() {
    return `${WIKI_URL_BASE}/${encodeURIComponent(this.wikiName)}/page/12345.html`;
  }
}

class TitleChangeManager {
  titleChanges: { pageId: string; previousTitle: string; newTitle: string }[] = [];

  addTitleChange(pageId: string, previousTitle: string, newTitle: string) {
    this.titleChanges.push({ pageId, previousTitle, newTitle });
  }

  get titleChangedCount() {
    return this.titleChanges.filter((t) => t.newTitle != t.newTitle).length;
  }

  getTitleChange(pageId: string) {
    return this.titleChanges.filter((t) => t.pageId == pageId)[0];
  }
}
