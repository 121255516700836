var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-image-modal__wrapper" },
    [
      _c(
        "b-modal",
        {
          ref: "mainImageModal",
          staticClass: "main-image-modal",
          attrs: { centered: "", static: "" },
          on: { shown: _vm.onShown },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("b-button", { on: { click: _vm.hide } }, [
                        _vm._v("キャンセル"),
                      ]),
                      _vm._v(" "),
                      _c("b-button", { attrs: { variant: "primary" } }, [
                        _vm._v("プレビュー"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "main-image-content" }, [
            _c(
              "div",
              { staticClass: "main-image-rule d-flex justify-content-center" },
              [
                _c(
                  "div",
                  { staticClass: "main-image-section" },
                  [
                    _c("div", { staticClass: "section-top-boader" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "main-image-section__title" }, [
                      _vm._v("\n            セクション\n            "),
                      _c("div", { staticClass: "section-title-boader" }),
                    ]),
                    _vm._v(" "),
                    _vm.sections.length > 0
                      ? _c(
                          "div",
                          _vm._l(_vm.sections, function (section, i) {
                            return _c(
                              "div",
                              { key: section, staticClass: "mt-3" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s("セクション" + (i + 1)) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-3",
                        attrs: { type: "submit", variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.addSection()
                          },
                        },
                      },
                      [_vm._v("セクション追加")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "main-image-verticalline__left" }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "main-image-control d-flex flex-column align-items-center",
                  },
                  [
                    _c("vue-cropper", {
                      ref: "cropper",
                      staticClass: "modal-image",
                      attrs: {
                        "initial-aspect-ratio": 16 / 9,
                        src: _vm.$props.imageUrl,
                        autoCrop: true,
                      },
                      on: { ready: _vm.onReady },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "main-image-input" },
                  [
                    _c("div", { staticClass: "input-top-boader" }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "main-image-verticalline__right",
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "main-image-input__title" }, [
                      _vm._v("\n            ルール入力\n            "),
                      _c("div", { staticClass: "input-title-boader" }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        staticClass: "input-rule-type",
                        attrs: { "aria-label": "Select option" },
                      },
                      [
                        _c("option", { attrs: { selected: "" } }, [
                          _vm._v("Option 1"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("Option 2"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "3" } }, [
                          _vm._v("Option 3"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-3 btn btn-lg",
                        attrs: { type: "submit", variant: "primary" },
                      },
                      [_vm._v("確定する")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }