







































import KamigameVue from '@/KamigameVue';
import { V1WikiCollection, V1Wiki } from '@/api-client/generated/models';
import Component from 'vue-class-component';
import { Header as CoreUIHeader } from '~core-ui/components/';
import WikiSelectModal from '@/components/WikiSelectModal.vue';

const maxWikiTitleLength: number = 20;

@Component({
  name: 'kamigame-header',
  components: {
    WikiSelectModal,
  },
})
export default class Header extends KamigameVue {
  wikiTitle: string = '';
  joinedWiki: V1WikiCollection = {
    wiki: [],
  };

  get excerptedWiki() {
    return this.joinedWiki
      .wiki!.map((wiki) => {
        let title = wiki.title || '';
        if (title.length > maxWikiTitleLength) {
          title = title.slice(0, maxWikiTitleLength) + '...';
        }
        return { ...wiki, title };
      })
      .sort((x, y) => {
        const xTitle = x.title || '';
        const yTitle = y.title || '';
        return xTitle.localeCompare(yTitle, 'ja');
      });
  }

  mounted() {
    this.$store.getters.getWiki(this.wikiName).then((wiki: V1Wiki) => {
      this.wikiTitle = wiki.title || '';
      if (this.wikiTitle.length > maxWikiTitleLength) {
        this.wikiTitle = this.wikiTitle.slice(0, maxWikiTitleLength) + '...';
      }
    });
  }

  openWikiMenu() {
    (this.$refs.wikiSelectModal as WikiSelectModal).open();

    if (!this.joinedWiki.wiki || this.joinedWiki.wiki.length > 0) {
      return;
    }

    this.api.listJoinedWiki().then((result: V1WikiCollection) => {
      this.joinedWiki = result;
    });
  }

  moveToWiki(wiki: V1Wiki) {
    const route = this.$router.resolve({ name: 'wiki_dashboard', params: { name: wiki.name! } });
    window.open(route.href, '_blank');
  }

  sidebarToggle(e: Event) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e: Event) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e: Event) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle(e: Event) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }
}
