





































import VueRouter from 'vue-router';
import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';
import nav from '@/_nav';
import { Footer as AppFooter, Header as AppHeader, Sidebar, AppAside, Breadcrumb } from '@/components';
import { hideMenuForContributors } from '@/patch/hide-menu-for-contributors';

Component.registerHooks(['beforeRouteEnter']);

@Component({
  name: 'container',
  components: {
    AppHeader,
    Sidebar,
    AppAside,
    AppFooter,
    Breadcrumb,
  },
})
export default class Container extends KamigameVue {
  dragging = false;

  beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    this.clearAllFlashMessages();
    next();
  }

  mounted() {
    // 業務委託者向け wiki では利用しないメニューを隠すようにする
    this.$nextTick(() => {
      hideMenuForContributors();
    });
  }

  get nav() {
    return nav(this.wikiName, this.$ability, this.$router).items;
  }

  get name(): string {
    return this.route.name || '';
  }

  get list() {
    return this.route.matched.filter((r) => {
      if (r.meta && r.meta.index) {
        return false;
      }

      return true;
    });
  }

  isBreadcrumbShowed() {
    // NOTE: エディターを広くするために編集画面ではパンくずを表示しない
    return this.route.name !== 'wiki_page_edit';
  }

  onDrop(e: DragEvent) {
    this.dragging = false;

    let element: EventTarget | null = e.target;
    while (element) {
      if ((element as HTMLElement).classList.contains('kamigame-file-draggable')) {
        break;
      }

      element = (element as HTMLElement).parentElement;
    }

    if (!element) {
      return;
    }

    const files = e.dataTransfer ? Array.from(e.dataTransfer.items).map((v) => v.getAsFile()) : [];

    element.dispatchEvent(
      new CustomEvent('kamigame:fileDropped', {
        detail: {
          files,
        },
      })
    );
    e.preventDefault();
  }
}
