var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("ButtonDownloadCsvForDeferenceFromSource", {
            staticClass: "button",
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("ButtonBulkPublishDrafts", { staticClass: "button" }),
          _vm._v(" "),
          _c("ButtonBulkReplaceImageUrl", { staticClass: "button" }),
          _vm._v(" "),
          _c("ButtonBulkReplaceContentHeader", { staticClass: "button" }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [_c("ButtonBulkTransferArticles", { staticClass: "button" })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "card-header" }, [
      _c("strong", [_vm._v(" 情報取得 ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "card-header" }, [
      _c("strong", [_vm._v(" 記事一括変換 ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "card-header" }, [
      _c("strong", [_vm._v(" Wiki 移行 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }