import { KamigameWikiApi } from '@/api-client/generated/kamigameWikiApi';
import { V1Image } from '@/api-client/generated/models';

const calcInterval = (count: number) => {
  return (Math.log(count) / Math.log(1.5)) * 1000;
};

const isServingURL = (imageURL: string) => {
  const imageServingURLPrefix = API_URL_BASE.startsWith('http://localhost')
    ? `${API_URL_BASE}/_ah/`
    : 'https://lh3.googleusercontent.com';

  return imageURL.startsWith(imageServingURLPrefix);
};

const getImageWithSetTimeout = (api: KamigameWikiApi, count: number, imageId: string) => {
  const retryTimes = 10;

  return new Promise<V1Image>((resolve, reject) => {
    setTimeout(async () => {
      const image = await api.getImage(imageId);

      if (image.url && isServingURL(image.url || '')) {
        resolve(image);
      } else if (count > retryTimes) {
        reject('Cannot get serving url');
      } else {
        getImageWithSetTimeout(api, count + 1, imageId)
          .then((image: V1Image) => {
            resolve(image);
          })
          .catch(e => {
            reject(e);
          });
      }
    }, calcInterval(count));
  });
};

export async function wikiGetImageWithServingURL(api: KamigameWikiApi, imageId: string): Promise<V1Image> {
  return getImageWithSetTimeout(api, 1, imageId);
}
