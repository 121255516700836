




import Component from 'vue-class-component';
import KamigameVue from './KamigameVue';

@Component({
  name: 'app',
})
export default class App extends KamigameVue {}
