var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c(
                  "b-card",
                  { attrs: { "header-tag": "header", "footer-tag": "footer" } },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c("i", { staticClass: "fa fa-align-justify" }),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Wiki データシート編集")]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "Can",
                      { attrs: { I: "", do: "ACTION_wiki_db_update" } },
                      [
                        _c("p", [
                          _vm._v(
                            "データシートを更新します。更新したいデータシートを選択するか、新規に作成してください。"
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("kamigame-wiki-data-sheet-tab-table", {
                      staticClass: "mt-3",
                      attrs: {
                        data: _vm.data,
                        isOperationableSheet: true,
                        isAddableDataSheet: true,
                        isEditableTab: true,
                      },
                      on: {
                        "need-fetching-selected-sheet-data":
                          _vm.fetchSpecifiedSheetData,
                        "on-creating-tab": _vm.createTab,
                        "change-selected-sheet-index":
                          _vm.changeSelectedSheetIndex,
                        "delete-data-sheet": _vm.showDeleteDataSheetModal,
                        "edit-sheet-title": _vm.showEditSheetTitleModal,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (slotProps) {
                              return slotProps.dataSheet &&
                                !slotProps.dataSheet.spreadsheetId
                                ? [
                                    _c(
                                      "Can",
                                      {
                                        attrs: {
                                          I: "",
                                          do: "ACTION_wiki_db_update",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "download" },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                "\n                    以下のボタンから CSV ファイルをダウンロードし、 Microsoft® Excel®\n                    などのソフトウェアから編集してください。\n                  "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "b-btn",
                                              { on: { click: _vm.download } },
                                              [
                                                _vm._v(
                                                  "データシートをダウンロード"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "upload" },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                "編集した CSV ファイルを以下のフォームからアップロードしてください。"
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("b-form-file", {
                                              attrs: {
                                                state: Boolean(_vm.file),
                                                placeholder:
                                                  "ファイルを選択してください",
                                              },
                                              on: { change: _vm.upload },
                                              model: {
                                                value: _vm.file,
                                                callback: function ($$v) {
                                                  _vm.file = $$v
                                                },
                                                expression: "file",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "Can",
                                      {
                                        attrs: {
                                          I: "",
                                          do: "ACTION_wiki_db_update",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "b-btn",
                                                {
                                                  on: {
                                                    click:
                                                      _vm.showImageUploadModal,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "画像をアップロードする"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "b-modal",
                                          {
                                            ref: "imageUploderModal",
                                            class: {
                                              uploading:
                                                _vm.imageUploadingProgress,
                                            },
                                            attrs: {
                                              centered: "",
                                              title: "画像をアップロード",
                                              static: "",
                                            },
                                            on: {
                                              hide: _vm.clearImageUploader,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "custom-file-container",
                                                attrs: {
                                                  "data-upload-id":
                                                    "imageUpload",
                                                },
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    "\n                      画像\n                      "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.imageSelected,
                                                          expression:
                                                            "imageSelected",
                                                        },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        (\n                        "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "custom-file-container__image-clear",
                                                          attrs: {
                                                            href: "javascript:void(0)",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.clearImage,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "画像の選択を解除"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        ")\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "custom-file-container__custom-file",
                                                  },
                                                  [
                                                    _c("input", {
                                                      staticClass:
                                                        "custom-file-container__custom-file__custom-file-input",
                                                      attrs: {
                                                        type: "file",
                                                        accept:
                                                          "image/png, image/jpeg, image/gif",
                                                        multiple: "",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      staticClass:
                                                        "custom-file-container__custom-file__custom-file-control",
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("b-form-text", [
                                                  _vm._v(
                                                    "5 MB 以下 の JPEG, PNG, GIF のみ"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      挿入行_挿入列_ファイル名.拡張子\n                      "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "のファイル名で指定した行・列に画像の URL を挿入します。 "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "【例】3_5_画像.png\n                    "
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass:
                                                    "custom-file-container__image-preview article-image",
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "template",
                                              { slot: "modal-footer" },
                                              [
                                                _vm.imageUploadingProgress
                                                  ? _c(
                                                      "b-progress",
                                                      {
                                                        attrs: {
                                                          width: "100%",
                                                        },
                                                      },
                                                      [
                                                        _c("b-progress-bar", {
                                                          attrs: {
                                                            value: 100,
                                                            variant: "info",
                                                            striped: "",
                                                            animated: "",
                                                            label:
                                                              "アップロード中です。しばらくお待ちください",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      [
                                                        _c("b-button", [
                                                          _vm._v("キャンセル"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              disabled:
                                                                _vm.imageUploadingDisabled,
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.processImageUploader,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "この画像を記事に挿入"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "Can",
                                      {
                                        attrs: {
                                          I: "",
                                          do: "ACTION_wiki_db_update",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "b-btn",
                                                {
                                                  on: {
                                                    click:
                                                      _vm.showRelateSpreadsheetModal,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "スプレッドシートを紐付ける"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : undefined
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c(
                      "b-modal",
                      {
                        ref: "editSheetTitleModal",
                        attrs: { "ok-title": "保存", static: "" },
                        on: {
                          ok: _vm.save,
                          hide: function ($event) {
                            _vm.updateData = { sheets: [] }
                            _vm.updateSheetTitle = ""
                          },
                        },
                      },
                      [
                        _c("template", { slot: "modal-title" }, [
                          _vm._v("シートのタイトルを変更する"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "text-danger font-weight-bold" },
                          [_vm._v("【注意】")]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "text-danger font-weight-bold" },
                          [
                            _vm._v(
                              "\n              シートのタイトルを変更すると、このシートを参照する記事部品が正しく表示されなくなります。確認の上、変更してください。\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("更新したいシート名を入力してください。"),
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.updateSheetTitle,
                            callback: function ($$v) {
                              _vm.updateSheetTitle = $$v
                            },
                            expression: "updateSheetTitle",
                          },
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "b-modal",
                      {
                        ref: "editSheetDataModal",
                        attrs: { "ok-title": "保存", size: "xl", static: "" },
                        on: {
                          ok: _vm.save,
                          hide: function ($event) {
                            _vm.updateData = { sheets: [] }
                            _vm.comparedData = { sheets: [] }
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            以下のシートを追加します。内容を確認し、「追加」をクリックしください\n            "
                        ),
                        _c("kamigame-wiki-data-sheet-tab-table", {
                          attrs: {
                            data: _vm.updateData,
                            comparedData: _vm.comparedData,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.currentSheet
                      ? _c(
                          "b-modal",
                          {
                            ref: "deleteDataSheetModal",
                            attrs: { "ok-title": "削除", static: "" },
                            on: {
                              ok: _vm.deleteDataSheet,
                              hide: function ($event) {
                                _vm.confirmDeleteDataSheetText = ""
                              },
                            },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                "「" +
                                  _vm._s(_vm.currentSheet.sheetName) +
                                  "」のデータを削除します"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              上記シートを記事部品から参照している場合、記事が正しく表示されません。参照がないことを確認ください。\n            "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentSheet
                      ? _c(
                          "b-modal",
                          {
                            ref: "relateSpreadsheetModal",
                            attrs: { size: "xl" },
                            on: {
                              ok: _vm.save,
                              hide: function ($event) {
                                _vm.spreadsheetUrl = ""
                              },
                            },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                "スプレッドシートよりシートを取得します。下記に取得したいスプレッドシートの URL を入力してください。"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-row",
                              { staticClass: "my-1" },
                              [
                                _c("b-col", { attrs: { sm: "3" } }, [
                                  _c("label", [_vm._v("スプレッドシートURL:")]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  { attrs: { sm: "7" } },
                                  [
                                    _c("b-form-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.spreadsheetUrl,
                                        callback: function ($$v) {
                                          _vm.spreadsheetUrl = $$v
                                        },
                                        expression: "spreadsheetUrl",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  { attrs: { sm: "2" } },
                                  [
                                    _c(
                                      "b-btn",
                                      { on: { click: _vm.relateSpreadsheet } },
                                      [_vm._v("決定")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.updateData.sheets.length > 0
                              ? [
                                  _c("p", [
                                    _vm._v(
                                      "以下のシートを紐付けます。内容を確認し、「OK」をクリックしてください。"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("kamigame-wiki-data-sheet-tab-table", {
                                    attrs: { data: _vm.updateData },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }