













import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'error',
})
export default class ErrorPage extends KamigameVue {
  @Prop()
  error?: Error;

  @Prop()
  message?: string;

  @Prop()
  reason?: string;
}
