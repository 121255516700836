









































import Component from 'vue-class-component';
import LiquorTree from 'vls-liquor-tree';
import KamigameVue from '@/KamigameVue';
import { V1Wiki, V1WikiMeta, V1WikiPageCategory } from '@/api-client/generated/models';

@Component({
  name: 'wiki-page-category-create',
  components: {
    [LiquorTree.name]: LiquorTree,
  },
})
export default class WikiPageCategoryCreaye extends KamigameVue {
  newCategoryName: string = '';
  selectedParentCategory: V1WikiPageCategory | null = null;

  categoryTreeOptions = {
    fetchData: (node: any) => {
      return this.fetchParentCategories().then((categories: V1WikiPageCategory[]) =>
        categories.map(c => this.transformCategoryToTreeItem(c))
      );
    },
  };

  fetchParentCategories() {
    return this.api.listParentWikiPageCategory(this.wikiName).then(response => {
      return response.wikiPageCategories || [];
    });
  }

  transformCategoryToTreeItem(category: V1WikiPageCategory) {
    return {
      id: category.id,
      text: category.name,
      data: category,
      isBatch: false,
    };
  }

  clear() {
    this.newCategoryName = '';
    this.selectedParentCategory = null;
  }

  get selectedParentName() {
    if (!this.selectedParentCategory || !this.selectedParentCategory.displayName) {
      return '';
    }

    return this.selectedParentCategory.displayName;
  }

  onParentCategorySelected(node: any) {
    this.selectedParentCategory = node.data;
  }

  async save() {
    let parentID = undefined;
    let displayName = this.newCategoryName;
    if (this.selectedParentCategory) {
      parentID = this.selectedParentCategory.id;
      displayName = `${this.selectedParentName}/${this.newCategoryName}`;
    }

    const category = {
      name: this.newCategoryName,
      parentID: parentID,
      displayName,
    };
    this.api
      .createWikiPageCategory(this.wikiName, category)
      .then((result: V1WikiPageCategory) => {
        this.router.push(
          {
            name: 'wiki_page_category_list',
          },
          () => {
            this.setFlashMessage('success', 'カテゴリを作成しました');
          }
        );
      })
      .catch(e => {
        this.router.push(
          {
            name: 'wiki_page_category_list',
          },
          () => {
            this.setFlashMessage('danger', 'カテゴリの保存に失敗しました');
          }
        );
        console.error(e);
      });
  }
}
