var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn wiki-page-category-list" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { "header-tag": "header", "footer-tag": "footer" } },
                [
                  _c(
                    "div",
                    { attrs: { slot: "header" }, slot: "header" },
                    [
                      _c("strong", [_vm._v("カテゴリ一覧")]),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            size: "sm",
                            variant: "outline-primary",
                            to: { name: "wiki_page_category_create" },
                          },
                        },
                        [_vm._v("新規作成")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-container",
                    { staticClass: "mb-3", attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { lg: "6" } },
                            [
                              _c("b-form-select", {
                                staticClass: "w-100 mt-3",
                                attrs: { options: _vm.sortConditions },
                                on: {
                                  input: function ($event) {
                                    return _vm.getWikiPageCategories()
                                  },
                                },
                                model: {
                                  value: _vm.selectedSortCondition,
                                  callback: function ($$v) {
                                    _vm.selectedSortCondition = $$v
                                  },
                                  expression: "selectedSortCondition",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { lg: "6" } },
                            [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.getWikiPageCategories()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3" },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          placeholder: "検索ワードを入力",
                                        },
                                        model: {
                                          value: _vm.searchString,
                                          callback: function ($$v) {
                                            _vm.searchString = $$v
                                          },
                                          expression: "searchString",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        { attrs: { type: "submit" } },
                                        [_vm._v("検索")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "kamigame-paging",
                    {
                      ref: "kamigamePaging",
                      attrs: {
                        dataLength: _vm.totalCategoriesNum,
                        "parent-per-page": _vm.perRequestWikiPageCategoriesNum,
                      },
                      on: { "change-data-range": _vm.changeDataRange },
                    },
                    [
                      _c(
                        "b-table",
                        {
                          attrs: {
                            slot: "data-list",
                            responsive: "",
                            fields: _vm.fields,
                            items: _vm.categories.wikiPageCategories,
                            "empty-text": "表示可能なカテゴリが見つかりません",
                            "show-empty": "",
                            busy: _vm.loading,
                          },
                          slot: "data-list",
                          scopedSlots: _vm._u([
                            {
                              key: "cell(name)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "Can",
                                    {
                                      attrs: {
                                        I: "",
                                        do: "ACTION_wiki_category_update",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-link",
                                        {
                                          attrs: { variant: "outline-primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showUpdateModal(
                                                data.index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(data.item.name))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-modal",
                                        {
                                          ref: "alertUpdate_" + data.index,
                                          attrs: {
                                            "ok-disabled":
                                              _vm.updatedCategoryName === "",
                                            "hide-header": "",
                                            centered: "",
                                            "no-fade": "",
                                            lazy: "",
                                          },
                                          on: {
                                            ok: function ($event) {
                                              return _vm.updateCategoryName(
                                                data.item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v("変更前のカテゴリ名"),
                                          ]),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(data.item.displayName)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v("更新後のカテゴリ名"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "b-input-group",
                                            [
                                              data.item.parentID
                                                ? _c("b-input-group-prepend", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "input-group-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getParentCategoryName(
                                                              data.item
                                                            )
                                                          ) + " /"
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("b-form-input", {
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "新しいカテゴリ名",
                                                },
                                                model: {
                                                  value:
                                                    _vm.updatedCategoryName,
                                                  callback: function ($$v) {
                                                    _vm.updatedCategoryName =
                                                      $$v
                                                  },
                                                  expression:
                                                    "updatedCategoryName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "Can",
                                    {
                                      attrs: {
                                        not: "",
                                        I: "",
                                        do: "ACTION_wiki_category_update",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(data.item.name) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(parent_name)",
                              fn: function (data) {
                                return [
                                  _vm.getParentCategoryName(data.item)
                                    ? _c(
                                        "span",
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getParentCategoryName(
                                                data.item
                                              )
                                            ) + " ("
                                          ),
                                          _c(
                                            "b-link",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeParentCategory(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-times",
                                              }),
                                            ]
                                          ),
                                          _vm._v(")"),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "b-link",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openSetParentCategoryModal(
                                                    data.index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-plus",
                                              }),
                                              _vm._v("親カテゴリを設定する"),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                            {
                              key: "cell(category_page)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data.item.wikiPageTitle || "") +
                                      "\n              "
                                  ),
                                  _c(
                                    "Can",
                                    {
                                      attrs: {
                                        I: "",
                                        do: "ACTION_wiki_category_update",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-link",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.showSetCategoryPageModal(
                                                data.index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          data.item.wikiPageTitle
                                            ? _c("span", [
                                                _c("br"),
                                                _vm._v(
                                                  "設定済みの記事を変更する"
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  "このカテゴリに記事を設定する"
                                                ),
                                              ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-modal",
                                    {
                                      ref: "alertSettingPage_" + data.index,
                                      attrs: {
                                        "hide-header": "",
                                        centered: "",
                                        "no-fade": "",
                                        lazy: "",
                                      },
                                      on: {
                                        ok: function ($event) {
                                          return _vm.setCategoryPage(data.item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "kamigame-wiki-page-titles-search-modal",
                                        {
                                          attrs: { data: _vm.wikiPageTitles },
                                          on: {
                                            wikiPageTitleSelected:
                                              _vm.onWikiPageTitleSet,
                                          },
                                        }
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(_operation)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "Can",
                                    {
                                      attrs: {
                                        I: "",
                                        do: "ACTION_wiki_category_update",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            size: "sm",
                                            variant: "outline-danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showDeleteModal(
                                                data.index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash-o",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v(" 削除"),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-modal",
                                        {
                                          ref: "alertDelete_" + data.index,
                                          attrs: {
                                            "hide-header": "",
                                            centered: "",
                                            "no-fade": "",
                                            lazy: "",
                                          },
                                          on: {
                                            ok: function ($event) {
                                              return _vm.deleteCategory(
                                                data.index,
                                                data.item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              "指定されたカテゴリーを削除します。"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              "本当に続行してもよろしいですか？"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-center my-2",
                              attrs: { slot: "table-busy" },
                              slot: "table-busy",
                            },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _vm._v(" "),
                              _c("strong", [_vm._v("読み込み中...")]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "setParentCategoryModal",
          attrs: {
            title: "親カテゴリを設定する",
            "hide-footer": "",
            static: "",
          },
        },
        [
          _c(
            "b-input-group",
            [
              _c("b-form-input", {
                attrs: { type: "text", placeholder: "カテゴリ名で絞り込む" },
                model: {
                  value: _vm.categoryTreeFilter,
                  callback: function ($$v) {
                    _vm.categoryTreeFilter = $$v
                  },
                  expression: "categoryTreeFilter",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("tree", {
            ref: "tree",
            attrs: {
              options: _vm.categoryTreeOptions,
              filter: _vm.categoryTreeFilter,
            },
            on: { "node:selected": _vm.onParentCategorySelected },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }