




import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';

@Component({
  name: 'wiki-history-wrapper',
})
export default class WikiHistoryWrapper extends KamigameVue {
}
