


























import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import KamigameVue from '@/KamigameVue';
import { WikiPageTitleSearchModal } from '@/components';
import { V1WikiPageTitle } from '@/api-client/generated/models';

@Component({
  name: 'wiki-page-existance-state-update-modal',
  components: {
    'kamigame-wiki-page-title-search-modal': WikiPageTitleSearchModal,
  },
})
export default class WikiPageExistanceStateUpdateModal extends KamigameVue {
  @Prop()
  deletedWikiPageId!: string;

  wikiPageTitles: V1WikiPageTitle[] = [];
  selectedWikiPageTitleId: string = '';
  isDeletion: boolean = false;

  async mounted() {
    const response = await this.api.listWikiPageTitles(this.wikiName);
    if (!response.wikiPageTitles) {
      return;
    }

    this.wikiPageTitles = response.wikiPageTitles.filter(wikiPageTitle => wikiPageTitle.id !== this.deletedWikiPageId);
  }

  onWikiPageTitleSet(selectedWikiPageTitle: V1WikiPageTitle) {
    this.selectedWikiPageTitleId = selectedWikiPageTitle.id || '';
  }

  updateWikiPageExistanceState() {
    if (!this.deletedWikiPageId) {
      return;
    }

    if (!this.isDeletion && !this.selectedWikiPageTitleId) {
      return;
    }

    this.isDeletion ? this.deleteWikiPage() : this.setRedirect();
  }

  setRedirect() {
    this.api
      .updateWikiPageRedirect(this.wikiName, this.deletedWikiPageId, {
        redirectPageId: this.selectedWikiPageTitleId,
      })
      .then(() => {
        this.setFlashMessage('success', '記事のリダイレクト先設定に成功しました');
        this.$emit('wikiPageDeleted');
      })
      .catch(() => {
        this.setFlashMessage('danger', '記事のリダイレクト設定に失敗しました');
      })
      .finally(() => {
        this.clear();
      });
  }

  deleteWikiPage() {
    this.api
      .deleteWikiPage(this.wikiName, this.deletedWikiPageId)
      .then(() => {
        this.setFlashMessage('success', '記事の削除に成功しました');
        this.$emit('wikiPageDeleted');
      })
      .catch(() => {
        this.setFlashMessage('danger', '記事の削除に失敗しました');
      })
      .finally(() => {
        this.clear();
      });
  }

  clear() {
    this.selectedWikiPageTitleId = '';
    this.isDeletion = false;
  }
}
