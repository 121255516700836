






















































































































































































































import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { Watch } from 'vue-property-decorator';
import { RestError } from '@azure/ms-rest-js';

import KamigameVue from '@/KamigameVue';
import { CategoryTreeSelectModal, Paging, WikiPageExistanceStateUpdateModal } from '@/components';
import {
  V1WikiPageForListCollection,
  V1WikiPageForList,
  V1WikiPageCategory,
  V1WikiPage,
  V1WikiPageWithTitleAndBodyCollection,
} from '@/api-client/generated/models';
import FileSaver from 'file-saver';
import * as StringConverter from '@/service/StringConverter';
import { convertSearchQueryStringToArray } from '@/service/SearchQueryStringConverter';

@Component({
  name: 'wiki-page-list',
  components: {
    'kamigame-category-tree-select-modal': CategoryTreeSelectModal,
    'kamigame-paging': Paging,
    'wiki-page-existance-state-update-modal': WikiPageExistanceStateUpdateModal,
  },
})
export default class WikiPageList extends KamigameVue {
  dateFormat = format;

  loading = false;
  filteredByEmptyCategory = false;
  allChecked = false;
  indeterminate = false;
  checkedIds: string[] = [];

  pages: V1WikiPageForListCollection = { wikiPage: [] };
  fields = [
    { key: '_check' },
    { thStyle: { width: '35%' }, key: 'title', label: '記事名' },
    { thStyle: { width: '15%' }, key: 'category', label: 'カテゴリ' },
    { key: 'published', label: '公開状態' },
    { key: 'privilege', label: '編集権限' },
    { key: 'lastUpdator', label: '更新者' },
    { key: 'lastUpdatedAt', label: '最終更新日時' },
    { key: 'history', label: '更新履歴' },
    { key: '_operation', label: '操作' },
  ];
  sortConditions = [
    { text: '最終更新日が新しい順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' } },
    { text: '最終更新日が古い順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'ASC' } },
    { text: 'タイトル順', value: { sortedBy: 'title', sortOrder: 'ASC' } },
  ];
  selectedCategory: V1WikiPageCategory = {};
  category: V1WikiPageCategory[] = [];
  perRequestWikiPageNum = 20;
  totalPageNum = 0;
  searchString = '';
  includesBodyOnSearch = false;
  selectedSortCondition = { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' };
  kamigamePaging = this.$refs.kamigamePaging as Paging;
  categorySeclectTemplateItemIndex = 0;

  async mounted() {
    this.kamigamePaging = this.$refs.kamigamePaging as Paging;
    this.getWikiPages();
  }

  async getWikiPages(startAt: number = 0) {
    this.loading = true;

    const params = {
      limit: this.perRequestWikiPageNum,
      offset: startAt,
      searchWords: this.searchWords,
      sortedBy: this.selectedSortCondition.sortedBy,
      sortOrder: this.selectedSortCondition.sortOrder || '',
      categoryId: (this.filteredByEmptyCategory ? '-1' : this.selectedCategory.id) || '',
      excludeRedirected: true,
      includesBodyOnSearch: this.includesBodyOnSearch,
    };

    await this.api
      .listWikiPages(this.wikiName, params)
      .then((res: V1WikiPageForListCollection) => {
        this.loading = false;
        this.pages.wikiPage = (res.wikiPage || []).map((wikiPage: V1WikiPageForList) => {
          if (!wikiPage.wikiPage) {
            return wikiPage;
          }

          if (wikiPage.wikiPage.category) {
            return wikiPage;
          }

          // displayName が存在しないと変更に追従できないためデフォルトでいれておく
          const initCategory: V1WikiPageCategory = { displayName: '' };
          wikiPage.wikiPage.category = initCategory;

          return wikiPage;
        });

        this.totalPageNum = res.numOfTotalWikiPages || 0;
        if (startAt === 0) {
          this.kamigamePaging.resetPaging();
        }
      })
      .catch((e) => {
        if (e instanceof RestError && e.statusCode === 404) {
          this.pages.wikiPage = [];
          this.totalPageNum = 0;
          this.loading = false;
        }
      });
  }

  isPublished(obj: V1WikiPageForList) {
    if (!obj.wikiPage) {
      return false;
    }

    return obj.wikiPage.publishedAt && obj.wikiPage.publishedAt < new Date();
  }

  getWikiUrl(pageId: string) {
    const urlBase = this.$store.state.wiki.get(this.wikiName).isRootPath ? KAMIGAME_URL_BASE : WIKI_URL_BASE;
    return `${urlBase}/${encodeURIComponent(this.wikiName)}/page/${pageId}.html`;
  }

  createWikiPagePath(pageId: string, path: string | undefined) {
    return `${this.$store.state.wiki.get(this.wikiName).isRootPath ? '' : '/wiki'}/${encodeURIComponent(
      this.wikiName
    )}/${path ? path : `page/${pageId}`}.html`;
  }

  onPageCategorySelected(category: V1WikiPageCategory, itemIndex: number) {
    if (!this.pages.wikiPage || !this.pages.wikiPage[itemIndex].wikiPage) {
      return;
    }

    this.api
      .updateLinkedWikiPageCategory(this.wikiName, (this.pages.wikiPage[itemIndex].wikiPage as V1WikiPage).id || '', {
        categoryId: category.id,
      })
      .then(() => {
        this.setFlashMessage('success', 'カテゴリを設定しました。');

        if (!this.pages.wikiPage || !this.pages.wikiPage[itemIndex].wikiPage) {
          return;
        }

        (this.pages.wikiPage[itemIndex].wikiPage as V1WikiPage).category = category;
      });
  }

  clearCategory() {
    this.selectedCategory = {};
    this.getWikiPages();
  }

  onCategorySaved(category: V1WikiPageCategory) {
    this.selectedCategory = category;
    this.getWikiPages();
  }

  onBulkCategorySaved(category: V1WikiPageCategory) {
    this.api
      .bulkUpdateCategoryOfWikiPage(this.wikiName, {
        categoryId: category.id,
        pageId: this.checkedIds,
      })
      .then(() => {
        this.setFlashMessage('success', 'カテゴリを一括設定しました。');

        this.pages.wikiPage = (this.pages.wikiPage || []).map((v) => {
          if (!v.wikiPage) {
            return v;
          }

          if (this.checkedIds.includes(v.wikiPage.id || '')) {
            v.wikiPage.category = category;
          }

          return v;
        });
      });
  }

  async deletePage(pageId: string, index: number) {
    this.api.deleteWikiPage(this.wikiName, pageId).then(() => {
      if (this.pages.wikiPage === undefined) {
        return;
      }

      this.pages.wikiPage.splice(index, 1);
      this.setFlashMessage('success', 'ページを削除しました。');
    });
  }

  unlinkWikiPageCategory(item: V1WikiPageForList) {
    if (!item.wikiPage) {
      return;
    }

    this.api
      .updateLinkedWikiPageCategory(this.wikiName, item.wikiPage.id || '', {
        categoryId: '0',
      })
      .then(() => {
        this.setFlashMessage('success', 'カテゴリ設定を解除しました。');
        if (!item.wikiPage) {
          return;
        }

        item.wikiPage.category = { displayName: '' };
      });
  }

  showDeleteModal(wikiPage: V1WikiPage, index: number) {
    if (!!wikiPage.redirectPageTitle) {
      const modal = this.$refs[`deletePage_${index}`] as BModal;
      modal.show();
    }

    const modal = (this.$refs[`deletePageWithRedirection_${index}`] as KamigameVue).$refs
      .wikiPageDeleteWithRedirectionModal as BModal;
    modal.show();
  }

  extractWikiPageIdsOfCurrentRange() {
    return (this.pages.wikiPage || []).map((v) => (v.wikiPage ? v.wikiPage.id || '' : ''));
  }

  isAlreadyCheckedWikiPagesOfCurrentRange() {
    const entries = this.extractWikiPageIdsOfCurrentRange();

    return entries.filter((v) => this.checkedIds.includes(v)).length === entries.length;
  }

  changeDataRange(startAt: number) {
    this.getWikiPages(startAt).then(() => {
      this.allChecked = this.isAlreadyCheckedWikiPagesOfCurrentRange();
    });
  }

  async exportArticleListAsCSV() {
    const res = await this.api.listWikiPageSummary(this.wikiName);
    const pageSummaries = res.wikiPageSummaries;
    if (!pageSummaries) {
      return;
    }

    const csvHeader = [
      'タイトル',
      'URL',
      'パス',
      '親カテゴリ',
      'カテゴリ',
      '最終更新者',
      'description',
      '最終更新日時',
      'リダイレクト先',
    ];

    const csvData = pageSummaries
      .filter((pageSummary) => pageSummary.title && pageSummary.id)
      .map((pageSummary) => {
        const pageUrl = decodeURIComponent(this.getWikiUrl(pageSummary.id!));
        const pagePath = decodeURIComponent(this.createWikiPagePath(pageSummary.id!, pageSummary.path));
        const redirectPagePath = pageSummary.redirectPageId
          ? decodeURIComponent(this.createWikiPagePath(pageSummary.redirectPageId!, undefined))
          : '';

        return [
          pageSummary.title,
          pageUrl,
          pagePath,
          pageSummary.parentCategory || '',
          pageSummary.childCategory || '',
          pageSummary.lastAuthor || '',
          pageSummary.description || '',
          pageSummary.lastUpdatedAt ? this.dateFormat(pageSummary.lastUpdatedAt, 'yyyy/MM/dd HH:mm:ss') : '',
          redirectPagePath,
        ];
      });

    const csvRows = [csvHeader, ...csvData];
    const csv = csvRows.map((row) => row.join('\t')).join('\r\n');

    const blob = new Blob([StringConverter.convertStringToUTF16LEArray(csv)], {
      type: 'text/csv;charset=utf-16',
    });
    FileSaver.saveAs(blob, `${this.wikiName}-page-list-${format(new Date(), 'yyyyMMddHHmmss')}.csv`);
  }

  checkAll(checked: boolean) {
    const entries = this.extractWikiPageIdsOfCurrentRange();

    this.checkedIds = checked
      ? Array.from(new Set(this.checkedIds.concat(entries)))
      : this.checkedIds.filter((v) => !entries.includes(v));
  }

  get searchWords() {
    return convertSearchQueryStringToArray(this.searchString);
  }

  @Watch('checkedIds')
  onCheckedChanges(newValue: any) {
    if (this.isAlreadyCheckedWikiPagesOfCurrentRange()) {
      this.indeterminate = false;
      this.allChecked = true;
      return;
    }

    this.allChecked = false;
    this.indeterminate = newValue.length > 0;
  }

  @Watch('filteredByEmptyCategory')
  onFilteredByEmptyCategoryChanges() {
    this.getWikiPages();
  }
}
