import axios from 'axios';

import { KamigameWikiApi } from '@/api-client/generated/kamigameWikiApi';

export async function uploadWikiDataSheet(
  api: KamigameWikiApi,
  wikiName: string,
  serializedData: string
): Promise<string> {
  const { url, bucketPath } = await api.publishWikiDataUploadURL(wikiName, {});

  const jsonFile = new File([serializedData], `${Date.now()}.json`, { type: 'application/json' });
  if (!url) {
    throw new Error('No URL to upload wiki data is found');
  }
  if (!bucketPath) {
    throw new Error('No storage bucket path is found');
  }

  await axios.put(url, jsonFile, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });

  return bucketPath;
}

export async function downloadWikiDataSheet(api: KamigameWikiApi, wikiName: string, bucketPath: string): Promise<any> {
  const { url } = await api.publishWikiDataDownloadURL(wikiName, { bucketPath });
  if (!url) {
    throw new Error('No URL to download wiki data is found');
  }

  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });

  return JSON.stringify(response.data);
}
