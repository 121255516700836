


































import VueRouter from 'vue-router';
import { Watch } from 'vue-property-decorator';

import KamigameVue from '@/KamigameVue';
import { MarkdownEditor } from '@/components';
import Component from 'vue-class-component';

import {
  V1WikiPagePartial,
  V1ColumnNameListPerSheets,
  V1CreateWikiPagePartialRequest,
  V1EditWikiPagePartialRequest,
  ListWikiDataSheetResponse,
} from '@/api-client/generated/models';
import DatasheetsLoadButton from '@/components/DatasheetsLoadButton.vue';
import extractNotAllowedDomainsFromErrorMessage from '@/service/ExtractNotAllowedDomainsFromErrorMessage';

@Component({
  name: 'wiki-page-partial-edit',
  components: {
    'datasheets-loader-button': DatasheetsLoadButton,
    'kamigame-markdown-editor': MarkdownEditor,
  },
})
export default class WikiPagePartialEdit extends KamigameVue {
  pagePartialID = '';
  name: string = '';
  text: string = '';
  partialName = '';
  partialText = '';
  disabled: boolean = false;
  editable: boolean = true;

  @Watch('pagePartial')
  onPagePartialChanged() {
    this.init();
  }

  $refs!: {
    Editor: MarkdownEditor;
  };

  mounted() {
    this.init();
  }

  async init() {
    if (this.pagePartial) {
      await this.getWikiPagePartial();
    } else {
      this.resetPagePartialStates();
    }

    this.setPartialDocumentTitle();
    this.editable = this.$ability.can('ACTION_wiki_page_partial_update', 'OBJ_default');
  }

  onDatasheetsLoaded() {
    this.$refs.Editor.onEditorChange();
  }

  async getWikiPagePartial() {
    await this.api.getWikiPagePartial(this.wikiName, this.pagePartial).then((response: V1WikiPagePartial) => {
      this.name = response.name || '';
      this.text = response.body || '';
      this.partialName = this.name;
      this.partialText = this.text;
      if (response.id) {
        this.pagePartialID = response.id;
      }
    });
  }

  resetPagePartialStates() {
    this.pagePartialID = '';
    this.name = '';
    this.text = '';
    this.disabled = false;
    this.editable = true;
  }

  async create(params: V1CreateWikiPagePartialRequest) {
    await this.api.createWikiPagePartial(this.wikiName, params).then((response: V1WikiPagePartial) => {
      if (response.id) {
        this.pagePartialID = response.id || '';
      }
    });
  }

  async update(params: V1EditWikiPagePartialRequest) {
    await this.api.updateWikiPagePartial(this.wikiName, this.pagePartialID, params);
  }

  async save() {
    if (this.disabled) {
      return;
    }

    if (this.name === '') {
      this.setFlashMessage('danger', '記事部品名を入力してください');
      return;
    }

    this.disabled = true;

    const params = {
      name: this.name,
      body: this.text,
      columns: this.extractRequiredColumns(),
    };

    const isUpdate = !!this.pagePartialID;
    await (isUpdate ? this.update(params) : this.create(params))
      .then(() => {
        this.partialName = this.name;
        this.partialText = this.text;

        this.router.push(
          {
            name: 'wiki_page_partial_list',
          },
          () => {
            if (isUpdate) {
              this.setFlashMessage('success', '記事部品を更新しました。');
            } else {
              this.setFlashMessage('success', '記事部品を保存しました。');
            }
          }
        );
      })
      .catch((e: Error) => {
        if (isUpdate) {
          const domains = extractNotAllowedDomainsFromErrorMessage(e.message);
          if (domains.length !== 0) {
            this.setFlashMessage('danger', 'これらのドメインは記事中に使用できません: ' + domains.join(', '));
            return;
          }
          this.setFlashMessage('danger', '記事部品の更新に失敗しました。');
        } else {
          const domains = extractNotAllowedDomainsFromErrorMessage(e.message);
          if (domains.length !== 0) {
            this.setFlashMessage('danger', 'これらのドメインは記事中に使用できません: ' + domains.join(', '));
            return;
          }
          this.setFlashMessage('danger', '記事部品の作成に失敗しました');
        }
      });

    this.disabled = false;
  }

  extractRequiredColumns(): V1ColumnNameListPerSheets {
    type SheetNameToIteratorNameMap = Map<string, string>;
    const extractSheetNameToIteratorNameMapFromEjs = (text: string): SheetNameToIteratorNameMap => {
      const sheetNameToIteratorNameMap: SheetNameToIteratorNameMap = new Map();

      const matchedTags = text.match(/<%\s+.*?\s+%>/g);
      if (!matchedTags) {
        return sheetNameToIteratorNameMap;
      }

      matchedTags.forEach((matchedTag) => {
        const matchedTexts = matchedTag.match(/[^\s]*\s+of\s+[^)\s]*/g);
        if (!matchedTexts) {
          return;
        }

        matchedTexts.forEach((matchedText) => {
          const iteratorNameAndSheetName = matchedText.split('of').map((t) => t.trim());
          sheetNameToIteratorNameMap.set(iteratorNameAndSheetName[1], iteratorNameAndSheetName[0]);
        });
      });

      return sheetNameToIteratorNameMap;
    };

    const requiredColumnsPerSheet: V1ColumnNameListPerSheets = { sheets: {} };
    if (typeof requiredColumnsPerSheet.sheets === 'undefined') {
      return requiredColumnsPerSheet;
    }
    const sheetNameToIteratorNameMap = extractSheetNameToIteratorNameMapFromEjs(this.text);
    if (sheetNameToIteratorNameMap.size === 0) {
      return requiredColumnsPerSheet;
    }

    for (const [sheetName, iteratorName] of sheetNameToIteratorNameMap) {
      const matchArray = this.text.match(new RegExp(iteratorName + '\\.[^\\s]*[^);\\s]', 'g')) ?? [];
      const uniqueMatchArray = [...new Set(matchArray)];
      const columnNames = uniqueMatchArray.map((match) => match.split('.')[1]);

      requiredColumnsPerSheet.sheets[sheetName] = { columns: columnNames };
    }

    return requiredColumnsPerSheet;
  }

  isEditorClean(): boolean {
    return this.name === this.partialName && this.text === this.partialText;
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    if (this.isEditorClean()) {
      next();
      return;
    }

    if (window.confirm('変更が保存されていませんが、このページから離れますか？')) {
      next();
    }
  }

  setPartialDocumentTitle() {
    if (!this.partialName) {
      return;
    }

    this.setDocumentTitle(`${this.partialName} | ${this.routeDocumentTitle}`);
  }

  get pagePartial() {
    return this.route.params.wikiPagePartialID;
  }
}
