

































import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { sha1 } from 'crypto-hash';
import { getSpreadsheetIdBySpreadsheetUrl, getSheetIdBySpreadsheetUrl } from 'wiki-shared/template-code-util';

import {
  V1WikiTakahiroTemplateCodeSpreadsheetURL,
  V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki,
} from '@/api-client/generated/models';
import { fetchSpreadsheetMeta, fetchSpreadsheetSheetByTitle } from '@/service/SpreadsheetApi';
import { uploadWikiDataSheet } from '@/service/CloudStorage';
import KamigameVue from '@/KamigameVue';

interface SpreadsheetURLWithError extends V1WikiTakahiroTemplateCodeSpreadsheetURL {
  error: Error;
}

@Component({
  name: 'wiki-page-import',
})
export default class WikiTemplateCode extends KamigameVue {
  dateFormat: any;
  fields = [
    { key: 'url', label: '連携済みスプレッドシート URL' },
    { key: 'lastUpdatedAt', label: '最終更新日時' },
    { key: 'error', label: '' },
  ];

  spreadsheets: Map<string, SpreadsheetURLWithError> = new Map();

  get spreadsheetsValue() {
    return Array.from(this.spreadsheets.values());
  }

  mounted() {
    this.dateFormat = format;

    this.api
      .listWikiTakahiroTemplateCodeSpreadsheetURL(this.wikiName)
      .then((r: V1ListWikiTakahiroTemplateCodeSpreadsheetURLWithWiki) => {
        if (!r.spreadsheet) {
          return;
        }
        this.spreadsheets = new Map(r.spreadsheet.map((s: any) => [s.url, s]) || []);
      });
  }

  showFetchingSpreadsheetModal() {
    const modal = this.$refs.fetchingSpreadsheetModal as BModal;
    if (!modal) {
      return;
    }

    modal.show();

    return this.sleep(1000);
  }

  hideFetchingSpreadsheetModal() {
    const modal = this.$refs.fetchingSpreadsheetModal as BModal;
    if (!modal) {
      return;
    }

    modal.hide();
  }

  getSheetData(): any {
    const result: any = {};
    this.spreadsheets.forEach((s: any) => {
      const spreadsheetId = getSpreadsheetIdBySpreadsheetUrl(s.url);
      const sheetId = getSheetIdBySpreadsheetUrl(s.url);

      if (!result[spreadsheetId]) {
        result[spreadsheetId] = {};
      }
      if (!result[spreadsheetId][sheetId]) {
        result[spreadsheetId][sheetId] = [];
      }
      result[spreadsheetId][sheetId].push(s);
    });

    return result;
  }

  sleep(msec: number) {
    return new Promise((resolve) => setTimeout(resolve, msec));
  }

  async updateAndAdjustSpeculativeWikiDataSheet(
    spreadsheetId: string,
    sheetName: string,
    primaryId: string,
    cancellationIds: string[]
  ): Promise<boolean> {
    const sheetData = await fetchSpreadsheetSheetByTitle(spreadsheetId, sheetName);

    const serializedData = JSON.stringify(this.normalizeSpreadsheetSheetRowLength(sheetData.result.values));
    const serializedDataHash = await sha1(serializedData);

    const preData = await this.api.getWikiDataSheet(this.wikiName, primaryId);
    if (preData.sheetDataHash === serializedDataHash) {
      return false;
    }

    if (!preData.isForTemplateCode) {
      const message =
        `シート「${sheetName}」は、前回「データ編集（内部向け）」で更新されています。` +
        'このシートを記事部品で参照している場合、最新のデータが反映されない可能性があります。' +
        '更新を続けますか？';
      const shouldContinue = await this.$bvModal.msgBoxConfirm(message);
      if (!shouldContinue) {
        this.setFlashMessage('warning', `${sheetName}の変更をスキップしました`);
        return false;
      }
    }

    const bucketPath = await uploadWikiDataSheet(this.api, this.wikiName, serializedData);
    await this.api.updateWikiDataSheet(this.wikiName, primaryId, {
      id: primaryId,
      sheetName: sheetName,
      spreadsheetId: spreadsheetId,
      sheetDataHash: serializedDataHash,
      originBucketPath: bucketPath,
      isForTemplateCode: true,
    });

    if (cancellationIds.length > 0) {
      await this.api.adjustSpeculativeWikiDataSheets(this.wikiName, {
        primaryID: primaryId,
        cancellationID: cancellationIds,
      });
    }

    return true;
  }

  async fetchSpreadsheet() {
    await this.showFetchingSpreadsheetModal();

    const sheetData = this.getSheetData();
    const updatedURLs: string[] = [];

    const fetchMeta = async (spreadsheetId: any) => {
      const meta = await fetchSpreadsheetMeta(spreadsheetId);
      const fetchedSheets: any = {};
      const sheets = meta.result.sheets ?? [];
      sheets.forEach((s: any) => {
        fetchedSheets[s.properties.sheetId] = s.properties.title;
      });
      const expectedSheets = sheetData[spreadsheetId];

      for (const fid of Object.keys(fetchedSheets)) {
        const matchedWikiData = expectedSheets[fid] || [];
        if (matchedWikiData.length <= 0) {
          continue;
        }
        const primaryId = matchedWikiData[0].wikiDataID;
        const restIds = matchedWikiData
          .slice(1)
          .map((v: any) => v.wikiDataID)
          .filter((restId: string) => restId !== primaryId);

        const sheetName = fetchedSheets[fid];
        try {
          const proceeded = await this.updateAndAdjustSpeculativeWikiDataSheet(
            spreadsheetId,
            sheetName,
            primaryId,
            restIds
          );
          if (proceeded) {
            updatedURLs.push(matchedWikiData[0].url);
          }
        } catch (e) {
          this.spreadsheets.set(
            matchedWikiData[0].url,
            Object.assign({}, this.spreadsheets.get(matchedWikiData[0].url), {
              error: e,
            })
          );
          this.spreadsheets = new Map(this.spreadsheets.entries());
        }
      }
    };

    for (const spreadsheetId in sheetData) {
      try {
        await fetchMeta(spreadsheetId);
      } catch (e) {
        this.spreadsheets.forEach((v, k) => {
          if (v.url && getSpreadsheetIdBySpreadsheetUrl(v.url) === spreadsheetId) {
            this.spreadsheets.set(k, Object.assign({}, v, { error: e }));
          }
        });

        this.spreadsheets = new Map(this.spreadsheets.entries());
      }
      await this.sleep(1000);
    }

    if (updatedURLs.length <= 0) {
      this.hideFetchingSpreadsheetModal();

      this.setFlashMessage(
        'success',
        '更新されたスプレッドシートはありませんでした。テンプレートコードの実行をスキップします。'
      );
      return;
    }

    return this.api
      .requestTemplateCodeExecution(this.wikiName, {
        name: this.wikiName,
        spreadsheetURL: updatedURLs,
      })
      .then(() => {
        this.hideFetchingSpreadsheetModal();

        this.setFlashMessage(
          'success',
          'テンプレートコードの実行を予約しました。記事内容の更新までしばらくお待ちください'
        );
      })
      .catch(() => {
        this.hideFetchingSpreadsheetModal();

        this.setFlashMessage('danger', 'テンプレートコードの実行予約に失敗しました');
      });
  }

  normalizeSpreadsheetSheetRowLength(data: string[][] | undefined): string[][] {
    if (!data) {
      return [[]];
    }
    const colNum = Math.max(...data.map((row) => row.length));
    return data.map((row: string[]) => {
      const lackColNum = colNum - row.length;

      return row.concat(new Array(lackColNum).fill(''));
    });
  }
}
