'use strict';

const ejs = require('./ejs.min.js');

class PagePartialSheetData {
  constructor(condition, body, data) {
    this.condition = condition;
    this.body = body;
    this.data = data;
  }

  render() {
    return ejs.render(this.body, this.data || null);
  }

  createTemplateLiteral() {
    return `{template ${this.condition}}`;
  }
}

module.exports = PagePartialSheetData;
