


























































































































































































































import Vue from 'vue';
import KamigameVue from '@/KamigameVue';

import { BModal } from 'bootstrap-vue';
import Component from 'vue-class-component';
import { HotTable, hotInit } from '@handsontable/vue';
import Handsontable from 'handsontable';
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import FileUploadWithPreview from 'file-upload-with-preview';
import format from 'date-fns/format';
import { closestIndexTo } from 'date-fns';
import { sha1 } from 'crypto-hash';
import { TokenCredentials } from '@azure/ms-rest-js';

import {
  V1WikiDataSheet,
  V1WikiDataSheetCollection,
  V1Image,
  KamigameWikiApiGetImageOptionalParams,
  V1Wiki,
} from '@/api-client/generated/models';
import * as StringConverter from '@/service/StringConverter';
import { wikiImageUpload } from '@/service/ImageUploader';
import { wikiGetImageWithServingURL } from '@/service/WikiGetImageWithServingURL';
import wikiDataSheetTabTable from '@/components/WikiDataSheetTabTable.vue';
import WikiData from '@/components/WikiData.vue';
import { uploadWikiDataSheet, downloadWikiDataSheet } from '@/service/CloudStorage';
import { fetchSpreadsheetSheetByTitle } from '@/service/SpreadsheetApi';
import { normalizeSpreadsheetSheetRowLength } from '@/service/SpreadsheetSheetFormatter';
import { parseSpreadsheetIdFromUrl } from '@/service/SpreadsheetUrl';

declare interface HotTableComponent extends Vue {
  hotInstance?: Handsontable;
}

@Component({
  name: 'wiki-data_common',
  components: {
    HotTable,
    'kamigame-wiki-data-sheet-tab-table': wikiDataSheetTabTable,
  },
})
export default class WikiDataCommon extends WikiData {
  updateSheetTitle = '';
  file?: any = null;
  isConfirmedSheetConfigChangeWarning = false;
  imageUpload?: FileUploadWithPreview;
  imageUploadingProgress = false;
  imageSelected = false;
  spreadsheetUrl = '';

  async mounted() {
    window.addEventListener('fileUploadWithPreview:imagesAdded', (e: any) => {
      this.imageSelected = e.detail.uploadId === 'imageUpload';
    });
    window.addEventListener('fileUploadWithPreview:imageDeleted', (e: any) => {
      if (e.detail.uploadId === 'imageUpload' && e.detail.cachedFileArray.length < 1) {
        this.imageSelected = false;
      }
    });

    this.data = await this.api.listWikiDataSheet(this.wikiName);
    if ((this.data.sheets || []).length < 1) {
      this.createTab();
    }
  }

  createTab() {
    const currentSheets = this.data && this.data.sheets ? this.data.sheets : [];

    const newIndex = currentSheets.length;
    const newData = {
      sheets: currentSheets,
    };
    newData.sheets.push({
      sheetName: `シート ${newIndex + 1}`,
    });

    this.data = newData;
  }

  async download() {
    if (!this.currentSheet) {
      return;
    }

    const sheetName = this.currentSheet.sheetName ? this.currentSheet.sheetName : 'data';
    const csvStr = Papa.unparse(JSON.parse(this.currentSheet.sheetData || '[[]]'), {
      delimiter: '\t',
      newline: '\r\n',
    });
    const blob = new Blob([StringConverter.convertStringToUTF16LEArray(csvStr)], { type: 'text/csv;charset=utf-16' });
    FileSaver.saveAs(blob, `${sheetName}-${format(new Date(), 'yyyyMMddHHmmss')}.csv`);
  }

  upload(e: Event) {
    const input = e.target as HTMLInputElement;
    if (!input || !input.files || input.files.length <= 0) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (fileEvent) => {
      if (!reader.result) {
        return;
      }

      const buffers = new Uint8Array(reader.result as ArrayBuffer);
      let csvString = '';
      let parseConfigDelimiter = '';
      let parseConfigNewline = '';

      // 255, 254 の BOM 付きの場合は UTF16LE として、それ以外は UTF8 として扱う
      // それ以外の形式はサポートしない
      if (buffers[0] === 255 && buffers[1] === 254) {
        csvString = StringConverter.convertUTF16LEArrayToString(buffers);
        parseConfigDelimiter = '\t';
        parseConfigNewline = '\r\n';
      } else {
        csvString = StringConverter.convertUTF8ArrayToString(buffers);
        parseConfigDelimiter = ',';
        // 改行コード: numbers・スプレッドシートからの csv -> \r\n, mac excel -> \r なので指定せず Papa に検出してもらう
        // 実装は https://github.com/mholt/PapaParse/blob/master/papaparse.js guessLineEnding()
        parseConfigNewline = '';
      }
      Papa.parse(csvString, {
        delimiter: parseConfigDelimiter,
        newline: parseConfigNewline,
        complete: (result) => {
          if (
            !this.currentSheet ||
            !this.updateData.sheets ||
            !this.comparedData.sheets ||
            this.selectedSheetIndex === null
          ) {
            return;
          }

          this.$set(this.updateData.sheets, '0', {
            id: this.currentSheet.id,
            sheetName: this.currentSheet.sheetName,
            sheetData: JSON.stringify(result.data),
          });

          if (this.currentSheet.id) {
            this.$set(this.comparedData.sheets, '0', this.currentSheet);
          }

          this.showEditSheetDataModal();
        },
        error: (err) => {
          this.setFlashMessage('danger', 'ファイルアップロード中にエラーが発生しました');
          console.error(err);
        },
      });
    };
    reader.readAsArrayBuffer(input.files[0]);
  }

  async save() {
    const sheet = this.currentSheet;
    if (!sheet || !this.updateData.sheets) {
      return;
    }

    const client = this.api;
    const sheetId = sheet.id;
    const sheetData = this.updateData.sheets[0].sheetData;
    const params = {
      id: sheetId,
      sheetName: this.updateSheetTitle ? this.updateSheetTitle : sheet.sheetName,
      sheetDataHash: await sha1(sheetData || ''),
      originBucketPath: await uploadWikiDataSheet(client, this.wikiName, sheetData ?? ''),
      spreadsheetId: this.updateData.sheets[0] ? this.updateData.sheets[0].spreadsheetId || '' : '',
      isForTemplateCode: false,
    };

    await this.saveToRealtimeDatabase(params.sheetName || '', sheetData || '[[]]');
    await this.modifyRealtimeDatabaseIndexes(this.updateData);

    if (sheetId) {
      await client.updateWikiDataSheet(this.wikiName, sheetId, params);
      this.setFlashMessage('success', '変更を保存しました');
    } else {
      const newData = await client.createWikiDataSheet(this.wikiName, params);
      if (newData && this.selectedSheetIndex !== null) {
        this.setFlashMessage('success', '変更を保存しました');
      } else {
        this.setFlashMessage('danger', '変更を保存できませんでした。画面の再読み込みをしてもう一度やり直してください');
      }
    }
  }

  async processImageUploader(e: Event): Promise<any> {
    e.preventDefault();

    if (!this.imageUpload) {
      return Promise.reject(new Error('image uploader has not been initialized'));
    }

    const files = this.imageUpload.cachedFileArray;
    if (!files) {
      return Promise.reject(new Error('files is not found'));
    }
    this.imageUploadingProgress = true;

    return wikiImageUpload(this.api.credentials as TokenCredentials, this.wikiName, false, files).then(
      (result: Map<string, string>) => {
        const getImageServingURLPromises: Promise<V1Image>[] = [];
        const imageIdName: { [key: string]: string } = {};

        result.forEach(async (val, key) => {
          getImageServingURLPromises.push(wikiGetImageWithServingURL(this.api, val));
          imageIdName[val] = key;
        });

        Promise.all(getImageServingURLPromises)
          .then((res: V1Image[]) => {
            this.insertURLtoCells(res, imageIdName);
            this.imageUploadingProgress = false;
            this.closeImageUploader();
            this.showEditSheetDataModal();
          })
          .catch(() => {
            this.setFlashMessage('danger', '画像のアップロード中にエラーが発生しました。');
            this.imageUploadingProgress = false;
            this.closeImageUploader();
          });
      }
    );
  }

  insertURLtoCells(images: V1Image[], imageIdName: { [key: string]: string }) {
    if (!this.currentSheet) {
      return;
    }

    const insertedData = JSON.parse(this.currentSheet.sheetData || '[[]]');
    images.forEach((el) => {
      if (!el.id) {
        return;
      }

      const matched = imageIdName[el.id].match(/(\d+)_(\d+)_*/);
      if (!matched || (matched || []).length < 3) {
        this.setFlashMessage(
          'danger',
          '画像のアップロード中にエラーが発生しました。ファイル名の形式が正しくありません。行_列_ファイル名.png の形式で入力してください。 例: 3_6_image.png'
        );
        return;
      }

      const rowIndexFromZero = parseInt(matched[1], 10) - 1;
      const colIndesFromZero = parseInt(matched[2], 10) - 1;
      if (rowIndexFromZero < 0 || colIndesFromZero < 0) {
        this.setFlashMessage(
          'danger',
          '画像のアップロード中にエラーが発生しました。セルの行、列番号は 1 以上で指定してください'
        );
        return;
      }

      insertedData[rowIndexFromZero][colIndesFromZero] = el.url || insertedData[rowIndexFromZero][colIndesFromZero];

      if (!this.updateData.sheets || !this.currentSheet || !this.comparedData.sheets) {
        return;
      }

      this.$set(this.updateData.sheets, '0', {
        id: this.currentSheet.id,
        sheetName: this.currentSheet.sheetName,
        sheetData: JSON.stringify(insertedData),
      });

      this.$set(this.comparedData.sheets, '0', this.currentSheet);
    });
  }

  closeImageUploader() {
    const modal = this.$refs.imageUploderModal as BModal;
    if (modal) {
      modal.hide();
    }
  }

  clearImageUploader(e?: Event) {
    if (e && this.imageUploadingProgress) {
      e.preventDefault();
      return;
    }

    this.imageSelected = false;
    if (this.imageUpload) {
      this.imageUpload.clearPreviewPanel();
    }
  }

  showImageUploadModal() {
    const modal = this.$refs.imageUploderModal as BModal;
    modal.show();
    this.imageUpload = new FileUploadWithPreview('imageUpload');
  }

  get imageUploadingDisabled() {
    return !this.imageSelected || this.imageUploadingProgress;
  }

  clearImage() {
    this.imageSelected = false;
  }

  changeDataSheetTitle(sheetName: string) {
    if (!this.currentSheet) {
      return;
    }

    this.currentSheet.sheetName = sheetName;
  }

  showEditSheetTitleModal() {
    const modal = this.$refs.editSheetTitleModal as BModal;
    if (!modal) {
      return;
    }

    if (!this.updateData.sheets) {
      return;
    }

    this.$set(this.updateData.sheets, 0, this.currentSheet);
    modal.show();
  }

  showRelateSpreadsheetModal() {
    const modal = this.$refs.relateSpreadsheetModal as BModal;
    if (!modal) {
      return;
    }

    this.updateData.sheets = [];

    modal.show();
  }

  hideRelateSpreadsheetModal() {
    const modal = this.$refs.imageUploderModal as BModal;
    if (modal) {
      modal.hide();
    }
  }

  async relateSpreadsheet() {
    if (!this.currentSheet) {
      return;
    }

    try {
      if (!this.currentSheet || !this.currentSheet.sheetName || !this.updateData.sheets) {
        this.setFlashMessage('danger', 'スプレッドシートの取得に失敗しました');
        return;
      }

      const spreadsheetId = parseSpreadsheetIdFromUrl(this.spreadsheetUrl);
      const response = await fetchSpreadsheetSheetByTitle(spreadsheetId, this.currentSheet.sheetName);

      this.updateData.sheets.push({
        id: this.currentSheet.id,
        sheetName: this.currentSheet.sheetName,
        sheetData: JSON.stringify(normalizeSpreadsheetSheetRowLength(response.result.values)),
        spreadsheetId: spreadsheetId,
      });
    } catch (e) {
      this.hideRelateSpreadsheetModal();
      this.setFlashMessage('danger', 'スプレッドシートの取得に失敗しました');
    }
  }
}
