

















































































































































import { V1WikiDataSheet, V1WikiDataChangelogSheet } from '@/api-client/generated/models';
import WikiData from '@/components/WikiData.vue';
import wikiDataSheetTabTable from '@/components/WikiDataSheetTabTable.vue';
import { downloadWikiDataSheet, uploadWikiDataSheet } from '@/service/CloudStorage';
import { fetchSpreadsheetMeta, fetchSpreadsheetSheetByTitle } from '@/service/SpreadsheetApi';
import { extractSpreadsheetSheetReference } from '@/service/SpreadsheetReferenceFormulaParser';
import { HotTable } from '@handsontable/vue';
import { BModal } from 'bootstrap-vue';
import { sha1 } from 'crypto-hash';
import Component from 'vue-class-component';

import { normalizeSpreadsheetSheetRowLength } from '@/service/SpreadsheetSheetFormatter';
import { parseSpreadsheetIdFromUrl } from '@/service/SpreadsheetUrl';
import { countUpdatedCells } from '@/service/CountUpdatedCells';

interface SheetNameAndSpreadsheetId {
  sheetName: string;
  spreadsheetId: string;
}

@Component({
  name: 'wiki-data',
  components: {
    HotTable,
    'kamigame-wiki-data-sheet-tab-table': wikiDataSheetTabTable,
  },
})
export default class WikiDataSpreadsheetSync extends WikiData {
  updateSheetId: string | null = null;
  spreadsheetUrl = '';
  spreadsheetSheetTitles: string[] = [];
  sheetTitle = '';
  hasGottenSpreadsheetSheetTitles = false;
  updatesReferencedSheets = true;
  updateSuccessSheets: V1WikiDataSheet[] = [];
  updateFailureSheets: V1WikiDataSheet[] = [];
  saving: boolean = false;
  tempChangelogSheets: V1WikiDataChangelogSheet[] = [];

  async mounted() {
    this.init();
  }

  async init() {
    this.data = await this.api.listWikiDataSheet(this.wikiName);
  }

  async updateSheet(updateSheet: V1WikiDataSheet) {
    const sheetSizeLimit = 16 * 1024 * 1024;

    if (!updateSheet || !updateSheet.sheetData) {
      this.updateFailureSheets.push(updateSheet);
      return;
    }

    if (updateSheet.sheetData.length > sheetSizeLimit) {
      const sheetSizeLimitMb = (sheetSizeLimit / 1024 / 1024).toFixed(1);
      const message =
        `シート「${updateSheet.sheetName}」は ` +
        `${sheetSizeLimitMb}MB を超えているため、` +
        'アップロードに失敗する可能性があります。保存しますか？';
      const shouldContinue = await this.$bvModal.msgBoxConfirm(message);
      if (!shouldContinue) {
        this.updateFailureSheets.push(updateSheet);
        return;
      }
    }

    const originBucketPath = await uploadWikiDataSheet(this.api, this.wikiName, updateSheet.sheetData);
    const sheetId = updateSheet.id;
    const sheetDataHash = await sha1(updateSheet.sheetData || '');
    const params = {
      id: sheetId,
      sheetName: updateSheet.sheetName,
      spreadsheetId: updateSheet.spreadsheetId,
      sheetDataHash,
      originBucketPath,
      sheetData: '',
      isForTemplateCode: false,
    };

    if (sheetId) {
      if (!this.data.sheets) {
        this.updateFailureSheets.push(updateSheet);
        return;
      }

      const updateSheetIndex = this.data.sheets.findIndex((sheet) => sheet.id === updateSheet.id);

      await this.updateRealtimeDatabase(params.sheetName || '', updateSheet.sheetData);

      await this.api.updateWikiDataSheet(this.wikiName, sheetId, params);

      params.id = sheetId;
      params.sheetData = updateSheet.sheetData || '';

      this.tempSaveChangelogSheet(this.data.sheets[updateSheetIndex], params);

      this.$set(this.data.sheets, updateSheetIndex, params);
    } else {
      if (!this.data.sheets) {
        this.data.sheets = [];
      }

      await this.saveToRealtimeDatabase(params.sheetName || '', updateSheet.sheetData || '[[]]');
      const newData = await this.api.createWikiDataSheet(this.wikiName, params);

      params.id = newData.id || '';
      params.sheetData = updateSheet.sheetData || '';

      this.tempSaveChangelogSheet(null, params);

      this.data.sheets.push(params);
    }

    await this.saveDataSheetLink(updateSheet.spreadsheetId || '', updateSheet.sheetName || '');
    this.updateSuccessSheets.push(updateSheet);
  }

  async save() {
    try {
      this.saving = true;

      if (!this.updateData.sheets) {
        return;
      }

      const bulkNum = 3;
      const bulkUpdateSheetArray: V1WikiDataSheet[][] = [];
      for (let i = 0; i < this.updateData.sheets.length; i += bulkNum) {
        bulkUpdateSheetArray.push(this.updateData.sheets.slice(i, i + bulkNum));
      }

      await Promise.all(
        bulkUpdateSheetArray.map(async (bulkSheets) => {
          for (const sheet of bulkSheets) {
            await this.updateSheet(sheet);
          }
        })
      );

      await this.saveWikiDataChangelog();

      if (this.updateSuccessSheets.length > 0) {
        this.setFlashMessage(
          'success',
          `${this.updateSuccessSheets.map((sheet) => sheet.sheetName).join(', ')} の更新に成功しました`
        );
      }
      if (this.updateFailureSheets.length > 0) {
        this.setFlashMessage(
          'danger',
          `${this.updateFailureSheets
            .map((sheet) => sheet.sheetName)
            .join(', ')} の更新に失敗しました。画面の再読み込みをしてもう一度お試しください。`
        );
      }

      await this.modifyRealtimeDatabaseIndexes(this.updateData);
    } finally {
      this.saving = false;
      this.clean();
    }
  }

  showCreateTabModal() {
    this.updateSheetId = null;
    const modal = this.$refs.createTabModal as BModal;
    if (!modal) {
      return;
    }

    modal.show();
  }

  showEditSpreadSheetSheetModalUpdating() {
    if (!this.currentSheet) {
      return;
    }

    this.updateSheetId = this.currentSheet.id || '';

    const modal = this.$refs.createTabModal as BModal;
    if (!modal) {
      return;
    }

    modal.show();
  }

  async fetchSpreadsheetMeta() {
    if (!this.spreadsheetUrl) {
      return;
    }

    this.showFetchingSpreadsheetModal();

    try {
      const response = await fetchSpreadsheetMeta(parseSpreadsheetIdFromUrl(this.spreadsheetUrl));
      const sheets = response.result.sheets ?? [];
      this.spreadsheetSheetTitles = sheets
        .filter((sheet: any) => {
          if (this.data && this.data.sheets) {
            const existsSheetTitles = this.data.sheets.map((existSheet) => existSheet.sheetName);
            if (existsSheetTitles.includes(sheet.properties.title)) {
              return false;
            }
          }
          return true;
        })
        .map((sheet: any) => {
          return sheet.properties.title;
        });
      this.hasGottenSpreadsheetSheetTitles = true;
    } catch {
      this.hideCreateTabModal();
      this.setFlashMessage(
        'danger',
        'スプレッドシートの情報の取得に失敗しました。画面の再読み込みをしてもう一度お試しください。'
      );
    } finally {
      this.hideFetchingSpreadsheetModal();
    }
  }

  async editSpreadsheetSheet() {
    if (!this.updateData.sheets) {
      return;
    }

    this.showFetchingSpreadsheetModal();

    const spreadsheetId = parseSpreadsheetIdFromUrl(this.spreadsheetUrl);
    const sheetTitle = this.sheetTitle;
    try {
      const response = await fetchSpreadsheetSheetByTitle(spreadsheetId, this.sheetTitle);

      this.validateSpreadsheetErrorValueInSheet(response.result.values, sheetTitle);

      this.updateData.sheets.push({
        id: this.updateSheetId || undefined,
        sheetName: sheetTitle,
        sheetData: JSON.stringify(this.filterEmptyRows(normalizeSpreadsheetSheetRowLength(response.result.values))),
        spreadsheetId,
      });

      this.showEditSpreadsheetSheetsModal();
    } catch (e: any) {
      this.setFlashMessage(
        'danger',
        e.message || 'スプレッドシートの保存に失敗しました。画面の再読み込みをしてもう一度お試しください。'
      );
    } finally {
      this.hideFetchingSpreadsheetModal();
    }
  }

  showEditSpreadsheetSheetsModal() {
    const modal = this.$refs.editSpreadsheetSheetsModal as BModal;
    if (!modal) {
      return;
    }

    if (this.updateSheetId && this.data.sheets && this.comparedData.sheets && this.selectedSheetIndex !== null) {
      this.$set(this.comparedData.sheets, '0', this.data.sheets[this.selectedSheetIndex]);
    }

    modal.show();
  }

  async syncAllDataSheet() {
    if (!this.data.sheets) {
      return;
    }

    this.showFetchingSpreadsheetModal();

    const syncTargetSheets = this.data.sheets.filter((sheet) => sheet.spreadsheetId && sheet.sheetName).concat();
    try {
      await this.setUpdateSheetData(syncTargetSheets);
      await this.setComparedSheetData(syncTargetSheets);
      this.showEditSpreadsheetSheetsModal();
    } catch (e: any) {
      this.setFlashMessage(
        'danger',
        e.message || 'スプレッドシートの更新に失敗しました。画面の再読み込みをしてもう一度お試しください。'
      );
    } finally {
      this.hideFetchingSpreadsheetModal();
    }
  }

  async syncIndividualDataSheet() {
    if (!this.data.sheets || !this.currentSheet) {
      return;
    }

    this.showFetchingSpreadsheetModal();

    try {
      const syncTargetSheetIdNames = this.updatesReferencedSheets
        ? await this.listSyncTargetSheetIncludingReference(
            this.currentSheet.sheetName || '',
            this.currentSheet.spreadsheetId || ''
          )
        : [
            {
              sheetName: this.currentSheet.sheetName || '',
              spreadsheetId: this.currentSheet.spreadsheetId || '',
            },
          ];

      const syncTargetSheets = this.data.sheets.filter((sheet) =>
        syncTargetSheetIdNames.some(
          (idName) => idName.spreadsheetId === sheet.spreadsheetId && idName.sheetName === sheet.sheetName
        )
      );

      await this.setUpdateSheetData(syncTargetSheets);
      await this.setComparedSheetData(syncTargetSheets);
      this.showEditSpreadsheetSheetsModal();
    } catch (e: any) {
      this.setFlashMessage(
        'danger',
        e.message || 'スプレッドシートの更新に失敗しました。画面の再読み込みをしてもう一度お試しください。'
      );
    } finally {
      this.hideFetchingSpreadsheetModal();
    }
  }

  async setUpdateSheetData(syncTargetSheets: V1WikiDataSheet[]) {
    const targetSpreadsheetIdSet = new Set<string>();
    syncTargetSheets.forEach((sheet) => {
      if (!sheet.spreadsheetId) {
        return;
      }

      targetSpreadsheetIdSet.add(sheet.spreadsheetId);
    });

    const syncSheetMetas = await Promise.all(
      [...targetSpreadsheetIdSet].map(async (sheet) => await fetchSpreadsheetMeta(sheet))
    );
    const existingSheetNames: { [key: string]: string[] } = syncSheetMetas.reduce((previous, meta) => {
      if (!meta.result.sheets || !meta.result.spreadsheetId) {
        return previous;
      }

      return {
        ...previous,
        [meta.result.spreadsheetId]: meta.result.sheets.map((sheet: any) => sheet.properties.title),
      };
    }, {});

    syncTargetSheets.forEach((sheet: any) => {
      if (!existingSheetNames[sheet.spreadsheetId].includes(sheet.sheetName)) {
        throw new Error(
          `${sheet.sheetName}はスプレッドシート上から削除されているか、シート名が変更されています。` +
            'シートが存在するか確認した後、もう一度お試しください。'
        );
      }
    });

    const syncSheetPromises = syncTargetSheets.map((sheet) => {
      return fetchSpreadsheetSheetByTitle(sheet.spreadsheetId || '', sheet.sheetName || '');
    });

    const bulkNum = 10;
    for (let bulkStartIndex = 0; bulkStartIndex < syncSheetPromises.length; bulkStartIndex += bulkNum) {
      const bulkSyncSheetPromises = syncSheetPromises.slice(bulkStartIndex, bulkStartIndex + bulkNum);

      const response = await Promise.all(bulkSyncSheetPromises);
      response.forEach((responseSheet, responseIndex) => {
        if (!responseSheet) {
          return;
        }

        this.validateSpreadsheetErrorValueInSheet(
          responseSheet.result.values,
          syncTargetSheets[bulkStartIndex + responseIndex]?.sheetName
        );

        const sheet = Object.assign({}, syncTargetSheets[bulkStartIndex + responseIndex]);
        sheet.sheetData = JSON.stringify(
          this.filterEmptyRows(normalizeSpreadsheetSheetRowLength(responseSheet.result.values))
        );
        (this.updateData.sheets || []).push(sheet);
      });
    }
  }

  async setComparedSheetData(syncTargetSheets: V1WikiDataSheet[]) {
    if (!this.data.sheets) {
      return;
    }

    const syncTargetDataSheetPromises = syncTargetSheets.map((sheet) => {
      return this.api
        .getWikiDataSheet(this.wikiName, sheet.id || '')
        .then(async (sheet: V1WikiDataSheet): Promise<V1WikiDataSheet> => {
          if (sheet.originBucketPath) {
            sheet.sheetData = await downloadWikiDataSheet(this.api, this.wikiName, sheet.originBucketPath);
          }

          return sheet;
        });
    });

    await Promise.all(syncTargetDataSheetPromises).then((response: V1WikiDataSheet[]) => {
      response.forEach((sheet) => {
        if (!this.data.sheets) {
          return;
        }

        const index = this.data.sheets.findIndex((sheetData) => sheetData.id === sheet.id);
        this.$set(this.data.sheets, index.toString(), sheet);
      });
    });

    this.comparedData.sheets = this.data.sheets.filter((sheet) =>
      syncTargetSheets.some((syncTargetSheet) => sheet.id === syncTargetSheet.id)
    );
  }

  showFetchingSpreadsheetModal() {
    const modal = this.$refs.fetchingSpreadsheetModal as BModal;
    if (!modal) {
      return;
    }

    modal.show();
  }

  hideFetchingSpreadsheetModal() {
    const modal = this.$refs.fetchingSpreadsheetModal as BModal;
    if (!modal) {
      return;
    }

    modal.hide();
  }

  hideCreateTabModal() {
    const modal = this.$refs.createTabModal as BModal;
    if (!modal) {
      return;
    }

    modal.hide();
  }

  filterEmptyRows(data: string[][] | undefined): string[][] {
    if (!data) {
      return [[]];
    }

    return data.filter((row: string[]) => {
      return row.slice(1).some((v) => !!`${v}`.trim());
    });
  }

  async listSyncTargetSheetIncludingReference(
    sheetName: string,
    spreadsheetId: string
  ): Promise<SheetNameAndSpreadsheetId[]> {
    if (!this.data || !this.data.sheets) {
      return [];
    }

    const updateSheetQueue: SheetNameAndSpreadsheetId[] = [];
    const syncTargetSheets: SheetNameAndSpreadsheetId[] = [];

    updateSheetQueue.push({
      sheetName,
      spreadsheetId,
    });

    while (updateSheetQueue.length > 0) {
      let updateSheetParam = updateSheetQueue.pop();
      if (!updateSheetParam) {
        continue;
      }

      syncTargetSheets.push(updateSheetParam);

      const linkedDataSheets =
        (
          await this.api.listWikiDataSheetLink(this.wikiName, {
            sheetName: updateSheetParam.sheetName,
            spreadsheetId: updateSheetParam.spreadsheetId,
            isInbound: true,
          })
        ).linkSheets || [];

      linkedDataSheets.forEach((linkedDataSheet) => {
        if (
          syncTargetSheets.some(
            (sheet) =>
              linkedDataSheet.linkSheetSpreadsheetId === sheet.spreadsheetId &&
              linkedDataSheet.linkSheetName === sheet.sheetName
          )
        ) {
          return;
        }

        updateSheetQueue.push({
          sheetName: linkedDataSheet.linkSheetName ?? '',
          spreadsheetId: linkedDataSheet.linkSheetSpreadsheetId ?? '',
        });
      });
    }

    return syncTargetSheets;
  }

  async saveDataSheetLink(spreadsheetId: string, sheetName: string) {
    const formulaSheetData = await fetchSpreadsheetSheetByTitle(spreadsheetId || '', sheetName || '', 'FORMULA');

    const sheetFormulaReferences = extractSpreadsheetSheetReference(
      formulaSheetData.result.values ?? [],
      spreadsheetId || ''
    ).filter((reference: { [key: string]: string }) => {
      return (this.data.sheets || []).some(
        (sheet) => sheet.spreadsheetId === reference.spreadsheetId && sheet.sheetName === reference.sheetName
      );
    });

    const storedOutboundLinkDataSheets =
      (
        await this.api.listWikiDataSheetLink(this.wikiName, {
          spreadsheetId: spreadsheetId,
          sheetName: sheetName,
        })
      ).linkSheets || [];

    const saveLinks = sheetFormulaReferences.filter((sheetFormulaReference) => {
      return !storedOutboundLinkDataSheets.some(
        (storedOutboundLinkDataSheet) =>
          storedOutboundLinkDataSheet.spreadsheetId === sheetFormulaReference.spreadsheetId &&
          storedOutboundLinkDataSheet.sheetName === sheetFormulaReference.sheetName
      );
    });

    const saveLinkPromises = saveLinks.map((saveLink) => {
      return this.api.createWikiDataSheetLink(this.wikiName, {
        sheetName: saveLink.sheetName,
        spreadsheetId: saveLink.spreadsheetId,
        linkSheetName: sheetName,
        linkSheetSpreadsheetId: spreadsheetId,
      });
    });

    const deleteLinks = storedOutboundLinkDataSheets.filter((storedOutboundLinkDataSheet) => {
      return !sheetFormulaReferences.some(
        (sheetFormulaReference) =>
          storedOutboundLinkDataSheet.spreadsheetId === sheetFormulaReference.spreadsheetId &&
          storedOutboundLinkDataSheet.sheetName === sheetFormulaReference.sheetName
      );
    });

    await Promise.all(saveLinkPromises);
    deleteLinks.forEach(async (deleteLink) => {
      await this.api.deleteWikiDataSheetLink(this.wikiName, deleteLink.id || '');
    });
  }

  validateSpreadsheetErrorValueInSheet(data: string[][] | undefined, sheetName: string | undefined) {
    if (!data) {
      return;
    }

    function searchErrorValues(cellValue: string): string[] {
      const spreadsheetErrorValues = ['#N/A', '#DIV/0', '#VALUE!', '#ERROR', '#REF'];
      return spreadsheetErrorValues.filter((errorValue: string) => cellValue.includes(errorValue));
    }

    data.forEach((row: string[]) => {
      row.forEach((cellValue: string) => {
        const errorValues = searchErrorValues(cellValue);
        if (errorValues.length > 0) {
          throw new Error(
            `${sheetName ?? 'シート'}にエラー値（${errorValues.join(
              ', '
            )}）が含まれています。エラーを解消してもう一度お試しください。`
          );
        }
      });
    });
  }

  tempSaveChangelogSheet(current: V1WikiDataSheet | null, updated: V1WikiDataSheet | null): void {
    this.tempChangelogSheets.push({
      spreadsheetId: updated?.spreadsheetId ?? current?.spreadsheetId ?? '',
      sheetName: updated?.sheetName ?? current?.sheetName ?? '',
      cellCount: `${countUpdatedCells(
        JSON.parse(current?.sheetData ?? '[[]]'),
        JSON.parse(updated?.sheetData ?? '[[]]')
      )}`,
    });
  }

  async saveWikiDataChangelog(): Promise<void> {
    try {
      if (this.tempChangelogSheets.length > 0) {
        await this.api.createWikiDataChangelog(this.wikiName, {
          sheets: this.tempChangelogSheets,
        });
        this.tempChangelogSheets.length = 0;
      }
    } catch (e) {
      this.setFlashMessage('warning', 'データシート更新履歴の保存に失敗しました');
      console.error(e);
    }
  }

  async deleteDataSheet() {
    if (!this.currentSheet || !this.currentSheet.id) {
      return;
    }

    await this.api.deleteWikiDataSheet(this.wikiName, this.currentSheet.id);
    if (!this.data.sheets || this.selectedSheetIndex === null) {
      return;
    }

    this.tempSaveChangelogSheet(this.data.sheets[this.selectedSheetIndex], null);
    await this.saveWikiDataChangelog();

    this.$delete(this.data.sheets, this.selectedSheetIndex.toString());
    this.setFlashMessage('success', 'データシートの削除に成功しました');
  }

  clean() {
    this.updateData = { sheets: [] };
    this.comparedData = { sheets: [] };
    this.updateSuccessSheets = [];
    this.updateFailureSheets = [];
  }

  get proceeded() {
    return this.updateSuccessSheets.length + this.updateFailureSheets.length;
  }
}
