


































import Component from 'vue-class-component';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';
import { V1WikiAllowedIPAddress } from '@/api-client/generated/models';

@Component({
  name: 'wiki-publish-config',
  components: {
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiAllowedIPAddress extends KamigameVue {
  allowedIPAddress: string = '';
  savedAllowedIPAddress: string = '';

  mounted() {
    this.api.getWikiAllowedIPAddress(this.wikiName).then((r: V1WikiAllowedIPAddress) => {
      this.allowedIPAddress = r.ipAddress || '';
      this.savedAllowedIPAddress = this.allowedIPAddress;
    });
  }

  save() {
    this.api
      .updateWikiAllowedIPAddress(this.wikiName, {
        ipAddress: this.allowedIPAddress,
      })
      .then(() => {
        this.savedAllowedIPAddress = this.allowedIPAddress;
        this.setFlashMessage('success', '変更を保存しました。');
      })
      .catch(() => {
        this.setFlashMessage('danger', '保存に失敗しました。');
      });
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    return this.allowedIPAddress !== this.savedAllowedIPAddress;
  }
}
