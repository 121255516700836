


































































































































import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { RestError } from '@azure/ms-rest-js';
import { Route } from 'vue-router';

import KamigameVue from '@/KamigameVue';
import { Paging } from '@/components';
import { V1WikiPagePartialCollection, V1WikiPagePartial } from '@/api-client/generated/models';
import { convertSearchQueryStringToArray } from '@/service/SearchQueryStringConverter';

@Component({
  name: 'wiki-page-partial-list',
  components: {
    'kamigame-paging': Paging,
  },
})
export default class WikiPagePagePartialList extends KamigameVue {
  dateFormat = format;
  loading = false;

  pagePartials: V1WikiPagePartialCollection = { pagePartials: [] };
  fields = [
    { thStyle: { width: '50%' }, key: 'name', label: '記事部品名' },
    { thStyle: { width: '20%' }, key: 'lastUpdatedAt', label: '最終更新日時' },
    { key: 'creator', label: '作成者' },
    { key: 'lastAuthor', label: '最終更新者' },
    { key: '_operation', label: '操作' },
  ];
  sortConditions = [
    { text: '最終更新日時が新しい順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' } },
    { text: '最終更新日時が古い順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'ASC' } },
    { text: 'タイトル順', value: { sortedBy: 'name', sortOrder: 'ASC' } },
  ];
  perRequestWikiPagePartialNum = 20;
  totalPartialsNum = 0;
  searchString = '';
  selectedSortCondition = { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' };
  kamigamePaging = this.$refs.kamigamePaging as Paging;

  async mounted() {
    this.kamigamePaging = this.$refs.kamigamePaging as Paging;
    this.getWikiPagePartials();
  }

  beforeRouteUpdate(to: Route, from: Route, next: () => void) {
    next();
    this.getWikiPagePartials();
  }

  async getWikiPagePartials(startAt: number = 0) {
    this.loading = true;
    await this.api
      .listWikiPagePartial(this.wikiName, {
        limit: this.perRequestWikiPagePartialNum,
        offset: startAt,
        searchWords: this.searchWords,
        sortedBy: this.selectedSortCondition.sortedBy,
        sortOrder: this.selectedSortCondition.sortOrder,
        isArchived: this.archived,
      })
      .then((res: V1WikiPagePartialCollection) => {
        this.loading = false;
        this.pagePartials.pagePartials = res.pagePartials;
        this.totalPartialsNum = res.numOfTotalPagePartials || 0;
        if (startAt === 0) {
          this.kamigamePaging.resetPaging();
        }
      })
      .catch((e) => {
        if (e instanceof RestError && e.statusCode === 404) {
          this.pagePartials.pagePartials = [];
          this.loading = false;
        }
      });
  }

  changeDataRange(startAt: number) {
    this.getWikiPagePartials(startAt);
  }

  showDeleteModal(index: number) {
    const modal = this.$refs[`deletePagePartial_${index}`] as BModal;
    modal.show();
  }

  async deletePagePartial(pageId: string, index: number) {
    this.api.deleteWikiPagePartial(this.wikiName, pageId).then(() => {
      if (this.pagePartials.pagePartials === undefined) {
        return;
      }

      this.pagePartials.pagePartials.splice(index, 1);
      this.setFlashMessage('success', '記事部品をアーカイブしました。');
    });
  }

  showRestoreModal(index: number) {
    const modal = this.$refs[`restorePagePartial_${index}`] as BModal;
    modal.show();
  }

  async restorePagePartial(pageId: string, index: number) {
    this.api.restoreWikiPagePartial(this.wikiName, pageId).then(() => {
      if (this.pagePartials.pagePartials === undefined) {
        return;
      }

      this.pagePartials.pagePartials.splice(index, 1);
      this.setFlashMessage('success', '記事部品を復元しました。');
    });
  }

  get archived(): boolean {
    return this.route.params.target === 'archived';
  }

  get searchWords() {
    return convertSearchQueryStringToArray(this.searchString);
  }
}
