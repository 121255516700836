var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.modal-translate-article",
              modifiers: { "modal-translate-article": true },
            },
          ],
        },
        [_vm._v("記事翻訳" + _vm._s(_vm.buttonTitleSuffix))]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-translate-article",
            title: "記事翻訳",
            size: "lg",
            "no-close-on-backdrop": "",
            static: "",
          },
          on: { ok: _vm.clickModalOkForTranslation },
        },
        [
          _c("p", [
            _vm._v(
              "最新の日本版の記事を元に翻訳を行います。元の記事が存在しない場合は翻訳できません。"
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("翻訳対象：")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-checkbox",
                {
                  model: {
                    value: _vm.shouldTranslateTitle,
                    callback: function ($$v) {
                      _vm.shouldTranslateTitle = $$v
                    },
                    expression: "shouldTranslateTitle",
                  },
                },
                [_vm._v("タイトル")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-checkbox",
                {
                  model: {
                    value: _vm.shouldTranslateDescription,
                    callback: function ($$v) {
                      _vm.shouldTranslateDescription = $$v
                    },
                    expression: "shouldTranslateDescription",
                  },
                },
                [_vm._v("ディスクリプション")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-checkbox",
                {
                  model: {
                    value: _vm.shouldTranslateBody,
                    callback: function ($$v) {
                      _vm.shouldTranslateBody = $$v
                    },
                    expression: "shouldTranslateBody",
                  },
                },
                [_vm._v("本文")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", [_vm._v("単語シート (列名 - 変換前: old, 変換後: new)：")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("b-form-input", {
                model: {
                  value: _vm.wordSpreadSheetUrl,
                  callback: function ($$v) {
                    _vm.wordSpreadSheetUrl = $$v
                  },
                  expression: "wordSpreadSheetUrl",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "画像シート (列名 - 変換前: Image URL, 変換後: New Image URL)："
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("b-form-input", {
                model: {
                  value: _vm.imageSpreadsheetUrl,
                  callback: function ($$v) {
                    _vm.imageSpreadsheetUrl = $$v
                  },
                  expression: "imageSpreadsheetUrl",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "checkFailedResultModal",
          on: { ok: _vm.saveTranslatedTextToDraft },
        },
        [
          _c("p", [
            _vm._v(
              "\n      以下は翻訳したときに一部のURLや記法が消えてしまっています。その上で下書き保存していい場合はチェックをつけてください。やり直す場合はキャンセルを押して再度翻訳を実行してください。\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-checkbox",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.translatedTitleResult &&
                        _vm.translatedTitleResult.isSuccess === false,
                      expression:
                        "translatedTitleResult && translatedTitleResult.isSuccess === false",
                    },
                  ],
                  model: {
                    value: _vm.shouldSaveTranslatedTitle,
                    callback: function ($$v) {
                      _vm.shouldSaveTranslatedTitle = $$v
                    },
                    expression: "shouldSaveTranslatedTitle",
                  },
                },
                [_vm._v("タイトル")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-checkbox",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.translatedDescriptionResult &&
                        _vm.translatedDescriptionResult.isSuccess === false,
                      expression:
                        "translatedDescriptionResult && translatedDescriptionResult.isSuccess === false",
                    },
                  ],
                  model: {
                    value: _vm.shouldSaveTranslatedDescription,
                    callback: function ($$v) {
                      _vm.shouldSaveTranslatedDescription = $$v
                    },
                    expression: "shouldSaveTranslatedDescription",
                  },
                },
                [_vm._v("ディスクリプション")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-checkbox",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.translatedBodyResult &&
                        _vm.translatedBodyResult.isSuccess === false,
                      expression:
                        "translatedBodyResult && translatedBodyResult.isSuccess === false",
                    },
                  ],
                  model: {
                    value: _vm.shouldSaveTranslatedBody,
                    callback: function ($$v) {
                      _vm.shouldSaveTranslatedBody = $$v
                    },
                    expression: "shouldSaveTranslatedBody",
                  },
                },
                [_vm._v("本文")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "checkDuplicatedWordsModal",
          on: { ok: _vm.clickModalOkForCheckDuplicatedWords },
        },
        [
          _c("p", [
            _vm._v(
              "以下の単語が重複しています。重複している単語を除外して単語変換を実行しますか？"
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "table",
              [
                _c("tr", [
                  _c("th", [_vm._v("変換前")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("変換後")]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.duplicatedWordsForReplacing, function (word) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(word.old))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(word.new))]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }