var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { class: _vm.classList },
    [
      _c("input", {
        staticClass: "switch-input",
        attrs: { type: "checkbox" },
        domProps: { value: _vm.value, checked: _vm.isChecked },
        on: { change: _vm.handleChange },
      }),
      _vm._v(" "),
      _vm.isOn
        ? [
            _c("span", {
              staticClass: "switch-label",
              attrs: { "data-on": _vm.on, "data-off": _vm.off },
            }),
          ]
        : [_c("span", { staticClass: "switch-label" })],
      _vm._v(" "),
      _c("span", { staticClass: "switch-handle" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }