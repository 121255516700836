







import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Pagination } from 'bootstrap-vue/src/index.d.ts';

import KamigameVue from '@/KamigameVue';

@Component({
  name: 'kamigame-paging',
  components: {},
})
export default class Paging extends KamigameVue {
  defaultPerPage = 20;
  perPage = this.defaultPerPage;
  currentPage = 1;

  @Prop()
  parentPerPage!: number;

  @Prop()
  dataLength!: number;

  @Prop()
  fields!: any[];

  mounted() {
    this.perPage = this.parentPerPage || this.defaultPerPage;
  }

  onPaginationNumChanged() {
    this.$emit('change-data-range', (this.currentPage - 1) * this.perPage);
  }

  resetPaging() {
    this.currentPage = 1;
  }
}
