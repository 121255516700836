var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app" }, [
    _c(
      "div",
      { staticClass: "w-50 mr-auto ml-auto" },
      [
        _c(
          "b-button",
          {
            staticClass: "w-100 mr-auto ml-auto",
            on: {
              click: function ($event) {
                return _vm.exportCsv()
              },
            },
          },
          [_vm._v("CSV エクスポート")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [
          _vm._v("全 wiki の情報を取得し、CSV としてダウンロードします。"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }