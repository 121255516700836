























import KamigameVue from '@/KamigameVue';
import { fetchSpreadsheetSheetByTitle } from '@/service/SpreadsheetApi';
import { parseSpreadsheetIdFromUrl } from '@/service/SpreadsheetUrl';
import Component from 'vue-class-component';

import { existsDraft, bulkReplaceWikiPageDrafts } from '@/service/ArticleApi';

import { mapHeaderToColumns } from '@/lib/spreadsheet';

@Component({
  name: 'button-bulk-replace-image-url',
})
export default class ButtonBulkReplaceImageUrl extends KamigameVue {
  spreadsheetUrl: string = '';
  sheetTitle: string = '';

  async bulkReplaceImageUrl() {
    const existsDraftResult = await existsDraft(this.api, this.wikiName);
    if (existsDraftResult) {
      this.setFlashMessage(
        'danger',
        '公開されていない下書きが存在します。全ての下書きを公開/削除してから実行してください。'
      );
      return;
    }

    const spreadsSheetId = parseSpreadsheetIdFromUrl(this.spreadsheetUrl);

    if (!spreadsSheetId) {
      this.setFlashMessage('danger', 'シートIDの取得に失敗しました。');
      return;
    }

    const res = await fetchSpreadsheetSheetByTitle(spreadsSheetId, this.sheetTitle, 'UNFORMATTED_VALUE').catch(
      (e: Error) => {
        return e;
      }
    );

    if (res instanceof Error) {
      console.log(res);
      this.setFlashMessage('danger', 'シートの取得に失敗しました。');
      return;
    }

    const cells = res.result.values;
    console.log(res);
    if (!cells) {
      this.setFlashMessage('danger', 'シート名を間違えているか、シートの値が存在しません。');
      return;
    }

    const headerToColumns = mapHeaderToColumns(cells);
    const oldImageURLs = headerToColumns['Image URL'];
    const newImageURLs = headerToColumns['New Image URL'];

    if (!oldImageURLs || !newImageURLs) {
      this.setFlashMessage('danger', 'シートの列名が正しくありません。');
      return;
    }

    const replacements = oldImageURLs
      .map((oldImageURL, index) => {
        return {
          old: oldImageURL.replace(/[\r|\n|\r\n|\s]+/g, '').split('=')[0],
          new: newImageURLs[index].replace(/[\r|\n|\r\n|\s]+/g, ''),
        };
      })
      .filter((r) => r.old && r.new);

    try {
      await bulkReplaceWikiPageDrafts(this.api, this.wikiName, replacements);
    } catch (e) {
      this.setFlashMessage('danger', '画像の一括更新に失敗しました。エンジニアに連絡してください。');
      console.error(e);
      return;
    }

    this.setFlashMessage('success', '画像の一括更新に成功しました。下書きを確認してください。');
  }
}
