








































































































































































import Component from 'vue-class-component';
import format from 'date-fns/format';
import { RestError } from '@azure/ms-rest-js';

import { Paging } from '@/components';
import {
  V1WikiNav,
  V1ListWikiNavWithWiki,
  V1WikiMemberRequest,
  Permission,
  V1WikiMemberCollection,
} from '@/api-client/generated/models';
import KamigameVue from '@/KamigameVue';
import { convertSearchQueryStringToArray } from '@/service/SearchQueryStringConverter';

@Component({
  name: 'wiki-member-list',
  components: {
    'kamigame-paging': Paging,
  },
})
export default class WikiMemberList extends KamigameVue {
  dateFormat: any;
  permissions = Permission;
  loading = false;

  members: any[] = [];
  membersFields = [
    { key: 'user', label: '名前' },
    { key: 'permission', label: '編集権限' },
    { key: 'lastLoggedInAt', label: '最終ログイン' },
  ];

  memberRequests: any[] = [];
  memberRequestsFields = [
    { key: 'user', label: '参加希望者' },
    { key: 'createdAt', label: '申請日時' },
    { key: 'description', label: '自己 PR' },
    { key: '_operation', label: '操作' },
  ];
  sortConditions = [
    { text: '並び順を選択してください', value: { sortedBy: '', sortOrder: '' }, disabled: true },
    { text: '最終ログイン日時が新しい順', value: { sortedBy: 'lastLoggedInAt', sortOrder: 'DESC' } },
    { text: '最終ログイン日時が古い順', value: { sortedBy: 'lastLoggedInAt', sortOrder: 'ASC' } },
    { text: '名前順', value: { sortedBy: 'nickname', sortOrder: 'ASC' } },
  ];
  perRequestWikiMemberNum = 20;
  totalPageNum = 0;
  searchString = '';
  selectedSortCondition = { sortedBy: '', sortOrder: '' };
  kamigamePaging = this.$refs.kamigamePaging as Paging;

  async mounted() {
    this.dateFormat = format;
    this.kamigamePaging = this.$refs.kamigamePaging as Paging;
    const apiClient = this.api;

    apiClient.listWikiMemberRequest(this.wikiName).then((response) => {
      this.memberRequests = (response.requests || []).map((request) => {
        return {
          request: Object.assign(
            {
              more: false,
              truncatedDescription: (request.description || '').replace(/\s/g, '').slice(0, 20),
            },
            request
          ),
          status: null,
        };
      });
    });

    this.getWikiMembers();
  }

  async getWikiMembers(startAt: number = 0) {
    this.loading = true;
    await this.api
      .listWikiMember(this.wikiName, {
        limit: this.perRequestWikiMemberNum,
        offset: startAt,
        searchWords: this.searchWords,
        sortedBy: this.selectedSortCondition.sortedBy,
        sortOrder: this.selectedSortCondition.sortOrder,
      })
      .then((res: V1WikiMemberCollection) => {
        this.loading = false;
        this.members = (res.members || []).map((member) => {
          let permission = Permission.MEMBER;
          if (member.isAdmin) {
            permission = Permission.ADMIN;
          } else if (member.isSubAdmin) {
            permission = Permission.SUBADMIN;
          }

          return {
            member,
            permission,
          };
        });

        this.totalPageNum = res.numOfTotalMembers || 0;
        if (startAt === 0) {
          this.kamigamePaging.resetPaging();
        }
      })
      .catch((e) => {
        if (e instanceof RestError && e.statusCode === 404) {
          this.members = [];
          this.totalPageNum = 0;
          this.loading = false;
        }
      });
  }

  changeDataRange(startAt: number) {
    this.getWikiMembers(startAt);
  }

  async approve(target: any) {
    this.api
      .approveWikiMemberRequest(this.wikiName, target.request.id)
      .then(() => {
        target.status = 'approved';
        this.setFlashMessage('success', 'メンバー参加申請を承認しました。');
      })
      .catch((e) => {
        this.setFlashMessage('danger', 'エラーが発生しました。');
        console.error(e);
      });
  }

  async reject(target: any) {
    this.api
      .rejectWikiMemberRequest(this.wikiName, target.request.id)
      .then(() => {
        target.status = 'rejected';
        this.setFlashMessage('success', 'メンバー参加申請を拒否しました。');
      })
      .catch((e) => {
        this.setFlashMessage('danger', 'エラーが発生しました。');
      });
  }

  changePermissionConfirm(target: any) {
    if (target.permission === Permission.ADMIN) {
      const modal: any = this.$refs.alertAdminChanging;
      modal.show();
    } else {
      this.changePermission(target);
    }
  }

  async changePermission(target: any) {
    this.api
      .updateWikiMemberPermission(this.wikiName, target.member.user.id, {
        permission: target.permission,
      })
      .then(() => {
        this.getWikiMembers();
        this.setFlashMessage('success', 'メンバーの権限を変更しました。');
      })
      .catch((e) => {
        this.setFlashMessage('danger', 'エラーが発生しました。');
        console.error(e);
      });
  }

  async deleteMember(index: number, target: any) {
    this.api
      .deleteWikiMember(this.wikiName, target.member.user.id)
      .then(() => {
        this.setFlashMessage('success', 'メンバーを削除しました。');
        this.members = this.members.filter((_, k) => k !== index);
      })
      .catch((e) => {
        this.setFlashMessage('danger', 'エラーが発生しました。');
        console.error(e);
      });
  }

  get searchWords() {
    return convertSearchQueryStringToArray(this.searchString);
  }
}
