






import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';

@Component({
  name: 'status_check',
})
export default class StatusCheckPage extends KamigameVue {}
