var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { app: true, dragging: _vm.dragging },
      on: {
        dragover: function ($event) {
          $event.preventDefault()
          _vm.dragging = true
        },
        dragleave: function ($event) {
          $event.preventDefault()
          _vm.dragging = false
        },
        drop: _vm.onDrop,
      },
    },
    [
      _c("AppHeader"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-body" },
        [
          _c("Sidebar", { attrs: { navItems: _vm.nav } }),
          _vm._v(" "),
          _c(
            "main",
            { staticClass: "main" },
            [
              _c("breadcrumb", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isBreadcrumbShowed(),
                    expression: "isBreadcrumbShowed()",
                  },
                ],
                attrs: { list: _vm.list },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container-fluid" },
                [
                  _vm._l(_vm.flashes, function (flashValue, flashKey) {
                    return [
                      _c(
                        "b-alert",
                        {
                          key: flashKey,
                          attrs: {
                            dismissible: "",
                            fade: "",
                            variant: flashValue.variant,
                            show: !!flashValue.variant,
                          },
                        },
                        [
                          _vm._v(_vm._s(flashValue.message) + "\n            "),
                          flashValue.linkLabel && flashValue.linkTo
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: flashValue.linkTo,
                                    target: flashValue.linkNewTab
                                      ? "_blank"
                                      : "_self",
                                  },
                                },
                                [_vm._v(_vm._s(flashValue.linkLabel))]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  }),
                  _vm._v(" "),
                  _c("router-view"),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("AppAside"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }