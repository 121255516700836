














































import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';
import { RestError } from '@azure/ms-rest-js';
import {
  V1WikiMember,
  V1WikiMemberCollection,
  V1User,
  V1WikiPageHistory,
  KamigameWikiApiListWikiPageHistoriesOptionalParams,
} from '@/api-client/generated/models';
import * as JsDiff from 'diff';
import format from 'date-fns/format';
import FileSaver from 'file-saver';
import * as StringConverter from '@/service/StringConverter';
import { calcDiffLines } from '@/service/CalcDiffLines';

type EditType = '作成' | '更新';

type CSVRow = {
  createdAt: string;
  title: string;
  url: string;
  nickname: string;
  editType: EditType;
  diff: number;
};

@Component({
  name: 'work-report',
})
export default class WorkReport extends KamigameVue {
  users: V1User[] = [];

  startDate: string = '';
  endDate: string = '';
  selectedUsers: V1User[] = [];
  selectedUserID: string = '';

  mounted() {
    this.getWikiMembers();
  }

  addUserCondition() {
    if (!this.selectedUserID) {
      return;
    }
    const user = this.users.find(u => u.id === this.selectedUserID);
    if (!user) {
      return;
    }
    this.selectedUsers.push(user);
    this.selectedUserID = '';
  }

  async download() {
    if (this.startDate === '' || this.endDate === '') {
      this.setFlashMessage('danger', 'レポートを出力する日付の範囲を指定してください');
      return;
    }

    const csv = await this.createCSV();
    const blob = new Blob([StringConverter.convertStringToUTF16LEArray(csv)], {
      type: 'text/csv;charset=utf-16',
    });

    FileSaver.saveAs(blob, `${this.wikiName}-work-report-${format(new Date(), 'yyyyMMddHHmmss')}.csv`);

    this.setFlashMessage('success', 'CSV の出力に成功しました！');
  }

  async createCSV() {
    const hisotries = await this.fetchHistories();
    const rows: CSVRow[] = [];

    await Promise.all(
      hisotries.map(async h => {
        let diffLines: number;
        if (!h.diffLines) {
          diffLines = await this.calcDiffFromPrevious(h);
          if (h.id) {
            await this.api.updateWikiPageHistoryDiff(this.wikiName, h.id, { diffLines: `${diffLines}` });
          }
        } else {
          diffLines = parseInt(h.diffLines);
        }

        const editType: EditType = h.parentId ? '更新' : '作成';
        const url = h.wikiPage && h.wikiPage.id ? this.getWikiUrl(h.wikiPage.id) : '';

        const row = {
          createdAt: h.createdAt ? format(h.createdAt, 'yyyy-MM-dd HH:mm') : '',
          title: h.title || '',
          url,
          nickname: (h.author as V1User).nickname || '',
          editType,
          diff: diffLines,
        };

        rows.push(row);
      })
    );

    const csvHeader = '操作日時\tタイトル\tURL\tユーザー名\t操作種別\t変更行\r\n';
    const csv = rows.reduce(
      (acc, row) =>
        acc + `${row.createdAt}\t${row.title}\t${row.url}\t${row.nickname}\t${row.editType}\t${row.diff}\r\n`,
      ''
    );

    return csvHeader + csv;
  }

  async fetchHistories() {
    // できるだけリクエスト回数を減らすため API が許容する最大数で取得する
    const perRequestHistoriesNum = 99;

    const [sYear, sMonth, sDay] = this.startDate.split('-').map(d => parseInt(d, 10));
    const [eYear, eMonth, eDay] = this.endDate.split('-').map(d => parseInt(d, 10));

    const fetch = async (offset: number): Promise<V1WikiPageHistory[]> => {
      const param: KamigameWikiApiListWikiPageHistoriesOptionalParams = {
        limit: perRequestHistoriesNum,
        offset,
        startUnixtime: `${Math.floor(new Date(sYear, sMonth - 1, sDay, 0, 0, 0).getTime() / 1000)}`,
        endUnixtime: `${Math.floor(new Date(eYear, eMonth - 1, eDay, 23, 59, 59).getTime() / 1000)}`,
      };

      if (this.selectedUsers.length > 0) {
        param.userIds = this.selectedUsers.map(u => u.id!);
      }

      const res = await this.api.listWikiPageHistories(this.wikiName, param).catch(e => {
        if (e instanceof RestError && e.statusCode === 404) {
          return null;
        }

        throw e;
      });

      if (!res) {
        return [];
      }

      const total = res.numOfTotalHistories || 0;
      const histories = res.history || [];
      if (total <= perRequestHistoriesNum + offset) {
        return histories;
      }

      const remainder = await fetch(offset + perRequestHistoriesNum);
      return histories.concat(...remainder);
    };

    return await fetch(0);
  }

  async calcDiffFromPrevious(base: V1WikiPageHistory) {
    if (!base.parentId) {
      return calcDiffLines(base.body || '', '');
    }

    const previous = await this.api.getWikiPageHistory(this.wikiName, base.parentId);
    const aBody = base.body || '';
    const bBody = previous.body || '';

    return calcDiffLines(aBody, bBody);
  }

  async getWikiMembers() {
    // できるだけリクエスト回数を減らすため API が許容する最大数で取得する
    const perRequestUsersNum = 99;

    const fetch = async (offset: number): Promise<V1WikiMember[]> => {
      const res = await this.api.listWikiMember(this.wikiName, {
        limit: perRequestUsersNum,
        offset,
      });

      const total = res.numOfTotalMembers || 0;
      if (!res.members) {
        return [];
      }
      const members = res.members;
      if (total <= perRequestUsersNum + offset) {
        return members;
      }

      const remainder = await fetch(offset + perRequestUsersNum);
      return members.concat(...remainder);
    };

    const members = await fetch(0);

    this.users = members.filter(m => !!m.user).map(m => m.user!);
  }

  removeConditonedUser(id: string) {
    const idx = this.selectedUsers.findIndex(u => u.id === id);
    this.selectedUsers.splice(idx, 1);
  }

  getWikiUrl(pageId: string) {
    return `${WIKI_URL_BASE}/${encodeURIComponent(this.wikiName)}/page/${pageId}.html`;
  }

  get userOptions() {
    const selectedUserIDs = this.selectedUsers.map(u => u.id) as string[];
    const options: { value: string; text: string; disabled: boolean }[] = [
      { value: '', text: 'ユーザーを選択してください', disabled: true },
    ].concat(
      Object.entries(this.users)
        .map(([_, u]) => u)
        .filter(u => !!u.id && !selectedUserIDs.includes(u.id))
        .map(u => ({
          value: u.id,
          text: u.nickname,
          disabled: false,
        })) as { value: string; text: string; disabled: boolean }[]
    );
    this.selectedUserID = '';

    return options;
  }
}
