


















































































import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import KamigameVue from '@/KamigameVue';

@Component({
  name: 'wiki-member-invite-list',
})
export default class WikiMemberInviteList extends KamigameVue {
  dateFormat: any;

  fields = [
    { key: 'label', label: 'タイトル' },
    { key: 'url', label: '招待リンク' },
    { key: 'usedCount', label: '利用回数' },
    { key: 'limitCount', label: '利用回数制限' },
    { key: 'expiredAt', label: '有効期限' },
    { key: '_operation', label: '操作' },
  ];
  invites: any[] = [];

  newLabel: string = '';
  newLimitCount: string = '0';
  newExpiredAt: number | null = null;

  lastGeneratedUrl: string = '';

  expiredAtSelections = [
    { value: null, text: '無期限' },
    { value: 24, text: '24 時間' },
    { value: 24 * 2, text: '2 日間' },
    { value: 24 * 3, text: '3 日間' },
    { value: 24 * 4, text: '4 日間' },
    { value: 24 * 5, text: '5 日間' },
    { value: 24 * 6, text: '6 日間' },
    { value: 24 * 7, text: '1 週間' },
  ];

  filterZeroDate(dateTime: Date | undefined): Date | undefined {
    return dateTime && dateTime.getTime() <= 0 ? undefined : dateTime;
  }

  async mounted() {
    this.dateFormat = format;

    const response = await this.api.listWikiMemberInvite(this.wikiName);
    if (response.invites) {
      this.invites = response.invites.map(v => {
        return Object.assign(v, {
          expiredAt: this.filterZeroDate(v.expiredAt),
        });
      });
    }
  }

  async create() {
    this.api
      .createWikiMemberInvite(this.wikiName, {
        label: this.newLabel,
        limitCount: this.newLimitCount,
        expiredAt: this.newExpiredAt ? new Date(Date.now() + this.newExpiredAt * 60 * 60 * 1000) : undefined,
      })
      .then(response => {
        this.newLabel = '';
        this.newLimitCount = '0';
        this.newExpiredAt = null;

        if (response.id) {
          this.lastGeneratedUrl = this.getInviteUrl(response.id);
        }

        this.invites.unshift(
          Object.assign(response, {
            expiredAt: this.filterZeroDate(response.expiredAt),
          })
        );

        this.setFlashMessage('success', '招待リンクを生成しました');
      });
  }

  resetLastGeneratedUrl() {
    this.lastGeneratedUrl = '';
  }

  getInviteUrl(id: string) {
    return `${ADMIN_URL_BASE}/-/invite/${this.wikiName}/${encodeURIComponent(id)}`;
  }

  showDeleteModal(index: number) {
    const modal = this.$refs[`deleteInvite_${index}`] as BModal;
    modal.show();
  }

  async deleteInvite(index: number, target: string) {
    this.api
      .deleteWikiMemberInvite(this.wikiName, target)
      .then(() => {
        this.setFlashMessage('success', '招待リンクを削除しました。');
        this.invites = this.invites.filter((_, k) => k !== index);
      })
      .catch(e => {
        this.setFlashMessage('danger', 'エラーが発生しました。');
      });
  }
}
