import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Mapper } from '@azure/ms-rest-js';

import { KamigameWikiApi } from '@/api-client/generated/kamigameWikiApi';
import { createApiClientWithToken } from '@/service/WikiAPIClientFactory';
import { KamigameCommentApiClient } from './comment-api-client/KamigameCommentAPIClient';
import { createCommentApiClient } from './service/CommentAPIClientFactory';
import { V1Wiki } from './api-client/generated/models';
import { DEFAULT_DOCUMENT_TITLE } from './constants';

Component.registerHooks([
  // for vue-router
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

@Component
export default class KamigameVue extends Vue {
  beforeUpdate() {
    this.setDocumentTitle(this.routeDocumentTitle);
  }

  get api(): KamigameWikiApi {
    return createApiClientWithToken(this.$store.getters.sessionId || 'anonymous');
  }

  get user() {
    return this.$store.state.user;
  }

  get flashes() {
    return this.$store.state.flashes;
  }

  get commentApi(): KamigameCommentApiClient {
    return createCommentApiClient();
  }

  validate(mapper: Mapper, value: any, fieldName: string, required: boolean): string | void {
    if ((!value || !value.trim()) && required) {
      return '必須項目です';
    }

    if (mapper.constraints && value !== undefined) {
      const {
        ExclusiveMaximum,
        ExclusiveMinimum,
        InclusiveMaximum,
        InclusiveMinimum,
        MaxItems,
        MaxLength,
        MinItems,
        MinLength,
        MultipleOf,
        Pattern,
        UniqueItems,
      } = mapper.constraints;
      if (ExclusiveMaximum !== undefined && value >= ExclusiveMaximum) {
        return `${fieldName}の値は${ExclusiveMaximum}未満である必要があります`;
      }
      if (ExclusiveMinimum !== undefined && value <= ExclusiveMinimum) {
        return `${fieldName}の値は${ExclusiveMinimum}よりも大きい必要があります`;
      }
      if (InclusiveMaximum !== undefined && value > InclusiveMaximum) {
        return `${fieldName}の値は${InclusiveMaximum}以下である必要があります`;
      }
      if (InclusiveMinimum !== undefined && value < InclusiveMinimum) {
        return `${fieldName}の値は${InclusiveMinimum}以上である必要があります`;
      }
      if (MaxItems !== undefined && value.length > MaxItems) {
        return `${fieldName}の数は${MaxItems}未満である必要があります`;
      }
      if (MaxLength !== undefined && value.length > MaxLength) {
        return `${fieldName}の長さは${MaxLength}未満である必要があります`;
      }
      if (MinItems !== undefined && value.length < MinItems) {
        return `${fieldName}の数は${MinItems}よりも多い必要があります`;
      }
      if (MinLength !== undefined && value.length < MinLength) {
        return `${fieldName}の長さは${MinLength}よりも大きい必要があります`;
      }
      if (MultipleOf !== undefined && value % MultipleOf !== 0) {
        return `${fieldName}の数は${MultipleOf}の倍数である必要があります`;
      }
      if (Pattern && value.match(Pattern) === null) {
        return `入力された${fieldName}の形式が正しくありません`;
      }
      if (UniqueItems && value.some((item: any, i: number, ar: Array<any>) => ar.indexOf(item) !== i)) {
        return `${fieldName}の値が重複しています`;
      }
    }
  }

  setFlashMessage(
    variant: string,
    message: string,
    linkTo?: string,
    linkLabel?: string,
    linkNewTab?: boolean,
    flashMillisecond: number = 5000
  ): number {
    window.scrollTo(0, 0);
    const flashId = window.setTimeout(() => {
      this.clearFlashMessage(flashId);
    }, flashMillisecond);
    this.$store.commit('setFlashMessage', { flashId, variant, message, linkTo, linkLabel, linkNewTab });
    return flashId;
  }

  clearFlashMessage(flashId: number) {
    this.$store.commit('clearFlashMessage', flashId);
    window.clearTimeout(flashId);
  }

  clearAllFlashMessages() {
    this.$store.commit('clearAllFlashMessages');
  }

  setDocumentTitle(documentTitle: string) {
    this.$store.commit('setDocumentTitle', documentTitle);
  }

  get route() {
    return this.$route;
  }

  get router() {
    return this.$router;
  }

  get wikiName(): string {
    return this.route.params.name || '';
  }

  get wikiTopUrl(): string {
    return `${WIKI_URL_BASE}/${this.wikiName}`;
  }

  get wikiId(): Promise<string> {
    return (this.$store.getters.getWiki(this.wikiName) as Promise<V1Wiki>).then((wiki) => wiki.id as string);
  }

  get routeDocumentTitle(): string {
    if (this.route.meta.lazyLabel) {
      return `${this.route.meta.lazyLabel(this.route)} - ${DEFAULT_DOCUMENT_TITLE}`;
    }
    if (!this.route.meta || !this.route.meta.label) {
      return DEFAULT_DOCUMENT_TITLE;
    }

    return `${this.route.meta.label} - ${DEFAULT_DOCUMENT_TITLE}`;
  }
}
