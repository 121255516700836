import { replaceBlocks, restoreBlocks } from '@/lib/replace/replaceBlock';
import { getMarkdownChunks } from '@/lib/translator/gpt/util';
import { KamigameWikiApi } from '@/api-client/generated/kamigameWikiApi';

const MODEL_NAME = 'gpt-3.5-turbo-0125';
const MODEL_TEMPERATURE = 0.2; // NOTE: 結果がイマイチだった時にやり直して別の結果が出るようにしたいので少し遊びを持たせる

export const gptMarkdownTranslator = async (
  api: KamigameWikiApi,
  wikiName: string,
  mdText: string,
  systemInstruction: string,
  userInstruction: string
) => {
  const replacedBlocksResult = replaceBlocks(mdText);

  const mdChunks = await getMarkdownChunks(replacedBlocksResult.replacedText);

  const mdMessages = mdChunks.map((chunk) => ({ role: 'user', content: chunk }));
  const messages = mdMessages.map((m) => [
    { role: 'system', content: systemInstruction },
    { role: 'user', content: userInstruction },
    m,
  ]);

  const promises = messages.map(
    async (m) =>
      await api.getChatGptCompletions(wikiName, {
        model: MODEL_NAME,
        messages: m,
        temperature: MODEL_TEMPERATURE,
        wikiName,
      })
  );

  const results = await Promise.all(promises);
  const text = results.map((r) => r.content).join('\n');

  const restored = restoreBlocks(text, replacedBlocksResult.blocks);

  return { translatedText: restored.result, isSuccess: restored.isSuccess };
};
