




























































import Component from 'vue-class-component';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';
import { V1Wiki, V1WikiMeta } from '@/api-client/generated/models';

@Component({
  name: 'wiki-base-config',
  components: {
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiBaseConfig extends KamigameVue {
  name: string = '';
  title: string = '';
  description: string = '';
  pageTitlePrefix: string = '';
  titleSuffix: string = '';
  teamName: string = '';
  teamSnsUrl: string = '';
  wiki: V1Wiki = {};
  savedName: string = '';
  savedTitle: string = '';
  savedDescription: string = '';
  savedPageTitlePrefix: string = '';
  savedTitleSuffix: string = '';
  savedTeamName: string = '';
  savedTeamSnsUrl: string = '';

  mounted() {
    this.$store.getters.getWiki(this.wikiName).then((wiki: V1Wiki) => {
      this.wiki = wiki;
      this.name = wiki.name || '';
      this.title = wiki.title || '';
      this.description = wiki.description || '';
      this.titleSuffix = wiki.titleSuffix || '';
      this.teamName = wiki.teamName || '';
      this.teamSnsUrl = wiki.teamSNSURL || '';

      if (wiki.meta) {
        const pageTitlePrefixConfig = wiki.meta.filter((v) => v.name === 'page-title-prefix').shift();
        if (pageTitlePrefixConfig) {
          this.pageTitlePrefix = pageTitlePrefixConfig.value || '';
        }
      }

      this.updateSavedContentState();
    });
  }

  async save() {
    if (this.pageTitlePrefix.includes('【') || this.pageTitlePrefix.includes('】')) {
      this.setFlashMessage('danger', 'タイトルキーワードに 【 または 】 を含めることはできません');
      return;
    }
    this.api
      .updateWiki(this.name, {
        title: this.title,
        description: this.description,
        titleSuffix: this.titleSuffix,
        teamName: this.teamName,
        teamSNSURL: this.teamSnsUrl,
      })
      .then((wiki: V1Wiki) => {
        this.wiki.title = wiki.title;
        this.wiki.description = wiki.description;
        this.wiki.titleSuffix = wiki.titleSuffix;
        this.wiki.teamName = wiki.teamName;
        this.wiki.teamSNSURL = wiki.teamSNSURL;
        return this.api.updateWikiMeta(this.name, 'page-title-prefix', { value: this.pageTitlePrefix });
      })
      .then((wikiMetaPageTitlePrefix: V1WikiMeta) => {
        this.wiki.meta = (this.wiki.meta || []).map((wikiMeta: V1WikiMeta) => {
          if (wikiMeta.name === 'page-title-prefix') {
            return Object.assign(wikiMeta, wikiMetaPageTitlePrefix);
          } else {
            return wikiMeta;
          }
        });

        this.updateSavedContentState();

        this.$store.commit('setWiki', { wiki: this.wiki });

        this.setFlashMessage('success', '変更を保存しました。', this.wikiTopUrl, 'サイト上で内容を確認する', true);
      });
  }

  updateSavedContentState() {
    this.savedName = this.name;
    this.savedTitle = this.title;
    this.savedDescription = this.description;
    this.savedPageTitlePrefix = this.pageTitlePrefix;
    this.savedTitleSuffix = this.titleSuffix;
    this.savedTeamName = this.teamName;
    this.savedTeamSnsUrl = this.teamSnsUrl;
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    return (
      this.title !== this.savedTitle ||
      this.description !== this.savedDescription ||
      this.pageTitlePrefix !== this.savedPageTitlePrefix ||
      this.titleSuffix !== this.savedTitleSuffix ||
      this.teamName !== this.savedTeamName ||
      this.teamSnsUrl !== this.savedTeamSnsUrl
    );
  }
}
